import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormGroup, Row, Button, Label } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import { required } from 'util/Validators';

const propTypes = {
  contacts: PropTypes.array,
  onPreview: PropTypes.func,
  formFieldKey: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string
};

const NoticeFields = ({
  contacts,
  onPreview,
  formFieldKey,
  label = 'Recipients',
  buttonText = 'Preview Notice'
}) => {
  const handleClick = () => onPreview();
  return (
    <Row>
      <FormGroup className="col-md-4">
        <Field
          name={`${formFieldKey}.contactIds`}
          label={label}
          component={SearchableSelect}
          placeholder="Select contacts"
          options={contacts}
          valueKey="id"
          labelKey="name"
          multi
          simpleValue
          validate={required}
        />
      </FormGroup>
      <FormGroup className="col-md-3">
        <Label>&nbsp;</Label>
        <div>
          <Button color="secondary" onClick={handleClick}>
            {buttonText}
          </Button>
        </div>
      </FormGroup>
    </Row>
  );
};

NoticeFields.propTypes = propTypes;

export default NoticeFields;
