import React from 'react';
import PropTypes from 'prop-types';
import { Band } from 'components/layout';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const propTypes = {
  options: PropTypes.object,
  areas: PropTypes.array
};

const AreasListTable = ({ areas, options }) => {
  return (
    <Band dark>
      <div className="table-wrap">
        <BootstrapTable
          bodyContainerClass="px-2"
          className=""
          trClassName="app-table-row"
          version="4"
          data={areas}
          bordered={false}
          remote
          options={options}
        >
          <TableHeaderColumn
            isKey
            className="app-table-header"
            columnClassName="keyColumn"
            dataField="id"
            hidden
          />
          <TableHeaderColumn
            className="app-table-header"
            dataField="displayName"
            width="20%"
          >
            Name
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </Band>
  );
};

AreasListTable.propTypes = propTypes;

export default AreasListTable;
