import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Container, FormGroup, Button } from 'reactstrap';

import { jobDetailsForm } from '../../constants/FormConstants';
import { integer, required, requiredTrue } from '../../util/Validators';
import * as Validators from '../../util/Validators';
// import { disclaimerReceived } from '../../util/JobDetailsValidators';
import {
  isImportAndContainer,
  isExportAndContainer,
  isImportJobType,
  isExportAndLogs,
  isMBLogsInHold,
  isBreakBulkJobType,
  isImportOrExportContainerAndMethylBromide,
  isImportOrExportAndContainer,
  isImportAndHeatTreatment
} from '../../util/JobTypeHelper';
import { minZero } from '../../util/NormalizingHelper';
import { userIsCustomer } from '../../util/AdGroupsHelper';
import JobTypeIds from '../../constants/JobTypeIdConstants';

import { Band } from '../layout';
import ToggleButton from '../layout/ToggleButton';
import TechnicianSection from '../jobdetails/TechnicianSection';

import RenderField from '../fields/RenderField';
import CheckboxField from '../fields/CheckboxField';
import CustomTagSelect from '../fields/CustomTagSelect';
import SearchableSelect from '../fields/SearchableSelect';
import MarkingsFieldArray from '../fields/MarkingsFieldArray';

import JobDetailsForm from './JobDetailsForm';

import styles from './TreatmentTypeDetails.module.scss';
import {
  isAlPVessel,
  isWaterBlasting,
  isVaporPhos,
  isAlPNonVessel,
  isGasFree,
  isHydrogenCyanide
} from '../../util/TreatmentTypeHelper';
import { movements,travelCost } from '../../constants/ListConstants';

const propTypes = {
  jobType: PropTypes.object,
  containerTypes: PropTypes.array,
  sizeOfContainers: PropTypes.array,
  jobCategories: PropTypes.array,
  areas: PropTypes.array,
  markings: PropTypes.array,
  destinations: PropTypes.array,
  commodities: PropTypes.array,
  actions: PropTypes.object,
  customerCompleted: PropTypes.bool,
  treatmentTypeDetails: PropTypes.object,
  customerCompletedChecked: PropTypes.bool,
};

class TreatmentTypeDetails extends Component {
  state = {
    isDisclaimerRequired: false,
    containerType: '',
    isBACCAttached: false,
  };

  componentDidMount = () => {
    const { treatmentTypeDetails } = this.props;
    if (treatmentTypeDetails) {
      this.changeCommodityType(treatmentTypeDetails.commodity);
    }
  };

  getContainerFromID(id) {
    if (id === 'a729f04c-ce8f-4d76-81b7-f032de8a9519') {
      return 'Not Applicable';
    }
    if (id === 'ac5e0e4f-7396-49c6-99d5-5df4d5c8e2f8') {
      return 'Flat Rack';
    }
    if (id === '9d61c23d-c869-4ab0-978e-55718553d80a') {
      return 'Sea Base';
    }
    if (id === '487276fe-3d4d-4ab0-b31e-9963e045013b') {
      return 'Container';
    }
    if (id === 'cb4e7938-e3d1-47f7-b9d8-e3669811a26f') {
      return 'High Cube';
    }
    if (id === '859f3a2f-0750-437c-a0a0-58e638d4a7b2') {
      return 'Iso-base';
    }
    if (id === 'f7851638-1937-454a-a39d-cc2e509317f0') {
      return 'open Top';
    }
    if (id === '2a210cc4-68e1-4014-bf91-edb4e8d0fe44') {
      return 'Break Bulk';
    }
    if (id === '4542993e-ea90-4d7e-b551-4415b0521a75') {
      return 'Pallets';
    }
    if (id === '3f6ebdda-59fd-4508-ada5-062d96a89de5') {
      return 'Standard';
    } else {
      return 'Nothing';
    }
  }

  changeCommodityType = (commodityName) => {
    const { commodities } = this.props;

    const getSelectedCommodity = commodities.find(
      (commodity) => commodityName === commodity.displayName,
    );

    const doesCommodityRequireDisclaimer =
      getSelectedCommodity && getSelectedCommodity.disclaimerRequired;
    if (doesCommodityRequireDisclaimer !== this.state.isDisclaimerRequired) {
      this.setState({
        isDisclaimerRequired: doesCommodityRequireDisclaimer,
      });
    }
    this.setState({ commodity: getSelectedCommodity });
  };

  //removes or sets logs in hold from the the commoditied list depending on the job
  getCommodityList(jobType, commoditiesOld, treatmentId) {
    let commoditiesNoLogsInHold = [];
    let name = '';
    if (jobType.id !== JobTypeIds.logsInHold) {
      for (let i = 0; i < commoditiesOld.length; i++) {
        name = commoditiesOld[i].displayName;
        if (name !== 'Logs in hold') {
          commoditiesNoLogsInHold.push(commoditiesOld[i]);
        }
      }
    } else {
      for (let i = 0; i < commoditiesOld.length; i++) {
        name = commoditiesOld[i].displayName;
        if (name === 'Logs in hold') {
          commoditiesNoLogsInHold.push(commoditiesOld[i]);
        }
      }
    }
    return commoditiesNoLogsInHold;
  }

  handleContainerType = (selectedType) => {
    const arr = [];
    Object.keys(selectedType).forEach((key) =>
      arr.push({ value: selectedType[key] }),
    );
    let st = '';
    for (let i = 0; i < arr.length - 1; i++) {
      st = st + arr[i].value;
    }
    this.setState({ containerType: this.getContainerFromID(st) });
  };

  handlePopulate = (value) => {
    let jobDescription = '';
    const { actions } = this.props;
    const containerType = this.getContainerFromID(this.state.containerType);
    this.state.containerType === ''
      ? actions.change(
          jobDetailsForm,
          'treatmentTypeDetails.jobDescription',
          this.getContainerFromID(
            this.props.treatmentTypeDetails.containerTypeId,
          ),
        )
      : actions.change(
          jobDetailsForm,
          'treatmentTypeDetails.jobDescription',
          this.state.containerType,
        );
  };

  render() {
    const {
      jobType,
      containerTypes,
      sizeOfContainers,
      jobCategories,
      destinations,
      commodities,
      customerCompletedChecked,
      status,
      stopCredit,
      treatmentId,
    } = this.props;
    const customerCompleted =
      this.props.customerCompleted !== null
        ? this.props.customerCompleted
        : false;

    const isContainer =
      isImportAndContainer(jobType) || isExportAndContainer(jobType);

    const newCom = this.getCommodityList(jobType, commodities, treatmentId);
    return (
      <Band dark>
        <section className="form-section">
          <Container>
            <h4>Treatment Type</h4>
            <div className="form-row align-items-center">
              {isImportAndHeatTreatment(jobType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentTypeDetails.categoryId"
                    label="Category"
                    component={SearchableSelect}
                    disabled={status === 'Inactive' || stopCredit === true}
                    options={jobCategories}
                    type="text"
                  />
                </FormGroup>
              ) : null}
              {!isExportAndLogs(jobType) &&
              !isMBLogsInHold(jobType) &&
              !isWaterBlasting(treatmentId) &&
              !isAlPVessel(treatmentId) &&
              !isVaporPhos(treatmentId) &&
              !isGasFree(treatmentId)? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentTypeDetails.sizeOfContainerId"
                    label="Size Of Container"
                    component={SearchableSelect}
                    disabled={status === 'Inactive' || stopCredit === true}
                    options={sizeOfContainers}
                  />
                </FormGroup>
              ) : null}
              {!isExportAndLogs(jobType) &&
              !isMBLogsInHold(jobType) &&
              !isWaterBlasting(treatmentId) &&
              !isAlPVessel(treatmentId) &&
              !isGasFree(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentTypeDetails.numberOfContainers"
                    label="Number Of Containers"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    validate={integer}
                    normalize={minZero}
                  />
                </FormGroup>
              ) : null}

              <FormGroup className="col-md-4">
                <Field
                  name="treatmentTypeDetails.destination"
                  label="Destination *"
                  component={CustomTagSelect}
                  options={
                    isWaterBlasting(treatmentId)
                      ? ['Water Blasting']
                      : destinations && destinations.length > 0
                      ? destinations
                      : ['New Zealand']
                  }
                  multi={false}
                  validate={required}
                  value={'Water Balsting'}
                />
              </FormGroup>
              {!isGasFree(treatmentId)?(
              <FormGroup className="col-md-4">
                <Field
                  name="treatmentTypeDetails.commodity"
                  label="Commodity"
                  component={SearchableSelect}
                  options={newCom}
                  disabled={status === 'Inactive'}
                  onSelect={this.changeCommodityType}
                  valueKey="displayName"
                  labelKey="displayName"
                  type="text"
                  multi={false}
                />
              </FormGroup>
              ):null}

<FormGroup className="col-md-2">
                <Field
                  name="treatmentTypeDetails.movement"
                  label="Movement Fees"
                  component={SearchableSelect}
                  options={movements}
                  disabled={status === 'Inactive'}
                 // onSelect={this.changeCommodityType}
                  valueKey="name"
                  labelKey="name"
                  type="text"
                  multi={false}
                />
              </FormGroup>
              <FormGroup className="col-md-4" />
              {isExportAndLogs(jobType) ? (
                <FormGroup className="col-md-4" />
              ) : null}
              {isBreakBulkJobType(jobType) &&
              !isWaterBlasting(treatmentId) &&
              !isAlPVessel(treatmentId) &&
              !isVaporPhos(treatmentId) && !isGasFree(treatmentId)? (
                <FormGroup className="col-lg-4">
                  <Field
                    name="jas"
                    label={'Client Vol M3'}
                    component={RenderField}
                    type="number"
                    placeholder="Enter Client Vol"
                    validate={
                      isBreakBulkJobType(jobType)
                        ? requiredTrue
                        : undefined && Validators.number
                    }
                    normalize={minZero}
                  />
                </FormGroup>
              ) : null}
              {!isExportAndLogs(jobType) &&
              !isWaterBlasting(treatmentId) &&
              !isAlPVessel(treatmentId) &&
              !isVaporPhos(treatmentId) &&
              !isGasFree(treatmentId)? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentTypeDetails.disclaimerReceived"
                    label={
                      this.state.isDisclaimerRequired
                        ? 'Disclaimer Received*'
                        : 'Disclaimer Received'
                    }
                    component={CheckboxField}
                    disabled={status === 'Inactive' || stopCredit === true}
                    validate={
                      this.state.isDisclaimerRequired ? requiredTrue : undefined
                    }
                  />
                </FormGroup>
              ) : null}
              {(isImportOrExportContainerAndMethylBromide(jobType,treatmentId))&&
              !isWaterBlasting(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentTypeDetails.covered"
                    label="Covered"
                    disabled={status === 'Inactive' || stopCredit === true}
                    component={CheckboxField}
                  />
                </FormGroup>
              ) : null}
            </div>
            {status !== 'Inactive' &&
            stopCredit !== true &&
            !isWaterBlasting(treatmentId) ? (
              <FieldArray
                name="treatmentTypeDetails.markings"
                label="Markings"
                component={MarkingsFieldArray}
                jobType={jobType}
              />
            ) : null}
            {!isWaterBlasting(treatmentId) ? (
              <FormGroup className="col-md-12">
                <Field
                  name="treatmentTypeDetails.jobDescription"
                  label="Job Description"
                  component={RenderField}
                  readOnly={status === 'Inactive' || stopCredit === true}
                  type="textarea"
                />
              </FormGroup>
            ) : null}

            {userIsCustomer() & isImportJobType(jobType) &&
            customerCompletedChecked ? (
              <Field
                name="BACCAttached"
                label="BACC Attached as required"
                disabled={status === 'Inactive' || stopCredit === true}
                validate={requiredTrue}
                component={CheckboxField}
              />
            ) : null}
            <h6>Select below when job is complete and ready for processing.</h6>
            <Field
              name="customerCompleted"
              label="Customer completed"
              disabled={status === 'Inactive' || stopCredit === true}
              component={CheckboxField}
            />
            {userIsCustomer()?null:
            <Field className="col-md-3"
              name="splitCost"
              label="Split Cost"
              disabled={status === 'Inactive' || stopCredit === true}
              component={CheckboxField}
            />}
            {userIsCustomer()?null:
            <FormGroup className="col-md-4">
                  <Field
                    name="travelZone"
                    label="Travel Zone"
                    placeholder="Select Travel Zone"
                    component={SearchableSelect}
                    options={travelCost}
                    valueKey="name"
                    labelKey="name"
                    onChange={this.handleContainerType}
                  />
                </FormGroup>
            }
          </Container>
        </section>
      </Band>
    );
  }
}

TreatmentTypeDetails.propTypes = propTypes;

export default TreatmentTypeDetails;
