import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container } from 'reactstrap';
import SingleFileUploadField from 'components/fields/SingleFileUploadField';
import ImageAttachment from 'components/fields/ImageAttachment';
import { newSignature } from 'util/TechnicianValidators';

const propTypes = {
  signature: PropTypes.object
};

const TechnicianSignatureSection = ({ signature }) => (
  <section className="form-section">
    <Container>
      <h4>Signature</h4>
      {signature && !signature.removed ? (
        <Field name="signature" component={ImageAttachment} />
      ) : (
        <Field
          name="newSignature"
          component={SingleFileUploadField}
          multiple={false}
          accept=".jpeg,.jpg,.png"
          validate={newSignature}
        />
      )}
    </Container>
  </section>
);

TechnicianSignatureSection.propTypes = propTypes;

export default TechnicianSignatureSection;
