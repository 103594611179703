import React from 'react';
import PropTypes from 'prop-types';
import * as areaHelper from 'util/AreaHelper';
import { Band } from 'components/layout';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const propTypes = {
  vessels: PropTypes.array,
  options: PropTypes.object,
  areas: PropTypes.array,
};

const VesselsListTable = ({ vessels, options }) => {
  return (
    <Band dark>
      <div className="table-wrap">
        <BootstrapTable
          bodyContainerClass="px-2"
          className=""
          trClassName="app-table-row"
          version="4"
          data={vessels}
          bordered={false}
          remote
          options={options}
        >
          <TableHeaderColumn
            isKey
            className="app-table-header"
            columnClassName="keyColumn"
            dataField="id"
            hidden
          />
          <TableHeaderColumn
            className="app-table-header"
            dataField="displayName"
            width="80%"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            dataField="active"
            hidden
          >
            Email
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            dataField="ordinal"
            hidden
          >
            Ordinal
          </TableHeaderColumn>

          <TableHeaderColumn
            className="app-table-header"
            columnClassName="mobileToggleColumn hidden-sm hidden-md hidden-lg"
          />
        </BootstrapTable>
      </div>
    </Band>
  );
};

VesselsListTable.propTypes = propTypes;

export default VesselsListTable;
