import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

import Logger from '../../../util/Logger';
import { getTechnicianDisplayName,getContainerNumber, getCoveredTechInRow,getTechnicianUncoveredInRow  } from '../../../util/JobHelper';
import {
  isExportAndLogs,
  isBreakBulkJobType,
  isMBLogsInHold,
  isImportAndContainer,
  isImportOrExportAndContainer,
  isExportAndContainer,
  isExportOtherMBApples,
  isImportOrExportContainerAndMethylBromide,
} from '../../../util/JobTypeHelper';
import   {isAlPNonVessel, isEthylFormate,isMethylBromide} from '../../../util/TreatmentTypeHelper'
import { roundToPrevious } from '../../../util/DateHelper';
import { confirmAlert } from '../../../util/Alerts';
import * as operationsApi from '../../../api/operationsApi';
import { userIsCustomer } from '../../../util/AdGroupsHelper';
import Checkbubble from '../../common/Checkbubble';
import ToggleButton from '../../layout/ToggleButton';

import TreatmentResultRecordsModal from './TreatmentResultRecordsModal';

const propTypes = {
  actions: PropTypes.object,
  treatmentResultRecords: PropTypes.array.isRequired,
  technicians: PropTypes.array.isRequired,
  jobType: PropTypes.object.isRequired,
  currentJob: PropTypes.object.isRequired,
  //jobTypeImageUrl: PropTypes.string.isRequired,
  isTreatmentResultModalDirty: PropTypes.bool,
};

class TreatmentResultRecordsList extends Component {
  state = {
    selectedTreatmentResultRecord: null,
  };

  toggleShowTreatmentResultModal = (TreatmentResultRecord) => {
    const { showTreatmentResultModal } = this.state;
    this.setState({
      showTreatmentResultModal: !showTreatmentResultModal,
      selectedTreatmentResultRecord: TreatmentResultRecord,
    });
  };

  closeModals = () => {
    if (this.props.isTreatmentResultModalDirty) {
      confirmAlert(
        'Confirm',
        'You have unsaved changes. Are you sure you want to exit?',
      ).then((confirmed) => {
        if (confirmed) {
          this.setState({
            showTreatmentResultModal: false,
          });
        }
      });
    } else {
      this.setState({
        showTreatmentResultModal: false,
      });
    }
    this.getJob({ jobId: this.props.currentJob.id });
  };

  handleSubmission = (formValues) => {
    console.log('handleSubmission-here',formValues)
    const { treatmentResultRecords, jobType } = this.props;
    const saveAction = formValues.id
      ? operationsApi.updateTreatmentResultRecord
      : operationsApi.createNewTreatmentResultRecord;

    //Check for duplicate row/stack number in other treatment results
    const {currentJob} = this.props;
    const isCoveredContainer = currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null;
    
    const rowAndStackValidation = this.validateRowAndStackNumbers(
      formValues,
      treatmentResultRecords,
      jobType,
    )


    if (rowAndStackValidation.showConfirm) {
      confirmAlert(
        'Duplicate Values',
        rowAndStackValidation.confirmMessage,
      ).then(
        (confirm) => confirm && this.performSaveAction(saveAction, formValues),
      );
      return;
    }

    const monitoringValidation = this.validateMonitoringReadingsAgainstStd(
      formValues,
    );

    if (monitoringValidation.showConfirm && !isCoveredContainer) {
      confirmAlert('Confirm', monitoringValidation.confirmMessage).then(
        (confirm) => confirm && this.performSaveAction(saveAction, formValues),
      );
      return;
    }

    this.performSaveAction(saveAction, formValues);
  };

  validateRowAndStackNumbers = (
    formValues,
    treatmentResultRecords,
    jobType,
    isCoveredContainer
  ) => {
    const filteredTreatmentResults = treatmentResultRecords.filter(
      (tr) => tr.id !== formValues.id,
    );

    if (isExportAndLogs(jobType)) {
      if (formValues.yardNumber) {
        const yardNumbers = filteredTreatmentResults
          .filter((e) => e !== undefined)
          .map((e) => e.yardNumber);

        if (yardNumbers.indexOf(formValues.yardNumber) !== -1) {
          return {
            showConfirm: true,
            confirmMessage: `The yard number '${formValues.yardNumber}' is already in use on this job. Are you sure you want to use this yard number?`,
          };
        }
      }
    } else if (isBreakBulkJobType(jobType) && formValues.stackNumber) {
      const stackNumbers = filteredTreatmentResults
        .filter((e) => e !== undefined)
        .map((e) => e.stackNumber);

      return {
        showConfirm: stackNumbers.indexOf(formValues.stackNumber) !== -1,
        confirmMessage: `The stack number '${formValues.stackNumber}' is already in use on this job. Are you sure you want to use this stack number?`,
      };
    }

    return {
      showConfirm: false,
      confirmMessage: null,
    };
  };

  validateMonitoringReadingsAgainstStd = (formValues) => {
    const readings = formValues.concentrationReadingItem || [];
    const warn = readings.some((row) => {
      return (
        Boolean(+row.stdgramPerCubicMetre) &&
        !+row.topupDose &&
        [1, 2, 3].some((i) => {
          const value = +row[`reading${i}`];
          return value && value < +row.stdgramPerCubicMetre;
        })
      );
    });
  
    return {
      showConfirm: warn,
      confirmMessage: warn
        ? 'Treatment standards have not been meet do you wish to proceed'
        : null,
    }};;


  performSaveAction = (saveAction, formValues) => {
    saveAction(formValues)
      .then((response) => Logger.success('Treatment Record Saved'))
      .then(() => this.getJob(formValues));
  };

  deleteTreatmentResultRecord = (record) => {
    const { currentJob } = this.props;
    confirmAlert('Delete', 'Are you sure you want to delete this record?').then(
      (confirm) =>
        confirm &&
        operationsApi
          .deleteTreatmentResultsRecord(record.id)
          .then(() => this.getJob({ jobId: currentJob.id })),
    );
  };

  getJob = (formValues) => {
    const { actions } = this.props;
    actions.getJob(formValues.jobId).then((response) => {
      this.setState({
        showTreatmentResultModal: false,
        selectedTreatmentResultRecord: null,
      });
    });
  };

  render() {
    const {
      treatmentResultRecords,
      jobTypeImageUrl,
      jobType,
      technicians,
      currentJob,
      clientDetails,
    } = this.props;
    const commodity = currentJob.treatmentTypeDetails.commodity;
    const standardId = currentJob.treatmentDetails.standardId;
    const treatmentTypeId = currentJob.treatmentDetails.treatmentTypeId;
    const isAppleMB = isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)
  
    const {
      showTreatmentResultModal,
      selectedTreatmentResultRecord,
    } = this.state;
    var status = "Active"
    if( clientDetails != null && clientDetails !== undefined){
       status = clientDetails.status;
    }
   // var treatmentResultsRecordsCheck = treatmentResultRecords;
  var  treatmentResultsRecordsCheck = isImportOrExportContainerAndMethylBromide(jobType,currentJob.treatmentDetails.treatmentTypeId)?
   treatmentResultRecords.sort((a,b)=>moment(a.startDate) - moment(b.startDate)):treatmentResultRecords

    if(isImportAndContainer(jobType)&& isMethylBromide(currentJob.treatmentDetails.treatmentTypeId)) {
     // treatmentResultsRecordsCheck = treatmentResultsRecordsCheck.sort((a,b)=>moment(a.startDate) - moment(b.startDate))
     treatmentResultsRecordsCheck=treatmentResultRecords.filter((e)=>e.coveredContainer!==true)
    }
    if (!treatmentResultsRecordsCheck) {
      return <div>No Records</div>;
    } else {
      return (
        <div>
          <BootstrapTable
            headerContainerClass="table-sm"
            trClassName="app-table-row"
            version="4"
            data={treatmentResultRecords}
            bordered={false}
            remote
            options={{
              onRowClick: userIsCustomer() ? null: status==='Active'? this.toggleShowTreatmentResultModal :null,
            }}
          > 
            <TableHeaderColumn
              isKey
              className="col-hidden"
              columnClassName="keyColumn"
              dataField="id"
              hidden
            />
            <TableHeaderColumn
              width="55"
              dataFormat={(cell, row) => (
                <img
                  style={{ maxHeight: '30px' }}
                  src={jobTypeImageUrl}
                  alt="Job Type Icon"
                />
              )}
            />
             {isImportOrExportAndContainer(jobType) && isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) ? (
              <TableHeaderColumn  width = "250" dataFormat={(_, row) => ( 
                <div>
                  {getContainerNumber(
                    row
                  )}
                </div>
              )}>
                Container #
              </TableHeaderColumn>
            ) :null}
            {isExportAndLogs(jobType) ? (
              <TableHeaderColumn width="100" dataField="yardNumber">
                Yard #
              </TableHeaderColumn>
            ) : 
            jobType.name ==='MB Logs In Hold'?
            <TableHeaderColumn width="80" dataField="yardNumber">
            Hold #
            </TableHeaderColumn>:
            null}
            {isBreakBulkJobType(jobType) ? (
              <TableHeaderColumn width="80" dataField="stackNumber">
                Stack #
              </TableHeaderColumn>
            ) : null}
            <TableHeaderColumn
              width="100"
              dataField="jas"
              dataFormat={ isImportOrExportAndContainer(jobType) && isMethylBromide(currentJob.treatmentDetails.treatmentTypeId)?(_, row) => row.mbkg && row.mbkg.toFixed(1):(_, row) => row.jas && row.jas.toFixed(3)}
              >
              {isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)||isAppleMB?"Vol M3":isBreakBulkJobType(jobType) 
              || isMBLogsInHold(jobType) ? 'M3' :  
              isImportOrExportAndContainer(jobType) && isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) ? 'MB Kg':'JAS'}
            </TableHeaderColumn>

                {isAppleMB ?
            <TableHeaderColumn
              width="100"
              dataField="tarpaulinIdNumber"
              dataFormat={ (_, row) => parseInt(row.tarpaulinIdNumber && row.tarpaulinIdNumber) +1}
              >
              {isAppleMB?"Chamber":null}
            </TableHeaderColumn>
   :null}

            <TableHeaderColumn
              width="150"
              dataFormat={(_, row) => (
               /* <div>{isAppleMB?formatDate(row.gasInDateTime):isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)? row.gasInDateTime 
                  && formatDate(row.startDate) :row.startDate && formatDate(row.startDate)}</div>*/
                  <div>{isAppleMB?formatDate(row.gasInDateTime):isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)? formatDate(row.gasInDateTime) 
                  :row.startDate?formatDate(row.startDate):null}</div>//formatDate(row.startDate) }</div>
              )}
            >
          {isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)||isAppleMB?"Gas In Date":"Started at"} 
            </TableHeaderColumn>
            <TableHeaderColumn
              dataFormat={(_, row) => (
                <div>
                  {
                  (isAppleMB?null:!isEthylFormate(currentJob.treatmentDetails.treatmentTypeId))&&isImportOrExportAndContainer(jobType)? 
                  getCoveredTechInRow( row.concentrationReadingItem):
                  getTechnicianDisplayName(
                  row.coveringTechnicianId,
                    technicians,
                  )
                }
                </div>
              )}
            >
             {isImportOrExportAndContainer(jobType)?'Fumigated by':'Covered by'}
            </TableHeaderColumn>
            <TableHeaderColumn
              width="150"
              dataFormat={(_, row) => (
                <div>
                   {isImportOrExportAndContainer(jobType)&&isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)
                   && row.efConcentrationReadingItem.filter(x=>x.phase==2).length>=1
                   ?formatDate(row.efConcentrationReadingItem.filter(x=>x.phase==2)[0].readingDateTime): 
                   isImportOrExportAndContainer(jobType)&& row.endDate? formatDate(row.endDate):
                  !isExportAndContainer(jobType)&&row.uncoveringTechnicianId ? formatDate(row.endDate) : null}
                </div>
              )}
            >
              Finished at
            </TableHeaderColumn>
            <TableHeaderColumn
              dataFormat={(cell, row) => (
                <div>
                  {isImportOrExportAndContainer(jobType)&& !isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)?
                  getTechnicianUncoveredInRow(row.concentrationReadingItem):
                  getTechnicianDisplayName(
                    row.uncoveringTechnicianId,
                    technicians,
                  )}
                </div>
              )}
            >
           {isEthylFormate(currentJob.treatmentDetails.treatmentTypeId) || isImportOrExportAndContainer(jobType)?'Venting by':'Uncovered by'}
            </TableHeaderColumn>
            <TableHeaderColumn
              width="120"
              dataFormat={(cell, row) => (
                <div>
                  {row.uncoveringTechnicianId ? (row.passed ? 'Y' : 'N') : null}
                </div>
              )}
              >
              {isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)||isAppleMB?'Passed':null}
          </TableHeaderColumn>
            {currentJob.canEditJob && status === 'Active'? (
              <TableHeaderColumn
                width="58"
                dataFormat={(cell, row) => (
                  <Button
                    outline
                    color={'danger'}
                    style={{
                      width: '32px',
                      height: '32px',
                      position: 'relative',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.deleteTreatmentResultRecord(row);
                    }}
                  >
                    <i
                      aria-hidden="true"
                      className="material-icons"
                      style={{
                        position: 'absolute',
                        top: '4px',
                        left: '4px',
                      }}
                    >
                      delete_outline
                    </i>
                  </Button>
                )}
              />
            ) : null}
          </BootstrapTable>

          {isEthylFormate(currentJob.treatmentDetails.treatmentTypeId)&& treatmentResultRecords.length>0?null:currentJob.canEditJob && status === 'Active' ? (
            <div className="text-right mt-3">
              <ToggleButton
                color="light"
                onClick={() =>
                  this.toggleShowTreatmentResultModal({
                    jobId: currentJob.id,
                    coveringTechnicianId: currentJob.technicianId,
                    startDate: null, //roundToPrevious(moment().startOf('minute'), 5)
                  })
                }
                ligature="add"
              />
            </div>
          ) : (
            <br />
          )}

          <TreatmentResultRecordsModal
            title={"Treatment Record "+currentJob.jobReference}
            isOpen={showTreatmentResultModal}
            toggle={this.closeModals}
            onSubmit={this.handleSubmission}
            currentTreatmentResultRecord={selectedTreatmentResultRecord}
            jobType={jobType}
            treatmentTypeId = {currentJob.treatmentDetails.treatmentTypeId}
            currentJob ={currentJob}
          />
        </div>
      );
    }
  }
}

TreatmentResultRecordsList.propTypes = propTypes;

function formatDate(date) {
  const d = moment(date);
  const now = moment();
  let format;
  if (d.year() === now.year()) {
    format = 'DD/MM HH:mm';
  } else {
    format = 'DD/MM/YYYY';
  }
  return d.format(format);
}

export default TreatmentResultRecordsList;
