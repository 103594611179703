import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.bool,
  rowData: PropTypes.object,
  onClick: PropTypes.func
};

const CheckboxFormatter = ({ value, rowData, onClick }) => {
  const handleClick = event => (onClick ? onClick(rowData) : null);
  return (
    <label className="custom-control custom-checkbox">
      <input
        type="checkbox"
        name="checkbubble"
        checked={value}
        className="custom-control-input"
        onChange={handleClick}
      />
      <span className="custom-control-indicator" />
    </label>
  );
};

CheckboxFormatter.propTypes = propTypes;

export default CheckboxFormatter;
