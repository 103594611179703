import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrlFull: PropTypes.string,
  isActive: PropTypes.bool
};

const JobTypeItem = ({ isActive, id, name, imageUrlFull }) => (
  <Card>
    <Link
      className={`card-link ${isActive ? 'is-active' : ''}`}
      to={`/jobs/details/create/${id}`}
    >
      {isActive && <div className="card-tag">Current Type</div>}
      <CardImg top height="40" src={imageUrlFull} alt={name} />
      <CardBody>
        <CardTitle>{name}</CardTitle>
      </CardBody>
    </Link>
  </Card>
);

JobTypeItem.propTypes = propTypes;

export default JobTypeItem;
