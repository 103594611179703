import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function jobLocationReducer(
  state = initialState.referenceData.jobLocations,
  action
) {
  if (action.type === types.GET_JOB_LOCATIONS_SUCCESS) {
    return [...action.jobLocations];
  }
  return state;
}
