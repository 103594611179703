import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { Label, FormFeedback } from 'reactstrap';

import { withIsTouch } from '../../hoc/media';

import ClassicSelect from './ClassicSelect';

const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  responsive: PropTypes.bool,
  isTouch: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  responsive: true,
};

const SearchableSelect = ({
  input,
  label,
  options,
  placeholder,
  onSelect,
  multi,
  clearable,
  disabled,
  valueKey = 'id',
  labelKey = 'displayName',
  meta: { touched, error, warning },
  responsive,
  isTouch,
  className,
}) => {
  const handleChange = (selectedOption) => {
    input.onChange(selectedOption);
    if (!onSelect) return false;
    onSelect(selectedOption);
  };

  return (
    <div>
      <Label for={input.name}>{label}</Label>
      {multi || !responsive || !isTouch ? (
        <Select
          className={classNames(
            {
              'is-invalid': touched && error,
            },
            className,
          )}
          value={input.value}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          multi={multi}
          clearable={clearable}
          searchable
          valueKey={valueKey}
          labelKey={labelKey}
          disabled={disabled}
          simpleValue
        />
      ) : (
        <ClassicSelect
          className={classNames({
            'is-invalid': touched && error,
          })}
          value={input.value}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          multi={multi}
          valueKey={valueKey}
          labelKey={labelKey}
          disabled={disabled}
        />
      )}
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};

SearchableSelect.propTypes = propTypes;
SearchableSelect.defaultProps = defaultProps;

export default withIsTouch(SearchableSelect);
