import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logger from 'util/Logger';
import keycloakManager from '../keycloakManager';

const withAuth = WrappedComponent => {
  const propTypes = {
    // actions: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool
  };

  class AuthedComponent extends Component {
    state = {
      isAuthenticated: false
    };

    componentDidMount() {
      this.authenticate();
    }

    authenticate() {
      keycloakManager
        .authenticate()
        .then(isAuthenticated => this.authenticateSuccess(isAuthenticated))
        .catch(error => this.authenticateFailure(error));
    }

    authenticateSuccess(isAuthenticated) {
      if (!isAuthenticated) return false;
      this.setState({
        isAuthenticated
      });
    }

    authenticateFailure(error) {
      Logger.error('Keycloak failed to initialize: ', error);
      keycloakManager.logout();
    }

    render() {
      let { isAuthenticated } = this.state;

      if (!isAuthenticated) {
        return null;
      }
      return <WrappedComponent />;
    }
  }

  AuthedComponent.propTypes = propTypes;

  return AuthedComponent;
};

export default withAuth;
