import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const propTypes = {
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  className: PropTypes.string,
  multi: PropTypes.bool,
};

function ClassicSelect({
  value,
  options,
  placeholder,
  onChange,
  disabled,
  valueKey,
  labelKey,
  multi,
  className,
}) {
  const handleChange = (e) => {
    if (onChange) {
      // Note: the native select cannot use other types than string
      // as values, e.g. true = "true"
      let v = e.target.value;
      try {
        v = JSON.parse(v);
      } catch (err) {
        /* noop */
      }

      if (multi) {
        const current = value.split(',').map((x) => {
          try {
            return JSON.parse(x);
          } catch (err) {
            return x;
          }
        });
        if (current.includes(v)) {
          v = current.filter((x) => v !== x).join(',');
        } else {
          v = [...current, v].join(',');
        }
      }
      onChange(v);
    }
  };

  return (
    <Input
      className={className}
      type="select"
      disabled={disabled}
      value={
        multi
          ? value.split(',').map((x) => JSON.stringify(x))
          : JSON.stringify(value)
      }
      onChange={handleChange}
      multiple={multi}
    >
      <option value="">{placeholder || ''}</option>
      {options.map((option) => (
        <option
          key={JSON.stringify(option[valueKey])}
          value={JSON.stringify(option[valueKey])}
        >
          {option[labelKey]}
        </option>
      ))}
    </Input>
  );
}

ClassicSelect.propTypes = propTypes;

export default ClassicSelect;
