import { confirmAlert, alert } from './Alerts';
import * as operationsApi from '../api/operationsApi';

export function validateJob(formValues, callback) {
  validateParentJobReference(formValues).then((result) => {
    if (result.error) {
      alert('Invalid Parent Job #', result.message);
    } else {
      validateUniquesMarkingsForJob(formValues, callback);
    }
  });
}

const validateParentJobReference = (values) => {
  const { parentJobReference } = values;
  let response = { error: false };

  if (parentJobReference) {
    return operationsApi
      .getParentJobReference(parentJobReference)
      .then((job) => {
        const jobReferenceDoesNotExist =
          job && job.jobReference !== parseInt(parentJobReference, 10);
        if (!job || jobReferenceDoesNotExist) {
          response.error = true;
          response.message =
            "The entered 'Parent Job #' does not exist, please correct this value and try again.";
        }

        return response;
      });
  } else {
    return new Promise((resolve) => {
      resolve(response);
    });
  }
};

const validateUniquesMarkingsForJob = (formValues, callback) => {
  if (
    !formValues.treatmentTypeDetails ||
    !formValues.treatmentTypeDetails.markings
  ) {
    callback(formValues);
  } else {
    operationsApi
      .validateJobMarkings(
        formValues.id,
        formValues.treatmentTypeDetails.markings,
      )
      .then((validationResponse) => {
        if (validationResponse.isValid) {
          callback(formValues);
        } else {
          confirmAlert(
            'Warning: The following markings are already in use. Do you wish to continue?',
            validationResponse.additionalInfo,
            'Yes',
            'No',
          ).then((confirm) => {
            if (confirm) {
              callback(formValues);
            }
          });
        }
      });
  }
};
