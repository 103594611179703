import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { userCanAccessAdmin, userIsCustomer } from 'util/AdGroupsHelper';

const propTypes = {
  isOpen: PropTypes.bool,
  logout: PropTypes.func,
};

const TopNav = ({ isOpen, logout, envprops }) => {
  return (
    <div
      className="app-topnav"
      style={{ backgroundColor: envprops.BackgroundColor }}
    >
      <Collapse isOpen={isOpen} navbar>
        <Navbar dark expand="md">
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink className="nav-link" to="/">
                Dashboard
              </NavLink>
            </NavItem>
            {userIsCustomer() ? null : (
              <NavItem>
                <NavLink className="nav-link" to="/search">
                  Search
                </NavLink>
              </NavItem>
            )}
            {userCanAccessAdmin() ? (
              <UncontrolledDropdown nav>
                <DropdownToggle nav caret>
                  Admin
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag={Link} to="/technicians">
                    Maintain Technicians
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/contacts">
                    Maintain Contacts
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/clients">
                    Maintain Clients
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/areas">
                    Maintain Locations
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/vessels">
                    Maintain Vessels
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
            <NavItem>
              <Button outline className="ml-3" color="light" onClick={logout}>
                Logout
              </Button>
            </NavItem>
          </Nav>
        </Navbar>
      </Collapse>
    </div>
  );
};

TopNav.propTypes = propTypes;

export default TopNav;
