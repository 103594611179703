import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function containerTypesReducer(
  state = initialState.referenceData.sizeOfContainers,
  action
) {
  if (action.type === types.GET_SIZE_OF_CONTAINERS_SUCCESS) {
    return [...action.sizeOfContainers];
  }
  return state;
}
