import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import AuthedRoute from 'hoc/AuthedRoute';
import DashboardPage from 'components/dashboard/DashboardPage';
import UnauthorisedAccessPage from 'pages/UnauthorisedAccessPage';
import JobPageContainer from 'components/jobs/JobPageContainer';
import JobDetailsCreatePageContainer from 'components/jobdetails/JobDetailsCreatePageContainer';
import JobDetailsEditPageContainer from 'components/jobdetails/JobDetailsEditPageContainer';
import SearchPageContainer from 'components/search/SearchPageContainer';
import TechnicianListPage from 'components/technicians/TechnicianListPage';
import TechnicianPage from 'components/technicians/TechnicianPage';
import ContactsListPage from 'components/contacts/ContactsListPage';
import ContactPage from 'components/contacts/ContactPage';
import ClientListPageContainer from 'components/clients/ClientListPageContainer';
import AreasListPage from 'components/locations/AreasListPage';
import LocationsListPage from 'components/locations/LocationsListPage';
import LocationEditPage from 'components/locations/LocationEditPage';
import VesselsListPage from './vessels/VesselsListPage';
import VesselsPage from './vessels/VesselsPage';

const AppRoutes = () => {
  const { AdminGroupName, OperationsGroupName, ComplianceGroupName } =
    window.env;
  const adminSecurityRoles = [
    AdminGroupName,
    OperationsGroupName,
    ComplianceGroupName,
  ];
  const defaultSortParams = 'modifieddate,DESC';

  return (
    <Switch>
      <Route
        path="/"
        exact
        component={() => <Redirect to="/dashboard/new/" />}
      />
      <Route
        path="/dashboard"
        exact
        component={() => <Redirect to="/dashboard/new/" />}
      />
      <Route
        path="/dashboard/:state"
        exact
        component={({ match }) => (
          <Redirect
            to={`/dashboard/${match.params.state}/1/${defaultSortParams}`}
          />
        )}
      />
      <Route path="/dashboard/:state/:page/:sort" component={DashboardPage} />
      <Route exact path="/jobs/:id" component={JobPageContainer} />
      <Route
        exact
        path="/jobs/details/create/:id"
        component={JobDetailsCreatePageContainer}
      />
      <Route
        exact
        path="/jobs/details/edit/:id"
        component={JobDetailsEditPageContainer}
      />
      <Route exact path="/search" component={SearchPageContainer} />
      <AuthedRoute
        exact
        path="/technicians"
        component={TechnicianListPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        path="/technicians/:action/:id?"
        component={TechnicianPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        exact
        path="/areas"
        component={AreasListPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        exact
        path="/areas/:areaid"
        component={LocationsListPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        exact
        path="/areas/:areaid/:action"
        component={LocationEditPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        exact
        path="/areas/:areaid/:action/:locationid"
        component={LocationEditPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        exact
        path="/contacts"
        component={ContactsListPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        path="/contacts/:action/:id?"
        component={ContactPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        exact
        path="/vessels"
        component={VesselsListPage}
        roles={adminSecurityRoles}
      />
      <AuthedRoute
        path="/vessels/:action/:id?"
        component={VesselsPage}
        roles={adminSecurityRoles}
      />

      <Route
        path="/clients"
        exact
        component={() => <Redirect to="/clients/1/" />}
      />
      <AuthedRoute
        exact
        path="/clients/:page"
        component={ClientListPageContainer}
        roles={adminSecurityRoles}
      />
      <Route path="/401" component={UnauthorisedAccessPage} />
    </Switch>
  );
};

export default AppRoutes;
