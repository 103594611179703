import { connect } from 'react-redux';
import { compose } from 'redux';
import PhosphineRecordsSummary from './PhosphineRecordsSummary';

function mapStateToProps(state) {
  const { currentJob } = state;
  const phosphineRecords = currentJob.phosphineTreatmentResultRecords || [];

  return {
    phosphineRecords: phosphineRecords,
    currentJob: currentJob
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PhosphineRecordsSummary
);
