import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, FieldArray } from 'redux-form';
import { Container, Button, Collapse, Row, Col } from 'reactstrap';
import FileUploadField from 'components/fields/FileUploadField';
import DocumentFieldArray from 'components/fields/DocumentFieldArray';
import { Band, ToggleButton } from 'components/layout';
import { isVerified } from 'util/JobStateHelper';
import { JOB_ATTACHMENT_ACCEPTED_TYPES } from 'constants/jobConstants';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func,
  canEditJob: PropTypes.bool,
  attachments: PropTypes.array
};

class JobAttachments extends Component {
  state = {
    collapsed: isVerified(this.props.currentState) ? false : true
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  render() {
    const { handleSubmit, isSubmitting, canEditJob, attachments,clientDetails } = this.props;
    const { collapsed } = this.state;
    var status = "Active";
    if( clientDetails != null && clientDetails !== undefined){
      status = clientDetails.status;
   }


    if (!canEditJob && !attachments.length) return false;

    return (
      <Band dark>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>Attachments</h4>
              </Col>
              <Col xs="4" className="text-right">
                <ToggleButton
                  className="ml-3"
                  color="light"
                  active={collapsed}
                  onClick={this.toggleExpand}
                  ligature="expand_less"
                />
              </Col>
            </Row>
            <Collapse isOpen={!this.state.collapsed}>
              <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
                <FieldArray
                  readOnly={!canEditJob}
                  name="attachments"
                  component={DocumentFieldArray}
                />
                {canEditJob && status !=='Inactive'? (
                  <Field
                    name="newAttachments"
                    component={FileUploadField}
                    accept={JOB_ATTACHMENT_ACCEPTED_TYPES.join(',')}
                  />
                ) : null}
                {canEditJob && status !=='Inactive'? (
                  <Button
                    color="primary"
                    className="mt-3 btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                ) : null}
              </Form>
            </Collapse>
          </Container>
        </div>
      </Band>
    );
  }
}

JobAttachments.propTypes = propTypes;

export default JobAttachments;
