import React from 'react';
import { compose, withProps, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { formValueSelector, getFormInitialValues } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';

import { CONCENTRATION_READING_PHASE_END } from '../../../../constants/ConcentrationReadingPhase';
import { containerTreatmentResultRecordsForm } from '../../../../constants/FormConstants';

import { withDeepCompareShouldUpdate } from '../enhancers';

import * as CalculationUtils from '../../../../util/CalculationUtils';

export const withAccordionActiveItemIndex = compose(
  // Calculate which item to open, depending on mandatory fields.
  // If all filled in, open the last item.
  withProps(({ currentTreatmentResultRecord, showMonitoring }) => {
    const accordionItemsMandatoryFields = [
      showMonitoring ? ['id'] : ['id', 'startDate'],
      showMonitoring && [
        ({ concentrationReadingItem = [] }) => {
          return (
            concentrationReadingItem.some(
              ({ phase }) => phase === CONCENTRATION_READING_PHASE_END,
            ) && concentrationReadingItem.every((item) => Boolean(item.id))
          );
        },
      ],
      showMonitoring ? ['actualTemperature'] : ['endDate', 'actualTemperature'],
    ].filter(Boolean);
    let currentItem = accordionItemsMandatoryFields.findIndex(
      (mandatoryFields) =>
        mandatoryFields.some((field) => {
          if (typeof field === 'function') {           
            return !field(currentTreatmentResultRecord);
          }
          return (
            !currentTreatmentResultRecord ||
            !(field in currentTreatmentResultRecord) ||
            typeof currentTreatmentResultRecord[field] === 'undefined' ||
            currentTreatmentResultRecord[field] === null
          );
        }),
    );
    if (currentItem === -1) {
      currentItem = accordionItemsMandatoryFields.length - 1;
      

    }


    return { accordionActiveItemIndex: currentItem };
  }),
);

export const withSortedReadings = withProps(
  ({ currentTreatmentResultRecord }) => ({
    currentTreatmentResultRecord: {
      ...(currentTreatmentResultRecord || {}),
      concentrationReadingItem: _.sortBy(
        (currentTreatmentResultRecord || {}).concentrationReadingItem || [],
        [
          // Put empty last
          (o) => (o.readingDateTime ? -1 : 1),
          // Sort by readingDateTime, ASC
          (o) => moment(o.readingDateTime).toDate(),
        ],
      ),
    },
  }),
);

const withRecalculateField = (propNames, calculatedFieldName, recalculateFn) =>
  compose(
    // Watch fields, which are triggering changes
    connect((state, props) => {
      const values = propNames.reduce(
        (acc, propName) => ({
          ...acc,
          [propName]: formValueSelector(containerTreatmentResultRecordsForm)(
            state,
            propName,
          ),
        }),
        {},
      );
      return {
        // Performance: We want to remember which props we've added so that
        // we can remove them
        _propsToRemove: propNames.filter((propName) =>
          _.isNil(props[propName]),
        ),
        ...values,
      };
    }),

    // recalculate on changes
    (Component) => {
      class WrappedComponent extends React.Component {
        componentDidUpdate(prevProps) {
          const hasAnyChanged = propNames.some(
            (propName) => !_.isEqual(prevProps[propName], this.props[propName]),
          );
          if (hasAnyChanged) {
            this.recalculate();
          }
        }

        recalculate() {
          const { actions } = this.props;
          actions.changeFieldValue(
            containerTreatmentResultRecordsForm,
            calculatedFieldName,
            recalculateFn(
              this.props,
              propNames.reduce(
                (acc, propName) => ({
                  ...acc,
                  [propName]: this.props[propName],
                }),
                {},
              ),
            ),
          );
        }

        render() {
          return <Component {...this.props} />;
        }
      }
      return WrappedComponent;
    },
    mapProps(({ _propsToRemove, ...props }) => _.omit(props, _propsToRemove)),
    withDeepCompareShouldUpdate,
  );

export const withCalculatedFields = compose(
  // mbTopUp => MB kg
  withRecalculateField(
    ['mbTopUp'],
    'mbkg',
    ({ calculatedMbkg }, { mbTopUp }) => {
      return CalculationUtils.calculateTotalMbKgFrom(calculatedMbkg, mbTopUp);
    },
  ),
  // concentration reading item top up dose => mbTopUp
  withRecalculateField(
    ['concentrationReadingItem'],
    'mbTopUp',
    (_, { concentrationReadingItem }) => {
      return CalculationUtils.sumMb(
        concentrationReadingItem.map(({ topupDose }) => topupDose),
      );
    },
  ),
  // concentration reading item top up dose => startDate
  withRecalculateField(
    ['concentrationReadingItem'],
    'startDate',
    (props, { concentrationReadingItem }) => {
      const dateTimes = concentrationReadingItem
        .map(({ readingDateTime }) =>
          readingDateTime ? moment(readingDateTime) : null,
        )
        .filter((x) => Boolean(x) && x.isValid());
      const dateTimesSorted = _.sortBy(dateTimes, (x) => x.toDate());
      return dateTimesSorted.length > 0 ? dateTimesSorted[0] : null;
    },
  ),
  // concentration reading item top up dose => endDate
  withRecalculateField(
    ['concentrationReadingItem'],
    'endDate',
    (props, { concentrationReadingItem }) => {
      const endDateTimes = concentrationReadingItem
        .filter(({ phase }) => phase === CONCENTRATION_READING_PHASE_END)
        .map(({ readingDateTime }) =>
          readingDateTime ? moment(readingDateTime) : undefined,
        )
        .filter(Boolean);
      const endDateTimesSorted = _.sortBy(endDateTimes, (x) => x.toDate());
      return endDateTimesSorted.length > 0
        ? endDateTimesSorted[endDateTimesSorted.length - 1]
        : null;
    },
  ),
);

export const withIsSectionTouched = (propsToFields) =>
  compose(
    // Get watched fields values
    connect((state, props) => {
      const selector = formValueSelector(containerTreatmentResultRecordsForm);
      const initialValues = getFormInitialValues(containerTreatmentResultRecordsForm)(
        state,
      );
      const fields = propsToFields(props).filter(Boolean);
      const touched = fields.some((field) => {
        const v = selector(state, field);
        // We don't care about null-ish values
        if (_.isNil(v) && _.isNil(initialValues[field])) return false;
        if (!_.isEqual(v, initialValues[field])) {
          console.debug('touched', field, v, initialValues[field]);
        }
        // Let's say something is touched if the value has changed
        return !_.isEqual(v, initialValues[field]);
      });
      return {
        sectionTouched: touched,
      };
    }),
  );
