import React from 'react';

const HeaderTitle = ({ children, imageUrl, label }) => (
  <div className="app-header-title">
    {imageUrl ? (
      <img
        className="app-header-title-image"
        src={imageUrl}
        alt="Header Logo"
      />
    ) : null}
    {label ? (
      <div className="app-header-title-label">
        {label} {children}
      </div>
    ) : null}
  </div>
);

export default HeaderTitle;
