import { compose } from 'recompose';
import { connect } from 'react-redux';

import { getCanEditJob, getJobId, getFieldValueFn ,getCurrentJob,getCurrentConcentrationChamberReadingItem} from '../selectors';

import TreatmentResultRecordsMonitoringSection from './TreatmentResultRecordsMonitoringSection';

export default compose(
  connect((state) => ({
    canEditJob: getCanEditJob(state),
    jobId: getJobId(state),
    job:getCurrentJob(state),
    checkRikenDataMatch: getFieldValueFn(state)('checkRikenDataMatch'),
    currentConcentrationReadingItem:getCurrentConcentrationChamberReadingItem(state),
  })),
)(TreatmentResultRecordsMonitoringSection);
