import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
} from 'reactstrap';

import { generatePDF } from '../../../api/operationsApi';

import PreviewEmailFrame from '../PreviewEmailFrame';
import ResponsiveModal from '../ResponsiveModal';

import styles from './PreviewEmailModal.module.scss';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  onPreview: PropTypes.func,
  emailContent: PropTypes.string,
  downloadFileName: PropTypes.string,
};

class PreviewEmailModal extends Component {
  previewPDF = () => {
    const { title, emailContent, downloadFileName } = this.props;
    generatePDF(emailContent).then((response) => {
      let blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, downloadFileName || title + '.pdf');
    });
  };

  destroyPrintedBody = () => {
    let body = document.getElementsByTagName('BODY')[0];
    if (body && body.classList) {
      body.classList.remove('print-hidden');
    }
    let printed = document.getElementsByClassName('printedBody');
    if (printed && printed.length) {
      for (let i = 0; i < printed.length; i++) {
        document.body.removeChild(printed[i]);
      }
    }
    document.body.removeEventListener('onafterprint', this.destroyPrintedBody);
  };

  previewPrint = () => {
    const { emailContent } = this.props;

    let body = document.getElementsByTagName('BODY')[0];
    if (body && body.classList) {
      body.classList.add('print-hidden');
    }
    let printed = document.createElement('div');
    if (printed && printed.length) {
      this.destroyPrintedBody();
    }
    printed.className = 'printedBody';
    document.body.appendChild(printed);
    printed.innerHTML = emailContent;
    document.body.onafterprint = this.destroyPrintedBody;
    window.print();
  };

  handleClick = (e) => this.props.toggle();
  handleDownload = (e) => this.previewPDF();
  handlePrint = (e) => this.previewPrint();

  render() {
    const { title, isOpen, toggle, emailContent } = this.props;
    return (
      <ResponsiveModal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        className="app-modal"
      >
        <ModalHeader toggle={toggle}>{title || ''}</ModalHeader>
        <ModalBody>
          <FormGroup className={styles.FormGroup}>
            <PreviewEmailFrame
              title="Notice of Inted Treatment Email Preview"
              emailContent={emailContent}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={this.handlePrint}>
            <i className="material-icons mr-1">print</i>
            <span>Print</span>
          </Button>
          <Button color="primary" outline onClick={this.handleDownload}>
            <i className="material-icons mr-1">file_download</i>
            <span>Download PDF</span>
          </Button>
          <Button color="primary" onClick={this.handleClick}>
            Close
          </Button>
        </ModalFooter>
      </ResponsiveModal>
    );
  }
}

PreviewEmailModal.propTypes = propTypes;

export default PreviewEmailModal;
