import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import JobTypeItem from './JobTypeItem';

const propTypes = {
  jobTypes: PropTypes.array.isRequired,
  activeJobType: PropTypes.string
};

const JobTypeItems = ({ activeJobType, jobTypes = [] }) => (
  <Row>
    {jobTypes.map(jobType => (
      <Col sm="3" key={jobType.id} className="mb-4">
        <JobTypeItem
          isActive={activeJobType === jobType.id}
          id={jobType.id}
          name={jobType.name}
          imageUrlFull={jobType.imageUrlFull}
        />
      </Col>
    ))}
  </Row>
);

JobTypeItems.propTypes = propTypes;

export default JobTypeItems;
