import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function containerTypesReducer(
  state = initialState.referenceData.containerTypes,
  action
) {
  if (action.type === types.GET_CONTAINER_TYPES_SUCCESS) {
    return [...action.containerTypes];
  }
  return state;
}
