import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import JobTypeChanger from './JobTypeChanger';

const propTypes = {
  jobTypes: PropTypes.array.isRequired,
  activeJobType: PropTypes.string,
  handleChange: PropTypes.func
};

const JobTypeChangers = ({ handleChange, activeJobType, jobTypes = [] }) => (
  <Row>
    {jobTypes.map(jobType => (
      <Col sm="3" key={jobType.id} className="mb-4">
        <JobTypeChanger
          handleChange={handleChange}
          isActive={activeJobType === jobType.id}
          id={jobType.id}
          name={jobType.name}
          imageUrlFull={jobType.imageUrlFull}
        />
      </Col>
    ))}
  </Row>
);

JobTypeChangers.propTypes = propTypes;

export default JobTypeChangers;
