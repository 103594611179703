import React from 'react';
import { Alert } from 'reactstrap';
import { jobCertificationWarnings } from 'util/WarningsHelper';

const JobCertificationAlerts = ({currentJob,status }) => (
  
  
<div>

    {currentJob.doNotCertify || currentJob.stopCredit  ? 
    Object.keys(jobCertificationWarnings).map((key, index) => {
      const warning = jobCertificationWarnings[key];
      return warning.showWarning(currentJob) ? (
        <Alert color="warning" key={index}>
          {warning.message}
        </Alert>
      )  : null;
    }):
    status==='Inactive' ? 
      <Alert color="warning" >
        {" The Treatment Certificate cannot be issued because the client is inactive"}
      </Alert>
      
    :null}
  </div>
);

export default JobCertificationAlerts;
