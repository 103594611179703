import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  light: PropTypes.bool,
  tint: PropTypes.bool,
  dark: PropTypes.bool,
  className: PropTypes.string
};

const Band = ({ light, tint, dark, className, children }) => (
  <section
    className={classNames('app-band', className, {
      'app-band--light': light,
      'app-band--tint': tint,
      'app-band--dark': dark
    })}
  >
    {children}
  </section>
);

Band.propTypes = propTypes;

export default Band;
