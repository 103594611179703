import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from 'reactstrap';

const propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.object,
  ligature: PropTypes.string,
  noMargin: PropTypes.bool
};

const HeaderButton = ({ color, outline, onClick, ligature, noMargin }) => (
  <Button
    color={color}
    outline={outline}
    className={cx('btn-toggle', { 'mr-3': !noMargin })}
    onClick={onClick}
  >
    <i aria-hidden="true" className="material-icons">
      {ligature}
    </i>
  </Button>
);

HeaderButton.propTypes = propTypes;

export default HeaderButton;
