import keycloakManager from '../keycloakManager';
import {
  newJob,
  assigned,
  inProgress,
  completed,
  verified,
  certified,
  failed,
  cancelled
} from 'constants/JobStateConstants';

const {
  ReadOnlyGroupName,
  AdminGroupName,
  OperationsGroupName,
  ComplianceGroupName,
  LicensedTechnicianGroupName,
  TechnicianGroupName,
  CustomerGroupName
} = window.env;

const ADGroupPermissions = {
  edit: {
    [newJob]: [
      AdminGroupName,
      OperationsGroupName,
      ComplianceGroupName,
      LicensedTechnicianGroupName,
      TechnicianGroupName,
      CustomerGroupName
    ],
    [assigned]: [
      AdminGroupName,
      OperationsGroupName,
      ComplianceGroupName,
      LicensedTechnicianGroupName,
      TechnicianGroupName
    ],
    [inProgress]: [
      AdminGroupName,
      OperationsGroupName,
      ComplianceGroupName,
      LicensedTechnicianGroupName,
      TechnicianGroupName
    ],
    [completed]: [
      AdminGroupName,
      OperationsGroupName,
      ComplianceGroupName,
      LicensedTechnicianGroupName,
      TechnicianGroupName
    ],
    [verified]: [AdminGroupName, OperationsGroupName, ComplianceGroupName],
    [certified]: [AdminGroupName, ComplianceGroupName],
    [failed]: [
      AdminGroupName,
      OperationsGroupName,
      ComplianceGroupName,
      LicensedTechnicianGroupName,
      TechnicianGroupName
    ],
    [cancelled]: [
      AdminGroupName,
      OperationsGroupName,
      ComplianceGroupName,
      LicensedTechnicianGroupName,
      TechnicianGroupName
    ]
  },
  cancel: {
    [newJob]: [AdminGroupName, ComplianceGroupName],
    [assigned]: [AdminGroupName, ComplianceGroupName],
    [inProgress]: [AdminGroupName, ComplianceGroupName],
    [completed]: [AdminGroupName, ComplianceGroupName],
    [verified]: [AdminGroupName, ComplianceGroupName],
    [certified]: [AdminGroupName, ComplianceGroupName]
  }
};

export const userCanAccessSystem = () => {
  const userCanAccessSystem = keycloakManager.isInGroup([
    ReadOnlyGroupName,
    AdminGroupName,
    OperationsGroupName,
    ComplianceGroupName,
    LicensedTechnicianGroupName,
    TechnicianGroupName,
    CustomerGroupName
  ]);
  return userCanAccessSystem;
};

export const userCanAddJobs = () => {
  const userCanAddJobs = keycloakManager.isInGroup([
    AdminGroupName,
    OperationsGroupName,
    ComplianceGroupName,
    LicensedTechnicianGroupName,
    TechnicianGroupName,
    CustomerGroupName
  ]);
  return userCanAddJobs;
};

export const userCanAccessAdmin = () => {
  const userCanAccessAdmin = keycloakManager.isInGroup([
    AdminGroupName,
    OperationsGroupName,
    ComplianceGroupName
  ]);
  return userCanAccessAdmin;
};

export const userCanEdit = state => {
  return userHasPermissions('edit', state, true);
};

export const userCanCancel = state => {
  return userHasPermissions('cancel', state, false);
};

export const userIsCustomer = () => {
  const userIsClient = keycloakManager.isInGroup([CustomerGroupName]);
  return userIsClient;
};
export const userIsTechnician = () => {
  const userIsTech = keycloakManager.isInGroup([TechnicianGroupName]);
  return userIsTech;
};

export const userIsLicencedTechnician = () => {
  const userIdLicensedTech = keycloakManager.isInGroup([LicensedTechnicianGroupName]);
  return userIdLicensedTech;
};

const userHasPermissions = (action, state, userHasPermissionsDefault) => {
  const restrictedAdGroups = ADGroupPermissions[action][state];

  const userHasPermissions = restrictedAdGroups
    ? keycloakManager.isInGroup(restrictedAdGroups)
    : userHasPermissionsDefault;

  return userHasPermissions;
};
