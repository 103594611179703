import Decimal from 'decimal.js';

import {
  isPhosphine,
  isMethylBromide,
  isVaporPhos,
} from './TreatmentTypeHelper';
import {
  isContainerOrBreakBulkOrOther,
  isExportAndLogsOrBreakBulk,
  isImportOrExportAndContainer,
} from './JobTypeHelper';

function isValidNumber(value) {
  return value && /^\d*[.]*\d*$/.test(value) ? true : false;
}

function ceilTo(number, precision) {
  const factor = Decimal.pow(10, precision);
  return new Decimal(number).times(factor).ceil().dividedBy(factor).toNumber();
}

export function nicelyRoundUp(number) {
  if (!isValidNumber(number)) return null;

  let precision = 1;
  return ceilTo(number, precision);
}

export function calculateCoveredVolume(length, width, height) {
  if (isValidNumber(length) && isValidNumber(width) && isValidNumber(height)) {
    return new Decimal(length).times(width).times(height).toNumber();
  }
  return null;
}

export function calculateMBkgLogsInHold(m3, dosage) {
  if (isValidNumber(m3)) {
    let roundCalresult = new Decimal(m3).times(dosage / 1000).toNumber();
    return ceilTo(roundCalresult, 0);
  }
  return null;
}

export function calculateDosageKgBasedOnVolume(volume, currentJob) {
  let roundToNextWholeNumber = isExportAndLogsOrBreakBulk(currentJob.type);
  let volumeMultiplier = 1;
  let dosageRate =
    currentJob && currentJob.treatmentDetails
      ? currentJob.treatmentDetails.dosageRate
      : null;

  if (!isValidNumber(volume) || !isValidNumber(dosageRate)) {
    return null;
  }

  if (
    isPhosphine(currentJob.treatmentDetails.treatmentTypeId) ||
    isVaporPhos(currentJob.treatmentDetails.treatmentTypeId)
  ) {
    volumeMultiplier = isImportOrExportAndContainer(currentJob.type)
      ? currentJob.treatmentTypeDetails.numberOfContainers
      : 1;
    return calculateForProsphine(volume, volumeMultiplier, dosageRate);
  } else if (
    isContainerOrBreakBulkOrOther(currentJob.type) &&
    isMethylBromide(currentJob.treatmentDetails.treatmentTypeId)
  ) {
    return calculateForMethylBromide(volume, dosageRate);
  }

  let calculatedResult = new Decimal(volume)
    .times(volumeMultiplier)
    .times(dosageRate)
    .dividedBy(1000)
    .toNumber();

  if (roundToNextWholeNumber) {
    calculatedResult = ceilTo(calculatedResult, 0);
  }

  return calculatedResult;
}

function calculateForProsphine(volume, volumeMultiplier, dosageRate) {
  return new Decimal(volume)
    .times(volumeMultiplier)
    .times(dosageRate)
    .dividedBy(1000)
    .toNumber();
}

function calculateForMethylBromide(volume, dosageRate) {
  return new Decimal(volume).times(dosageRate).dividedBy(1000).toNumber();
}

export function calculateTotalUsageWithMethylBromideTopUp(totalUsage, mbTopUp) {
  if (!isValidNumber(totalUsage) || !isValidNumber(mbTopUp)) {
    return null;
  }
  return new Decimal(totalUsage).add(mbTopUp);
}

export function sumMb(mbDoses) {
  if (!mbDoses) return 0;
  return mbDoses
    .reduce((acc, n) => {
      if (isValidNumber(n)) {
        return acc.add(n);
      }
      return acc;
    }, new Decimal(0))
    .toNumber();
}

export function calculateTotalMbKgFrom(calculatedMbkg, mbTopUp) {
  if (isValidNumber(calculatedMbkg) && isValidNumber(mbTopUp)) {
    return new Decimal(calculatedMbkg).add(mbTopUp).toNumber();
  }
  return calculatedMbkg;
}
export function roundWholeNumber(number) {
  if (!isValidNumber(number)) return null;
  const factor = Decimal.pow(10, 0);
  return new Decimal(number).times(factor).ceil().dividedBy(factor).toNumber();
}
