import React from 'react';
import PropTypes from 'prop-types';
import DocumentLink from 'components/common/DocumentLink';

const propTypes = {
  files: PropTypes.array,
  onDelete: PropTypes.func
};

const FileUploadAttachments = ({ files, onDelete }) => {
  return (
    <ul className="list-unstyled">
      {files.map((file, i) => (
        <li className="my-2" key={i}>
          <DocumentLink
            disableClick={true}
            name={file.name}
            id={file.id}
            onDelete={() => onDelete(file.name)}
          />
        </li>
      ))}
    </ul>
  );
};

FileUploadAttachments.propTypes = propTypes;

export default FileUploadAttachments;
