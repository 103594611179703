import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Row, Col } from 'reactstrap';

import RenderField from '../../../components/fields/RenderField';
import CustomTagSelect from '../../../components/fields/CustomTagSelect';
import SearchableSelect from '../../../components/fields/SearchableSelect';
import DatePickerField from '../../../components/fields/DatePickerField';
import CheckboxField from '../../../components/fields/CheckboxField';
import MarkingsSightedFieldArray from '../../../components/fields/MarkingsSightedFieldArray';

import * as Validators from '../../../util/Validators';
import * as CalculationUtils from '../../../util/CalculationUtils';
import { treatmentResultsForm } from '../../../constants/FormConstants';
import { preventEnterSubmit } from '../../../actions/formActions';
import {
  isExportOther,
  isImportAndHeatTreatment,
  isOtherAndWaterBlasting,
  isImportOtherAndGasFree,
  isOtherAndThermofog,
  isPestigasContainer,
  isImportOther,
  isImportOrExportAndContainer,
  isOtherJobType,
  isImportOtherTypeID,
} from '../../../util/JobTypeHelper';
import {
  isMethylBromide,
  isCallington1Shot,
  isVapormate,
  isWaterBlasting,
  isAlPVessel,
  isVaporPhos,
  isAlPNonVessel,
  isGasFree,
  isHydrogenCyanide,
  isMacSlay,
} from '../../../util/TreatmentTypeHelper';
import JobSignoffAlerts from '../../../components/jobs/jobSignoff/JobSignoffAlerts';
import {
  nicelyRoundUp,
  roundToWholeNumber,
} from '../../../util/FormattingHelper';
import { getCompetentTechnicians } from '../../../selectors/technicianSelectors';
import { roundToPrevious } from '../../../util/DateHelper';
import { userIsCustomer } from '../../../util/AdGroupsHelper';
import PlainTextField from '../../fields/PlainTextField';
import actions from 'redux-form/lib/actions';
import MarkingsFieldArray from '../../fields/MarkingsFieldArray';
import MBMarkingsFieldArray from '../../fields/MBMarkingsFieldArray';
import { isNumber } from 'lodash';
import ApprovalField from '../../fields/ApprovalField';

const propTypes = {
  handleSubmit: PropTypes.func,
  currentJob: PropTypes.object.isRequired,
  mbTopUp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientsVolume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  technicians: PropTypes.array,
  isSubmitting: PropTypes.bool,
  formValues: PropTypes.object,
  markings: PropTypes.array,
  actions: PropTypes.shape({
    changeFieldValue: PropTypes.func.isRequired,
  }),
};

const isBetweenOneAndNinityNine = (value) => {
  const limit = 99;
  if (value < 0) {
    return 0;
  } else if (value > limit) {
    return limit;
  } else {
    return value;
  }
};

class TreatmentResultsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFumigating: 'Fumigating Technician *',
      fumigatingRequiredValidator: Validators.required,
      isVenting: `Venting Technician *`,
      ventingRequiredValidator: false,
      userInput:'',
    };
  }

   // Function to format the date with '-'
   formatWithDash(dateString) {
    if (dateString.length >=10) {
      var fDate =  `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
      return fDate;
    }
    return dateString;
  }

  // Handle user input
  handleInputChange = (e) => {
    const userInput = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    var t = this.formatWithDash(userInput)
    this.setState({ userInput:t });
  };

  onChangeClientVolume = (event, value) => {
    const { actions, currentJob, mbTopUp } = this.props;
    let calculatedTotalUsage = isVapormate(
      currentJob.treatmentDetails.treatmentTypeId,
    )
      ? currentJob.treatmentDetails.dosageRate !== null
        ? (calculatedTotalUsage =
            (value * currentJob.treatmentDetails.dosageRate) / 1000)
        : 0
      : isCallington1Shot(currentJob.treatmentDetails.treatmentTypeId)
      ? (calculatedTotalUsage = value * 150)
      : isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId)
      ? (calculatedTotalUsage = value * currentJob.treatmentDetails.dosageRate)
      : this.setCalculatedTotalUsage(actions, currentJob, value);
    this.updateTotalUsageFrom(calculatedTotalUsage, mbTopUp);
  };

  onChangeMbTopUp = (event, value) => {
    const { actions, currentJob, clientsVolume } = this.props;
    const calculatedTotalUsage = this.setCalculatedTotalUsage(
      actions,
      currentJob,
      clientsVolume,
    );
    this.updateTotalUsageFrom(calculatedTotalUsage, value);
  };

  setCalculatedTotalUsage = (actions, currentJob, value) => {
    let calculatedTotalUsage = 0;

    if (value) {
      calculatedTotalUsage = CalculationUtils.calculateDosageKgBasedOnVolume(
        value,
        currentJob,
      );
    }

    actions.changeFieldValue(
      treatmentResultsForm,
      'calculatedTotalUsage',
      calculatedTotalUsage,
    );

    return calculatedTotalUsage;
  };

  updateTotalUsageFrom = (calculatedTotalUsage, mbTopUp) => {
    const { actions } = this.props;
    let totalUsage = calculatedTotalUsage;
    if (mbTopUp) {
      totalUsage = CalculationUtils.calculateTotalUsageWithMethylBromideTopUp(
        calculatedTotalUsage,
        mbTopUp,
      );
    }
    actions.changeFieldValue(treatmentResultsForm, 'totalUsage', totalUsage);
  };

  onChangeStartDate = (event, value) => {
    value = roundToPrevious(value.startOf('minute'), 5);
    const isRequired =
      value !== null && typeof value === 'object' && typeof value !== 'string';
    this.setState((prevState) => ({
      isFumigating: `Fumigating Technician ${isRequired ? '*' : ''}`,
      fumigatingRequiredValidator: isRequired ? Validators.required : null,
    }));
  };

  onChangeEndDate = (event, value) => {
    const isRequired =
      value !== null && typeof value === 'object' && typeof value !== 'string';
    this.setState((prevState) => ({
      isVenting: `Venting Technician ${isRequired ? '*' : ''}`,
      ventingRequiredValidator: isRequired ? Validators.required : null,
    }));
  };

  onChangeTotalMinutes(value) {
    const { actions } = this.props;
    let hours = value / 60;
    actions.changeFieldValue(treatmentResultsForm, 'totalUsage', hours);
  }
  render() {
    const {
      technicians,
      handleSubmit,
      isSubmitting,
      currentJob,
      formValues,
      markings,
      clientDetails,
      initialDosage,
      topupDosage
     // stateData,   
     } = this.props;
    const { canEditJob } = currentJob;
    const {
      isVenting,
      isFumigating,
      fumigatingRequiredValidator,
      ventingRequiredValidator,
    } = this.state;
    const isImportOtherAndGasFreeBool = isImportOtherAndGasFree(
      { id: currentJob.typeId },
      currentJob.treatmentDetails.treatmentTypeId,
    );
    let status = 'Active';
    if (clientDetails !== null) {
      status = clientDetails.status;
    }
    console.log('')
    const isChamberHCN = (isImportOther(currentJob.type))&&isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId);
    const isContainer = isImportOrExportAndContainer(currentJob.type);
    const showSachetFields = false;
    const dosageArray = [{id:1,value:0.5},{id:2,value:1.0},{id:3,value:1.5},{id:4,value:2.0},{id:5,value:2.5},{id:6,value:3.0}]
    const topupArray = [{id:1,value:0.5},{id:2,value:1.0},{id:3,value:1.5},{id:4,value:2.0},{id:5,value:2.5},{id:6,value:3.0}]

    const actualTemperatureRequired =
      ((isImportOther({ id: currentJob.typeId })|| isExportOther({id: currentJob.typeId}))&& currentJob.currentState!="assigned") ||
      (isContainer && isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId) && currentJob.currentState !='assigned')||
      (isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) &&
        !isExportOther({ id: currentJob.typeId }));

    const ventDateRequired =
      isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) &
      (isImportOrExportAndContainer({ id: currentJob.typeId }) ||
        isOtherJobType({ id: currentJob.typeId }));

    const compTechs = getCompetentTechnicians(
      technicians,
      currentJob.treatmentDetails.treatmentTypeId,
      currentJob.treatmentTypeDetails.commodity,
      currentJob.treatmentDetails.standardId,
      currentJob.typeId,
      currentJob.areaId,
    );
    var iniDose = 0;
    var topDose = 0;
    if(initialDosage!=undefined){
      iniDose = initialDosage;
    }
    if(topupDosage!=undefined){
      topDose = topupDosage
    }

    var validateVentTechTest = !isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId)? ventingRequiredValidator:false
    
    var validateVentTech = validateVentTechTest?'Require':undefined

    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <fieldset disabled={!canEditJob && 'disabled'}>
          <Row>
            <FormGroup className="col-md-12">
              <h5>Start Details</h5>
            </FormGroup>
            {!isGasFree(currentJob.treatmentDetails.treatmentTypeId)?(
            <FormGroup className="col-md-3">
              <Field
                name="startDate"
                label="Start Date *"
                component={DatePickerField}
                showTimeSelect
                defaultToEmpty
                type="text"
                placeholder="Enter Start Date"
                validate={isMacSlay(currentJob.treatmentDetails.treatmentTypeId)?null:[Validators.isValidDateAndTime, Validators.required]}
                timeIntervals={1}
                onChange={isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId)||isMacSlay(currentJob.treatmentDetails.treatmentTypeId)?null: this.onChangeStartDate}
              />
            </FormGroup>
            ):null}
              {isVaporPhos(currentJob.treatmentDetails.treatmentTypeId) 
              ||isAlPVessel(currentJob.treatmentDetails.treatmentTypeId)? (
              <FormGroup className="col-md-2">
                <Field
                  name="initialDosage"
                  label= "Initial Dose"
                  component={SearchableSelect}
                  placeholder="Initial dose gm"
                  options={dosageArray}
                  valueKey="value"
                  labelKey="value"
                  disabled={userIsCustomer()?true:false}
                  validate={Validators.dropDownNumber}
                />
              </FormGroup>
            ) : <FormGroup className="col-md-2"></FormGroup>}
            {isVaporPhos(currentJob.treatmentDetails.treatmentTypeId) ||isAlPVessel(currentJob.treatmentDetails.treatmentTypeId)? (
              <FormGroup className="col-md-2">
                <Field
                  name="topupDosage"
                  label= "Topup Dose"
                  component={SearchableSelect}
                  placeholder="Topup dose gm"
                  options={topupArray}
                  disabled={userIsCustomer()?true:false}
                  valueKey="value"
                  labelKey="value"
                  validate={Validators.dropDownNumber}
                />
              </FormGroup>
            ) : <FormGroup className="col-md-5"></FormGroup>}
            {isChamberHCN? 
          <FormGroup className={"col-md-4"} >
            <Field
            name = "numberOfSachets"
            label={'Number of Tins Used'}
            component={RenderField}
            readOnly={status === 'Inactive'}
            type="text"
            placeholder={"Enter Number Of Tins used"}
            />
          </FormGroup>  :null
          }
  {!(isGasFree(currentJob.treatmentDetails.treatmentTypeId)||isMacSlay(currentJob.treatmentDetails.treatmentTypeId))?(
            <FormGroup className="col-md-4">
              <Field
                name="clientsVolume"
                label={isChamberHCN? 'Room Size':
                  isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) ||
                  isVaporPhos(currentJob.treatmentDetails.treatmentTypeId)
                    ? 'Total Hold Volume'
                    : isWaterBlasting(
                        currentJob.treatmentDetails.treatmentTypeId,
                      )
                    ? 'Total Volume'
                    : isVapormate(currentJob.treatmentDetails.treatmentTypeId)
                    ? 'Clients Volume'
                    : isCallington1Shot(
                        currentJob.treatmentDetails.treatmentTypeId,
                      )
                    ? 'Total Cans Used '
                    : `Clients Volume${
                        !isImportAndHeatTreatment({ id: currentJob.typeId })
                          ? ' *'
                          : ''
                      }`
                }
                component={RenderField}
                readOnly={status === 'Inactive'}
                type="text"
                placeholder={
                  isCallington1Shot(currentJob.treatmentDetails.treatmentTypeId)
                    ? 'Total Cans Used'
                    : isAlPVessel(
                        currentJob.treatmentDetails.treatmentTypeId,
                      ) ||
                      isVaporPhos(currentJob.treatmentDetails.treatmentTypeId)
                    ? 'Enter Total Hold Volume'
                    : 'Enter a Clients Volume'
                }
                validate={
                  !isImportAndHeatTreatment({ id: currentJob.typeId }) && !isGasFree(currentJob.treatmentDetails.treatmentTypeId)
                    ? [Validators.number, Validators.required]
                    : Validators.number
                }
                onChange={
                  !isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) &&
                  !isVaporPhos(currentJob.treatmentDetails.treatmentTypeId)
                    ? this.onChangeClientVolume
                    : null
                }
              />
            </FormGroup> ):null}
            {!isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId) &&
            !isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) &&
            !isVaporPhos(currentJob.treatmentDetails.treatmentTypeId) &&
            !isGasFree(currentJob.treatmentDetails.treatmentTypeId) && !isChamberHCN ? (
              <FormGroup className="col-md-2">
                <Field
                  name="coveringCharge"
                  label="Covering Charge"
                  component={CheckboxField}
                />
              </FormGroup>
            ) : null}

            {isChamberHCN?null:showSachetFields ||
            isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId) ? (
              <FormGroup className="col-md-4">
                <Field
                  name="numberOfSachets"
                  label={
                    isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId)
                      ? 'Total Minutes'
                      : 'Number of Sachets'
                  }
                  component={RenderField}
                  type="text"
                  placeholder={
                    isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId)
                      ? 'Enter Total Minutes'
                      : 'Enter Number of Sachets'
                  }
                  onChange={(e) => this.onChangeTotalMinutes(e.target.value)}
                />
              </FormGroup>
            ) : null}

            {showSachetFields ? (
              <FormGroup className="col-lg-4">
                <Field
                  name="sachetNumberList"
                  label={'Sachet #'}
                  component={CustomTagSelect}
                  options={[]}
                  type="text"
                  multi
                  placeholder="Enter Sachet #"
                />
              </FormGroup>
            ) : null}
           {isMethylBromide(currentJob.treatmentDetails.treatmentTypeId)?
               <Col xs="6" lg="4">
                <PlainTextField
                  name="totalMb"
                  label="Total MB"
                  value={initialDosage}
                  format={nicelyRoundUp}
                />
              </Col> :null}
            {isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId) ? (
              <FormGroup className="col-md-4">
                <Field
                  name="totalUsage"
                  label={'Mins to Hours'}
                  component={RenderField}
                  readOnly
                  format={nicelyRoundUp}
                />
              </FormGroup>
            ) : null}

            {isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) ? (
              <FormGroup className="col-lg-4">
                <Field
                  name="mbTopUp"
                  label="MB Top Up (kg)"
                  component={RenderField}
                  validate={Validators.number}
                  readOnly={status == 'Inactive'}
                  type="text"
                  placeholder="Enter MB Top Up"
                  onChange={this.onChangeMbTopUp}
                />
              </FormGroup>
            ) : null}
            {/*isChamberHCN ?(
            <Col xs="6" lg="4">
            <PlainTextField
              name="rows"
              label={'Total Cans Required (Est)'}
              value={
                currentJob && currentJob.treatmentResultRecords
                  ? currentJob.treatmentResultRecords.length
                  : 0
              }
            />
          </Col>):null
            
            */ }
            {isChamberHCN||isMacSlay(currentJob.treatmentDetails.treatmentTypeId)?null: !isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId) && ! isGasFree(currentJob.treatmentDetails.treatmentTypeId) ? (
              <FormGroup className="col-md-4">
                <Field
                  name="totalUsage"
                  label={
                    isCallington1Shot(
                      currentJob.treatmentDetails.treatmentTypeId,
                    ) || isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId)
                      ? 'Total Usage (gm)'
                      : 'Total Usage (kg)'
                  }
                  component={RenderField}
                  readOnly={status === 'Inactive'}
                  format={isChamberHCN?null:
                    isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId)
                      ? roundToWholeNumber
                      : !isAlPVessel(
                          currentJob.treatmentDetails.treatmentTypeId,
                        ) &&
                        !isVaporPhos(
                          currentJob.treatmentDetails.treatmentTypeId,
                        ) &&
                        !isMethylBromide(
                          currentJob.treatmentDetails.treatmentTypeId,
                        )
                      ? nicelyRoundUp
                      : null
                  }
                />
              </FormGroup>
            ) : null}
                        {/*isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="roomSize"
                 label={"Room M3"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Room Size"
                >
                </Field>
                 </FormGroup>
                        ):null*/}
            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="gramsRequired"
                 label={"Grams Required"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Grams Required"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
            ):null}
            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="batchNumber"
                 label={"Batch / Tin #"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Batch Number"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
            ):null}{isChamberHCN?(
              <FormGroup className="col-md-4">
              <Field
                name="expiryDate"
                label={"Expiry Date (dd/MM/yyyy)"}
                component={DatePickerField}
                //value={userInput}
               // type="text"
                //placeholder="Enter yyyymmmdd Expiry Date"
              // onChange={this.handleInputChange}
               >
               </Field>
                </FormGroup>
           ):null}
            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="gramsFromFullTin"
                 label={"Grams from Full Tin"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Grams from full tin"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
            ):null}
             {/*isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="numberOfTinsUsed"
                 label={"Number Of Tins used"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter number of tins used"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
             ):null*/}
            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="gramsFromSplitTin"
                 label={"Grams From split tin"}
                 component={RenderField}
                 type="numeric"
                 placeholder="Enter Grams from split tin"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
            ):null}
            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="discUsed"
                 label={"Number of Disc Used"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter number of disc used"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
            ):null}
            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="packagingPerforated"
                 label={"Packaging Perforated"}
                 component={RenderField}
                 type="text"
                 placeholder="Is packaging perforated"
                >
                </Field>
                 </FormGroup>
            ):null}

            {!isWaterBlasting(currentJob.treatmentDetails.treatmentTypeId) ? (
              <FormGroup className="col-md-4">
                <Field
                  name="fumigatingTechnicianId"
                  label={isFumigating}
                  component={SearchableSelect}
                  placeholder="Please select a technician"
                  options={compTechs}
                  valueKey="id"
                  labelKey="name"
                  disabled={userIsCustomer()?true:false}
                  validate={isMacSlay(currentJob.treatmentDetails.treatmentTypeId)?null:fumigatingRequiredValidator}
                />
              </FormGroup>
            ) : null}

              {isChamberHCN?(
                <FormGroup className={"col-md=4"} >
              <Field 
              name = "totalUsage"
              label = "Historic Usage (KG)"
              component={RenderField}
              readOnly = {true}
              />
              </FormGroup> ):null
              //totalUsage
              
              }

          </Row>
          <FormGroup className="col-lg-12" />
          {isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) ? (
            <FormGroup className="col-lg-10">
              MB Bottle S/N *
              <FieldArray
                name="mbBottles"
                label=""
                component={MBMarkingsFieldArray}
                validate={Validators.mandatoryList}
              />
            </FormGroup>
          ) : null}
          <FormGroup className="col-lg-12" />
          {isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) ||
          isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId) ||
          isVaporPhos(currentJob.treatmentDetails.treatmentTypeId) ? (
            <Col xs="12" lg="12">
              <PlainTextField
                name="markings"
                label="Markings"
                value={
                  markings
                    ? markings
                        .map((marking) =>
                          isContainer
                            ? `${marking.containerNumber}${
                                marking.sealNumber
                                  ? ` ${marking.sealNumber}`
                                  : ''
                              }`
                            : marking.markingNumber,
                        )
                        .join(', ')
                    : ''
                }
              />
            </Col>
          ) : null}
          {!isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) &&
          !isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId) &&
          !isVaporPhos(currentJob.treatmentDetails.treatmentTypeId)&&
          !isGasFree(currentJob.treatmentDetails.treatmentTypeId)
            ? markings &&
              markings.length > 0 && (
                <Row style={{ paddingTop: 25 }}>
                  <FormGroup className="col-md-12">
                    <h5>Markings</h5>
                  </FormGroup>
                  <FormGroup className="col-md-12">
                    <FieldArray
                      name="markings"
                      component={MarkingsSightedFieldArray}
                      jobType={currentJob.type}
                    />
                  </FormGroup>
                </Row>
              )
            : null}
          {currentJob.currentState !== 'assigned' &&
          isGasFree(currentJob.treatmentDetails.treatmentTypeId)||
          !isOtherAndWaterBlasting(
            { id: currentJob.typeId },
            currentJob.treatmentDetails.treatmentTypeId,
          ) &&
          !isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) &&
          !isVaporPhos(currentJob.treatmentDetails.treatmentTypeId) ? (
            <Row style={{ paddingTop: '25px' }}>
              <FormGroup className="col-md-12">
                {isGasFree(currentJob.treatmentDetails.treatmentTypeId)?<h5>Start Details</h5>:<h5>End Details</h5>}
              </FormGroup>
              {!isGasFree(currentJob.treatmentDetails.treatmentTypeId)?(
              <FormGroup className="col-md-4">
                <Field
                  name="actualTemperature"
                  label={
                    actualTemperatureRequired && isChamberHCN? "Min Recorded Temp": actualTemperatureRequired
                      ? 'Actual Temperature *'
                      : 'Actual Temperature'
                  }
                  component={RenderField}
                  type="text"
                  placeholder="Enter an Actual Temperature"
                  validate={actualTemperatureRequired?[
                    actualTemperatureRequired && Validators.required,
                    Validators.number,
                  ].filter(Boolean):null}
                />
              </FormGroup>):null}
              {!isImportAndHeatTreatment({ id: currentJob.typeId }) && !isGasFree(currentJob.treatmentDetails.treatmentTypeId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="thermometerNumber"
                    label="Thermo #"
                    component={RenderField}
                    type="text"
                    placeholder="Enter a Thermo #"
                  />
                </FormGroup>
              ) : null}
                           { isChamberHCN ?(
             <FormGroup className="col-md-4">
                  <Field
                    name="correctionFactor"
                    label="Thermo Correction Factor"
                    component={RenderField}
                    type="text"
                    placeholder="Enter Thermo Correction factor"
                  />
                </FormGroup>):null}
              <FormGroup className="col-md-4">
                <Field
                  name="endDate"
                  //label={`End Date ${isImportOtherAndGasFreeBool ? '' : '*'}`}
                  label={isImportOtherAndGasFreeBool ? "Start Date *" : "End Date"}
                  component={DatePickerField}
                  showTimeSelect
                  type="text"
                  placeholder="Enter End Date"
                  defaultToEmpty
                  validate={isMacSlay(currentJob.treatmentDetails.treatmentTypeId)?false:
                    isImportOtherAndGasFreeBool ||isImportOther({ id: currentJob.typeId})||isExportOther({id:currentJob.typeId})||isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId)
                     || isVapormate(currentJob.treatmentDetails.treatmentTypeId) 
                     ? [Validators.isValidDateAndTime]
                      : [Validators.isValidDateAndTime, Validators.required]
                  }
                  timeIntervals={1}
                  onChange={this.onChangeEndDate}
                />
              </FormGroup>
              {!isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) &&
              !isAlPNonVessel(currentJob.treatmentDetails.treatmentTypeId) &&
              !isGasFree(currentJob.treatmentDetails.treatmentTypeId) && ! isChamberHCN ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="unintentionalRelease"
                    label="Unintentional Release"
                    component={SearchableSelect}
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    valueKey={'value'}
                    labelKey={'label'}
                  />
                </FormGroup>
              ) : null}
              {isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="recaptured"
                    label={
                      isCallington1Shot(
                        currentJob.treatmentDetails.treatmentTypeId,
                      )
                        ? 'Recaptured'
                        : 'Recaptured *'
                    }
                    component={SearchableSelect}
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                    valueKey={'value'}
                    labelKey={'label'}
                    validate={
                      isCallington1Shot(
                        currentJob.treatmentDetails.treatmentTypeId,
                      )||  currentJob.currentState==='assigned'
                        ? null
                        : Validators.required
                    }
                  />
                </FormGroup>
              ) : null}
              {isImportOtherAndGasFreeBool ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="residueRemoved"
                    label="Residue Removed"
                    component={CheckboxField}
                  />
                </FormGroup>
              ) : null}
                                  {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="verificationMethod"
                 label={"Verification Method"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Verification Method"
                >
                </Field>
                 </FormGroup>
            ):null}

            {isChamberHCN ? (         
               <FormGroup className="col-sm-4 align-self-center">
            <Field
              name="status"
              label="Status"
              component={ApprovalField}
              validate={Validators.required}
            />
          </FormGroup>):null}
              {!isImportAndHeatTreatment({ id: currentJob.typeId })  && ! isGasFree(currentJob.treatmentDetails.treatmentTypeId)? (
                <FormGroup className="col-md-4">
                  <Field
                    name="ventingTechnicianId"
                    label={isVenting}
                    component={SearchableSelect}
                    placeholder="Please select a technician"
                    options={technicians}
                    valueKey="id"
                    labelKey="name"
                    validate={isMacSlay(currentJob.treatmentDetails.treatmentTypeId)?false:isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId)?Validators.isFumigatingHCNTechnicianRequired:Validators.isFumigatingTechnicianRequired}
                  />
                </FormGroup>
              ) : null}
            </Row>
          ) : null}
          <Row>
            {!isAlPVessel(currentJob.treatmentDetails.treatmentTypeId) &&
            !isVaporPhos(currentJob.treatmentDetails.treatmentTypeId) &&
            !isGasFree(currentJob.treatmentDetails.treatmentTypeId) && !isChamberHCN? (
              <FormGroup className="col-md-4">
                <Field
                  name="extraHours"
                  label="Extra Hours"
                  component={RenderField}
                  type="text"
                  disabled
                  validate={Validators.number}
                  normalize={isBetweenOneAndNinityNine}
                  placeholder="Extra Hours to be charged (1-99)"
                />
              </FormGroup>
            ) : null}
                      {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="reseallerNumber"
                 label={"Resealler #"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Resealler number"
                 Validators={isNumber}
                >
                </Field>
                 </FormGroup>
            ):null}

            {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="discResealled"
                 label={"Disc Resealled#"}
                 component={RenderField}
                 type="text"
                 placeholder="Enter Disc Resealled"
                >
                </Field>
                 </FormGroup>
            ):null}
              {isChamberHCN?(
               <FormGroup className="col-md-4">
               <Field
                 name="notes"
                 label={"Comments/Notes"}
                 component={RenderField}
                 type="text"
                >
                </Field>
                 </FormGroup>
            ):null}

            {ventDateRequired && currentJob.currentState !== 'assigned' ? (
              <FormGroup className="col-md-4">
                <Field
                  name="ventDateTime"
                  label="Vent Date/Time"
                  component={DatePickerField}
                  defaultToEmpty
                  showTimeSelect
                  type="text"
                  placeholder="Enter Vent Date/Time"
                  timeIntervals={1}
                />
              </FormGroup>
            ) : null}
            {isOtherAndWaterBlasting(
              { id: currentJob.typeId },
              currentJob.treatmentDetails.treatmentTypeId,
            ) ? (
              <FormGroup className="col-md-4">
                <Field
                  name="endDate"
                  label={`End Date *`}
                  component={DatePickerField}
                  showTimeSelect
                  type="text"
                  placeholder="Enter End Date"
                  defaultToEmpty
                  validate={[
                    Validators.isValidDateAndTime,
                    Validators.required,
                  ]}
                  timeIntervals={1}
                  onChange={this.onChangeEndDate}
                />
              </FormGroup>
            ) : null}
          </Row>
          <Row>
            <FormGroup className="col-md-4">
              {status === 'Active' && !userIsCustomer() ? (
                <Button
                  color="primary"
                  className="mt-3"
                  disabled={isSubmitting}
                >
                  Save Treatment Details
                </Button>
              ) : null}
            </FormGroup>
            <FormGroup className="col-8">
              <JobSignoffAlerts
                currentJob={{
                  ...currentJob,
                  treatmentResults: { ...formValues },
                }}
              />
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    );
  }
}

/*const mapStateToProps = state =>{
  return {stateData: state.currentJob};
};*/
TreatmentResultsForm.propTypes = propTypes;

//export default connect(mapStateToProps)(TreatmentResultsForm);
export default TreatmentResultsForm;
