import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import * as SelectHelper from 'util/SelectHelper';

const getJobTypes = (state, props) => state.referenceData.jobTypes;
const getRouteJobTypeId = (state, props) => props.match.params.id;

export const getJobTypeById = createSelector(
  getJobTypes,
  getRouteJobTypeId,
  (jobTypes, jobTypeId) => {
    return jobTypes.find(jobType => jobType.id === jobTypeId);
  }
);

const getContacts = state => state.referenceData.contacts;
const getAreaId = (state, areaId) => areaId;
const getNoticeKey = (state, areaId, noticeKey) => noticeKey;

export const getContactsByAreaId = createCachedSelector(
  getContacts,
  getAreaId,
  (contacts, areaId) => {
    return SelectHelper.getContactsByAreaId(contacts, areaId);
  }
)((state, areaId) => areaId);

export const getDefaultContactsByNoticeKey = createCachedSelector(
  getContactsByAreaId,
  getNoticeKey,
  (contacts, noticeKey) => {
    return SelectHelper.getDefaultContactsByNoticeKey(contacts, noticeKey);
  }
)((state, areaId, noticeKey) => noticeKey);

const getCurrentJobState = (state, props) =>
  state.currentJob ? state.currentJob.currentState : null;

export const getDefaultSendNoticeOfIntentionValue = createSelector(
  getCurrentJobState,
  currentJobState => {
    const isNewJob = !currentJobState || currentJobState === 'new';
    return isNewJob;
  }
);

export const getDefaultSendNoticeOfCompletion = createSelector(
  getCurrentJobState,
  currentJobState => {
    const isInProgress = !currentJobState || currentJobState === 'in progress';
    return isInProgress;
  }
);
