import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import classNames from 'classnames';

const propTypes = {
  active: PropTypes.bool,
  outline: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  ligature: PropTypes.string
};

const ToggleButton = ({
  active,
  outline,
  color,
  onClick,
  className,
  ligature
}) => {
  const handleClick = () => onClick();
  return (
    <Button
      outline={outline}
      color={color}
      className={classNames('btn-toggle', className, {
        'is-active': active
      })}
      onClick={handleClick}
    >
      <i aria-hidden="true" className="material-icons">
        {ligature}
      </i>
    </Button>
  );
};

ToggleButton.propTypes = propTypes;

export default ToggleButton;
