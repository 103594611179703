import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { isDirty } from 'redux-form';

import * as jobActions from '../../../actions/jobActions';
import {
  treatmentResultRecordsForm,
  treatmentResultRecordsUncoveringForm,
} from '../../../constants/FormConstants';

import TreatmentResultRecordsList from './TreatmentResultRecordsList';

function mapStateToProps(state) {
  const { referenceData, currentJob } = state;
  const clientDetails = state.clientDetails;
  const treatmentResultRecords = currentJob.treatmentResultRecords || [];

  return {
    clientDetails,
    technicians: referenceData.technicians,
    treatmentResultRecords: treatmentResultRecords,
    jobTypeImageUrl: currentJob.type.imageUrlFull,
    jobType: currentJob.type,
    currentJob: currentJob,
    isTreatmentResultModalDirty:
      isDirty(treatmentResultRecordsForm)(state) ||
      isDirty(treatmentResultRecordsUncoveringForm)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobActions,
      },
      dispatch,
    ),
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TreatmentResultRecordsList,
);
