import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import DocumentField from './DocumentField';

const propTypes = {
  fields: PropTypes.object,
  readOnly: PropTypes.bool
};

const hasVisibleFields = fields => {
  let arrayFields = fields.getAll();

  return arrayFields
    ? arrayFields.filter(field => !field.removed).length > 0
    : false;
};

const DocumentFieldArray = ({ fields, readOnly = false }) => {
  if (!hasVisibleFields(fields)) return null;
  return (
    <ul className="list-unstyled app-document-list">
      {fields.map((field, index) => (
        <Field
          name={`${field}`}
          readOnly={readOnly}
          component={DocumentField}
          key={index}
        />
      ))}
    </ul>
  );
};

DocumentFieldArray.propTypes = propTypes;

export default DocumentFieldArray;
