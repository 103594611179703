import { connect } from 'react-redux';
import JobDocuments from './JobDocuments';

function mapStateToProps(state) {
  return {
    attachments: state.currentJob.attachments
  };
}

export default connect(mapStateToProps)(JobDocuments);
