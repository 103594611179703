import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import cx from 'classnames';
import _ from 'lodash';

import ResponsiveModal from '../../common/ResponsiveModal';

import { withShowMonitoring } from './enhancers';

import TreatmentResultRecordsForm from './TreatmentResultRecordsForm';

import styles from './TreatmentResultRecordsModal.module.scss';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  currentTreatmentResultRecord: PropTypes.object,
  showMonitoring: PropTypes.bool,
};

class TreatmentResultRecordsModal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    // deep equal
    return !_.isEqual(nextProps, this.props);
  }

  render() {
    const {
      title,
      isOpen,
      toggle,
      onSubmit,
      currentTreatmentResultRecord,
      showMonitoring,
      jobType,
      treatmentTypeId,
      currentJob,
    } = this.props;


    return (
      <ResponsiveModal
        size="lg"
        title={title}
        isOpen={isOpen}
        toggle={toggle}
        className={cx({ [styles.largeModal]: showMonitoring })}
      >
        <ModalHeader toggle={toggle}>{title || ''}</ModalHeader>
        <ModalBody className={styles.modalBody}>
          <TreatmentResultRecordsForm
            currentTreatmentResultRecord={currentTreatmentResultRecord}
            jobType ={jobType}
            onSubmit={onSubmit}
            treatmentTypeId={treatmentTypeId}
            currentJob ={currentJob}
          />
        </ModalBody>
      </ResponsiveModal>
    );
  }
}

TreatmentResultRecordsModal.propTypes = propTypes;

export default withShowMonitoring(TreatmentResultRecordsModal);
