import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as userColumnVisibilitiesActions from 'actions/userColumnVisibilitiesActions';
import { getDefinitionForWorkflow } from 'selectors/jobTableDefinitionSelector';
import { HeaderDropDown } from 'components/layout';
import Checkbubble, {
  CHECKBUBLE_SIZE_SMALL
} from 'components/common/Checkbubble';

import './styles.css';

class TableColumnsDropDown extends React.Component {
  handleItemClick(column) {
    this.props.actions.setUserColumnVisibility(
      this.props.match.params.state.replace('-', ' '),
      column.key,
      !column.visible
    );
  }

  handleResetItemClick() {
    const workflowState = this.props.match.params.state.replace('-', ' ');
    this.props.actions.deleteUserColumnVisibilities(workflowState);
  }

  handleCheckboxClick(e) {
    e.stopPropagation();
  }

  handleClose() {
    const workflowState = this.props.match.params.state.replace('-', ' ');
    this.props.actions.saveUserColumnVisibilities(workflowState);
  }

  render() {
    const { jobTableDefinition } = this.props;
    return (
      <HeaderDropDown
        className="table-columns-drop-down"
        ligature="more_horiz"
        onClose={this.handleClose.bind(this)}
      >
        <div
          className="table-columns-drop-down-item"
          onClick={this.handleResetItemClick.bind(this)}
        >
          <span>{'Reset to default'}</span>
        </div>

        {jobTableDefinition.map(column => (
          <div
            className="table-columns-drop-down-item"
            key={column.key}
            onClick={this.handleItemClick.bind(this, column)}
          >
            <Checkbubble
              checked={column.visible}
              size={CHECKBUBLE_SIZE_SMALL}
              onClick={this.handleCheckboxClick.bind(this)}
            />
            <span>{column.name}</span>
          </div>
        ))}
      </HeaderDropDown>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    jobTableDefinition: getDefinitionForWorkflow(state, ownProps)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userColumnVisibilitiesActions
      },
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TableColumnsDropDown);
