import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function formInitialValuesReducer(
  state = initialState.formInitialValues,
  action
) {
  switch (action.type) {
    case types.LOAD_INITIAL_VALUES:
      return {
        ...state,
        ...action.initialValues
      };
    default:
      return state;
  }
}
