import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Header, HeaderTitle } from 'components/layout';
import AreasListTable from './AreasListTable';

const propTypes = {
  history: PropTypes.object,
  areas: PropTypes.array
};

class AreasList extends Component {
  state = {
    showOverlay: false
  };

  toggleNavigation = () => {
    this.setState(prevState => ({
      showOverlay: !prevState.showOverlay
    }));
  };

  render() {
    const { history, areas } = this.props;

    const options = {
      onRowClick: function(row) {
        history.push('/areas/' + row.id);
      }
    };

    return (
      <div>
        <Header showLogo>
          <HeaderTitle label={'Areas'} />
        </Header>
        <AreasListTable options={options} areas={areas} />
      </div>
    );
  }
}

AreasList.propTypes = propTypes;

function mapStateToProps(state) {
  return { areas: state.referenceData.areas };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasList);
