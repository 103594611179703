import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container, FormGroup } from 'reactstrap';
import {
  isImportJobType,
  isImportAndHeatTreatment,
  isExportJobType,
  isExportAndLogs,
  isExportOther,
  isImportOtherAndGasFree,
  isMBLogsInHold,
} from 'util/JobTypeHelper';
import RenderField from 'components/fields/RenderField';
import DatePickerField from 'components/fields/DatePickerField';
import SearchableSelect from 'components/fields/SearchableSelect';
import CheckboxField from 'components/fields/CheckboxField';
import * as Validators from 'util/Validators';
import { Band } from 'components/layout';
import { jobDetailsForm } from 'constants/FormConstants';
import { userCanAccessAdmin, userIsCustomer } from 'util/AdGroupsHelper';
import { getClientByCode } from 'util/JobHelper';
import { getCurrentJob } from '../jobs/treatmentResultRecords/selectors';
import {
  isWaterBlasting,
  isVaporPhos,
  isAlPVessel,
  isAlPNonVessel,
  isGasFree,
} from '../../util/TreatmentTypeHelper';

const propTypes = {
  jobLocations: PropTypes.array,
  onSelectArea: PropTypes.func,
  onSelectLocation:PropTypes.func,
  areas: PropTypes.array,
};

const isLessThanOneMillion = (value) => {
  const limit = 999999;
  if (value < 0) {
    return 0;
  } else if (value > limit) {
    return limit;
  } else {
    return value;
  }
};

class ShipmentDetailsSection extends Component {
  onPrivateClientChange = (value) => {
    const { actions } = this.props;
    actions.change(jobDetailsForm, 'stopCredit', value === true);
  };

  onOverrideChange = (value) => {
    const { actions } = this.props;
    actions.change(jobDetailsForm, 'stopCredit', value === true);

    actions.change(jobDetailsForm, 'privateClient', value === true);
    //  actions.change(jobDetailsForm, 'donotcertify', value );
  };

  canEditClientType() {
    const { initialValues } = this.props;
    return (
      userCanAccessAdmin() ||
      !initialValues['currentState'] ||
      initialValues['currentState'] === 'new'
    );
  }

  isOrderNumberMandatory() {
    const { clientCode, clientSummaries } = this.props;
    const client = getClientByCode(clientCode, clientSummaries);
    return client && client.orderNumberMandatory;
  }

  render() {
    const {
      jobLocations,
      updatedArea,
      jobType,
      treatmentType,
      onSelectArea,
      disableLocationField,
      areas,
      initialValues,
      status,
      currentJob,
      stopCredit,
      vessels,
      onSelectLocation,
      // ,stopCredit,actions
    } = this.props;
    const importOtherGasFreeJob = isImportOtherAndGasFree(
      jobType,
      treatmentType,
    );
   
    // CHECK IF JOB LOCATION EXISTS IN DROPDOWN
    let filteredJobLocations = jobLocations;
    if (initialValues.location) {
      const currentLocation = filteredJobLocations.find(
        (location) => location.name === initialValues.location,
      );
      if (!currentLocation && !updatedArea) {
        filteredJobLocations = [
          { name: initialValues.location },
          ...filteredJobLocations,
        ];
      }
    }
  

    return (
      <Band dark>
        <section className="form-section">
          <Container>
            <h4>Shipment Details</h4>
            <div className="form-row align-items-center">
              {!userIsCustomer() ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="privateClient"
                    label="Private Client"
                    component={CheckboxField}
                    onChange={this.onPrivateClientChange}
                    disabled={
                      !this.canEditClientType() ||
                      status === 'Inactive' ||
                      stopCredit === true
                    }
                  />
                </FormGroup>
              ) : null}
              {!userIsCustomer() ? (
                <FormGroup className="col-md-4 ">
                  <Field
                    name="stopCredit"
                    label="Stop Credit"
                    component={CheckboxField}
                    disabled
                  />
                </FormGroup>
              ) : null}

              {!userIsCustomer() ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="doNotCertify" //"stopCreditOverride"
                    label="Do Not Certify"
                    component={CheckboxField}
                    onChange={this.onOverrideChange}
                    disabled={false}
                  />
                </FormGroup>
              ) : null}

              <FormGroup className="col-md-4">
                <Field
                  name="vessel"
                  label={`Vessel${
                    !isExportOther(jobType) && !isAlPNonVessel(treatmentType)
                      ? ' *'
                      : ''
                  }`}
                  component={SearchableSelect}
                  options={vessels}
                  labelKey="displayName"
                  valueKey="displayName"
                  disabled={status === 'Inactive' || stopCredit === true}
                  validate={
                    !isExportOther(jobType) && !isAlPNonVessel(treatmentType) && ! isGasFree(treatmentType)
                      ? Validators.required
                      : null
                  }
                />
              </FormGroup>
              {!isWaterBlasting(treatmentType) && !isGasFree(treatmentType)? (
                <FormGroup className="col-md-4">
                  <Field
                    name="voyage"
                    label={`Voyage${
                      !isExportAndLogs(jobType) &&
                      !isExportOther(jobType) &&
                      !isMBLogsInHold(jobType) &&
                      !isAlPVessel(treatmentType) &&
                      !isAlPNonVessel(treatmentType)&&
                      !isGasFree(treatmentType)
                        ? ' *'
                        : ''
                    }`}
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="text"
                    placeholder="Enter a Voyage"
                    validate={
                      !isExportAndLogs(jobType) &&
                      !isExportOther(jobType) &&
                      !isMBLogsInHold(jobType) &&
                      !isAlPNonVessel(treatmentType)&&
                      !isGasFree(treatmentType)
                        ? Validators.required
                        : null
                    }
                  />
                </FormGroup>
              ) : null}
              <FormGroup className="col-md-4">
                <Field
                  name={
                    isImportJobType(jobType)
                      ? 'importShipmentDetails.orderNumber'
                      : 'exportShipmentDetails.orderNumber'
                  }
                  label={`Order # ${this.isOrderNumberMandatory() ? '*' : ''}`}
                  component={RenderField}
                  readOnly={status === 'Inactive' || stopCredit === true}
                  type="text"
                  placeholder="Enter an Order Number"
                  validate={
                    this.isOrderNumberMandatory() ? Validators.required : null
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Field
                  name="areaId"
                  label="Area *"
                  component={SearchableSelect}
                  options={areas}
                  onSelect={onSelectArea}
                  disabled={status === 'Inactive' || stopCredit === true}
                  valueKey="id"
                  labelKey="displayName"
                  type="text"
                  validate={Validators.required}
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Field
                  name="location"
                  label="Location *"
                  component={SearchableSelect}
                  disabled={status === 'Inactive' || stopCredit === true}
                  options={filteredJobLocations}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Enter a Location"
                  validate={Validators.required}
                 onSelect={onSelectLocation}
                  allowCommasInValue
                />
              </FormGroup>
              {!isWaterBlasting(treatmentType) && ! isGasFree(treatmentType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="estimatedTimeOfArrival"
                    label="ETA *"
                    component={
                      status !== 'Inactive' && stopCredit !== true
                        ? DatePickerField
                        : RenderField
                    }
                    readOnly={status === 'Inactive' || stopCredit === true}
                    defaultToEmpty
                    type="text"
                    placeholder="Enter an ETA"
                    validate={[Validators.required, Validators.isValidDate]}
                  />
                </FormGroup>
              ) : null}
              <FormGroup className="col-md-4">
                <Field
                  name="dateOfRequest"
                  label="Date of Request"
                  component={
                    status !== 'Inactive' && stopCredit !== true
                      ? DatePickerField
                      : RenderField
                  }
                  readOnly={status === 'Inactive' || stopCredit === true}
                  type="text"
                  placeholder="DD/MM/YYYY"
                  validate={Validators.isValidDate}
                />
              </FormGroup>
              {isImportJobType(jobType) && !isGasFree(treatmentType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="importShipmentDetails._BillOfLading"
                    label="Bill of Lading"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="text"
                    placeholder="Enter a Bill of Lading"
                  />
                </FormGroup>
              ) : null}
              {isImportJobType(jobType) && !isGasFree(treatmentType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="importShipmentDetails.bacc"
                    label={`BACC # ${
                      importOtherGasFreeJob ||
                      isAlPVessel(treatmentType) ||
                      isAlPNonVessel(treatmentType)
                        ? ''
                        : '*'
                    }`}
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="text"
                    placeholder="Enter a BACC #"
                    validate={
                      importOtherGasFreeJob ||
                      isAlPNonVessel(treatmentType) ||
                      isAlPVessel(treatmentType)||isGasFree(treatmentType)
                        ? null
                        : Validators.required
                    }
                  />
                </FormGroup>
              ) : null}
              {isImportAndHeatTreatment(jobType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="importShipmentDetails.tpin"
                    label="TPIN"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    placeholder="Enter a TPIN"
                    validate={Validators.integer}
                  />
                </FormGroup>
              ) : null}
              {isExportJobType(jobType) &&
              !isExportAndLogs(jobType) &&
              !isWaterBlasting(treatmentType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="exportShipmentDetails.bookingReference"
                    label="Booking Reference"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="text"
                    placeholder="Enter a Booking Reference"
                  />
                </FormGroup>
              ) : null}
              {/*isExportAndLogs(jobType)?null:
              (!userIsCustomer() &&
              !isAlPVessel(treatmentType) &&
              !isVaporPhos(treatmentType)) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="exportShipmentDetails.vbCode"
                    label="VB Code/Link"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="text"
                    placeholder="Enter a VB Code/Link"
                  />
                </FormGroup>
              ) : null*/}
              {/*isExportAndLogs(jobType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="exportShipmentDetails.cargoLocation"
                    label="Cargo Location"
                    component={SearchableSelect}
                    options={[
                      { label: 'Deck Cargo', value: 'deck cargo' },
                      { label: 'In Hold', value: 'in hold' }
                    ]}
                    type="text"
                    placeholder="Select a Cargo Location"
                  />
                </FormGroup>
                  ) : null*/}

              {isExportAndLogs(jobType)?null:
              (!userIsCustomer() && !isWaterBlasting(treatmentType)) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="parentJobReference"
                    label="Parent Job #"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    normalize={isLessThanOneMillion}
                  />
                </FormGroup>
              ) : null}

              {!userIsCustomer() ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="invoiceNumber"
                    label="Invoice #"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="text"
                    placeholder="Enter an Invoice Number"
                  />
                </FormGroup>
              ) : null}

              {/*isExportAndLogs(jobType) || isExportOther(jobType)?null:
              !userIsCustomer() &&
              !isWaterBlasting(treatmentType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="exportShipmentDetails.splitVessel"
                    label="Split Vessel"
                    component={CheckboxField}
                    disabled={status === 'Inactive' || stopCredit === true}
                  />
                </FormGroup>
              ) : null}

              {/*isExportAndLogs(jobType) || isExportOther(jobType)?null:
              !userIsCustomer() &&
              !isWaterBlasting(treatmentType) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="exportShipmentDetails.splitVesselPercentage"
                    label="Split Vessel Percentage (%)"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    placeholder="Enter a Split Vessel Percentage (%)"
                    validate={Validators.percentage}
                  />
                </FormGroup>
              ) : null*/}
            </div>
          </Container>
        </section>
      </Band>
    );
  }
}

ShipmentDetailsSection.propTypes = propTypes;

export default ShipmentDetailsSection;
