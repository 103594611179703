import axios from 'axios';
import keycloakManager from '../keycloakManager';
import Logger from 'util/Logger';

// Custom Axios Instance
const operationsApi = axios.create();

// Custom instance defaults
const env = window.env;
operationsApi.defaults.baseURL = env.OPS_API_HOST + env.OPS_API_PATH;
operationsApi.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

/**
 * INTERCEPTORS
 */

// Request Interceptor
operationsApi.interceptors.request.use(requestSuccess, requestFailure);

function requestSuccess(config) {
  return keycloakManager
    .updateToken()
    .then(addTokenToHeader)
    .catch(() => keycloakManager.logout());

  function addTokenToHeader(token) {
    config.headers.common['Authorization'] = 'Bearer ' + token;
    return config;
  }
}

function requestFailure(error = {}) {
  Logger.error(error);
  return Promise.reject(error);
}

// Response Interceptor
operationsApi.interceptors.response.use(responseSuccess, responseFailure);

function responseSuccess(config) {
  return config;
}

function responseFailure(error = {}) {
  Logger.error(error.response.data.message, error.response.data);
  throw Promise.reject(error);
}

export default operationsApi;
