import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as locationActions from 'actions/locationActions';
import * as jobLocationActions from 'actions/jobLocationActions';
import * as formActions from 'actions/formActions';
import { confirmAlert } from 'util/Alerts';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';
import LocationsListTable from './LocationsListTable';

class LocationsList extends Component {
  state = {
    showOverlay: false,
    areaLoaded: false,
    locationsLoaded: false
  };

  componentDidMount() {
    const { match } = this.props;
    operationsApi.getArea(match.params.areaid).then(result => {
      return this.setState({
        areaLoaded: true,
        area: result.data
      });
    });

    operationsApi.getAreaLocations(match.params.areaid).then(result => {
      return this.setState({
        locationsLoaded: true,
        locations: result.data
      });
    });
  }

  toggleNavigation = () => {
    this.setState(prevState => ({
      showOverlay: !prevState.showOverlay
    }));
  };

  back = () => {
    const { match } = this.props;
    this.props.history.push('/areas/' + match.params.areaid);
  };

  render() {
    const { areaLoaded, locationsLoaded, area, locations } = this.state;
    const { history, match } = this.props;
    if (!areaLoaded || !locationsLoaded) return false;

    const options = {
      onRowDelete: row => {
        confirmAlert(
          'Confirm',
          'Are you sure you want to delete this location?'
        ).then(confirmed => {
          if (confirmed) {
            operationsApi.deleteLocation(row.id).then(() => {
              operationsApi
                .getAreaLocations(match.params.areaid)
                .then(result => {
                  return this.setState({
                    locations: result.data
                  });
                });
            });
          }
        });
      },
      onRowEdit: row => {
        history.push('/areas/' + row.areaId + '/edit/' + row.id);
      }
    };

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            <HeaderButton
              color="secondary"
              onClick={() => this.props.history.push('/areas')}
              ligature="keyboard_backspace"
            />
            <HeaderButton
              color="primary"
              onClick={() =>
                this.props.history.push(
                  '/areas/' + match.params.areaid + '/create'
                )
              }
              ligature="add"
            />
          </HeaderButtonNav>
          <HeaderTitle label={`Locations for ${area.displayName}`} />
        </Header>
        <LocationsListTable options={options} locations={locations} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...locationActions,
        ...formActions,
        ...jobLocationActions
      },
      dispatch
    )
  };
}

export default connect(null, mapDispatchToProps)(LocationsList);
