import React from 'react';
import { Alert } from 'reactstrap';
import { signoffWarnings } from 'util/WarningsHelper';

const JobSignoffAlerts = ({ currentJob }) => (
  <div>
    {Object.keys(signoffWarnings).map((key, index) => {
      const warning = signoffWarnings[key];
      return warning.showWarning(currentJob) ? (
        <Alert color="warning" key={index}>
          {warning.message}
        </Alert>
      ) : null;
    })}
  </div>
);

export default JobSignoffAlerts;
