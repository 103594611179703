import * as types from 'constants/actionTypes';

export function loadInitialValues(initialValues) {
  return { type: types.LOAD_INITIAL_VALUES, initialValues };
}

export function preventEnterSubmit(event) {
  if (event.key === 'Enter' && event.target.type !== 'submit') {
    event.preventDefault();
  }
}
