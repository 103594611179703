import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getJobLocations() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getJobLocations()
      .then(
        response => dispatch(getJobLocationsSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getJobLocationsSuccess(response) {
/*var locations =[response.data].sort((a,b)=>{a.data.localCompare(b.data)})

console.log('locationResp',locations)
  /*response = [response].sort((a, b) => {
    return a.data.localeCompare(b.data);
  });*/
  return {
    type: types.GET_JOB_LOCATIONS_SUCCESS,
    jobLocations: response.data || []
  };
}
