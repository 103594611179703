import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as jobActions from 'actions/jobActions';
import * as clientDetailsActions from 'actions/clientDetailsActions';
import * as jobLocationActions from 'actions/jobLocationActions';
import JobDetailsEditPage from './JobDetailsEditPage';
import {
  getDefaultContactsByNoticeKey,
  getDefaultSendNoticeOfIntentionValue
} from 'selectors/formSelectors';

function mapStateToProps(state, ownProps) {
  const { currentJob } = state;
  const{clientDetails} = state;
  const isCreditAllowed = clientDetails ? clientDetails.isCreditAllowed:'false'  ;
  return {
    isCreditAllowed,
    clientDetails,
    currentJob: currentJob && {
      ...currentJob,
      newNoticeOfIntendedTreatment: {
        sendNotice: getDefaultSendNoticeOfIntentionValue(state, ownProps),
        contactIds: getDefaultContactsByNoticeKey(
          state,
          currentJob.areaId,
          'defaultForNotificationOfIntent'
        )
      }
    },
    canEditJob: currentJob && currentJob.canEditJob ,
    jobId: ownProps.match.params.id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobActions,
        ...clientDetailsActions,
        ...jobLocationActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailsEditPage);
