import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Collapse } from 'reactstrap';
import AttachmentLinkList from 'components/common/AttachmentLinkList';
import { Band, ToggleButton } from 'components/layout';
import PreviewEmailModal from 'components/common/PreviewEmailModal';
import { getEmail } from 'api/operationsApi';
import { isVerified } from 'util/JobStateHelper';

const propTypes = {
  sentNotices: PropTypes.array
};

class JobNotices extends Component {
  state = {
    collapsed: isVerified(this.props.currentState) ? false : true,
    showPreviewEmail: false,
    email: null
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  previewNotice = emailId => {
    getEmail(emailId).then(response => {
      this.setState({
        showPreviewEmail: true,
        email: response.data
      });
    });
  };

  hideModal = emailId => {
    this.setState({
      showPreviewEmail: false
    });
  };

  getNoticeTitle = notice => {
    if (notice.noticeType === 10) return 'Notice of Intended Treatment';
    if (notice.noticeType === 11) return 'Cancelled Notification';
    if (notice.noticeType === 20) return 'Notice of Completion';
    if (notice.noticeType === 30) return 'Treatment Certificate';
  };

  render() {
    const { showPreviewEmail, email, collapsed } = this.state;
    const { sentNotices } = this.props;

    if (!sentNotices || !sentNotices.length) return false;

    return (
      <Band>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>Notices</h4>
              </Col>
              <Col xs="4" className="text-right">
                <ToggleButton
                  className="ml-3"
                  color="light"
                  active={collapsed}
                  onClick={this.toggleExpand}
                  ligature="expand_less"
                />
              </Col>
            </Row>
            <Collapse isOpen={!this.state.collapsed}>
              <Row className="align-items-center">
                <Col xs="6">
                  <AttachmentLinkList
                    iteratorKey="emailId"
                    attachments={sentNotices}
                    linkLabelFunction={this.getNoticeTitle}
                    ligature="email"
                    onClick={this.previewNotice}
                  />
                </Col>
              </Row>
            </Collapse>
          </Container>
        </div>
        {email ? (
          <PreviewEmailModal
            title="View Email"
            isOpen={showPreviewEmail}
            toggle={this.hideModal}
            emailContent={email.body}
            emailId={email.id}
            downloadFileName={email.subject}
          />
        ) : null}
      </Band>
    );
  }
}

JobNotices.propTypes = propTypes;

export default JobNotices;
