import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Container } from 'reactstrap';
import FileUploadField from 'components/fields/FileUploadField';
import DocumentFieldArray from 'components/fields/DocumentFieldArray';
import { Band } from 'components/layout';
import { JOB_ATTACHMENT_ACCEPTED_TYPES } from 'constants/jobConstants';

const propTypes = {
  onDrop: PropTypes.func,
  attachments: PropTypes.array,
  onRemove: PropTypes.func
};

const AttachmentsSection = () => {
  return (
    <Band dark>
      <section className="form-section">
        <Container>
          <h4>Attachments</h4>
          <FieldArray name="attachments" component={DocumentFieldArray} />
          <Field
            name="newAttachments"
            component={FileUploadField}
            accept={JOB_ATTACHMENT_ACCEPTED_TYPES.join(',')}
          />
        </Container>
      </section>
    </Band>
  );
};

AttachmentsSection.propTypes = propTypes;

export default AttachmentsSection;
