import { combineReducers } from 'redux';
import * as types from 'constants/actionTypes';
import initialState from './initialState';
import jobTypes from './jobTypeReducer';
import jobLocations from './jobLocationReducer';
import clientSummaries from './clientSummariesReducer';
import jobTableDefinitions from './jobTableDefinitionsReducer';
import userColumnVisibilities from './userColumnVisibilitiesReducer';
import jobStandards from './jobStandardsReducer';
import treatmentTypes from './treatmentTypesReducer';
import containerTypes from './containerTypesReducer';
import jobCategories from './jobCategoriesReducer';
import areas from './areasReducer';
import destinations from './destinationsReducer';
import commodities from './commoditiesReducer';
import technicians from './techniciansReducer';
import contacts from './contactsReducer';
import sizeOfContainers from './sizeOfContainersReducer';
import vessels from './vesselsReducer';
function referenceDataLoaded(
  state = initialState.referenceData.referenceDataLoaded,
  action,
) {
  if (action.type === types.LOAD_REFERENCE_DATA) {
    return action.referenceDataLoaded;
  }
  return state;
}

const referenceData = combineReducers({
  jobTypes,
  jobLocations,
  clientSummaries,
  referenceDataLoaded,
  jobTableDefinitions,
  userColumnVisibilities,
  jobStandards,
  treatmentTypes,
  containerTypes,
  jobCategories,
  areas,
  destinations,
  commodities,
  technicians,
  contacts,
  sizeOfContainers,
  vessels,
});

export default referenceData;
