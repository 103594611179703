import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isImportJobType } from 'util/JobTypeHelper';
import {
  isAssigned,
  isInProgress,
  isCompleted,
  isCertified,
  isFailed,
  isVerified
} from 'util/JobStateHelper';
import AppModal from 'components/common/AppModal';
import ResendNoticesFormContainer from './ResendNoticesFormContainer';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  currentState: PropTypes.string,
  jobType: PropTypes.object,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func
};

class ResendNotices extends Component {
  render() {
    const {
      title,
      isOpen,
      currentState,
      jobType,
      toggle,
      onSubmit
    } = this.props;
    return (
      <AppModal title={title} isOpen={isOpen} toggle={toggle}>
        {isAssigned(currentState) || isInProgress(currentState) ? (
          <ResendNoticesFormContainer
            onSubmit={onSubmit}
            submitLabel="Resend Notice of Intended Treatment"
            formFieldKey="newNoticeOfIntendedTreatment"
            noticeKey="defaultForNotificationOfIntent"
          />
        ) : null}
        {isCompleted(currentState) ||
        isVerified(currentState) ||
        isFailed(currentState) ? (
          <ResendNoticesFormContainer
            onSubmit={onSubmit}
            submitLabel="Resend Notice of Completion"
            formFieldKey="newNoticeOfCompletion"
            noticeKey="defaultForNotificationOfCompletion"
          />
        ) : null}
        {isCertified(currentState) ? (
          <ResendNoticesFormContainer
            onSubmit={onSubmit}
            submitLabel="Resend Treatment Certificate"
            formFieldKey="newTreatmentCertificate"
            noticeKey={
              isImportJobType(jobType)
                ? 'defaultForTreatmentCertificateImport'
                : 'defaultForTreatmentCertificateExport'
            }
          />
        ) : null}
      </AppModal>
    );
  }
}

ResendNotices.propTypes = propTypes;

export default ResendNotices;
