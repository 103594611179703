import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as technicianActions from 'actions/technicianActions';
import * as formActions from 'actions/formActions';
import TechnicianForm from 'components/technicians/TechnicianForm';
import Logger from 'util/Logger';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';

const propTypes = {
  actions: PropTypes.object
};

class CreateTechnicianPage extends Component {
  state = {
    loaded: false,
    isSubmitting: false
  };

  componentDidMount() {
    const { technicianId, mode } = this.props;
    if (mode === 'edit') {
      this.getTechnician(technicianId);
    }
  }

  createOrUpdateSuccess = message => {
    let { actions, history } = this.props;
    this.toggleFormSubmit();
    actions.getTechnicians();
    Logger.success(message);
    history.push('/technicians');
  };

  getTechnician = technicianId => {
    operationsApi.getTechnician(technicianId).then(result => {
      this.setState({
        loaded: true,
        technician: result.data
      });
    });
  };

  createTechnician = formValues => {
    this.toggleFormSubmit();
    operationsApi.createNewTechnician(formValues).then(() => {
      this.createOrUpdateSuccess('New Technician Created');
    });
  };

  updateTechnician = formValues => {
    this.toggleFormSubmit();
    operationsApi.updateTechnician(formValues).then(() => {
      this.createOrUpdateSuccess('Technician Updated');
    });
  };

  toggleFormSubmit = () => {
    this.setState(prevState => ({
      isSubmitting: !prevState.isSubmitting
    }));
  };

  cancel = () => {
    this.props.history.push('/technicians');
  };

  render() {
    const { mode } = this.props;
    const { loaded, technician } = this.state;

    if (mode === 'edit' && !loaded) {
      return null;
    }

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={this.cancel}
              ligature="clear"
            />
          </HeaderButtonNav>
          <HeaderTitle label={`Create a New Technician`} />
        </Header>
        <TechnicianForm
          onSubmit={
            mode === 'edit' ? this.updateTechnician : this.createTechnician
          }
          isSubmitting={this.state.isSubmitting}
          initialValues={mode === 'edit' ? technician : {}}
        />
      </div>
    );
  }
}

CreateTechnicianPage.propTypes = propTypes;

function mapStateToProps(state, ownProps) {
  return {
    mode: ownProps.match.params.action,
    technicianId: ownProps.match.params.id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...technicianActions,
        ...formActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  CreateTechnicianPage
);
