import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button, FormFeedback } from 'reactstrap';
import * as FileHelper from 'util/FileHelper';
import FileUploadAttachments from 'components/fields/FileUploadAttachments';
import classNames from 'classnames';

const propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object
};

const FileUploadField = ({
  onDrop,
  accept,
  input,
  meta: { touched, error, warning }
}) => {
  const currentFile = input.value;

  const handleDrop = uploadedFiles => {
    FileHelper.convertFileToBase64(uploadedFiles[0]).then(file => {
      input.onChange(file);
    });
  };

  const deleteFile = () => {
    input.onChange(null);
  };

  return (
    <div>
      <Dropzone
        className={classNames('app-dropzone', {
          error: touched && error
        })}
        acceptClassName="success"
        rejectClassName="error"
        onDropAccepted={handleDrop}
        accept={accept}
        multiple={false}
      >
        <div className="app-dropzone-content">
          <h2 className="app-dropzone-title">
            Drag &amp; Drop a file to upload
          </h2>
          <h5 className="app-dropzone-or">or</h5>
          <Button type="button" outline size="lg" color="primary">
            Browse
          </Button>
        </div>
      </Dropzone>
      {currentFile ? (
        <FileUploadAttachments files={[currentFile]} onDelete={deleteFile} />
      ) : null}
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};

FileUploadField.propTypes = propTypes;

export default FileUploadField;
