import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as technicianActions from 'actions/technicianActions';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';
import TechnicianListTable from './TechnicianListTable';

class TechnicianList extends Component {
  state = {
    showOverlay: false,
    loaded: false,
    technicians: []
  };

  componentDidMount() {
    operationsApi.getTechnicians().then(result => {
      return this.setState({
        loaded: true,
        technicians: result.data
      });
    });
  }

  toggleNavigation = () => {
    this.setState(prevState => ({
      showOverlay: !prevState.showOverlay
    }));
  };

  render() {
    const { loaded, technicians } = this.state;
    const { history, areas } = this.props;

    if (!loaded) return false;

    const options = {
      onRowClick: function(row) {
        history.push('/technicians/edit/' + row.id);
      }
    };

    return (
      <div>
        <Header showLogo>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={() => this.props.history.push('/technicians/create')}
              ligature="add"
            />
          </HeaderButtonNav>
          <HeaderTitle label={'Technicians'} />
        </Header>
        <TechnicianListTable
          technicians={technicians}
          options={options}
          areas={areas}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { areas: state.referenceData.areas };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...technicianActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianList);
