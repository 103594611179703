import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'reactstrap';
import AttachmentLink from './AttachmentLink';
import { formatDate } from 'util/DateHelper';

const propTypes = {
  attachments: PropTypes.array.isRequired,
  iteratorKey: PropTypes.string,
  linkLabelFunction: PropTypes.func,
  ligature: PropTypes.string,
  onClick: PropTypes.func
};

const AttachmentLinkList = ({
  attachments,
  iteratorKey,
  linkLabelFunction,
  ligature,
  onClick
}) => {
  if (!attachments || !attachments.length) return false;
  return (
    <ListGroup>
      {attachments.map(attachment => (
        <AttachmentLink
          key={attachment[iteratorKey]}
          label={linkLabelFunction(attachment)}
          createdDate={formatDate(attachment.dateSent)}
          createdBy={attachment.createdBy}
          ligature={ligature}
          value={attachment.emailId}
          onClick={onClick}
        />
      ))}
    </ListGroup>
  );
};

AttachmentLinkList.propTypes = propTypes;

export default AttachmentLinkList;
