import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function techniciansReducer(
  state = initialState.referenceData.technicians,
  action
) {
  switch (action.type) {
    case types.GET_TECHNICIANS_SUCCESS: {
      return action.technicians;
    }
    default:
      return state;
  }
}
