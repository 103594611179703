export default {
  MethylBromide: '38764bb4-55b3-4be9-9433-a347a27ba39f',
  Phosphine: 'fa3a2d70-b743-4f98-867b-9e152a364233',
  Heat: '6b2571ec-62a5-4ee2-a6d5-62829862e7dd',
  HydrogenCyanide: 'fd587c29-5a6b-4932-a7d8-ca1bc14af155',
  Pestigas: '0f3395bf-1052-4d4d-a803-f975be39b5ce',
  Thermofog: '655117ab-5a89-4a77-8a82-68416c6a72b0',
  GasFree: 'a7bcdbe9-243d-4621-bbe8-5768a4a84e1d',
  WaterBlasting: '2e5aa48c-48c9-49e7-9ba6-3b7a438b549b',
  Callington1Shot: '2e5ab48c-48c9-49e7-9ba6-3b7a438b550a',
  Vapormate: 'd2d32f97-d3b6-455a-8597-c5864d140f45',
  AlPhosVessel: 'b3c32f99-a3b8-465d-5568-c5864d240b66',
  AlphosNonVessel: 'b5532f99-23b8-185d-7521-c5864d240159',
  VaporPhos: 'a2a32f99-b3b6-455c-8598-a5864d240a56',
  EthylFormate:'95faca5e-cc67-4824-8cf2-6938dec46157',
  MacSlay:'0b189349-8457-480f-85b9-75cb42cf0d94',
};
