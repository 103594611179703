import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getJobTableDefinitions() {
  return function(dispatch) {
    dispatch(beginAjaxCall());

    return operationsApi
      .getJobTableDefinitions()
      .then(response => response.data)
      .then(data => data.tableDefinition.columnDefinitions)
      .catch(() => dispatch(ajaxCallError()))
      .then(data => {
        dispatch(getJobTableDefinitionsSuccess(data));
      });
  };
}

function getJobTableDefinitionsSuccess(data) {
  return {
    type: types.GET_JOB_TABLE_DEFINITION_SUCCESS,
    jobTableDefinitions: data || []
  };
}
