import { connect } from 'react-redux';
import JobNotices from './JobNotices';

function mapStateToProps(state) {
  const { currentJob } = state;
  return {
    jobId: currentJob.id,
    currentState: currentJob.currentState,
    sentNotices: currentJob.sentNotices
  };
}

export default connect(mapStateToProps)(JobNotices);
