import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Badge } from 'reactstrap';
import { getJobStateUrl } from 'util/JobStateHelper';
import { userIsCustomer } from '../../util/AdGroupsHelper';

const propTypes = {
  jobStateSummaries: PropTypes.array.isRequired
};



const JobStateNavigation = ({ jobStateSummaries = [], onStateChange }) => (
  <div>
    <Nav tabs className="app-statenav">
      {jobStateSummaries.map((jobState, index) => (
        <NavItem key={index}>
          <NavLink
            className={'nav-link'}
            onClick={() => onStateChange(jobState)}
            to={getJobStateUrl(jobState)}
          >
            {jobState.stateName}&nbsp;
            {userIsCustomer() ? null : jobState.stateCount ? (
              <Badge color="secondary">{jobState.stateCount}</Badge>
            ) : null}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  </div>
);

JobStateNavigation.propTypes = propTypes;

export default JobStateNavigation;
