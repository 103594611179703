import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isImportJobType } from 'util/JobTypeHelper';
import {
  isAssigned,
  isInProgress,
  isCompleted,
  isCertified,
  isFailed,
  isVerified
} from 'util/JobStateHelper';
import AppModal from 'components/common/AppModal';
import ResendNoticeCompletionFormContainer from './ResendNoticeCompletionFormContainer';
import JobCompletionContainer from '../jobCompletion/JobCompletionContainer';
import { isHydrogenCyanide } from '../../../util/TreatmentTypeHelper';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  currentState: PropTypes.string,
  jobType: PropTypes.object,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func
};

class ResendNoticeCompletion extends Component {
  render() {
    const {
      title,
      isOpen,
      currentState,
      jobType,
      toggle,
      onSubmit
    } = this.props;
    return (
      <AppModal title={title} isOpen={isOpen} toggle={toggle}>
          <ResendNoticeCompletionFormContainer
            onSubmit={onSubmit}
            submitLabel="Send Notice of Completion"
            formFieldKey="newNoticeOfCompletion"
            noticeKey="defaultForNotificationOfCompletion"
          />
      </AppModal>
    );
  }
}

ResendNoticeCompletion.propTypes = propTypes;

export default ResendNoticeCompletion;
