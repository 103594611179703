import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form, FormGroup, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import Logger from 'util/Logger';
import * as jobActions from 'actions/jobActions';
import RenderField from 'components/fields/RenderField';
import SearchableSelect from 'components/fields/SearchableSelect';
import DatePickerField from 'components/fields/DatePickerField';
import * as Validators from 'util/Validators';
import { phosphineRecordsApplicationForm } from 'constants/FormConstants';
import { minZero } from 'util/NormalizingHelper';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  technicians: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
  canEditJob: PropTypes.bool
};

class PhosphineRecordsApplicationForm extends Component {
  render() {
    const { technicians, handleSubmit, canEditJob } = this.props;

    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <fieldset disabled={!canEditJob && 'disabled'}>
          <Row>
            <FormGroup className="col-lg-4">
              <Field
                name="initialTechnicianId"
                label="Covering Technician"
                component={SearchableSelect}
                placeholder="Please select a technician"
                options={technicians}
                valueKey="id"
                labelKey="name"
              />
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Field
                name="startDate"
                label="Start Date *"
                component={DatePickerField}
                showTimeSelect
                type="text"
                placeholder="dd/mm/yyyy"
                validate={[Validators.isValidDateAndTime, Validators.required]}
                timeIntervals={5}
              />
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Field
                name="holdNumber"
                label="Hold #"
                component={RenderField}
                type="number"
                placeholder="Enter the Hold #"
                validate={Validators.number}
              />
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Field
                name="rowNumber"
                label="Row #"
                component={RenderField}
                type="number"
                placeholder="Enter the Row #"
                validate={Validators.number}
              />
            </FormGroup>
            <FormGroup className="col-lg-4">
              <Field
                name="volume"
                label="Volume"
                component={RenderField}
                type="number"
                placeholder="Enter Volume"
                validate={Validators.number}
                normalize={minZero}
              />
            </FormGroup>
            <FormGroup className="col-12">
              <Button type="submit" className="mt-3" color="primary">
                Save
              </Button>
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    );
  }
}

PhosphineRecordsApplicationForm.propTypes = propTypes;

function mapStateToProps(state, props) {
  const { referenceData, currentJob } = state;
  const { currentPhosphineRecord } = props;
  return {
    technicians: referenceData.technicians,
    canEditJob: currentJob.canEditJob,
    jobType: currentJob.type,
    initialValues: currentPhosphineRecord
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...jobActions }, dispatch)
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: phosphineRecordsApplicationForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Log Row Record not saved'
      );
    }
  })
)(PhosphineRecordsApplicationForm);
