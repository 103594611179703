import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Label } from 'reactstrap';

import * as Validators from '../../util/Validators';
import {
  isImportAndContainer,
  isExportAndContainer,
  isImportJobType,
  isExportAndLogs,
} from '../../util/JobTypeHelper';

import RenderField from './RenderField';
import CheckboxField from './CheckboxField';

import ToggleButton from '../layout/ToggleButton';

import styles from './MarkingsFieldArray.module.scss';

const propTypes = {
  // label: PropTypes.string,
  // fields: PropTypes.object.isRequired,
  // meta: PropTypes.object.isRequired,
};

function MBMarkingsFieldArray({ fields, meta: { error }, label }) {
  const addNewMarking = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();
      fields.push({});
    }
  };

  return (
    <div className={styles.mbMarkingsFieldArray}>
      {label && <Label className={styles.mbLabel}>{label}</Label>}
      {fields.map((mbJson, index) => (
        <div key={index} className={styles.mbMarkingItem}>
          <Field
            name={`${mbJson}.mbJson`}
            type="text"
            component={RenderField}
            placeholder={'Enter Bottle Number'}
            onKeyPress={addNewMarking}
            autoFocus={index === fields.length - 1}
            validate={Validators.requiredTrue}
          />
          <Field
            name={`${mbJson}.mbUsage`}
            type="text"
            component={RenderField}
            placeholder="Usage Kg"
            onKeyPress={addNewMarking}
            validate={Validators.requiredTrue}
          />
          <span
            title="Remove Marking"
            aria-label="Clear value"
            onClick={() => fields.remove(index)}
          >
            <span className={styles.clear}>×</span>
          </span>
        </div>
      ))}

      <ToggleButton
        className={styles.addButton}
        color="light"
        onClick={() => fields.push({})}
        ligature="add"
      />
    </div>
  );
}

MBMarkingsFieldArray.propTypes = propTypes;

export default MBMarkingsFieldArray;
