import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getClientSummariesPage,
  createClient,
  deleteClient,
  patchClient,
  getClientSearch,
  getClientSummaries,
} from 'api/operationsApi';
import { Header, HeaderTitle } from 'components/layout';
import ClientListTable from './ClientListTable';
import ClientLookup from './ClientLookup';
import Logger from 'util/Logger';
import { confirmAlert } from 'util/Alerts';
import { Label, Row, Col, Button, Card, CardBody, FormGroup } from 'reactstrap';
import Select from 'react-select';
import Field from 'react-select';
import Input from 'react-select';
import { Async } from 'react-select';
import RenderField from 'components/fields/RenderField';

class ClientListPage extends Component {
  state = {
    loaded: true,
    clientTableData: null,
    searchValue: null,
    searchList: null,
    value: null
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.reloadClientSummaries();
  }

  reloadClientSummaries = () => {
    const { match } = this.props;
    this.getClientSummaries({
      page: match && match.params ? match.params.page - 1 : 0
    });
  };

  getClientSummaries = params => {
    getClientSummariesPage(params)
      .then(result => result.data)
      .then(clientTableData => this.setState({ clientTableData }));
  };

  getSearch = searchString => {
    getClientSearch(searchString)
      .then(result => result.data)
      .then(clientTableData => this.setState({ clientTableData }));
    var searchResult = this.state.clientTableData;
    this.setState({ searchList: searchResult });
  };

  goToClientPage = row => {
    const { history } = this.props;
    history.push('/contacts/edit/' + row.id);
  };

  addClient = (newClient, clients) => {
    const clientExists = clients.find(client => client.code === newClient.code);
    if (clientExists) {
      Logger.error('Client already exists!');
      return false;
    }
    this.createClient(newClient);
  };

  createClient = client => {
    const { actions } = this.props;
    createClient(client).then(
      () => {
        actions.getClientSummaries();
        this.getClientSummaries();
      },
      error => Logger.error(error.message)
    );
  };

  deleteClient = client => {
    confirmAlert(
      'Confirm',
      'Are you sure you want to delete this client?'
    ).then(confirmed => {
      if (confirmed) {
        this.deleteClientConfirm(client);
      }
    });
  };

  deleteClientConfirm = client => {
    deleteClient(client.id).then(() => this.getClientSummaries());
  };

  onPageChange = page => {
    this.getClientSummaries({
      page: page - 1
    });
    const routerAction = this.context.router.history.replace;
    routerAction(`/clients/${page}`);
  };

  updateOrderNumberMandatory = rowData => {
    confirmAlert(
      'Confirm',
      'Are you sure you want to update Order Number Mandatory?'
    ).then(confirmed => {
      if (confirmed) {
        this.updateOrderNumberMandatoryConfirm(rowData);
      }
    });
  };

  updateOrderNumberMandatoryConfirm = rowData => {
    var patch = [
      {
        op: 'replace',
        path: '/orderNumberMandatory',
        value: !rowData.orderNumberMandatory
      }
    ];
    patchClient(rowData.id, patch).then(() => this.reloadClientSummaries());
  };



  updateIsHidden = rowData => {
    confirmAlert(
      'Confirm',
      'Are you sure you want to edit client visibility?'
    ).then(confirmed => {
      if (confirmed) {
        this.updateIsHiddenConfirm(rowData);
      }
    });
  };

  updateIsHiddenConfirm = rowData => {
    var patch = [
      {
        op: 'replace',
        path: '/isHidden',
        value: !rowData.isHidden
      }
    ];
    patchClient(rowData.id, patch).then(() => this.reloadClientSummaries());
  };

  render() {
    if (!this.state.loaded) return false;
    const { value } = this.state;
    const { clientTableData } = this.state;
    const onRowClick = this.goToClientPage;

    return (
      <div>
        <Header showLogo>
          <HeaderTitle label={'Clients'} />
        </Header>
        <ClientLookup
          onAddClient={this.addClient}
          clients={clientTableData ? clientTableData.content : null}
          handleSearch={this.handleSearch}
        />
        <br />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Select
                  id="Search"
                  name="Search Val"
                  placeholder="Search Client Name"
                  onInputChange={this.getSearch}
                />
              </Col>
              <Col>
                {' '}
                <Label>&nbsp;</Label>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br />
        <ClientListTable
          clientTableData={clientTableData}
          onPageChange={this.onPageChange}
          onRowDelete={this.deleteClient}
          options={{ onRowClick }}
          actions={{
            onClickOrderNumberMandatory: this.updateOrderNumberMandatory,
            onClickIsHidden: this.updateIsHidden
          }}
        />
      </div>
    );
  }
}

export default ClientListPage;
