const optionalDisclaimerCommodities = [
  'logs',
  'timber',
  'sawn timber',
  'plywood',
  'empty container',
  'tyres',
  'scrap metal',
  'wooden pallet'
];

export function disclaimerReceived(
  value,
  { technicianId, treatmentTypeDetails }
) {
  if (
    technicianId &&
    treatmentTypeDetails.commodity &&
    !optionalDisclaimerCommodities.includes(
      treatmentTypeDetails.commodity.toLowerCase()
    ) &&
    !treatmentTypeDetails.disclaimerReceived
  ) {
    return 'Required';
  }
  return undefined;
}

export function technicianId(technicianId, { currentState }) {
  if (currentState && currentState !== 'new' && !technicianId) {
    return 'Required';
  }
  return undefined;
}
