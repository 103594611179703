import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import {
  isImportJobType,
  isImportAndHeatTreatment,
} from '../util/JobTypeHelper';
import jobStandardIds from '../constants/JobStandardIds';
import treatmentTypeIds from '../constants/TreatmentTypeIds';
import { getJobTypeById } from '../selectors/formSelectors';
import { get } from 'lodash';

export const getDefaultStandard = createSelector(getJobTypeById, (jobType) => {
  if (!jobType) return null;
  return isImportJobType(jobType) ? jobStandardIds.MPI : null;
});

export const getDefaultDestination = createSelector(
  getJobTypeById,
  (jobType) => {
    if (!jobType) return null;
    return isImportJobType(jobType) ? 'New Zealand' : null;
  },
);

export const getDefaultTreatmentType = createSelector(
  getJobTypeById,
  (jobType) => {
    if (!jobType) return null;
    //return isImportAndHeatTreatment(jobType) ? treatmentTypeIds.Heat : null;
    return isImportAndHeatTreatment(jobType)
      ? treatmentTypeIds.Heat
      : jobType.name === 'MB Logs In Hold'
      ? treatmentTypeIds.MethylBromide
      : null;
  },
);

export const getDefaultCommodity = createSelector(getJobTypeById, (jobType) => {
  if (!jobType) return null;
  return jobType.name === 'MB Logs In Hold' ? 'Logs in hold' : null;
});

const getReferenceData = (state) => state.referenceData;
const getCollectionPath = (state, collectionPath) => collectionPath;
const getLookupKey = (state, collectionPath, key) => key;
const getLookupValue = (state, collectionPath, key, value) => value;

export const getReferenceDataRecord = createCachedSelector(
  getReferenceData,
  getCollectionPath,
  getLookupKey,
  getLookupValue,
  (referenceData, collectionPath, key, value) => {
    const collection = get(referenceData, collectionPath);
    if (!collection) {
      return null;
    }
    return collection.find((record) => record[key] === value);
  },
)((state, collectionPath, key, value, cacheKey) => cacheKey);
