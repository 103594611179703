export function validCode(code, { licenceNumber }) {
  const isFourDigitCode = /^[0-9]{4}$/.test(code);
  if (code && !licenceNumber) {
    return 'Only licensed technicians can enter a code for sign-off';
  }
  if (!code && licenceNumber) {
    return 'Code required for a licensed technician';
  }
  if (code && !isFourDigitCode) {
    return 'Code must be 4 digits';
  }
  return undefined;
}

export function newSignature(newSignature, { licenceNumber, signature }) {
  if (!licenceNumber) return undefined;
  const noSignature = !signature && !newSignature;
  const removedSignature = signature && signature.removed;
  return noSignature || (removedSignature && !newSignature)
    ? 'Signature required for a licensed technician'
    : undefined;
}
