import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, FormGroup } from 'reactstrap';
import NoticeFields from 'components/common/NoticeFields';
import { Band } from 'components/layout';
import * as operationsApi from 'api/operationsApi';
import PreviewEmailModal from 'components/common/PreviewEmailModal';

const propTypes = {
  contacts: PropTypes.array,
  onPreviewNotice: PropTypes.func,
  contactIds: PropTypes.string
};

class NoticeSection extends Component {
  state = {
    noticeOfIntendedTreatmentEmail: '',
    showPreviewEmail: false
  };

  handlePreview = () => {
    const { jobDetailsFormValues } = this.props;
    this.getNoticeOfIntendedTreatment(jobDetailsFormValues);
  };

  getNoticeOfIntendedTreatment = formValues => {
    operationsApi.getNoticeOfIntendedTreatment(formValues).then(response => {
      this.setState({
        noticeOfIntendedTreatmentEmail: response.data,
        showPreviewEmail: true
      });
    });
  };

  togglePreviewEmail = () => {
    this.setState(prevState => ({
      showPreviewEmail: !prevState.showPreviewEmail
    }));
  };

  render() {
    const { contacts, jobId } = this.props;
    const { showPreviewEmail, noticeOfIntendedTreatmentEmail } = this.state;
    return (
      <Band dark>
        <section className="form-section">
          <Container>
            <h4>Notice of Intended Treatment</h4>
            <div className="form-row align-items-center">
              <FormGroup className="col-12">
                <NoticeFields
                  contacts={contacts}
                  onPreview={this.handlePreview}
                  formFieldKey="newNoticeOfIntendedTreatment"
                />
              </FormGroup>
            </div>
          </Container>
        </section>
        <PreviewEmailModal
          title="Preview Notice of Intended Treatment"
          isOpen={showPreviewEmail}
          toggle={this.togglePreviewEmail}
          jobId={jobId}
          emailContent={noticeOfIntendedTreatmentEmail}
        />
      </Band>
    );
  }
}

NoticeSection.propTypes = propTypes;

export default NoticeSection;
