import moment from 'moment';
import Decimal from 'decimal.js';

export function required(value) {
  return value || value === false ? undefined : 'Required';
}
export function requiredTrue(value) {
  return value || value === true ? undefined : 'Required';
}

export function mandatoryList(value) {
  if (value != null) {
    if (value === '' || value.length === 0) {
      return 'Required';
    }
  }

  return value || value === false ? undefined : 'Required';
}

export function isValidDate(value) {
  return !value ||
    moment(value, ['DD/MM/YYYY', 'YYYY-MM-DDTHH:mm:ssZ'], true).isValid()
    ? undefined
    : 'Invalid Date';
}

export function isValidDateAndTime(value) {
  return !value ||
    moment(value, ['DD/MM/YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ssZ'], true).isValid()
    ? undefined
    : 'Invalid Date & Time';
}

export function number(value) {
  return !value || /^\d*[.]*\d*$/.test(value)
    ? undefined
    : 'Please enter a valid number';
}

export function dropDownNumber(value) {
  return value == undefined?'Please select from drop down':undefined  
}

export function integer(value) {
  return !value || /^\d*$/.test(value)
    ? undefined
    : 'Please enter a valid whole number';
}

export function percentage(value) {
  if (!value) {
    return undefined;
  }

  return /^\d*[.]*\d*$/.test(value) &&
    parseFloat(value) >= 1 &&
    parseFloat(value) <= 100
    ? undefined
    : 'Please enter a valid pecentage';
}

/**
 * Validates an email address and returns a warning message if it fails validation.
 * @param {String} value - value of the input
 * @returns {String | undefined}
 */
export function email(value) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const message = 'Please enter a valid email';
  if (!value || typeof value !== 'string') return message;
  return regex.test(value.trim()) ? undefined : message;
}

export function isFourDigitCode(value) {
  const isFourDigitCode = /^[0-9]{4}$/.test(value);
  return isFourDigitCode ? undefined : 'Code must be 4 digits';
}

export function isFumigatingTechnicianRequired(technicianId, { startDate }) {
  if (startDate && !technicianId) {
    return 'Required';
  }
  return undefined;
}

export function isFumigatingHCNTechnicianRequired(technicianId, { endDate }) {
  if (endDate && !technicianId) {
    return 'Required';
  }
  return undefined;
}


export function isVentingTechnicianRequired(technicianId, { endDate }) {
  if (endDate && !technicianId) {
    return 'Required';
  }
  return undefined;
}

export function evenDecimal(value) {
  if (!value) return undefined;
  const x = new Decimal(value);
  const match = `${x.toNumber()}`.match(/^\d+(?:\.(\d))?$/);
  if (match) {
    if (!match[1] || +match[1] % 2 === 0) {
      return undefined;
    }
  }
  return 'Must be even decimal point';
}

export function mandatory(value) {
  if (value === '') {
    return 'Required';
  }
}
