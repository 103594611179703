import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function userColumnVisibilitiesReducer(
  state = initialState.referenceData.userColumnVisibilities,
  action
) {
  switch (action.type) {
    case types.GET_USER_COLUMN_VISIBILITIES_SUCCESS:
      return [...action.userColumnVisibilities];
    case types.SET_USER_COLUMN_VISIBILITY:
      return state.map(setting => {
        if (setting.state === action.state) {
          if (
            action.visible &&
            !setting.visibleColumns.includes(action.column)
          ) {
            return {
              ...setting,
              visibleColumns: [...setting.visibleColumns, action.column]
            };
          } else if (!action.visible) {
            return {
              ...setting,
              visibleColumns: setting.visibleColumns.filter(
                x => x !== action.column
              )
            };
          }
        }
        return setting;
      });
    case types.SAVE_USER_COLUMN_VISIBILITY_SUCCESS:
      return state.map(setting => {
        if (setting.state === action.setting.state) {
          return action.setting;
        }
        return setting;
      });
    case types.DELETE_USER_COLUMN_VISIBILITY_SUCCESS:
      return state.map(setting => {
        if (setting.state === action.setting.state) {
          return action.setting;
        }
        return setting;
      });
    default:
      return state;
  }
}
