import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  getTechnicians,
  getCanEditJob,
  getJobType,
  getTreatmentType,
  getCalculatedMbkg,
  getShowMissingEndPhaseMessage,
} from '../selectors';
import { withIsSectionTouched } from '../TreatmentResultRecordsForm/enhancers';

import {
  isBreakBulkJobType,
  isExportAndLogs,
  isMBLogsInHold,
} from '../../../../util/JobTypeHelper';
import { isMethylBromide } from '../../../../util/TreatmentTypeHelper';

import TreatmentResultRecordsUncoveringSection from './TreatmentResultRecordsUncoveringSection';

export default compose(
  connect((state) => ({
    technicians: getTechnicians(state),
    canEditJob: getCanEditJob(state),
    jobType: getJobType(state),
    treatmentType: getTreatmentType(state),
    calculatedMbkg: getCalculatedMbkg(state),
    showNoEndPhaseMessage: getShowMissingEndPhaseMessage(state),
  })),
 
  withIsSectionTouched(({ jobType, treatmentType }) => [
    'uncoveringTechnicianId',
    !(
      isMethylBromide(treatmentType) && 
      (isBreakBulkJobType(jobType) || isExportAndLogs(jobType)||isMBLogsInHold(jobType))
    ) && 'endDate',
    'actualTemperature',
    'ventDateTime',
    'unintentionalRelease',
    'recaptured',
    'uncoveringComments',
    !(isMethylBromide(treatmentType) && isBreakBulkJobType(jobType)) &&
      'passed',
  ]),

)(TreatmentResultRecordsUncoveringSection);
