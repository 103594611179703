import React from 'react';
import PropTypes from 'prop-types';
import { NavbarToggler } from 'reactstrap';

const propTypes = {
  onClick: PropTypes.func
};

const TopNavToggler = ({ onClick, envprops }) => {
  return (
    <div
      className="app-topnav-toggler"
      style={{ backgroundColor: envprops.BackgroundColor }}
    >
      <NavbarToggler onClick={onClick} />
    </div>
  );
};

TopNavToggler.propTypes = propTypes;

export default TopNavToggler;
