import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getTreatmentTypes() {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.getTreatmentTypes().then(
      (response) => dispatch(getTreatmentTypesSuccess(response)),
      (error) => dispatch(ajaxCallError()),
    );
  };
}

function getTreatmentTypesSuccess(response) {
  return {
    type: types.GET_TREATMENT_TYPES_SUCCESS,
    treatmentTypes: response.data || [],
  };
}
