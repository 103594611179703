import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './TouchModal.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
};

class TouchModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.togglePortal();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      // handle portal events/dom updates
      this.togglePortal();
    } else if (this._element) {
      // rerender portal
      this.renderIntoSubtree();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  togglePortal() {
    if (this.props.isOpen) {
      this.show();
    } else {
      this.destroy();
    }
  }

  destroy() {
    if (this._element) {
      ReactDOM.unmountComponentAtNode(this._element);
      document.body.removeChild(this._element);
      this._element = null;
    }

    this.enableBodyScroll(this.originalBodyPadding);
  }

  hide() {
    this.renderIntoSubtree();
    this.enableBodyScroll();
  }

  show() {
    this._element = document.createElement('div');
    this.disableBodyScroll();
    document.body.appendChild(this._element);
    this.renderIntoSubtree();
  }

  disableBodyScroll() {
    document.body.classList.add(styles.isTouchModalOpen);
  }

  enableBodyScroll() {
    document.body.classList.remove(styles.isTouchModalOpen);
  }

  renderIntoSubtree() {
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      this.renderChildren(),
      this._element,
    );
  }

  renderChildren() {
    const { children, isOpen } = this.props;
    return (
      <div className={cx(styles.TouchModal, { [styles.isOpen]: isOpen })}>
        <div className={styles.Wrapper}>{children}</div>
      </div>
    );
  }

  render() {
    return null;
  }
}

TouchModal.propTypes = propTypes;

export default TouchModal;
