import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getDestinations() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getDestinations()
      .then(
        response => dispatch(getDestinationsSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getDestinationsSuccess(response) {
  return {
    type: types.GET_DESTINATIONS_SUCCESS,
    destinations: response.data || []
  };
}
