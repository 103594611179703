import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

const propTypes = {
  title: PropTypes.string.isRequired
};

class Printer extends React.Component {
  handleTriggerClick() {
    this.page.print();
  }

  render() {
    const all = React.Children.toArray(this.props.children);
    if (all.length < 2) {
      throw new Error('Printer needs to have at least 2 children.');
    }
    const [trigger, ...children] = all;

    return [
      React.cloneElement(trigger, {
        key: 'trigger',
        onClick: this.handleTriggerClick.bind(this)
      }),
      <PrintPage
        key="page"
        title={this.props.title}
        ref={page => (this.page = page)}
      >
        {children}
      </PrintPage>
    ];
  }
}

Printer.propTypes = propTypes;

class PrintPage extends React.Component {
  print() {
    const el = findDOMNode(this.el);
    const pageContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8" />
          <title>${this.props.title}</title>
        </head>
        <body>
          ${el.innerHTML}
        </body>
      </html>`;
    const w = this.iframe.contentWindow;
    w.document.open();
    w.document.write(pageContent);
    w.document.close();
    w.focus();
    w.print();
  }

  render() {
    return (
      <div style={{ display: 'none' }}>
        <div ref={el => (this.el = el)}>{this.props.children}</div>
        <iframe
          title={this.props.title}
          style={{ height: 0, width: 0, position: 'absolute' }}
          ref={iframe => {
            this.iframe = iframe;
          }}
        />
      </div>
    );
  }
}

export default Printer;
