import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const CHECKBUBLE_SIZE_NORMAL = 'CHUCKBUBLE_SIZE_NORMAL';
export const CHECKBUBLE_SIZE_SMALL = 'CHUCKBUBLE_SIZE_SMALL';

const propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([CHECKBUBLE_SIZE_NORMAL, CHECKBUBLE_SIZE_SMALL])
    .isRequired
};

const defaultProps = {
  checked: false,
  size: CHECKBUBLE_SIZE_NORMAL
};

const sizeClassNames = {
  [CHECKBUBLE_SIZE_NORMAL]: '',
  [CHECKBUBLE_SIZE_SMALL]: 'app-checkbubble--small'
};

const Checkbubble = ({ checked, className, onClick, size }) => {
  const classes = classNames(
    'app-checkbubble',
    sizeClassNames[size],
    className,
    {
      'app-checkbubble--clickable': Boolean(onClick)
    }
  );
  return (
    <label className={classes}>
      <input
        type="checkbox"
        name="checkbubble"
        checked={checked}
        className="app-checkbubble-input"
        readOnly
        onClick={onClick}
      />
      <span className="app-checkbubble-indicator" />
    </label>
  );
};

Checkbubble.propTypes = propTypes;
Checkbubble.defaultProps = defaultProps;

export default Checkbubble;
