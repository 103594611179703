import React from 'react';
import PropTypes from 'prop-types';
import * as areaHelper from 'util/AreaHelper';
import { Band } from 'components/layout';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const propTypes = {
  technicians: PropTypes.array,
  options: PropTypes.object,
  areas: PropTypes.array
};

const TechnicianListTable = ({ technicians, options, areas }) => {
  return (
    <Band dark>
      <div className="table-wrap">
        <BootstrapTable
          bodyContainerClass="px-2"
          className=""
          trClassName="app-table-row"
          version="4"
          data={technicians}
          bordered={false}
          remote
          options={options}
        >
          <TableHeaderColumn
            isKey
            className="app-table-header"
            columnClassName="keyColumn"
            dataField="id"
            hidden
          />
          <TableHeaderColumn
            width="20%"
            className="app-table-header"
            dataField="name"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            width="20%"
            className="app-table-header"
            dataField="email"
          >
            Email
          </TableHeaderColumn>
          <TableHeaderColumn
            width="20%"
            className="app-table-header"
            dataField="licenceNumber"
          >
            License Number
          </TableHeaderColumn>
          <TableHeaderColumn
            width="20%"
            className="app-table-header"
            dataFormat={(cell, row) => (
              <span>
                {' '}
                {areaHelper.getAreaDisplayNames(
                  row.areas.map((o, i) => o.areaId),
                  areas
                )}{' '}
              </span>
            )}
          >
            Areas
          </TableHeaderColumn>
          <TableHeaderColumn
            width="20%"
            className="app-table-header"
            dataField="cellPhoneNumber"
          >
            Cell Phone
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            columnClassName="mobileToggleColumn hidden-sm hidden-md hidden-lg"
          />
        </BootstrapTable>
      </div>
    </Band>
  );
};

TechnicianListTable.propTypes = propTypes;

export default TechnicianListTable;
