import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contactActions from 'actions/contactActions';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle,
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';
import VesselsListTable from './VesselsListTable';
import { Label, Row, Col, Button, Card, CardBody, FormGroup } from 'reactstrap';
import Select from 'react-select';

class VesselsList extends Component {
  state = {
    showOverlay: false,
    loaded: false,
  };

  componentDidMount() {
    operationsApi.getAllVessels().then((result) => {
      return this.setState({
        loaded: true,
        vessels: result.data,
      });
    });
  }

  searchVessels = (searchString) => {
    if (searchString.length >= 2) {
      operationsApi
        .getVesselSearch(searchString)
        .then((result) => result.data)
        .then((data) => this.setState({ vessels: data }));
      this.setState({ loaded: true });
    }
  };

  toggleNavigation = () => {
    this.setState((prevState) => ({
      showOverlay: !prevState.showOverlay,
    }));
  };

  resetView = () => {
    this.componentDidMount();
  };

  render() {
    const { loaded, vessels } = this.state;
    const { history } = this.props;

    if (!loaded) return false;

    const options = {
      onRowClick: function (row) {
        history.push('/vessels/edit/' + row.id);
      },
    };

    return (
      <div>
        <Header showLogo>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={() => this.props.history.push('/vessels/create')}
              ligature="add"
            />
          </HeaderButtonNav>
          <HeaderTitle label={'Vessels'} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Select
                    name="Search Val"
                    placeholder="Enter Vessel to search"
                    onInputChange={this.searchVessels}
                  />
                </Col>
                <button onClick={this.resetView}>Reset</button>
                <Col>
                  {' '}
                  <Label>&nbsp;</Label>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Header>
        <VesselsListTable options={options} vessels={vessels} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contactActions,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselsList);
