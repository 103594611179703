import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'reactstrap';
import { getClientNameByCode } from 'util/JobHelper';
import { isCompleted } from 'util/JobStateHelper';
import JobMastheadContainer from '../jobs/JobMastheadContainer';
import ClientDetailsSectionContainer from './ClientDetailsSectionContainer';
import ClientLookupSection from './ClientLookupSection';
import ShipmentDetailsSectionContainer from './ShipmentDetailsSectionContainer';
import TreatmentDetailsContainer from './TreatmentDetailsContainer';
import TreatmentTypeDetailsContainer from './TreatmentTypeDetailsContainer';
import TechnicianSection from './TechnicianSection';
import AttachmentsSection from './AttachmentsSection';
import NoticesSectionContainer from './NoticesSectionContainer';
import { jobDetailsForm } from 'constants/FormConstants';
import JobSubmitSection from './JobSubmitSection';
import { preventEnterSubmit } from 'actions/formActions';
import { change, untouch, formValueSelector, getFormValues } from 'redux-form';
import {
  getContactsByAreaId,
  getDefaultContactsByNoticeKey,
} from 'util/SelectHelper';
import TreatmentTypeDetails from './TreatmentTypeDetails';
import JobDetailsEditPage from './JobDetailsEditPage';
import { userIsCustomer } from 'util/AdGroupsHelper';
import { isWaterBlasting } from '../../util/TreatmentTypeHelper';
import { getSubjob } from '../../api/operationsApi';
import { travelCost } from '../../constants/ListConstants';

const propTypes = {
  handleSubmit: PropTypes.func,
  clientSummaries: PropTypes.array,
  onSelectClient: PropTypes.func,
  initialValues: PropTypes.object,
};

class JobDetailsForm extends Component {
  state = {
    clientLoaded: false,
    updatedArea: false,
    filteredJobLocations: [],
    commodity: TreatmentTypeDetails.commodity,
    displayDownButton: false,
    displayUpButton: false,
    scrollPosition: 0,
    //stopCredit:false,
    clientDetails: null,
    clientCode: '',
    contatinerType: '',
    status: '',
    skipEralisNumber: null,
    hasEralisNumber: '',
    hideSubmit: false,
  };

  componentWillMount = () => {
    const { initialValues, jobLocations } = this.props;

    if (initialValues.clientCode) {
      this.selectClient(initialValues.clientCode);
    }
    if (initialValues.areaId) {
      this.setState({
        filteredJobLocations: jobLocations.filter(
          (location) => location.areaId === initialValues.areaId,
        ),
      });
    }
  };

  selectClient = (code) => {
    const { actions } = this.props;
    if (!code) {
      actions.clearClientDetails();
    } else {
      this.setClientName(code);
      actions
        .getClientDetails(code)
        .then((result) =>
          actions.change(
            jobDetailsForm,
            'stopCredit',
            result.clientDetails.stopCredit,
          ),
        )
        .then((result) => this.setState({ clientLoaded: true }));
    }
  };

  setClientName = (code) => {
    const { clientSummaries, actions } = this.props;
    const clientName = getClientNameByCode(code, clientSummaries);
    actions.change(jobDetailsForm, 'clientName', clientName);
  };

  markDocumentAsRemoved = (attachments, document) => {
    const { actions } = this.props;
    const markedDocument = { ...document, removed: true };
    markedDocument.removed = true;
    actions.change(jobDetailsForm, 'attachments', [
      ...attachments.filter((doc) => doc.name !== document.name),
      markedDocument,
    ]);
  };
  updateAreaBasedOnTreatment = (treatmentId) => {
    if (treatmentId === '2e5aa48c-48c9-49e7-9ba6-3b7a438b549b') {
      const areaId = '894cecaf-7685-4d51-98bc-1d02c86839af';
      const { actions, jobLocations, dispatch } = this.props;
      const clearTechnicianField = () =>
        actions.change(jobDetailsForm, 'technicianId', null);
      clearTechnicianField();
      this.setDefaultRecipients(areaId);

      const filterJobLocations = jobLocations.filter(
        (location) => location.areaId === areaId,
      );
      if (isWaterBlasting(treatmentId)) {
        const { technicians } = this.props;
        let techId = '';
        if (technicians !== undefined) {
          const waterTechList = technicians.filter((tech) => {
            return tech.name === 'Water Blaster';
          });
          if (waterTechList !== null) {
            const waterTech = waterTechList[0];
            techId = waterTech.id;
            actions.change(
              jobDetailsForm,
              'technicianId',
              techId, //e9b0a70f-33f6-447a-92c1-67943d28bc80
            );
          }
        }
      }
      this.setState({
        filteredJobLocations: filterJobLocations,
        updatedArea: true,
      });
      dispatch(change(jobDetailsForm, 'location', 'Port of Tauranga'));
      dispatch(untouch(jobDetailsForm, 'location'));
      dispatch(change(jobDetailsForm, 'areaId', areaId));
      dispatch(untouch(jobDetailsForm, 'area'));
      dispatch(
        change(jobDetailsForm, 'treatmentTypeDetails', {
          destination: 'water blast',
        }),
      );
      dispatch(untouch(jobDetailsForm, 'treatmentTypeDetails'));
    }
  };

  selectArea = (areaId) => {
    const { actions, jobLocations, dispatch } = this.props;
    const clearTechnicianField = () =>
      actions.change(jobDetailsForm, 'technicianId', null);
    clearTechnicianField();
    this.setDefaultRecipients(areaId);

    const filterJobLocations = jobLocations.filter(
      (location) => location.areaId === areaId,
    );
    this.setState({
      filteredJobLocations: filterJobLocations,
      updatedArea: true,
    });

    dispatch(change(jobDetailsForm, 'location', null));
    dispatch(untouch(jobDetailsForm, 'location'));
  };
  updateSkipEralisNumber = (value) => {
    this.setState((prevState) => ({
      skipEralisNumber: !prevState.skipEralisNumber,
    }));
  };

  updateEralisNumber = (value) => {
    if (value.length >= 7) {
      let resp = getSubjob(value)
        .then((result) => result.data)
        .then((result) => this.checkResult(result));
    } else {
      this.setState({ eralisJobNumber: '' });
      this.setState({ hideSubmit: true });
    }
    if (value.length === 0) {
      this.setState({ hideSubmit: false });
    }
  };

  checkResult(data) {
    if (data.Valid) {
      this.setState({ eralisJobNumber: data.SubJobNo });
      this.setState({ hideSubmit: false });
    } else {
      this.setState({ eralisJobNumber: '' });
      this.setState({ hideSubmit: true });
    }
  }

  setDefaultRecipients = (areaId) => {
    const { contacts, actions } = this.props;
    const contactsByArea = getContactsByAreaId(contacts, areaId);
    const defaultContactIds = getDefaultContactsByNoticeKey(
      contactsByArea,
      'defaultForNotificationOfIntent',
    );
    actions.change(
      jobDetailsForm,
      'newNoticeOfIntendedTreatment.contactIds',
      defaultContactIds,
    );
  };

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      theposition: scrolled,
    });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  changeTravelZone=(selectedTravelZone)=>{
    const{actions,jobLocations} = this.props
    const location = jobLocations.filter(x=>x.name==selectedTravelZone)
    const travZone = location?location[0]:null
    const travelCostArea = travelCost[travZone.travelZone-1]
    if(travelCostArea!=null)
    {actions.change(jobDetailsForm, 'travelZone', travelCostArea.name);}
  }
  /*
  handleGet(state){
    const formValues1 = formValueSelector("jobDetailsForm");
    const test = formValues1(state,'isAuthenticated')
  }*/
  
  render() {
    const {
      clientSummaries,
      jobLocations,
      technicians,
      handleSubmit,
      isSubmitting,
      jobType,
      attachments,
      initialValues,
      areaId,
      technicianId,
      actions,
      treatmentType,
      currentJob,
      selectedCommodity,
      selectedStandard,
      clientDetails,
      location,
    } = this.props;
    let status = 'Active';
    let stopCredit = false;
    if (clientDetails !== null) {
      status = clientDetails.status;
      stopCredit = clientDetails.stopCredit;
    }
    const {
      filteredJobLocations,
      updatedArea,
      skipEralisNumber,
      eralisJobNumber
    } = this.state;
    var subjob = eralisJobNumber;
   if( eralisJobNumber ===undefined &&currentJob!=null && currentJob.eralisJobNumber != undefined){
      subjob = currentJob.eralisJobNumber
   }
    const { treatmentTypeDetails } = currentJob || {};
    const { treatmentDetails } = currentJob || {};

    const { clientLoaded } = this.state;

    if (initialValues.clientCode && !clientLoaded) return null;
    const formValues = formValueSelector(jobDetailsForm);
        const completed =
      initialValues.customerCompleted !== null
        ? initialValues.customerCompleted
        : currentJob.customerCompleted;

    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <fieldset
          disabled={
            (currentJob && !currentJob.canEditJob && 'disabled') ||
            (completed && userIsCustomer()) ||
            status === 'Inactive' ||
            stopCredit === true
          }
        >
          {initialValues.jobReference ? <JobMastheadContainer /> : null}
          <ClientLookupSection
            options={clientSummaries}
            onSelect={this.selectClient}
          />
          {status !== 'Inactive' || stopCredit !== true ? (
            window.pageYOffset < 350 && window.pageYOffset >= 0 ? (
              <Button
                color="primary"
                className="btn-save"
                disabled={isSubmitting}
              >
                Save
              </Button>
            ) : null
          ) : null}
          <ClientDetailsSectionContainer status={status} />
          {status !== 'Inactive' || stopCredit !== true ? (
            window.pageYOffset < 550 && window.pageYOffset >= 350 ? (
              <Button
                color="primary"
                className="btn-save"
                disabled={isSubmitting}
              >
                Save
              </Button>
            ) : null
          ) : null}
          <ShipmentDetailsSectionContainer
            status={status}
            stopCredit={stopCredit}
            jobLocations={
              filteredJobLocations && filteredJobLocations.length >= 1
                ? filteredJobLocations
                : jobLocations
            }
            updatedArea={updatedArea}
            jobType={jobType}
            treatmentType={treatmentType}
            actions={actions}
            initialValues={initialValues}
            clientSummaries={clientSummaries}
            onSelectArea={this.selectArea}
            disableLocationField={
              filteredJobLocations.length >= 1 ? false : true
            }
            onSelectLocation={this.changeTravelZone}
          />
          {status !== 'Inactive' || stopCredit !== true ? (
            window.pageYOffset < 1000 && window.pageYOffset >= 550 ? (
              <Button
                color="primary"
                className="btn-save"
                disabled={isSubmitting}
              >
                Save
              </Button>
            ) : null
          ) : null}
          <TreatmentDetailsContainer
            jobType={jobType}
            actions={actions}
            status={status}
            stopCredit={stopCredit}
            updateArea={this.updateAreaBasedOnTreatment}
          />
          {status !== 'Inactive' || stopCredit !== true ? (
            window.pageYOffset < 1500 && window.pageYOffset >= 1000 ? (
              <Button
                color="primary"
                className="btn-save"
                disabled={isSubmitting}
              >
                Save
              </Button>
            ) : null
          ) : null}
          <TreatmentTypeDetailsContainer
            treatmentTypeDetails={treatmentTypeDetails}
            jobType={jobType}
            actions={this.props.actions}
            customerCompleted={completed}
            status={status}
            stopCredit={stopCredit}
            updateArea={this.selectArea}
          />
          {status !== 'Inactive' || stopCredit !== true ? (
            window.pageYOffset < 1700 && window.pageYOffset >= 1500 ? (
              <Button
                color="primary"
                className="btn-save"
                disabled={isSubmitting}
              >
                Save
              </Button>
            ) : null
          ) : null}
          {!userIsCustomer() ? (
            <TechnicianSection
              options={technicians}
              currentState={initialValues.currentState}
              treatmentType={treatmentType} //techcompentency
              selectedCommodity={selectedCommodity} //techComp
              selectedStandard={selectedStandard} //techComp
              jobTypeId={jobType.id} //techComp
              areaId={areaId} //techComp
              updateSkipEralisNumber={this.updateSkipEralisNumber}
              skipEralisNumber={skipEralisNumber}
              updateEralisNumber={this.updateEralisNumber}
              eralisJobNumber={subjob}
            />
          ) : null}
          {technicianId &&
          !isCompleted(initialValues.currentState) &&
          status !== 'Inactive' ? (
            <NoticesSectionContainer areaId={areaId} />
          ) : null}
          {status !== 'Inactive' || stopCredit !== true ? (
            <AttachmentsSection
              attachments={attachments}
              onRemove={this.markDocumentAsRemoved}
            />
          ) : null}

          {status !== 'Inactive' || stopCredit !== true ? (
            <JobSubmitSection isSubmitting={isSubmitting} />
          ) : null}
        </fieldset>
      </Form>
    );
  }
}

JobDetailsForm.propTypes = propTypes;

export default JobDetailsForm;
