import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container, Row, Col } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import * as Validators from 'util/Validators';
import { Band } from 'components/layout';

const propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func
};

const ClientLookupSection = ({ options, onSelect }) => {
  return (
    <Band light>
      <div className="form-section">
        <Container>
          <Row>
            <Col md="6">
              <Field
                name="clientCode"
                label="Client *"
                component={SearchableSelect}
                placeholder="Enter the name of a client"
                options={options}
                onSelect={onSelect}
                valueKey="code"
                labelKey="name"
                validate={Validators.required}
              />
            </Col>
            <Col md="6" className="text-right">
              <blockquote className="blockquote">
                <p>{/*#123456 */}</p>
              </blockquote>
            </Col>
          </Row>
        </Container>
      </div>
    </Band>
  );
};

ClientLookupSection.propTypes = propTypes;

export default ClientLookupSection;
