export const convertHTMLToBase64 = HTMLString => {
  if (!HTMLString) return false;
  const base64EncodedString = new Buffer(HTMLString).toString('base64');
  return base64EncodedString;
};

export const convertFileToBase64 = file => {
  return new Promise(convertFileResolver);

  function convertFileResolver(resolve, reject) {
    if (isBase64Converted(file)) {
      resolve(file);
    } else {
      readFileAsDataUrl(file, resolve);
    }
  }

  function readFileAsDataUrl(file, resolve) {
    const reader = new FileReader();
    reader.onload = () => {
      resolve({
        name: file.name,
        base64String: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
};

const isBase64Converted = file => 'base64String' in file;

export const convertFilesToBase64 = files => {
  return Promise.all(files.map(convertFileToBase64));
};
