import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';

import ResponsiveModal from '../../common/ResponsiveModal';
import PhosphineRecordsTopupForm from './PhosphineRecordsTopupForm';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  currentPhosphineRecord: PropTypes.object,
};

const PhosphineRecordsTopupModal = ({
  title,
  isOpen,
  toggle,
  onSubmit,
  currentPhosphineRecord,
}) => {
  return (
    <ResponsiveModal size="lg" title={title} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title || ''}</ModalHeader>
      <ModalBody>
        <PhosphineRecordsTopupForm
          currentPhosphineRecord={currentPhosphineRecord}
          onSubmit={onSubmit}
        />
      </ModalBody>
    </ResponsiveModal>
  );
};

PhosphineRecordsTopupModal.propTypes = propTypes;

export default PhosphineRecordsTopupModal;
