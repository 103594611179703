import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import { treatmentResultRecordsForm } from '../../../constants/FormConstants';

import {
  isExportAndLogs,
  isBreakBulkJobType,
  isImportOrExportAndContainer,
  isExportOtherMBApples,
  isExportOther,
} from '../../../util/JobTypeHelper';
import { isEthylFormate,isMethylBromide } from '../../../util/TreatmentTypeHelper';

export const getTechnicians = (state) =>
  (state.referenceData && state.referenceData.technicians) || [];
export const getCurrentJob = (state) => state.currentJob;

export const getRecapturePercentage = (state)=>state.form.treatmentResultRecordsForm.values? state.form.treatmentResultRecordsForm.values.recapturePercentage:0;

export const getCurrentConcentrationReadingItem = (state) => state.form.treatmentResultRecordsForm? state.form.treatmentResultRecordsForm.values.concentrationReadingItem:null;

export const getCurrentActiveConcentrationReadingField = (state) => state.form.treatmentResultRecordsForm? state.form.treatmentResultRecordsForm.active:null;
export const getCurrentEFConcentrationReadingItem = (state) => state.form.treatmentResultsForm? state.form.treatmentResultsForm.values.efConcentrationReadingItem:null;
export const getCurrentConcentrationChamberReadingItem = (state) => state.form.treatmentResultRecordsForm? state.form.treatmentResultRecordsForm.values.concentrationReadingChamberItem:null;


export const getCurrentRegisteredFields = (state) => state.form.treatmentResultsForm? state.form.treatmentResultsForm.registeredFields.efConcentrationReadingItem:null;

export const getCanEditJob = createSelector(
  getCurrentJob,
  (currentJob) => (currentJob && currentJob.canEditJob) || false,
);

export const getJobType = createSelector(
  getCurrentJob,
  (currentJob) => currentJob && currentJob.type,
);

export const getJobId = createSelector(
  getCurrentJob,
  (currentJob) => currentJob && currentJob.id,
);

export const getTreatmentType = createSelector(
  getCurrentJob,
  (currentJob) =>
    currentJob &&
    currentJob.treatmentDetails &&
    currentJob.treatmentDetails.treatmentTypeId,
);

export const getCommodity = createSelector(
  getCurrentJob,
  (currentJob) =>
    currentJob &&
    currentJob.treatmentTypeDetails &&
    currentJob.treatmentTypeDetails.commodity,
);

export const getStandardId = createSelector(
  getCurrentJob,
  (currentJob) =>
    currentJob &&
    currentJob.treatmentDetails &&
    currentJob.treatmentDetails.standardId,
);


export const getTreatmentTypeDetails = createSelector(
  getCurrentJob,(currentJob) => currentJob && currentJob.treatmentTypeDetails
);

export const getFieldValueFn = (state) => (field) =>
  formValueSelector(treatmentResultRecordsForm)(state, field);

export const getCalculatedMbkg = (state) =>
  formValueSelector(treatmentResultRecordsForm)(state, 'calculatedMbkg');

export const getMbTopUp = (state) =>
  formValueSelector(treatmentResultRecordsForm)(state, 'mbTopUp');

export const getEndDate = (state) =>
  formValueSelector(treatmentResultRecordsForm)(state, 'endDate');

  export const getVentDate = (state) =>
  formValueSelector(treatmentResultRecordsForm)(state, 'ventDate');
  export const getCovered = (state) =>
  formValueSelector(treatmentResultRecordsForm)(state, 'covered');

export const getShowMonitoring = createSelector(
  getJobType,
  getTreatmentType,
  getCommodity,
  getStandardId,
  (jobType, treatmentType,commodity,standardId) =>
   ((isMethylBromide(treatmentType) &&
    (isBreakBulkJobType(jobType) || isExportAndLogs(jobType)||jobType.name==='MB Logs In Hold')||isImportOrExportAndContainer(jobType)))
    ||isEthylFormate(treatmentType)||isExportOtherMBApples(jobType,treatmentType,commodity,standardId)
    );

export const getShowMissingEndPhaseMessage = createSelector(
  getShowMonitoring,
  getTreatmentType,
  getEndDate,
  getVentDate,
  getJobType,
  (showMonitoring, endDate,treatmentType,ventDate,jobType) => isEthylFormate(treatmentType)?showMonitoring && !ventDate:showMonitoring && !endDate,
);
