import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import * as jobActions from 'actions/jobActions';
import * as clientDetailsActions from 'actions/clientDetailsActions';
import * as jobLocationActions from 'actions/jobLocationActions';
import * as formActions from 'actions/formActions';
import { getJobTypeById } from 'selectors/formSelectors';
import {
  getDefaultStandard,
  getDefaultDestination,
  getDefaultTreatmentType,
  getDefaultCommodity,
} from 'selectors/referenceDataSelectors';
import JobDetailsCreatePage from './JobDetailsCreatePage';

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      dateOfRequest: moment().startOf('day'),
      typeId: ownProps.match.params.id,
      jobWorkflowId: '14a71deb-3807-4607-bba0-92d1b612ec97',
      treatmentDetails: {
        standardId: getDefaultStandard(state, ownProps),
        treatmentTypeId: getDefaultTreatmentType(state, ownProps),
      },
      treatmentTypeDetails: {
        destination: getDefaultDestination(state, ownProps),
        commodity: getDefaultCommodity(state, ownProps),
      },
      newNoticeOfIntendedTreatment: {
        sendNotice: true,
      },
    },
    jobType: getJobTypeById(state, ownProps),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobActions,
        ...clientDetailsActions,
        ...jobLocationActions,
        ...formActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDetailsCreatePage);
