import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container, FormGroup } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import RenderField from 'components/fields/RenderField';
import CheckboxField from 'components/fields/CheckboxField';
import * as Validators from 'util/Validators';

const propTypes = {
  areas: PropTypes.array
};

const ContactDetailsSection = ({ areas }) => (
  <section className="form-section">
    <Container>
      <h4>Contact Details</h4>
      <div className="form-row align-items-center">
        <FormGroup className="col-md-4">
          <Field
            name="name"
            label="Name *"
            component={RenderField}
            type="text"
            placeholder="Enter a Name"
            validate={Validators.required}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="description"
            label="Description"
            component={RenderField}
            type="text"
            placeholder="Enter a Description"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="areaIds"
            label="Area"
            component={SearchableSelect}
            options={areas}
            multi
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="email"
            label="Email *"
            component={RenderField}
            type="text"
            placeholder="Enter an Email"
            validate={[Validators.email, Validators.required]}
          />
        </FormGroup>
        <FormGroup className="col-md-4 align-middle">
          <Field
            name="defaultForNotificationOfIntent"
            label="Default NOI Contact"
            component={CheckboxField}
          />
          <Field
            name="defaultForNotificationOfCompletion"
            label="Default NOC Contact"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4 align-middle">
          <Field
            name="defaultForTreatmentCertificateExport"
            label="Default Treatment Cert - Export"
            component={CheckboxField}
          />
          <Field
            name="defaultForTreatmentCertificateImport"
            label="Default Treatment Cert - Import"
            component={CheckboxField}
          />
        </FormGroup>
        <br />
        <FormGroup className="col-md-4">
          <Field name="hidden" label="Hide Contact" component={CheckboxField} />
        </FormGroup>
      </div>
    </Container>
  </section>
);

ContactDetailsSection.propTypes = propTypes;

export default ContactDetailsSection;
