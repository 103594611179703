import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getUserColumnVisibilities() {
  return function(dispatch) {
    dispatch(beginAjaxCall());

    return operationsApi
      .getUserColumnVisibilities()
      .then(response => response.data)
      .catch(() => dispatch(ajaxCallError()))
      .then(data => {
        dispatch(getUserColumnVisibilitiesSuccess(data));
      });
  };
}

function getUserColumnVisibilitiesSuccess(data) {
  return {
    type: types.GET_USER_COLUMN_VISIBILITIES_SUCCESS,
    userColumnVisibilities: data || []
  };
}

export function setUserColumnVisibility(state, column, visible) {
  return {
    type: types.SET_USER_COLUMN_VISIBILITY,
    state,
    column,
    visible
  };
}

export function saveUserColumnVisibilities(currentState) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const visibilities = getState().referenceData.userColumnVisibilities.find(
      ({ state }) => state === currentState
    );
    operationsApi
      .setUserColumnVisibilities(visibilities)
      .then(response => response.data)
      .catch(() => dispatch(ajaxCallError()))
      .then(data => {
        dispatch(saveUserColumnVisibilitiesSuccess(data));
      });
  };
}

function saveUserColumnVisibilitiesSuccess(data) {
  return {
    type: types.SAVE_USER_COLUMN_VISIBILITY_SUCCESS,
    setting: data || {}
  };
}

export function deleteUserColumnVisibilities(currentState) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const visibilities = getState().referenceData.userColumnVisibilities.find(
      ({ state }) => state === currentState
    );
    operationsApi
      .deleteUserColumnVisibilities(visibilities.id)
      .then(response => response.data)
      .catch(() => dispatch(ajaxCallError()))
      .then(data => {
        dispatch(deleteUserColumnVisibilitiesSuccess(data));
      });
  };
}

function deleteUserColumnVisibilitiesSuccess(data) {
  return {
    type: types.DELETE_USER_COLUMN_VISIBILITY_SUCCESS,
    setting: data || {}
  };
}
