import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions';
import Logger from 'util/Logger';
import { userCanEdit } from 'util/AdGroupsHelper';

export function getJob(jobId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.getJob(jobId).then(
      (job) => dispatch(updateJobSuccess(job)),
      (error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error);
      },
    );
  };
}

export function createNewJob(formValues) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.createNewJob(formValues).then(
      (job) => dispatch(updateJobSuccess(job)),
      (error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error);
      },
    );
  };
}
export function updateJobSendNOC(job) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.updateJobSendNOC(job).then(
      (response) => Logger.success('NOC Sent'),
      (error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error);
      },
    );
  };
}

export function updateJob(formValues) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.updateJob(formValues).then(
      (response) => Logger.success('Job Updated'),
      (error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error);
      },
    );
  };
}
export function duplicateJob(jobId, jobValues) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.duplicateJob(jobId, jobValues).then(
      (job) => {
        Logger.success('Job Duplicated');
        return job;
      },
      (error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error);
      },
    );
  };
}
export function updateJobType(jobId, newTypeId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.updateJobType(jobId, newTypeId).then(
      (job) => {
        Logger.success('Job Type Updated');
        return job;
      },
      (error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error);
      },
    );
  };
}

function updateJobSuccess(job) {
  const canEditJob = userCanEdit(job.currentState);
  return {
    type: types.UPDATE_JOB_SUCCESS,
    job: {
      ...job,
      canEditJob,
    },
  };
}

export function clearTreatmentType() {
  return {
    type: types.CLEAR_JOB_TREATMENTTYPE,
  };
}
