import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Container, Form, FormGroup, Button, Row, Col } from 'reactstrap';
import { Band } from 'components/layout';
import RenderField from 'components/fields/RenderField';
import CheckboxField from 'components/fields/CheckboxField';
import SearchableSelect from 'components/fields/SearchableSelect';
import DatePickerField from 'components/fields/DatePickerField';
import * as Validators from 'util/Validators';
import { searchForm } from 'constants/FormConstants';
import { preventEnterSubmit } from 'actions/formActions';
import moment from 'moment';
import CustomTagSelect from 'components/fields/CustomTagSelect';

const propTypes = {
  handleSubmit: PropTypes.func,
  clientSummaries: PropTypes.array,
  treatmentTypes: PropTypes.array,
  destinations: PropTypes.array,
  jobStandards: PropTypes.array,
};

class SearchForm extends Component {
  onTreatmentDateFromChange = (newtreatmentDateFrom) => {
    const { treatmentDateTo, actions } = this.props;
    const isTreatmentDateOlder = this.isDateOlder(
      newtreatmentDateFrom,
      treatmentDateTo,
    );
    if (!isTreatmentDateOlder) {
      return false;
    }
    actions.change(searchForm, 'treatmentDateTo', newtreatmentDateFrom);
  };

  isDateOlder = (firstDate, secondDate) => {
    const firstDateMoment = moment(firstDate);
    const secondDateMoment = moment(secondDate);
    return firstDateMoment.isAfter(secondDateMoment);
  };

  clearForm = () => {
    const { onSubmit, initialValues } = this.props;
    this.resetFormValue();
    onSubmit(initialValues);
  };

  resetFormValue = () => {
    const { actions } = this.props;
    actions.reset(searchForm);
  };

  render() {
    const {
      handleSubmit,
      clientSummaries,
      treatmentTypes,
      treatmentDateFrom,
      destinations,
      jobStandards,
      areas,
    } = this.props;

    return (
      <Band tint>
        <div className="form-section">
          <Container>
            <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
              <div className="row align-items-center">
                <FormGroup className="col-sm-3">
                  <Field
                    name="clientCode"
                    label="Client "
                    placeholder="Enter a Client Code"
                    component={SearchableSelect}
                    options={clientSummaries}
                    valueKey="code"
                    labelKey="name"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="vessel"
                    label="Vessel"
                    component={RenderField}
                    placeholder="Enter a Vessel"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="treatmentTypeId"
                    label="Treatment Type"
                    options={treatmentTypes}
                    component={SearchableSelect}
                    placeholder="Enter a Treatment Type"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="marking"
                    label="Marking"
                    component={RenderField}
                    placeholder="Enter a Marking"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="bacc"
                    label="BACC #"
                    component={RenderField}
                    placeholder="Enter a BACC #"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="orderNumber"
                    label="Order #"
                    component={RenderField}
                    placeholder="Enter an Order #"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="invoiceNumber"
                    label="Invoice #"
                    component={RenderField}
                    placeholder="Enter an Invoice #"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="jobReference"
                    label="Job #"
                    component={RenderField}
                    placeholder="Enter a Job #"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="parentJobReference"
                    label="Parent Job #"
                    component={RenderField}
                    placeholder="Enter a Parent Job #"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="treatmentDateFrom"
                    label="Start Date"
                    component={DatePickerField}
                    defaultToEmpty
                    type="text"
                    placeholder="Enter an Start Date"
                    validate={Validators.isValidDate}
                    onChange={this.onTreatmentDateFromChange}
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="treatmentDateTo"
                    label="End Date"
                    minDate={treatmentDateFrom}
                    component={DatePickerField}
                    defaultToEmpty
                    type="text"
                    placeholder="Enter an End Date"
                    validate={Validators.isValidDate}
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="destination"
                    label="Destination"
                    options={destinations}
                    component={CustomTagSelect}
                    placeholder="Enter destination"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="jobStandard"
                    label="JobStandard"
                    options={jobStandards}
                    component={SearchableSelect}
                    placeholder="Select Job Standard"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="area"
                    label="Area"
                    options={areas}
                    component={SearchableSelect}
                    placeholder="Select Area"
                  />
                </FormGroup>
                <FormGroup className="col-sm-3">
                  <Field
                    name="eralisJobNumber"
                    label="Eralis Subjob #"
                    component={RenderField}
                    placeholder="Enter an Eralis Subjob #"
                  />
                </FormGroup>

                <FormGroup className="col-sm-3">
                  <Field
                    name="bookingReference"
                    label="Booking Ref #"
                    component={RenderField}
                    placeholder="Enter booking ref #"
                  />
                </FormGroup>

                <FormGroup className="col-sm-3" />

                <FormGroup className="col-sm-4 search-options">
                  <Field
                    className="ml-4"
                    name="includeNewJobs"
                    label="Include New Jobs"
                    component={CheckboxField}
                  />
                  <Field
                    className="ml-4"
                    name="includeCompletedJobs"
                    label="Include Completed Jobs"
                    component={CheckboxField}
                  />
                  <Field
                    className="ml-4"
                    name="certifiedOnlyJobs"
                    label="Certified Only Jobs"
                    component={CheckboxField}
                  />
                  <Field
                    className="ml-4"
                    name="includeCancelledJobs"
                    label="Include Cancelled/Failed Jobs"
                    component={CheckboxField}
                  />
                </FormGroup>
                <FormGroup className="col-sm-2 mb-0">
                  <Row>
                    <Col md="6">
                      <Button color="primary" type="submit">
                        Search
                      </Button>
                    </Col>
                    <Col md="6">
                      <Button color="secondary" onClick={this.clearForm}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            </Form>
          </Container>
        </div>
      </Band>
    );
  }
}

SearchForm.propTypes = propTypes;

export default reduxForm({ form: searchForm, destroyOnUnmount: false })(
  SearchForm,
);
