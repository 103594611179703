import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions';

export function getContacts() {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getContacts()
      .then(
        response => dispatch(getContactsSuccess(response.data)),
        error => dispatch(ajaxCallError())
      );
  };
}

export function createNewContact(contact) {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .createNewContact(contact)
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}

export function updateContact(contact) {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .updateContact(contact)
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}

function getContactsSuccess(contacts) {
  return {
    type: types.GET_CONTACTS_SUCCESS,
    contacts: contacts || []
  };
}
