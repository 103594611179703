import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import AppRoutes from './AppRoutes';
import * as referenceDataActions from 'actions/referenceDataActions';
import TopNav from 'components/common/TopNav';
import TopNavToggler from 'components/common/TopNavToggler';
import withAuth from 'hoc/withAuth';
import { userCanAccessSystem } from 'util/AdGroupsHelper';
import keycloakManager from '../keycloakManager';

const propTypes = {
  actions: PropTypes.object,
  isLoading: PropTypes.bool
};

const env = window.env;

class App extends Component {
  state = {
    topNavIsOpen: true,
    referenceDataLoaded: false
  };

  componentDidMount() {
    if (!userCanAccessSystem()) {
      keycloakManager.logout();
    }
    this.preloadReferenceData();
  }

  preloadReferenceData() {
    this.props.actions
      .getAllReferenceData()
      .then(() => this.setState({ referenceDataLoaded: true }));
  }

  logout = () => {
    keycloakManager.logout();
  };

  toggleTopNav = () => {
    this.setState(prevState => ({
      topNavIsOpen: !prevState.topNavIsOpen
    }));
  };

  render() {
    const { referenceDataLoaded } = this.state;
    if (!referenceDataLoaded) {
      return null;
    }
    return (
      <div className="app-layout">
        <div
          className="app-layout-sidebar"
          style={{ backgroundColor: env.BackgroundColor }}
        >
          <TopNavToggler onClick={this.toggleTopNav} envprops={env} />
        </div>
        <div className="app-layout-content">
          <TopNav
            isOpen={this.state.topNavIsOpen}
            logout={this.logout}
            envprops={env}
          />
          <AppRoutes />
        </div>
      </div>
    );
  }
}

App.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    currentJobState: state.currentJobState
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(referenceDataActions, dispatch)
  };
}

const RoutedApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
const AuthedApp = withAuth(RoutedApp);

export default AuthedApp;
