import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import HeaderButton from './HeaderButton';

const propTypes = {
  color: PropTypes.string,
  outline: PropTypes.bool,
  ligature: PropTypes.string,
  multi: PropTypes.bool,
  onClose: PropTypes.func
};

class HeaderDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(
      {
        dropdownOpen: !this.state.dropdownOpen
      },
      () => {
        if (this.props.onClose && !this.state.dropdownOpen) {
          this.props.onClose();
        }
      }
    );
  }

  render() {
    const { color, outline, ligature, children, className } = this.props;
    return (
      <Dropdown
        className={classNames('app-header-drop-down', className)}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle
          tag="div"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={this.state.dropdownOpen}
        >
          <HeaderButton
            color={color}
            outline={outline}
            ligature={ligature}
            noMargin
          />
        </DropdownToggle>
        <DropdownMenu right>
          <div className="app-header-drop-down-menu">
            {Children.map(children, child => (
              <DropdownItem toggle={false}>{child}</DropdownItem>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

HeaderDropDown.propTypes = propTypes;

export default HeaderDropDown;
