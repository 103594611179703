import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Collapse } from 'reactstrap';

import {
  updateTreatmentResults,
  createTreatmentResults,
  updateJob,
} from '../../../api/operationsApi';
import TreatmentTypeIds from '../../../constants/TreatmentTypeIds';
import Logger from '../../../util/Logger';
import { isVerified, isAssigned } from '../../../util/JobStateHelper';
import {
  isImportAndHeatTreatment,
  isExportOther,
  isOtherAndThermofog,
  isOtherAndWaterBlasting,
  isImportOtherAndGasFree,
  isExportAndContainerAndPestigas,
  isImportOtherTypeID,
} from '../../../util/JobTypeHelper';
import {
  isAlPVessel,
  isCallington1Shot,
  isVaporPhos,
  isAlPNonVessel,
  isGasFree,
  isMacSlay,
} from '../../../util/TreatmentTypeHelper';

import { Band, ToggleButton } from '../../layout';
import Checkbubble from '../../common/Checkbubble';

import TreatmentResultsFormContainer from './TreatmentResultsFormContainer';

const propTypes = {
  currentState: PropTypes.string,
  treatmentResults: PropTypes.object,
  currentJob: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

class TreatmentResultsSection extends Component {
  state = {
    collapsed: this.isSectionCollapsed(),
  };

  isSectionCollapsed() {
    const { currentState, treatmentResults } = this.props;
    if (isVerified(currentState)) {
      return false;
    }
    if (treatmentResults && treatmentResults.complete) {
      return true;
    }
  }

  submitTreatmentResults = (formValues) => {
    const { markings, ...data } = formValues;
    console.log('submitettr',formValues)
    const job = {
      ...data,
      id: this.props.treatmentResults
        ? this.props.treatmentResults.id
        : undefined,
      complete: this.getCompletionStatus(data, markings) && this.checkDosageRates(data)?true:false,
    };

    if(!this.checkDosageRates(data)){
      Logger.error("Job cannot complete please check Dosage rates")
    }

    this.saveTreatmentResults(job, markings);
  };

  saveTreatmentResults = (job, markings) => {
    const { onSave } = this.props;
    const saveTreatmentResultsOperation = job.id
      ? updateTreatmentResults
      : createTreatmentResults;
    const saveJobOperation = job.id ? updateJob : () => Promise.resolve();

    saveJobOperation({
      ...this.props.currentJob,
      treatmentTypeDetails: {
        ...this.props.currentJob.treatmentTypeDetails,
        markings: markings,
      },
    })
      .then(() => saveTreatmentResultsOperation(job))
      .then(() => {
        Logger.success('Treatment Updated Successfully');
        if (this.getCompletionStatus(job, markings)) {
          this.toggleExpand();
        }
        onSave();
      });
  };

  checkDosageRates= (formValues) => {
    const treatmentId = this.props.currentJob.treatmentDetails.treatmentTypeId;
    const currentJob = this.props.currentJob;
    if(isAlPVessel(treatmentId)||isVaporPhos(treatmentId)){
        var dr = currentJob.treatmentDetails.dosageRate
        var iniDose = formValues.initialDosage
        var topUp = formValues.topupDosage
        if((dr - (iniDose+topUp))!=0){
          return false;
        }

        return true;
    }
    return true;
  }

  getCompletionStatus = (formValues, markings) => {
    const treatmentId = this.props.currentJob.treatmentDetails.treatmentTypeId;
    if (
      !isAlPVessel(treatmentId) &&
      !isAlPNonVessel(treatmentId) &&
      !isVaporPhos(treatmentId)&&
      !isGasFree(treatmentId)
    ) {
      if (!markings && markings.some((marking) => !marking.sighted)) {
        return false;
      }
    }

    const { currentState } = this.props;
    const {
      actualTemperature,
      thermometerNumber,
      jobTypeId,
      treatmentTypeId,
      endDate,
    } = formValues;

    const otherWaterBlastingJob = isOtherAndWaterBlasting(
      { id: jobTypeId },
      treatmentTypeId,
    );
    const importOtherGasFreeJob = isImportOtherAndGasFree(
      { id: jobTypeId },
      treatmentTypeId,
    );
    const isHeatTreatmentJob = isImportAndHeatTreatment({ id: jobTypeId });
    const isOtherThermoFogJob = isOtherAndThermofog(
      { id: jobTypeId },
      treatmentTypeId,
    );

    const isPestigasTreatmentJob = isExportAndContainerAndPestigas(
      { id: jobTypeId },
      treatmentTypeId,
    );

    const thermoNumberRequired =
      !importOtherGasFreeJob &&
      !isHeatTreatmentJob &&
      !isOtherThermoFogJob &&
      !isPestigasTreatmentJob &&
      !isGasFree(treatmentTypeId)&&
      !isCallington1Shot;

    const actualTemperatureRequired =
      treatmentTypeId === TreatmentTypeIds.MethylBromide &&
      !isExportOther({ id: jobTypeId }) &&
      !isImportOtherTypeID(jobTypeId);

    const alpVessel = isAlPVessel(treatmentTypeId);
    const vaporPhos = isVaporPhos(treatmentTypeId);
    const alpNonVessel = isAlPNonVessel(treatmentId);
    return otherWaterBlastingJob ||
      alpVessel ||
      vaporPhos ||
      isGasFree(treatmentId)||
      (!isAssigned(currentState) &&
        !otherWaterBlastingJob &&
        !alpVessel &&
        !alpNonVessel &&
        !vaporPhos &&
        (!actualTemperatureRequired || actualTemperature) &&
        (!thermoNumberRequired || thermometerNumber) )
      ? true
      : false;
  };

  toggleExpand = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { treatmentResults } = this.props;
    const { collapsed } = this.state;

    return (
      <Band dark>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>Treatment</h4>
              </Col>
              <Col xs="4" className="text-right">
                <Checkbubble
                  className="align-middle"
                  checked={treatmentResults ? treatmentResults.complete : false}
                />
                <ToggleButton
                  className="ml-3"
                  color="light"
                  active={collapsed}
                  onClick={this.toggleExpand}
                  ligature="expand_less"
                />
              </Col>
            </Row>
            <Collapse isOpen={!collapsed}>
              <TreatmentResultsFormContainer
                onSubmit={this.submitTreatmentResults}
              />
            </Collapse>
          </Container>
        </div>
      </Band>
    );
  }
}

TreatmentResultsSection.propTypes = propTypes;

export default withRouter(TreatmentResultsSection);
