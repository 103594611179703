import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { Label, FormFeedback } from 'reactstrap';
import { isString } from 'lodash';
import { createSelectOptionsFromStringArray } from 'util/SelectHelper';

const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string
};

const CustomTagSelect = ({
  input,
  label,
  multi,
  placeholder,
  options,
  allowCommasInValue = false,
  meta: { touched, error }
}) => {
  const getDropdownOptions = (options = [], value) => {
    let dropdownOptions = [...options];
    if (value && isString(value) && options.indexOf(value) === -1) {
      dropdownOptions = dropdownOptions.concat(
        value.split(allowCommasInValue ? '_' : ',')
      );
    }
    return createSelectOptionsFromStringArray(dropdownOptions);
  };

  const dropdownOptions = getDropdownOptions(options, input.value);

  return (
    <div>
      <Label for={input.name}>{label}</Label>
      <Select.Creatable
        className={classNames({
          'is-invalid': touched && error
        })}
        multi={multi}
        options={dropdownOptions}
        placeholder={placeholder}
        onChange={input.onChange}
        value={input.value}
        simpleValue
        clearable={false}
        shouldKeyDownEventCreateNewOption={key => {
          if (allowCommasInValue && key.keyCode === 188) {
            return false;
          }

          switch (key.keyCode) {
            case 9: // TAB
            case 13: // ENTER
            case 188: // COMMA
              return true;
            default:
              return false;
          }
        }}
        delimiter={allowCommasInValue ? '_' : ','}
      />
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};

CustomTagSelect.propTypes = propTypes;

export default CustomTagSelect;
