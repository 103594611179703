import React from 'react';
import PropTypes from 'prop-types';
import { Label, FormFeedback } from 'reactstrap';
import ApprovalControl from 'components/common/ApprovalControl';
import classNames from 'classnames';

const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  approveLabel: PropTypes.string,
  rejectLabel: PropTypes.string,
  meta: PropTypes.object
};

const ApprovalField = ({
  input,
  label,
  approveLabel,
  rejectLabel,
  meta: { error, touched }
}) => {
  const onApprove = () => input.onChange(true);
  const onReject = () => input.onChange(false);
  return (
    <div>
      <Label>{label}</Label>
      <div
        className={classNames('form-control-custom', {
          'is-invalid': touched && error
        })}
      >
        <ApprovalControl
          onApprove={onApprove}
          onReject={onReject}
          approveLabel={approveLabel}
          rejectLabel={rejectLabel}
          {...input}
        />
      </div>
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};

ApprovalField.propTypes = propTypes;

export default ApprovalField;
