import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  isExportAndLogsOrBreakBulk,
  getCategoryDisplayName,
  isExportAndLogsAndPhosphine,
  isExportAndLogs,
  isExportAndLogsAndALPVessel,
  isExportAndLogsAndVaporPhos,
  isContainerOrBreakBulkOrOther,
  isImportOrExportAndContainer,
  isImportOrExportContainerAndMethylBromide,
  isExportOtherMBApples,
} from '../../util/JobTypeHelper';
import { isVerified, isCancelled, isFailed,isCertified } from '../../util/JobStateHelper';
import * as HistoryHelper from '../../util/HistoryHelper';
import {
  treatmentResultsComplete,
  treatmentResultRecordsComplete,
  phosphineTreatmentResultRecordsComplete,
  treatmentResultsCompleted,
  treatmentResultRecordCompleteContainer,
} from '../../util/SectionHelper';
import Logger from '../../util/Logger';
import ResendNotices from './resendNotices/ResendNotices';
import ResendNoticeCompletion from './resendNotices/ResendNoticeCompletion';
import { userCanCancel,userIsTechnician,userIsLicencedTechnician, userIsCustomer } from '../../util/AdGroupsHelper';
import { updateJob,updateJobSendNOC } from '../../api/operationsApi';

import { Header, HeaderButtonNav, HeaderButton, HeaderTitle } from '../layout';
import OverlayModal from '../layout/OverlayModal';
import JobChangerContainer from '../dashboard/JobChangerContainer';

import JobMastheadContainer from './JobMastheadContainer';
import JobDocumentsContainer from './documents/JobDocumentsContainer';
import JobAttachmentsContainer from './JobAttachmentsContainer';
import JobClientSummaryContainer from './JobClientSummaryContainer';
import JobDetailsSummaryContainer from './JobDetailsSummaryContainer';
import JobCertificationContainer from './jobCertification/JobCertificationContainer';
import TreatmentResultRecordsSection from './treatmentResultRecords/TreatmentResultRecordsSection';
import PhosphineRecordsSection from './phosphineRecords/PhosphineRecordsSection';
import TreatmentResultsSection from './treatmentResults/TreatmentResultsSection';
import JobNoticesContainer from './JobNoticesContainer';
import JobCompletionContainer from './jobCompletion/JobCompletionContainer';
import JobSignoffContainer from './jobSignoff/JobSignoffContainer';
import JobCancelModal from './JobCancelModal';
import JobDuplicateModal from './JobDuplicateModal';
import JobDuplicateConfirmationModal from './JobDuplicateConfirmationModal';
import { upperFirst } from 'lodash';
import {
  isAlPVessel,
  isVaporPhos,
  isAlPNonVessel,
  isGasFree,
  isHydrogenCyanide,
  isEthylFormate,
} from '../../util/TreatmentTypeHelper';
import TreatmentResultRecordCoveredContainerSection from './treatmentResultRecords/TreatmentResultRecordCoveredContainerSection';
import{isMethylBromide} from '../../util/TreatmentTypeHelper';

const propTypes = {
  actions: PropTypes.object,
  jobLocations: PropTypes.array,
  currentJob: PropTypes.object,
  jobId: PropTypes.string,
  history: PropTypes.object,
};

class JobPage extends Component {
  state = {
    isSubmitting: false,
    showResendModal: false,
    showResendCompletionModal: false,
    showCancelJob: false,
    showDuplicateJob: false,
    showDuplicateJobData: null,
    showDuplicateJobConfirmation: false,
    showJobModal: false,
  };

  componentDidMount() {
    this.getJobDetails();
  }

  handleJobAttachmentsUpdate = (formValues) => {
    const { currentJob } = this.props;
    const updatedJob = { ...currentJob, ...formValues };
    this.updateJob(updatedJob);
  };

  handleJobUpdate = (formValues) => {
    this.updateJob(formValues);
  };

  updateJob = (job) => {
    this.setState({ isSubmitting: true });
    return updateJob(job).then((result) => {
      this.getJobDetails();
      this.setState({ isSubmitting: false });
    });
  };

  getJobDetails = () => {
    const { jobId, actions } = this.props;
    actions.getJob(jobId).then((response) => {
      actions.getClientDetails(response.job.clientCode);
    });
  };

  duplicateJob = () => {
    this.setState({ showDuplicateJob: true });
  };
  duplicateJobConfirm = () => {
    this.setState({ showDuplicateJobConfirmation: true });
  };
  cancelJob = () => {
    this.setState({ showCancelJob: true });
  };

  cancelJobSuccess = (comment, cancellationOptions) => {
    const { currentJob, history } = this.props;
    const jobTransitionActions = {
      cancelJob: true,
      comment,
      cancellationOptions,
    };
    this.updateJob({ ...currentJob, jobTransitionActions }).then(() => {
      Logger.success('Job successfully cancelled');
      history.push('/dashboard/cancelled');
    });
  };

  exitJob = () => {
    let { history } = this.props;
    HistoryHelper.performSafeGoBack(history);
  };

  getPageHeaderLabel = (jobType) => {
    const jobCategory = getCategoryDisplayName(jobType.category);
    return `Update ${jobType.name} ${jobCategory} job sheet`;
  };

  toggleResendModal = () => {
    this.setState((prevState) => ({
      showResendModal: !prevState.showResendModal,
    }));
  };

  toggleResendCompletionModal = () => {
    this.setState((prevState) => ({
      showResendCompletionModal: !prevState.showResendCompletionModal,
    }));
  };

  toggleCancelJob = () => {
    this.setState((prevState) => ({
      showCancelJob: !prevState.showCancelJob,
    }));
  };
  toggleDuplicateJob = () => {
    this.setState((prevState) => ({
      showDuplicateJob: !prevState.showDuplicateJob,
    }));
  };
  toggleDuplicateJobConfirmation = (duplicatedJob) => {
    this.setState((prevState) => ({
      showDuplicateJobData: duplicatedJob,
      showDuplicateJobConfirmation: !prevState.showDuplicateJobConfirmation,
    }));
  };

  toggleChangeJob = () => {
    this.setState((prevState) => ({
      showJobModal: !prevState.showJobModal,
    }));
  };

  handleCancelJob = (formValues) => {
    this.cancelJobSuccess(formValues.comment, formValues.cancellationOptions);
  };

  handleDuplicateJob = (formValues) => {
    let { jobId, actions } = this.props;
    const jobValues = {
      ...formValues,
      dateOfIntendedTreatment: moment(
        formValues.dateOfIntendedTreatment,
      ).toISOString(false),
    };

    actions.duplicateJob(jobId, jobValues).then((response) => {
      this.toggleDuplicateJob();
      this.toggleDuplicateJobConfirmation(response);
      this.getJobDetails();
    });
  };

  handleJobTypeChange = (newTypeId) => {
    let { jobId, actions } = this.props;

    actions
      .updateJobType(jobId, newTypeId)
      .then((response) => response)
      .then((response) => {
        this.toggleChangeJob();
        this.getJobDetails();
      });
  };

  resendNotice = (formValues) => {
    this.updateJob(formValues).then(() => {
      Logger.success('Notice successfully sent');
      this.toggleResendModal();
    });
  };
  sendNoc = (job) => {
    this.setState({ isSubmitting: true });
    return updateJobSendNOC(job).then((result) => {
      this.getJobDetails();
      this.setState({ isSubmitting: false });
    });
  };


  sendNoticeOfCompletion = (formValues) => {
    var{currentJob,history} = this.props
    this.sendNoc(formValues).then(() => {
      Logger.success('Notice successfully sent');
      this.toggleResendCompletionModal();
    });
  };

  render() {
    if (!this.props.currentJob) return false;

    const {
      isSubmitting,
      showResendModal,
      showCancelJob,
      showDuplicateJob,
      showDuplicateJobData,
      showDuplicateJobConfirmation,
      showJobModal,
      showResendCompletionModal,
    } = this.state;
    const { currentJob, actions } = this.props;
    const {
      treatmentResults,
      treatmentResultRecords,
      phosphineTreatmentResultRecords,
      treatmentDetails,
      currentState,
      canEditJob,
      type,
      treatmentTypeDetails,
      //stopCredit
    } = currentJob;
        const standardId = currentJob.treatmentDetails.standardId?currentJob.treatmentDetails.standardId:null;
        const commodity = currentJob.treatmentTypeDetails.commodity?currentJob.treatmentTypeDetails.commodity:null;
        const treatmentId = currentJob.treatmentDetails.treatmentTypeId;

    const canDuplicateJob =
      currentState === 'assigned' || currentState === 'in progress';
    //  ||  currentState === 'certified';
    const canUseChangeJobType =
      currentState === 'new' || currentState === 'assigned';
   
    const ignoreMarkingsCheckBox =
      isAlPVessel(treatmentId) ||
      isAlPNonVessel(treatmentId) ||
      isVaporPhos(treatmentId)||isGasFree(treatmentId);

    let treatmentComplete = ignoreMarkingsCheckBox
      ? true
      : treatmentResultsComplete(
          treatmentResults,
          currentJob,
        ) ||
        treatmentResultRecordsComplete(
          treatmentResultRecords,
          isExportAndLogs(currentJob.type)
            ? undefined
            : currentJob.treatmentTypeDetails.markings,
        ) ||
        phosphineTreatmentResultRecordsComplete(
          phosphineTreatmentResultRecords,
        );
    if (
      isExportAndLogsAndALPVessel(type, treatmentDetails.treatmentTypeId) ||
      isExportAndLogsAndVaporPhos(type, treatmentDetails.treatmentTypeId)
    ) {
      treatmentComplete = treatmentResults && treatmentResults.complete;
    }
    if(isImportOrExportContainerAndMethylBromide(type,treatmentDetails.treatmentTypeId)){
      treatmentComplete = treatmentResultRecordCompleteContainer(treatmentResultRecords,currentJob.treatmentTypeDetails.markings)
    }

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            {canDuplicateJob ? (
              <HeaderButton
                color="light"
                onClick={this.duplicateJob}
                ligature="file_copy"
              />
            ) : null}
            {userCanCancel(currentState) ? (
              <HeaderButton
                color="light"
                onClick={this.cancelJob}
                ligature="delete"
              />
            ) : null}
            {canEditJob &&
            !isFailed(currentState) &&
            !isCancelled(currentState) &&
            !isHydrogenCyanide(treatmentDetails.treatmentTypeId)||
           (isHydrogenCyanide(treatmentDetails.treatmentTypeId) && isCertified(currentState))? (
              <HeaderButton
                color="light"
                onClick={this.toggleResendModal}
                ligature="mail_outline"
              />
            ) : null}
            {(canEditJob || userIsTechnician(currentState)||userIsLicencedTechnician(currentState)) && 
            isCertified(currentState)&& isHydrogenCyanide(treatmentDetails.treatmentTypeId) ? (
              <HeaderButton
                color="light"
                onClick={this.toggleResendCompletionModal}
                ligature="fact_check"
              />
            ) : null}   
            <HeaderButton
              color="primary"
              onClick={this.exitJob}
              ligature="clear"
            />
          </HeaderButtonNav>
          <HeaderTitle
            label={this.getPageHeaderLabel(type)}
            imageUrl={type.imageUrlFull}
          >
            {canUseChangeJobType && (
              <HeaderButton
                onClick={this.toggleChangeJob}
                color="light"
                ligature="edit"
              />
            )}
          </HeaderTitle>
        </Header>
        <JobMastheadContainer />
        <JobDocumentsContainer />
        <JobClientSummaryContainer />
        <JobDetailsSummaryContainer />

        {isExportAndLogsAndPhosphine(type, treatmentDetails.treatmentTypeId) ? (
          <PhosphineRecordsSection
            complete={phosphineTreatmentResultRecordsComplete(
              phosphineTreatmentResultRecords,
            )}
          />
        ) : ( isExportAndLogsOrBreakBulk(type) ||
        isEthylFormate(treatmentDetails.treatmentTypeId)||
        (isExportOtherMBApples(type,treatmentDetails.treatmentTypeId,commodity,standardId) && !currentJob.treatmentResults) ||
        (isImportOrExportContainerAndMethylBromide(type,treatmentDetails.treatmentTypeId)&& !currentJob.treatmentResults||(!currentJob.treatmentResults&&currentJob.treatmentResultRecords.length>0))
        ||isExportAndLogsOrBreakBulk(type) ||type.name === 'MB Logs In Hold') &&
          !isExportAndLogsAndALPVessel(
            type,
            treatmentDetails.treatmentTypeId,
          ) &&
          !isExportAndLogsAndVaporPhos(
            type,
            treatmentDetails.treatmentTypeId,
          ) ? (
          <TreatmentResultRecordsSection
          standardId = {standardId}
          commodity = {commodity}
            treatmentDetails={treatmentDetails}
            jobType={type}
            treatmentResultRecords={treatmentResultRecords}
            onSave={this.getJobDetails}
            currentState={currentState}
            complete={Boolean(
              treatmentResultRecordsComplete(
                treatmentResultRecords,
                isExportAndLogs(currentJob.type)
                  ? undefined
                  : currentJob.treatmentTypeDetails.markings,
              ),
              )}
          />
        ) : (
          <TreatmentResultsSection
            onSave={this.getJobDetails}
            treatmentResults={treatmentResults}
            commodity={treatmentTypeDetails.commodity}
            currentState={currentState}
            currentJob={currentJob}
          />
        )}

        {treatmentComplete ? <JobCompletionContainer /> : null}
            {userIsCustomer()?null:<JobNoticesContainer />}
        <JobAttachmentsContainer
          onSubmit={this.handleJobAttachmentsUpdate}
          isSubmitting={isSubmitting}
        />

        {treatmentComplete ? <JobSignoffContainer /> : null}

        {isVerified(currentState) && canEditJob ? (
          <JobCertificationContainer updateJob={this.updateJob} />
        ) : null}

        {showResendModal && (
          <ResendNotices
            title="Resend Notices"
            toggle={this.toggleResendModal}
            onSubmit={this.resendNotice}
            currentState={currentState}
            isOpen={showResendModal}
            jobType={type}
          />
        )}

          {showResendCompletionModal && (
          <ResendNoticeCompletion
            title="Send Notice of Completion"
            onSubmit={this.sendNoticeOfCompletion}
            currentState={currentState}
            isOpen={showResendCompletionModal}
            jobType={type}
          />
        )}

        {showCancelJob && (
          <JobCancelModal
            title="Cancel Job"
            isOpen={showCancelJob}
            toggle={this.toggleCancelJob}
            onSubmit={this.handleCancelJob}
          />
        )}

        {showDuplicateJob && (
          <JobDuplicateModal
            title="Duplicate Job"
            currentJob={currentJob}
            isOpen={showDuplicateJob}
            toggle={this.toggleDuplicateJob}
            onSubmit={this.handleDuplicateJob}
          />
        )}

        {showDuplicateJobConfirmation && (
          <JobDuplicateConfirmationModal
            title="Job Duplicated"
            duplicatedJob={showDuplicateJobData}
            isOpen={showDuplicateJobConfirmation}
            toggle={this.toggleDuplicateJobConfirmation}
          />
        )}

        {showJobModal && (
          <OverlayModal
            title="Change Job Type"
            isOpen={this.state.showJobModal}
            toggle={this.toggleChangeJob}
            wrapClassName="app-overlay"
          >
            <JobChangerContainer
              handleChange={this.handleJobTypeChange}
              currentJob={currentJob}
            />
          </OverlayModal>
        )}
      </div>
    );
  }
}

JobPage.propTypes = propTypes;

export default JobPage;
