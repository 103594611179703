import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getAreas() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getAreas()
      .then(
        response => dispatch(getAreasSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getAreasSuccess(response) {
  return {
    type: types.GET_AREAS_SUCCESS,
    areas: response.data || []
  };
}
