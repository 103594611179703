import React from 'react';

const IconAndTextFormatter = ({ name, imageUrlFull }) => (
  <div>
    <img
      style={{
        width: '26px',
        marginRight: '1rem'
      }}
      src={imageUrlFull}
      alt={`${name}-logo`}
    />
    {name}
  </div>
);

export default IconAndTextFormatter;
