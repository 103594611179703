import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field,FieldArray } from 'redux-form';
import { FormGroup, Row, Button, Form } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import RenderField from '../../../fields/RenderField';
import CustomTagSelect from '../../../fields/CustomTagSelect';
import SearchableSelect from '../../../fields/SearchableSelect';
import DatePickerField from '../../../fields/DatePickerField';

import CalculatedTreatmentResultRecordsFields from '../CalculatedTreatmentResultRecordsFields';
import DimensionTreatmentResultRecordsFields from '../DimensionTreatmentResultRecordsFields';

import * as Validators from '../../../../util/Validators';
import {
  isExportAndLogs,
  isBreakBulkJobType,
  isOtherAndThermofog,
  isPestigasContainer,
  isMBLogsInHold,
  isExportAndContainer,
  isImportOrExportAndContainer
} from '../../../../util/JobTypeHelper';
import { isMethylBromide } from '../../../../util/TreatmentTypeHelper';

import { getCompetentTechnicians } from '../../../../selectors/technicianSelectors';
import { minZero } from '../../../../util/NormalizingHelper';
import CheckboxField from 'components/fields/CheckboxField';
import {treatmentResultRecordsForm} from '../../../../constants/FormConstants'
import MBMarkingsFieldArray from '../../../fields/MBMarkingsFieldArray';


const propTypes = {
  canEditJob: PropTypes.bool,

  treatmentType: PropTypes.string,
  actions: PropTypes.shape({
    changeFieldValue: PropTypes.func.isRequired,
  }),
  calculatedMbkg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const isBetweenOneAndNinetyNine = (value) => {
  const limit = 99;
  if (value < 0) {
    return 0;
  } else if (value > limit) {
    return limit;
  } else {
    return value;
  }
};




class ContainerTreatmentResultRecordsStartDetailsSection extends Component {


  render() {
    const {
      technicians,
      canEditJob,
      jobType,
      treatmentType,
      currentJob,
      isCoveredContainer,
      actions,
    } = this.props;
    /*const showSachetFields =
      !isMethylBromide(treatmentType) &&
      !isOtherAndThermofog(jobType, treatmentType) &&
      !isPestigasContainer(jobType, treatmentType);*/
      const showSachetFields = false;
    const competentTechs = getCompetentTechnicians(
      technicians,
      treatmentType,
      currentJob.treatmentTypeDetails.commodity,
      currentJob.treatmentDetails.standardId,
      currentJob.typeId,
      currentJob.areaId,
    );

 
   actions.changeFieldValue(treatmentResultRecordsForm, 'coveredContainer', true);

    return (
      <fieldset disabled={!canEditJob && 'disabled'}>   
        <Row>
          <FormGroup className="col-lg-4">
            <Field
              name="coveringTechnicianId"
              label="Covering Technician"
              component={SearchableSelect}
              placeholder="Please select a technician"
              options={competentTechs}
              valueKey="id"
              labelKey="name"
            />
          </FormGroup> 

          <FormGroup className="col-lg-8">
            <CalculatedTreatmentResultRecordsFields isBreakBulk jobType={jobType} />
          
          </FormGroup>
          <FormGroup className="col-lg-4">
            <Field
              name="containerNumber"
              label={'Container Number'}
              component={RenderField}
              type="text"
              placeholder={"Enter Container No"}
            />
          </FormGroup>
          <FormGroup className="col-lg-4">
            <Field
              name="sealNumber"
              label={'Seal Number'}
              component={RenderField}
              type="text"
              placeholder={"Seal No"}
            />
          </FormGroup>
          <FormGroup className="col-lg-4"/>
          <FormGroup className="col-lg-4">
             <FieldArray 
              name="mbBottles"
              label="MB Bottle Number "
              component={MBMarkingsFieldArray}
              validate={Validators.mandatoryList}
            />
            </FormGroup>
          {isImportOrExportAndContainer(jobType)?null:
          <FormGroup className="col-lg-4">
            <Field
              name="lineNumber"
              label={'Line Number'}
              component={RenderField}
              type="text"
              placeholder={"Line No"}
            />
          </FormGroup>
  }
   
      
          {isBreakBulkJobType(jobType) ? (
            <FormGroup className="col-lg-4">
              <Field
                name="stackNumber"
                label="Stack #"
                component={RenderField}
                type="text"
                placeholder="Enter a Stack #"
              />
            </FormGroup>
          ) : null}
          {isBreakBulkJobType(jobType) ? (
            <FormGroup className="col-lg-4">
              <Field
                name="location"
                label="Location"
                component={RenderField}
                type="text"
                placeholder="Location"
              />
            </FormGroup>
          ) : null}
          <br/>
          {!(
            isMethylBromide(treatmentType) &&
            (isBreakBulkJobType(jobType) || isExportAndLogs(jobType)|| isMBLogsInHold(jobType))
          ) && (
            
            <FormGroup className="col-lg-4">
              <Field
                name="startDate"
                label="Start Date *"
                component={DatePickerField}
                showTimeSelect
                defaultToEmpty
                type="text"
                placeholder="Enter Start Date Time"
                validate={[Validators.isValidDateAndTime, Validators.required]}
                timeIntervals={1}
              />
            </FormGroup>
          ) }

          <FormGroup className="col-12">
            <DimensionTreatmentResultRecordsFields jobType={jobType} isCoveredContainer={true} />
          </FormGroup>
         
         { !(isMethylBromide(treatmentType) && isImportOrExportAndContainer(jobType)) ?
          <FormGroup className="col-lg-4">
            <Field
              name="jas"
              label={'M3'}
              component={RenderField}
              type="number"
             
              placeholder={"Enter M3"
              }
              validate={Validators.number}
              normalize={minZero}
            />
          </FormGroup> :null}
          {showSachetFields ? (
            <FormGroup className="col-lg-4">
              <Field
                name="sachetNumberList"
                label="Sachet #"
                component={CustomTagSelect}
                options={[]}
                type="text"
                multi
                placeholder={'Enter Sachet #'}
              />
            </FormGroup>
          ) : null}
        
         
        
          <FormGroup className="col-lg-5">
            <Field
              name="thermoNumber"
              label="Thermo #"
              component={RenderField}
              type="text"
              placeholder="Enter a Thermo #"
            />
          </FormGroup>
      
          <FormGroup className="col-lg-4">
            <Field
              name="extraHours"
              label="Extra Hours"
              component={RenderField}
              type="text"
              normalize={isBetweenOneAndNinetyNine}
              validate={Validators.number}
              placeholder="Extra hours (1-99 hours)"
            />
          </FormGroup>
          <FormGroup className="col-md-6">
              <Field component = {CheckboxField}
               name="coveredContainer"
               label = "Covered Container"
               disabled={true}
               />
                </FormGroup>
          
          <FormGroup className="col-12">
            <Button type="submit" className="mt-3" color="primary">
              Save
            </Button>
          </FormGroup>
        </Row>
      </fieldset>
    );
  }
}

ContainerTreatmentResultRecordsStartDetailsSection.propTypes = propTypes;

export default ContainerTreatmentResultRecordsStartDetailsSection;
