import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Button, Form, Col, Row, FormGroup } from 'reactstrap';

import * as Validators from '../../util/Validators';
import Logger from '../../util/Logger';
import { jobDuplicateForm } from '../../constants/FormConstants';
import * as jobActions from '../../actions/jobActions';
import { preventEnterSubmit } from '../../actions/formActions';

import ToggleButton from '../layout/ToggleButton';

import RenderField from '../fields/RenderField';
import PlainTextField from '../fields/PlainTextField';
import DatePickerField from '../fields/DatePickerField';
import CheckboxField from '../fields/CheckboxField';
import MarkingsFieldArray from '../fields/MarkingsFieldArray';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  currentJob: PropTypes.object,
};

class JobDuplicateForm extends Component {
  render() {
    const { handleSubmit, currentJob } = this.props;

    return (
      <div className="form-section">
        <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
          <fieldset className="c-fieldset--padding c-fieldset--no-padding-top">
            <Row>
              <FormGroup className="col-md-12">
                <h4>Job #{currentJob.jobReference}</h4>
                <Row>
                  <Col xs="6">
                    <PlainTextField
                      name="clientName"
                      label="Client"
                      value={currentJob.clientName}
                    />
                  </Col>
                  <Col xs="6">
                    <PlainTextField
                      name="location"
                      label="Location"
                      value={currentJob.location}
                    />
                  </Col>
                  <Col xs="6">
                    <PlainTextField
                      name="vessel"
                      label="Vessel"
                      value={currentJob.vessel}
                    />
                  </Col>
                  <Col xs="6">
                    <PlainTextField
                      name="voyage"
                      label="Voyage"
                      value={currentJob.voyage}
                    />
                  </Col>
                  <Col xs="6">
                    <PlainTextField
                      name="treatmentType"
                      label="Treatment Type"
                      value={currentJob.treatmentType.displayName}
                    />
                  </Col>
                  <Col xs="6">
                    <PlainTextField
                      name="dosageRate"
                      label="Dosage Rate"
                      value={currentJob.treatmentDetails.dosageRate}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Row>
          </fieldset>
          <hr />
          <fieldset className="c-fieldset--padding">
            <Row>
              <FormGroup className="col-md-12">
                <h4>Markings</h4>
                <FieldArray
                  name="markings"
                  label="Markings"
                  component={MarkingsFieldArray}
                  jobType={currentJob.type}
                />
              </FormGroup>
            </Row>
          </fieldset>
          <hr />
          <fieldset className="c-fieldset--padding c-fieldset--no-padding-bottom">
            <Row>
              <FormGroup className="col-md-12">
                <h4>Additional Information</h4>
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="col-md-6">
                <Field
                  name="dateOfIntendedTreatment"
                  label="Estimated Date of Treatment"
                  component={DatePickerField}
                  showTimeSelect
                  type="text"
                  placeholder="dd/mm/yyyy"
                  validate={[Validators.required]}
                  timeIntervals={5}
                />
              </FormGroup>
              <FormGroup className="col-md-6 align-self-center">
                <Field
                  name="isNOISelected"
                  label="Send a new NOI"
                  component={CheckboxField}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup className="col-md-12">
                <Field
                  name="description"
                  label="Job Description"
                  component={RenderField}
                  type="textarea"
                  placeholder="Enter a description"
                />
              </FormGroup>
            </Row>
          </fieldset>
          <fieldset className="c-fieldset--padding c-fieldset--no-padding-top c-fieldset--no-padding-bottom">
            <Button color="primary" className="mt-3" disabled={false}>
              Duplicate Job
            </Button>
          </fieldset>
        </Form>
      </div>
    );
  }
}

JobDuplicateForm.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...jobActions }, dispatch),
  };
}

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: jobDuplicateForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Record not saved',
      );
    },
  }),
)(JobDuplicateForm);
