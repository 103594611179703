import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import SearchPage from './SearchPage';
import { searchForm } from 'constants/FormConstants';
import { formValueSelector, getFormValues } from 'redux-form';

function mapStateToProps(state) {
  const {
    referenceData: {
      clientSummaries,
      treatmentTypes,
      destinations,
      jobStandards,
      areas,
    },
  } = state;
  const formValues = formValueSelector(searchForm);
  return {
    clientSummaries,
    treatmentTypes,
    destinations,
    jobStandards,
    areas,
    initialValues: {
      includeCompletedJobs: true,
    },
    treatmentDateFrom: formValues(state, 'treatmentDateFrom'),
    treatmentDateTo: formValues(state, 'treatmentDateTo'),
    formValues: getFormValues(searchForm)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        change,
        reset,
      },
      dispatch,
    ),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchPage,
);
