import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { roundToPrevious } from 'util/DateHelper';
import Logger from 'util/Logger';
import {
  updatePhosphineRecord,
  createPhosphineRecord
} from 'api/operationsApi';
import * as jobActions from 'actions/jobActions';
import Checkbubble from 'components/common/Checkbubble';
import ToggleButton from 'components/layout/ToggleButton';
import PhosphineRecordsApplicationModal from './PhosphineRecordsApplicationModal';
import PhosphineRecordsTopupModal from './PhosphineRecordsTopupModal';
import { getTechnicianDisplayName } from 'util/JobHelper';
import { Button } from 'reactstrap';
import { confirmAlert } from 'util/Alerts';
import * as operationsApi from 'api/operationsApi';

const propTypes = {
  actions: PropTypes.object,
  phosphineRecords: PropTypes.array.isRequired,
  technicians: PropTypes.array.isRequired
};

class PhosphineRecordsList extends Component {
  state = {
    showInitialApplicationModal: false,
    showTopupModal: false,
    selectedPhosphineRecord: null
  };

  toggleTopupModal = phosphineRecord => {
    const { showTopupModal } = this.state;
    this.setState({
      showTopupModal: !showTopupModal,
      selectedPhosphineRecord: phosphineRecord
    });
  };

  toggleInitialApplicationModal = phosphineRecord => {
    const { showInitialApplicationModal } = this.state;
    this.setState({
      showInitialApplicationModal: !showInitialApplicationModal,
      selectedPhosphineRecord: phosphineRecord
    });
  };

  closeModals = () =>
    this.setState({
      showInitialApplicationModal: false,
      showTopupModal: false
    });

  handleSubmission = formValues => {
    const saveAction = formValues.id
      ? updatePhosphineRecord
      : createPhosphineRecord;

    saveAction(formValues)
      .then(response => Logger.success('Treatment Record Saved'))
      .then(() => this.getJob(formValues));
  };

  deleteTreatmentResultRecord = record => {
    const { currentJob } = this.props;
    confirmAlert('Delete', 'Are you sure you want to delete this record?').then(
      confirm =>
        confirm &&
        operationsApi
          .deletePhosphineRecord(record.id)
          .then(() => this.getJob({ jobId: currentJob.id }))
    );
  };

  getJob = formValues => {
    const { actions } = this.props;
    actions.getJob(formValues.jobId).then(response => {
      this.setState({
        showTopupModal: false,
        showInitialApplicationModal: false,
        selectedPhosphineRecord: null
      });
    });
  };

  render() {
    const {
      phosphineRecords,
      jobTypeImageUrl,
      technicians,
      currentJob
    } = this.props;
    const {
      showInitialApplicationModal,
      showTopupModal,
      selectedPhosphineRecord
    } = this.state;

    if (!phosphineRecords) return <div>No Records</div>;

    return (
      <div>
        <BootstrapTable
          headerStyle={{ display: 'none' }}
          trClassName="app-table-row"
          version="4"
          data={phosphineRecords}
          bordered={false}
          remote
          options={{
            onRowClick: this.toggleInitialApplicationModal
          }}
        >
          <TableHeaderColumn
            isKey
            className="col-hidden"
            columnClassName="keyColumn"
            dataField="id"
            hidden
          />
          <TableHeaderColumn
            width="55"
            className="col-hidden"
            dataFormat={(cell, row) => (
              <img
                style={{ maxHeight: '30px' }}
                src={jobTypeImageUrl}
                alt="Job Type Icon"
              />
            )}
          />
          <TableHeaderColumn className="col-hidden" dataField="holdNumber" />
          <TableHeaderColumn
            className="col-hidden"
            dataFormat={(cell, row) => (
              <div>{moment(row.startDate).format('DD/MM/YYYY HH:mm')}</div>
            )}
          />
          <TableHeaderColumn className="col-hidden" dataField="rowNumber" />
          <TableHeaderColumn
            className="col-hidden"
            dataFormat={(cell, row) => (
              <div>
                {getTechnicianDisplayName(row.initialTechnicianId, technicians)}
              </div>
            )}
          />
          <TableHeaderColumn
            className="col-hidden"
            dataFormat={(cell, row) => (
              <div>
                {row.topUpTechnicianId
                  ? moment(row.endDate).format('DD/MM/YYYY HH:mm')
                  : null}
              </div>
            )}
          />
          <TableHeaderColumn
            className="col-hidden"
            dataFormat={(cell, row) => (
              <div>
                {getTechnicianDisplayName(row.initialTechnicianId, technicians)}
              </div>
            )}
          />
          <TableHeaderColumn
            className="col-hidden"
            width="60"
            dataFormat={(cell, row) => (
              <Checkbubble
                className="align-middle"
                checked={row.complete}
                onClick={() => this.toggleTopupModal(row)}
              />
            )}
          />
          {currentJob.canEditJob ? (
            <TableHeaderColumn
              className="col-hidden"
              width="58"
              dataFormat={(cell, row) => (
                <Button
                  outline={true}
                  color={'danger'}
                  style={{
                    width: '32px',
                    height: '32px',
                    position: 'relative'
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    this.deleteTreatmentResultRecord(row);
                  }}
                >
                  <i
                    aria-hidden="true"
                    className="material-icons"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      left: '5px'
                    }}
                  >
                    delete_outline
                  </i>
                </Button>
              )}
            />
          ) : null}
        </BootstrapTable>

        {currentJob.canEditJob ? (
          <div className="text-right mt-3">
            <ToggleButton
              color="light"
              onClick={() =>
                this.toggleInitialApplicationModal({
                  jobId: currentJob.id,
                  initialTechnicianId: currentJob.technicianId,
                  startDate: roundToPrevious(moment().startOf('minute'), 5)
                })
              }
              ligature="add"
            />
          </div>
        ) : (
          <br />
        )}

        <PhosphineRecordsApplicationModal
          title="Initial Application"
          isOpen={showInitialApplicationModal}
          toggle={this.closeModals}
          onSubmit={this.handleSubmission}
          currentPhosphineRecord={selectedPhosphineRecord}
        />

        <PhosphineRecordsTopupModal
          title="Top up Application"
          isOpen={showTopupModal}
          toggle={this.closeModals}
          onSubmit={this.handleSubmission}
          currentPhosphineRecord={selectedPhosphineRecord}
        />
      </div>
    );
  }
}

PhosphineRecordsList.propTypes = propTypes;

function mapStateToProps(state) {
  const { referenceData, currentJob } = state;
  const phosphineRecords = currentJob.phosphineTreatmentResultRecords || [];

  return {
    technicians: referenceData.technicians,
    phosphineRecords: phosphineRecords,
    jobTypeImageUrl: currentJob.type.imageUrlFull,
    currentJob: currentJob
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobActions
      },
      dispatch
    )
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PhosphineRecordsList
);
