import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  format: PropTypes.func
};

const PlainTextField = ({ name, label, value, className, format }) => (
  <div className="media">
    <label htmlFor={name} className="col-form-label">
      {label}
    </label>
    <div className="media-body">
      <Input id={name} plaintext>
        {value ? (format ? format(value) : value) : ' - '}
      </Input>
    </div>
  </div>
);

PlainTextField.propTypes = propTypes;

export default PlainTextField;
