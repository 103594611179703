import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, change, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';

import Logger from '../../util/Logger';
import * as clientDetailsActions from '../../actions/clientDetailsActions';
import { jobDetailsForm } from '../../constants/FormConstants';

import JobDetailsForm from '../jobdetails/JobDetailsForm';

function mapStateToProps(state) {
  const formValues = formValueSelector(jobDetailsForm);
  return {
    ...state.referenceData,
    currentJob: state.currentJob,
    clientDetails: state.clientDetails,
    markings: [],
    areaId: formValues(state, 'areaId'),
    treatmentType: formValues(state, 'treatmentDetails.treatmentTypeId'),
    technicianId: formValues(state, 'technicianId'),
    selectedCommodity: formValues(state, 'treatmentTypeDetails.commodity'),
    selectedStandard: formValues(state, 'treatmentDetails.standardId'),
    containerType: formValues(state, 'treatmentDetails.containerTypeId'),
    location:formValues(state,'location')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...clientDetailsActions, change }, dispatch),
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: jobDetailsForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Job not saved',
      );
    },
  }),
)(JobDetailsForm);
