import { createSelector } from 'reselect';
import { isWaterBlasting } from '../util/TreatmentTypeHelper';

const getTechnicians = (state) => state.referenceData.technicians;
const getCurrentJobAreaId = (state) => state.currentJob.areaId;

export const getTechniciansByAreaId = createSelector(
  getTechnicians,
  getCurrentJobAreaId,
  (technicians, areaId) => {
    return technicians.filter((technician) =>
      matchesAreaId(technician, areaId),
    );
  },
);

const matchesAreaId = (technician, areaId) => {
  const matchesAreaId = technician.areas.find((area) => area.areaId === areaId);
  return matchesAreaId;
};

export function getTechByArea(technicians,areaId){
  return technicians = technicians.filter((technician) =>
  matchesAreaId(technician, areaId)
  
);

}

export function licenseTech(technicians) {
  const licTechs = technicians.filter((tech) => tech.licenceNumber !== '');
  return licTechs;
}

export function getCompetentTechnicians(
  technicians,
  treatmentTypeId,
  commodity,
  selectedStandardId,
  jobTypeId,
  areaId,
) {
  technicians = technicians.filter((technician) =>
    matchesAreaId(technician, areaId),
  );
  if (isWaterBlasting(treatmentTypeId))
    technicians = technicians.filter((tech) => tech.name === 'Water Blaster');

  //standard ISPM15 fbc93987-6d8a-4fab-8480-e4b3b6fba547
  if (selectedStandardId === 'fbc93987-6d8a-4fab-8480-e4b3b6fba547') {
    technicians = technicians.filter((tech) => tech.ispM15 === true);
    // technicians !==null ? technicians :null
    return technicians;
  }
  //MB Fresh Fruit
  if (
    commodity !== null &&
    commodity !== undefined &&
    treatmentTypeId === '38764bb4-55b3-4be9-9433-a347a27ba39f' &&
    (commodity.includes('Fresh Fruit') ||
      commodity.includes('Fresh Produce') ||
      commodity.includes('Vege'))
  ) {
    return (technicians = technicians.filter(
      (tech) => tech.mbFreshProduce === true,
    ));
  }

  //PHOS In Hold = job type Logs
  if (
    (treatmentTypeId === 'fa3a2d70-b743-4f98-867b-9e152a364233' ||
      treatmentTypeId === 'a2a32f99-b3b6-455c-8598-a5864d240a56') &&
    jobTypeId === '1ce26e7e-6aca-43fd-8e30-444518318212'
  ) {
    return (technicians = technicians.filter(
      (tech) => tech.phosLogsInHold === true,
    ));
  }

  //MB Logs In Hold  = jobType is Other
  if (
    treatmentTypeId === '38764bb4-55b3-4be9-9433-a347a27ba39f' &&
    (jobTypeId === '6e8140cd-7571-4df7-a289-1ad4ff30a3ec' ||
      jobTypeId === '6e8140cd-7571-4df7-a289-1ad4ff30c395')
  ) {
    technicians = technicians.filter((tech) => tech.mbLogsInHold === true);
    return technicians;
  }
  //MB BreakBulk = MB JobType is Break Bulk import/Export
  if (
    treatmentTypeId === '38764bb4-55b3-4be9-9433-a347a27ba39f' &&
    (jobTypeId === 'fc2640a1-3d94-4dad-b052-117cb85d4b2d' ||
      jobTypeId === 'acd8f4c1-d491-41ea-ada0-54c3fd4aee58')
  ) {
    return (technicians = technicians.filter(
      (tech) => tech.mbBreakBulk === true,
    ));
  }

  //MB Container = MB JobType is Break Bulk import/Export
  if (
    treatmentTypeId === '38764bb4-55b3-4be9-9433-a347a27ba39f' &&
    (jobTypeId === '981e5107-7d75-44cd-8b98-291b8b2d540f' ||
      jobTypeId === 'c8c5e744-6c00-4356-a3f1-fec7f186d034')
  ) {
    return (technicians = technicians.filter(
      (tech) => tech.mbContainer === true,
    ));
  }

  // MB Log Rows JobType is Logs
  if (
    treatmentTypeId === '38764bb4-55b3-4be9-9433-a347a27ba39f' &&
    jobTypeId === '1ce26e7e-6aca-43fd-8e30-444518318212'
  ) {
    return (technicians = technicians.filter((tech) => tech.mbLogRow === true));
  }
  //MB
  if (treatmentTypeId === '38764bb4-55b3-4be9-9433-a347a27ba39f') {
    return (technicians = technicians.filter(
      (tech) => tech.methylBromide === true,
    ));
  }

  //PHOS OTHER
  if (
    (treatmentTypeId === 'a2a32f99-b3b6-455c-8598-a5864d240a56' ||
      treatmentTypeId === 'fa3a2d70-b743-4f98-867b-9e152a364233') &&
    (jobTypeId === '981e5107-7d75-44cd-8b98-291b8b2d540f' ||
      jobTypeId === 'c8c5e744-6c00-4356-a3f1-fec7f186d034' ||
      jobTypeId === 'fc2640a1-3d94-4dad-b052-117cb85d4b2d' ||
      jobTypeId === 'acd8f4c1-d491-41ea-ada0-54c3fd4aee58' ||
      jobTypeId === '1213f201-38c2-404e-9d9c-f16a9b51679e' ||
      jobTypeId === '6e8140cd-7571-4df7-a289-1ad4ff30a3ec' ||
      jobTypeId === '90787a34-53e4-4faa-b50b-11e0d951e2e7')
  ) {
    return (technicians = technicians.filter(
      (tech) => tech.phosNotInHold === true,
    ));
  }

  //HEAT
  if (treatmentTypeId === '6b2571ec-62a5-4ee2-a6d5-62829862e7dd') {
    return (technicians = technicians.filter((tech) => tech.heat === true));
  }
  //HydrogenCyanide
  if (treatmentTypeId === 'fd587c29-5a6b-4932-a7d8-ca1bc14af155') {
    return (technicians = technicians.filter(
      (tech) => tech.hydrogenCyanide === true,
    ));
  }
  //pestigas
  if (treatmentTypeId === '0f3395bf-1052-4d4d-a803-f975be39b5ce') {
    return (technicians = technicians.filter((tech) => tech.pestigas === true));
  }
  //thermofog
  if (treatmentTypeId === '655117ab-5a89-4a77-8a82-68416c6a72b0') {
    return (technicians = technicians.filter(
      (tech) => tech.thermofog === true,
    ));
  }
  //phosphine
  if (
    treatmentTypeId === 'fa3a2d70-b743-4f98-867b-9e152a364233' ||
    treatmentTypeId === 'a2a32f99-b3b6-455c-8598-a5864d240a56'
  ) {
    return (technicians = technicians.filter(
      (tech) => tech.phosphine === true,
    ));
  }
  //callington1shot
  if (treatmentTypeId === '2e5ab48c-48c9-49e7-9ba6-3b7a438b550a') {
    return (technicians = technicians.filter(
      (tech) => tech.callington1Shot === true,
    ));
  }
  //Gas Free
  if (treatmentTypeId === 'a7bcdbe9-243d-4621-bbe8-5768a4a84e1d') {
    return (technicians = technicians.filter((tech) => tech.gasFree === true));
  }
  //waterblasting
  if (treatmentTypeId === '2e5aa48c-48c9-49e7-9ba6-3b7a438b549b') {
    return (technicians = technicians.filter(
      (tech) => tech.waterBlasting === true,
    ));
  }
  //vapormate
  if (treatmentTypeId === 'd2d32f97-d3b6-455a-8597-c5864d140f45') {
    return (technicians = technicians.filter(
      (tech) => tech.vapormate === true,
    ));
  }

  //ALPVessel
  if (treatmentTypeId === 'b3c32f99-a3b8-465d-5568-c5864d240b66') {
    return (technicians = technicians.filter(
      (tech) => tech.alpVessel === true,
    ));
  }
  //ALPNonVessel
  if (treatmentTypeId === 'b5532f99-23b8-185d-7521-c5864d240159') {
    return (technicians = technicians.filter(
      (tech) => tech.alpNonVessel === true,
    ));
  }
  //vaporPhos
  if (treatmentTypeId === 'a2a32f99-b3b6-455c-8598-a5864d240a56') {
    return (technicians = technicians.filter(
      (tech) => tech.vaporPhos === true,
    ));
  }
     //ethylformate
     if (treatmentTypeId === '95faca5e-cc67-4824-8cf2-6938dec46157') {
      return (technicians = technicians.filter(
        (tech) => tech.ethylFormate === true,
      ));
    }
    //macslay
  if (treatmentTypeId === '0b189349-8457-480f-85b9-75cb42cf0d94') {
    return (technicians = technicians.filter(
      (tech) => tech.macSlay === true,
    ));
  }
  return technicians;
}
