import TreatmentTypeIds from 'constants/TreatmentTypeIds';
export const isHydrogenCyanide = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.HydrogenCyanide;

export const isPhosphine = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.Phosphine;

export const isMethylBromide = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.MethylBromide;

export const isThermoFog = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.Thermofog;

export const isWaterBlasting = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.WaterBlasting;

export const isGasFree = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.GasFree;

export const isPestigas = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.Pestigas;

export const isCallington1Shot = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.Callington1Shot;

export const isVapormate = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.Vapormate;

export const isVaporPhos = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.VaporPhos;

export const isAlPVessel = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.AlPhosVessel;

export const isAlPNonVessel = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.AlphosNonVessel;

  export const isEthylFormate = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.EthylFormate;

  export const isMacSlay = (treatmentTypeId) =>
  treatmentTypeId === TreatmentTypeIds.MacSlay;
