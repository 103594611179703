import { connect } from 'react-redux';
import { getReferenceDataRecord } from 'selectors/referenceDataSelectors';
import JobSignoffCompleted from './JobSignoffCompleted';

function mapStateToProps(state) {
  const { supervisorSignoff } = state.currentJob;
  return {
    ...supervisorSignoff,
    signoffTechnician:
      supervisorSignoff &&
      getReferenceDataRecord(
        state,
        'technicians',
        'id',
        supervisorSignoff.signoffTechnicianId,
        'jobSignoffCompleted.signoffTechnician'
      )
  };
}

export default connect(mapStateToProps)(JobSignoffCompleted);
