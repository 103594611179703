export default {
  columnDefinitions: [
    {
      key: 'jobReference',
      name: 'Job #',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'eralisJobNumber',
      name: 'Eralis #',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'treatmentType',
      name: 'TRT Type',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'jobType',
      name: 'Job Type',
      formatter: 'IconAndText',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'area',
      name: 'Area',
      formatter: 'Text',
      className: 'search-col-area',
      isHiddenViewportMediumAndBelow: true,
    },
    {
      key: 'clientName',
      name: 'Client',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'vessel',
      name: 'Vessel',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'parentJobNumber',
      name: 'Parent Job #',
      formatter: 'Text',
      className: 'search-col-parentjob',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'startDate',
      name: 'Start Date',
      formatter: 'Date',
      className: 'search-col-startdate',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'markings',
      name: 'Markings',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'bacc',
      name: 'BACC #',
      formatter: 'Text',
      className: 'search-col-bacc',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'orderNumber',
      name: 'Order #',
      formatter: 'Text',
      className: 'search-col-ordernumber',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'invoiceNumber',
      name: 'Invoice #',
      formatter: 'Text',
      className: 'search-col-invoice',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'extraHours',
      name: 'Extra Hours',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'currentState',
      name: 'Job State',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
    {
      key: 'bookingReference',
      name: 'Booking #',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false,
    },
  ],
};
