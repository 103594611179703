import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contactActions from 'actions/contactActions';
import * as formActions from 'actions/formActions';
import ContactForm from 'components/contacts/ContactForm';
import Logger from 'util/Logger';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';

const propTypes = {
  actions: PropTypes.object
};

class ContactFormPage extends Component {
  state = {
    loaded: false,
    isSubmitting: false
  };

  componentDidMount() {
    const { contactId, mode } = this.props;
    if (mode === 'edit') {
      this.getContact(contactId);
    }
  }

  createOrUpdateSuccess = message => {
    let { actions, history } = this.props;
    this.toggleFormSubmit();
    actions.getContacts();
    Logger.success(message);
    history.push('/contacts');
  };

  getContact = contactId => {
    operationsApi.getContact(contactId).then(result => {
      this.setState({
        loaded: true,
        contact: result.data
      });
    });
  };

  createContact = formValues => {
    this.toggleFormSubmit();
    operationsApi.createNewContact(formValues).then(() => {
      this.createOrUpdateSuccess('New Contact Created');
    });
  };

  updateContact = formValues => {
    this.toggleFormSubmit();
    operationsApi.updateContact(formValues).then(() => {
      this.createOrUpdateSuccess('Contact Updated');
    });
  };

  toggleFormSubmit = () => {
    this.setState(prevState => ({
      isSubmitting: !prevState.isSubmitting
    }));
  };

  cancel = () => {
    this.props.history.push('/contacts');
  };

  render() {
    const { mode } = this.props;
    const { loaded, contact } = this.state;

    if (mode === 'edit' && !loaded) {
      return null;
    }

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={this.cancel}
              ligature="clear"
            />
          </HeaderButtonNav>
          <HeaderTitle
            label={
              mode === 'edit'
                ? `Modify existing contact`
                : `Create a New Contact`
            }
          />
        </Header>
        <ContactForm
          onSubmit={mode === 'edit' ? this.updateContact : this.createContact}
          isSubmitting={this.state.isSubmitting}
          initialValues={mode === 'edit' ? contact : {}}
        />
      </div>
    );
  }
}

ContactFormPage.propTypes = propTypes;

function mapStateToProps(state, ownProps) {
  return {
    mode: ownProps.match.params.action,
    contactId: ownProps.match.params.id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contactActions,
        ...formActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormPage);
