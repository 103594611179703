import { connect } from 'react-redux';
import ClientDetailsSection from './ClientDetailsSection';

function mapStateToProps(state) {
  return {
    clientDetails: state.clientDetails
  };
}

export default connect(mapStateToProps)(ClientDetailsSection);
