import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { FormGroup, Button, Alert,Col, Row, } from 'reactstrap';
import _ from 'lodash';

import SearchableSelect from '../../../fields/SearchableSelect';
import DatePickerField from '../../../fields/DatePickerField';

import ConcentrationReadingsFieldArray from './ConcentrationReadingsFieldArray';
import EFConcentrationReadingArray from './EFConcentrationReadingArray';
import ConcentrationReadingChamberFieldArray from './ConcentrationReadingChamberFieldArray';
import { isBreakBulkJobType, isExportOtherMBApples } from '../../../../util/JobTypeHelper';

import styles from './styles.module.scss';
import { isEthylFormate } from '../../../../util/TreatmentTypeHelper';
import RenderField from '../../../fields/RenderField';
import PlainTextField from '../../../fields/PlainTextField';

const propTypes = {
  canEditJob: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  checkRikenDataMatch: PropTypes.bool, // can be undefined, too
};

class TreatmentResultRecordsMonitoringSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  onEditing(isEditing) {
    this.setState({ isEditing });
  }
  getTotals15=(currentConcentrationReading)=>{
    const total = currentConcentrationReading?currentConcentrationReading[0]?    
    this.getCTValues(currentConcentrationReading, 0,7.50)
    :null:null
    return total
  }

  getTotals30=(currentConcentrationReading)=>{
    const total = currentConcentrationReading?currentConcentrationReading[1]?
    this.getCTValues(currentConcentrationReading, 1,22.5)
    :null:null
    return total
  }
  getTotals60=(currentConcentrationReading)=>{
    const total = currentConcentrationReading?currentConcentrationReading[2]?
          this.getCTValues(currentConcentrationReading, 2,45.0)
    :null:null
    return total
  }
  getTotals120=(currentConcentrationReading)=>{
    const total = currentConcentrationReading?currentConcentrationReading[3]?
    this.getCTValues(currentConcentrationReading, 3,30.0)
    :null:null
    return total
  }

  getCTValues =(currentConcentrationReading, index,ctMult) =>{
   const totReading =  (currentConcentrationReading[index].reading1) + 
   (currentConcentrationReading[index].reading2)+
   (currentConcentrationReading[index].reading3)
    const avg = (totReading/3)
    const avg2DP = avg*100
    const floor = Math.floor(avg2DP)/100
    const ct =  this.multiplyWithoutRounding(floor,ctMult)
    return   ct

  }

   multiplyWithoutRounding(num1, num2) {
   

    return (num1 * num2).toFixed(2);
}

  render() {
    const { canEditJob, jobId, checkRikenDataMatch, job,actions,currentConcentrationReadingItem } = this.props;
    const treatmentType = job.treatmentDetails.treatmentTypeId;
    const jobType = job.type;
    const commodity = job.treatmentTypeDetails.commodity;
    const standardId = job.treatmentDetails.standardId;
    const treatmentTypeId = job.treatmentDetails.treatmentTypeId
    const isAppleMB = isExportOtherMBApples(jobId,treatmentTypeId,commodity,standardId)
    const checkRikenDataDownloadMatchesOptions = [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ];
      const tot15= this.getTotals15(currentConcentrationReadingItem)?this.getTotals15(currentConcentrationReadingItem):0
      const tot30= this.getTotals30(currentConcentrationReadingItem)?this.getTotals30(currentConcentrationReadingItem):0
      const tot60= this.getTotals60(currentConcentrationReadingItem)? this.getTotals60(currentConcentrationReadingItem):0
      const tot120= this.getTotals120(currentConcentrationReadingItem)?this.getTotals120(currentConcentrationReadingItem):0
      const total = parseInt((parseFloat(tot15)+parseFloat(tot30)+parseFloat(tot60)+parseFloat(tot120))*100)/100
      const ct = parseInt((total/60)*100)/100
    return (  
      <fieldset disabled={!canEditJob && 'disabled'}>
        {isBreakBulkJobType(job.type) ? (
          <div>
            <p className={styles.note}>
              **Start date and time is when all readings are above the standard
              for the FIRST sealed stack.
            </p>{' '}
            <p className={styles.notered}>
              ***End date and time is when LAST concentration is measured above
              the standard at the end of treatment.
            </p>{' '}
          </div>
        ) : isEthylFormate(treatmentType)?(
          <p className={styles.note}>
          **Device Readings  require row for date time. Please add a datetime row to match the reading phase when entering readings.
        </p>):(
          <p className={styles.note}>
            **Start date and time is when all initial readings are above the
            standard. ***End date and time is when all final readings are above
            the standard.
          </p>
        )}

        <FormGroup row className={styles.checkRikenDataMatch}>
          <Field
            name="gasInDateTime"
            label="Gas In Date/Time"
            component={DatePickerField}
            defaultToEmpty
            showTimeSelect
            type="text"
            placeholder="Enter Gas In Date/Time"
            timeIntervals={1}
          />
        </FormGroup>
        {isEthylFormate(treatmentType)?(<FormGroup className="col-lg-3">
          <Field
            name="topupValue"
            label="Top Up Value"
            component={RenderField}
            defaultToEmpty
            type="text"
            placeholder="Enter Top Up Value if applicable"
          />
        </FormGroup>):null}
        {isEthylFormate(treatmentType)?(
        <div><p>
          ***Percentage to Grams Calculation % ÷ 0.0325 = g/m3 (e.g 0.65% ÷ 0.0325 = 20g/m3)****
          </p>
        </div>) :null}
  

        {isEthylFormate(treatmentType)?(    <FieldArray
          name="efConcentrationReadingItem"
          component={EFConcentrationReadingArray}
          readOnly={!canEditJob}
          jobId={jobId}
          job={job}
          onEditing={this.onEditing.bind(this)}
        />):
        isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)?
        <FieldArray
          name="concentrationReadingChamberItem"
          component={ConcentrationReadingChamberFieldArray}
          readOnly={!canEditJob}
          jobId={jobId}
          job={job}
          onEditing={this.onEditing.bind(this)}
          actions = {actions}
        />:
        <FieldArray
          name="concentrationReadingItem"
          component={ConcentrationReadingsFieldArray}
          readOnly={!canEditJob}
          jobId={jobId}
          job={job}
          onEditing={this.onEditing.bind(this)}
        />}
       { isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)?
      <Row>
                  <Col xs="2">

            <PlainTextField
              label ={"CT 15"}
              value={tot15}
            />
        </Col>
        <Col xs="2">
        <td>
            <PlainTextField
              label ={"CT 30"}
              value={tot30}
            />
        </td>
        </Col>
        <Col xs="2">
        <td>
            <PlainTextField
              label ={"CT 60"}
              value={tot60}
            />
        </td>
        </Col>
        <Col xs="2">
        <td>
            <PlainTextField
              label ={"CT 120"}
              value={tot120}
            />
        </td>
        </Col>
        <Col xs="2">
        <td>
            <PlainTextField
              label ={"Total CT"}
              value={total}
            />
        </td>
        </Col>
        <Col xs="2">
        <td>
            <PlainTextField
              label ={"Final CT"}
              name = {"ct"}
              value={ct}
            />
        </td>
        </Col>
     </Row>
  :null}

        <FormGroup row className={styles.checkRikenDataMatch}>
          <Field
            name="checkRikenDataMatch"
            label={isEthylFormate(treatmentType)?"CHECK monitoring device data downloaded matches this form":"Check Riken data downloaded matches the values above"}
            component={SearchableSelect}
            options={checkRikenDataDownloadMatchesOptions}
            valueKey="value"
            labelKey="label"
          />

        </FormGroup>
        {checkRikenDataMatch === false && (
        <Alert color="warning">{isEthylFormate(treatmentType)?"Check Device Data and Job entry data":"Check Riken Data and Job entry data"}</Alert>
        )}
        <Button
          type="submit"
          className="mt-3"
          color="primary"
          disabled={this.state.isEditing}
        >
          Save
        </Button>
      </fieldset>
    );
  }
}

TreatmentResultRecordsMonitoringSection.propTypes = propTypes;

export default TreatmentResultRecordsMonitoringSection;
