import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import PlainTextField from 'components/fields/PlainTextField';
import PlainTextDateField from 'components/fields/PlainTextDateField';
import { Container, Row, Col } from 'reactstrap';
import { Band } from 'components/layout';

const propTypes = {
  showSummary: PropTypes.bool.isRequired,
  phosphineTreatmentResultsSummary: PropTypes.object.isRequired
};

class PhosphineRecordsSummary extends Component {
  render() {
    const {
      showSummary,
      phosphineTreatmentResultsSummary,
      phosphineRecords
    } = this.props;

    if (!showSummary) {
      return null;
    } else {
      return (
        <Band dark>
          <Container>
            <Row>
              <Col xs="6" lg="4">
                <PlainTextDateField
                  name="startDate"
                  label="Start Date"
                  value={phosphineTreatmentResultsSummary.startDate}
                  showTime
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="totalVolume"
                  label="Total Volume"
                  value={phosphineTreatmentResultsSummary.totalVolume}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextDateField
                  name="endDate"
                  label="End Date"
                  value={phosphineTreatmentResultsSummary.endDate}
                  showTime
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="initialApplication"
                  label="Initial Application"
                  value={phosphineTreatmentResultsSummary.initialApplication.toFixed(
                    3
                  )}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="topUpApplication"
                  label="Top Up Application"
                  value={phosphineTreatmentResultsSummary.topUpApplication.toFixed(
                    3
                  )}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="totalApplied"
                  label="Total Applied"
                  value={phosphineTreatmentResultsSummary.totalApplied}
                />
              </Col>
              <Col xs="6" lg="4">
                <PlainTextField
                  name="rows"
                  label={'Total Number of Records'}
                  value={phosphineRecords ? phosphineRecords.length : 0}
                />
              </Col>
            </Row>
          </Container>
        </Band>
      );
    }
  }
}

PhosphineRecordsSummary.propTypes = propTypes;

function mapStateToProps(state) {
  const { currentJob } = state;

  return {
    showSummary: currentJob.phosphineTreatmentResultRecords.length !== 0,
    phosphineTreatmentResultsSummary:
      currentJob.phosphineTreatmentResultsSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PhosphineRecordsSummary
);
