import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormFeedback } from 'reactstrap';
import classNames from 'classnames';

const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  manualError: PropTypes.string,
};

const RenderField = ({
  input,
  label,
  placeholder,
  type,
  className,
  readOnly,
  autoComplete,
  onKeyPress,
  autoFocus = false,
  meta: { touched, error, warning },
  manualError,
}) => (
  <div>
    <Label for={input.name}>{label}</Label>
    <Input
      className={classNames('form-control', {
        'is-invalid': (touched && error) || manualError,
      })}
      {...input}
      placeholder={placeholder}
      type={type}
      readOnly={readOnly}
      autoComplete={autoComplete || null}
      valid={touched && error ? false : undefined}
      onKeyPress={onKeyPress}
      autoFocus={autoFocus && !input.value.length ? true : false}
      onBlur={() => {}}
    />
    <FormFeedback>{error || manualError}</FormFeedback>
  </div>
);

RenderField.propTypes = propTypes;

export default RenderField;
