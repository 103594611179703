import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { FormGroup, Row, Button } from 'reactstrap';

import RenderField from '../../../fields/RenderField';
import CustomTagSelect from '../../../fields/CustomTagSelect';
import SearchableSelect from '../../../fields/SearchableSelect';
import DatePickerField from '../../../fields/DatePickerField';
import CheckboxField from '../../../fields/CheckboxField';

import CalculatedTreatmentResultRecordsFields from '../CalculatedTreatmentResultRecordsFields';
import DimensionTreatmentResultRecordsFields from '../DimensionTreatmentResultRecordsFields';

import * as Validators from '../../../../util/Validators';
import {
  isExportAndLogs,
  isBreakBulkJobType,
  isOtherAndThermofog,
  isPestigasContainer,
  isMBLogsInHold,
  isImportOrExportAndContainer,
  isExportOtherMBApples,
} from '../../../../util/JobTypeHelper';
import {isCallington1Shot,isEthylFormate,isMethylBromide } from '../../../../util/TreatmentTypeHelper';

import { getCompetentTechnicians } from '../../../../selectors/technicianSelectors';
import { minZero } from '../../../../util/NormalizingHelper';
import MBMarkingsFieldArray from '../../../fields/MBMarkingsFieldArray';

const propTypes = {
  technicians: PropTypes.array.isRequired,
  canEditJob: PropTypes.bool,
  jobType: PropTypes.object.isRequired,
  treatmentType: PropTypes.string,
  actions: PropTypes.shape({
    changeFieldValue: PropTypes.func.isRequired,
  }),
  calculatedMbkg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentJob: PropTypes.object.isRequired,
};

const isBetweenOneAndNinetyNine = (value) => {
  const limit = 99;
  if (value < 0) {
    return 0;
  } else if (value > limit) {
    return limit;
  } else {
    return value;
  }
};

class TreatmentResultRecordsStartDetailsSection extends Component {
  state = {
    covered : false
  };


  isCovered(e){
      if(e.target.checked==false){
        this.setState({covered:false})
      }
      if(e.target.checked){
        this.setState({covered:true})
      }


  }

  
  render() {
    const { technicians, canEditJob, jobType, treatmentType, currentJob,treatmentTypeDetails,covered,commodity,standardId } = this.props;
      var markingOptions = treatmentTypeDetails.markings;
      const doubled = markingOptions.map((marking,index) =>  marking.containerNumber +" "+marking.sealNumber);
      var markings2=[] ;
      for(var i =0;i<doubled.length;i++){
        var containerNumber ={containerNumber:''}
         containerNumber.containerNumber = doubled[i];
        markings2.push(containerNumber)

      }
    const showSachetFields = false;
    const isCovered = treatmentTypeDetails.covered?treatmentTypeDetails.covered:null;
    

    const competentTechs = getCompetentTechnicians(
      technicians,
      treatmentType,
      currentJob.treatmentTypeDetails.commodity,
      currentJob.treatmentDetails.standardId,
      currentJob.typeId,
      currentJob.areaId,
    );

    const treatmentTypeId = currentJob.treatmentDetails.treatmentTypeId;
    const chamberIdList =[{id:0,name:'chamber 1'},{id:1,name:'chamber 2'},{id:2,name:'chamber 3'},{id:3,name:'chamber 4'},{id:4,name:'chamber 5'}]
    const numberOfCovers = [{id:0,displayName:1},{id:1,displayName:2},{id:2,displayName:3},{id:3,displayName:4}
      ,{id:4,displayName:5},{id:5,displayName:6},{id:6,displayName:7},{id:7,displayName:8},{id:8,displayName:9},{id:9,displayName:10}]
    const NumberOfCansList = [{id:0,name:0},{id:1,name:1},{id:2,name:2},{id:3,name:3},{id:4,name:4},{id:5,name:5},{id:6,name:6}]


    return (
      <fieldset disabled={!canEditJob && 'disabled'}>
        <Row>
        {!isImportOrExportAndContainer(jobType)||isEthylFormate(treatmentTypeId)?(
          <FormGroup className="col-lg-4">
            <Field
              name="coveringTechnicianId"
              label={isEthylFormate(treatmentTypeId)?"Fumigating Technician":"Technician *"}
              component={SearchableSelect}
              placeholder="Please select a technician"
              options={competentTechs}
              valueKey="id"
              labelKey="name"
            />
          </FormGroup>
          ):null}
          {isCallington1Shot(treatmentTypeId)?
          <FormGroup className="col-lg-3">
            <Field
            name="covered"
            label="Covered Job"
            component={CheckboxField}
            onChange={e=>this.isCovered(e)}
          /> 
          </FormGroup>:null
        }

          {isCallington1Shot(treatmentTypeId) && covered ===false ||isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId) ?null:
          ((isImportOrExportAndContainer(jobType)&&covered==true||!isImportOrExportAndContainer(jobType))?(
          <FormGroup className="col-lg-5">
            <CalculatedTreatmentResultRecordsFields
              isBreakBulk
              jobType={jobType}
              treatmentType={treatmentType}
              isCoveredContainer={isCovered}
            />
          </FormGroup>):null)}

          {isExportAndLogs(jobType) || jobType.name === 'MB Logs In Hold' ? (
            <FormGroup className="col-lg-4">
              <Field
                name="yardNumber"
                label={jobType.name === 'MB Logs In Hold' ? 'Hold #' : 'Yard #'}
                component={RenderField}
                type="text"
                placeholder={
                  jobType.name === 'MB Logs In Hold'
                    ? 'Enter Hold Number'
                    : 'Enter a Yard #'
                }
              />
            </FormGroup>
          ) : null}
         {(isImportOrExportAndContainer(jobType)|| isExportAndLogs(jobType) || isBreakBulkJobType(jobType))&& isMethylBromide(treatmentType)||isEthylFormate(treatmentType)
         || isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)?(
          <FormGroup>
          <Field
            name="mbkg"
            label={isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)?'Total Usage  MB kg':isEthylFormate(treatmentType)?"EF/CO2 Kg":"MB kg"}
            component={RenderField}
            readOnly={false}
            validate={Validators.requiredTrue}
          />
          </FormGroup>
         ):null}
         
         {isBreakBulkJobType(jobType) ? (
            <FormGroup className="col-lg-4">
              <Field
                name="stackNumber"
                label="Stack #"
                component={RenderField}
                type="text"
                placeholder="Enter a Stack #"
              />
            </FormGroup>
          ) : null }

          {!(
            isMethylBromide(treatmentType)||isEthylFormate(treatmentType) &&
            (isBreakBulkJobType(jobType) ||
              isExportAndLogs(jobType) ||
              isMBLogsInHold(jobType)|| isImportOrExportAndContainer(jobType))
          ) && (
            <FormGroup className="col-lg-4">
              <Field
                name="startDate"
                label="Start Date *"
                component={DatePickerField}
                showTimeSelect
                defaultToEmpty
                type="text"
                placeholder="Enter Start Date Time"
               // validate={isEthylFormate(treatmentType)?false:[Validators.isValidDateAndTime, Validators.required]}
               validate={[Validators.isValidDateAndTime, Validators.required]} 
               timeIntervals={1}
              />
            </FormGroup>
          )}
         { isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)||isCallington1Shot(treatmentTypeId) && covered ===false ? null:
           ((isImportOrExportAndContainer(jobType)&& isCovered)||!isImportOrExportAndContainer(jobType))?(
          <FormGroup className="col-12">
            <DimensionTreatmentResultRecordsFields jobType={jobType} isCoveredContainer={isCovered} />
          </FormGroup>):null}
          {!isBreakBulkJobType(jobType) ? (
            <FormGroup className="col-lg-3">
              <Field
                name="jas"
                label={
                  //isBreakBulkJobType(jobType) ? 'M3' :
                  jobType.name === 'MB Logs In Hold'|| (isImportOrExportAndContainer(jobType) && isMethylBromide(treatmentType)) ? 'M3' 
                  :isEthylFormate(treatmentType)||isExportOtherMBApples(jobType,treatmentType,commodity,standardId)?"Client Volume"
                  : 'JAS'
                }
                component={RenderField}
                type="number"
                placeholder={
                  // isBreakBulkJobType(jobType)
                  //? 'Enter Client Vol' :
                  jobType.name === 'MB Logs In Hold' ||isImportOrExportAndContainer(jobType)||isEthylFormate(treatmentType)
                  ||isExportOtherMBApples(jobType,treatmentType,commodity,standardId) ?
                     'Enter Client Volume'
                    : 'Enter the JAS'
                }
                validate={Validators.number}
                normalize={minZero}
              />
            </FormGroup>
          ) : null}
          {showSachetFields ? (
            <FormGroup className="col-lg-4">
              <Field
                name="sachetNumberList"
                label="Sachet #"
                component={CustomTagSelect}
                options={[]}
                type="text"
                multi
                placeholder={'Enter Sachet #'}
              />
            </FormGroup>
          ) : null}

          {isMethylBromide(treatmentType) &&
            !isMBLogsInHold(jobType) &&!isImportOrExportAndContainer(jobType) &&
            !(isBreakBulkJobType(jobType) || isExportAndLogs(jobType)) && 
            !(isExportOtherMBApples(jobType,treatmentType,commodity,standardId) )&&
            (
              <FormGroup className="col-lg-2">
                <Field
                  name="mbTopUp"
                  label="MB Top Up (kg)"
                  validate={Validators.number}
                  component={RenderField}
                  type="text"
                  placeholder="Enter MB Top Up"
                />
              </FormGroup>
            )}

          { isEthylFormate(treatmentType)||isCallington1Shot(treatmentType)||isExportOtherMBApples(jobType,treatmentType,commodity,standardId)?null:!(isImportOrExportAndContainer(jobType) && isMethylBromide(treatmentType))? !isMBLogsInHold(jobType)
            ? !(
                isMethylBromide(treatmentType) &&
                (isBreakBulkJobType(jobType) || isExportAndLogs(jobType))
              ) && (
                <FormGroup className="col-lg-4">
                  <Field
                    name="batchNumber"
                    label="Batch #"
                    component={RenderField}
                    type="text"
                    placeholder="Enter a Batch #"
                  />
                </FormGroup>
              )
            : null:null}
            {isCallington1Shot(treatmentType)?null:
          <FormGroup className="col-lg-3">
            <Field
              name="thermoNumber"
              label="Thermo #"
              component={RenderField}
              type="text"
              placeholder="Enter a Thermo #"
            />
          </FormGroup>
        }

{(isImportOrExportAndContainer(jobType)||isExportAndLogs)&& isMethylBromide(treatmentType)?(
            <FormGroup>
              <Field 
              name ="mbMaxDose"
              label = "MB Max Dose(kg)"
              component={RenderField}
              type="number"
              />
            </FormGroup>
          ):null}

{(isImportOrExportAndContainer(jobType)||isExportAndLogs)&& isMethylBromide(treatmentType)?(
            <FormGroup>
              <Field 
              name ="mbDoseApplied"
              label = "MB Dose Applied(kg)"
              component={RenderField}
              type="number"
              />
            </FormGroup>
          ):null}
        
          {isCovered && isImportOrExportAndContainer(jobType)&& isMethylBromide(treatmentType)?(
            <FormGroup>
              <Field 
              name ="numberOfCovers"
              label = "Number of Covers"
              component={SearchableSelect}
              options = {numberOfCovers}
              />
            </FormGroup>
          ):null}
          {isEthylFormate(treatmentType)||isCallington1Shot(treatmentType)|| isExportOtherMBApples(jobType,treatmentType,commodity,standardId)?null:!(isImportOrExportAndContainer(jobType) && isMethylBromide(treatmentType))? !isMBLogsInHold(jobType)
            ? !(
                isMethylBromide(treatmentType) &&
                (isBreakBulkJobType(jobType) || isExportAndLogs(jobType))
              ) && (
                <FormGroup className="col-lg-4">
                  <Field
                    name="tarpaulinIdNumber"
                    label="Tarpaulin Id #"
                    component={RenderField}
                    type="text"
                    placeholder="Enter a Tarpaulin Id #"
                  />
                </FormGroup>
              )
            : null:null}
             {   isExportOtherMBApples(jobType,treatmentType,commodity,standardId)?(
               
                <FormGroup className="col-lg-2">
                  <Field
                    name="tarpaulinIdNumber"
                    label="ChamberId"
                    component={SearchableSelect}
                    placeholder="Select ChamberId"
                    options={chamberIdList}
                    valueKey="id"
                    labelKey="name"
                  />
                </FormGroup>
                )
            :null}
              {isCovered||isExportOtherMBApples(jobType,treatmentType,commodity,standardId)?null:!isImportOrExportAndContainer(jobType)?(
          <FormGroup className="col-lg-4">
            <Field
              name="extraHours"
              label= {isCallington1Shot(treatmentType)?"Man Hours":"Extra Hours"}
              component={RenderField}
              type="text"
              normalize={isBetweenOneAndNinetyNine}
              validate={Validators.number}
              placeholder={isCallington1Shot(treatmentType)?"Man Hours (1-99 hours)":"Extra hours (1-99 hours)"}
            />
          </FormGroup>):null}

          {isCallington1Shot(treatmentType) ?(
          <FormGroup className="col-lg-4">
            <Field
              name="numberOfCans"
              label="No. Of Cans"
              component={SearchableSelect}
              placeholder="Select Number of Cans Used"
              options={NumberOfCansList}
              valueKey="id"
              labelKey="name"
            />
          </FormGroup>
          ):null}
          {isImportOrExportAndContainer(jobType)&&isMethylBromide(treatmentType) ? (
            <FormGroup className="col-lg-6" />
          ) : null}
          
          {!isCovered&&!isMBLogsInHold(jobType) && !isExportAndLogs(jobType) && !isImportOrExportAndContainer(jobType) ? (
            <FormGroup className="col-lg-4" />
          ) : null}
          {isMethylBromide(treatmentType) ? (
            <FormGroup className="col-lg-4">
              MB Bottle *
              <FieldArray
                name="mbBottles"
                label=""
                component={MBMarkingsFieldArray}
                validate={Validators.mandatoryList}
              />
            </FormGroup>
          ) : null}
          <FormGroup className="col-12">
            <Button type="submit" className="mt-3" color="primary">
              Save
            </Button>
          </FormGroup>
        </Row>
      </fieldset>
    );
  }
}

TreatmentResultRecordsStartDetailsSection.propTypes = propTypes;

export default TreatmentResultRecordsStartDetailsSection;
