import React, { Component } from 'react';
import { Async } from 'react-select';
import { Label, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { Band } from 'components/layout';
import { getClientDetails } from 'api/operationsApi';
import { preventEnterSubmit } from 'actions/formActions';

class ClientLookup extends Component {
  state = {
    value: null,
    searchValue: null,
    stopCredit: false
  };

  onChange = value => {
    this.setState({ value });
  };

  lookupClient = input => {
    if (!input || input.length <= 4) {
      return Promise.resolve({ options: [] });
    }
    return this.getClientDetails(input);
  };

  getClientDetails = clientCode => {
    return getClientDetails(clientCode)
      .then(result => result.data)
      .then(client => {
        if (!client) {
          return { options: [] };
        }
        return { options: [client] };
      });
  };

  handleSubmit = event => {
    const { value } = this.state;
    const { clients, onAddClient } = this.props;
    event.preventDefault();
    onAddClient(value, clients);
    this.setState({ value: null });
  };

  handleSearchClient(val) {
    this.props.handleSearch(val);
  }

  render() {
    const { value } = this.state;
    //const cliList = this.props.clients;
    return (
      <Band dark className="p-2">
        <Card>
          <CardBody>
            <form onSubmit={this.handleSubmit} onKeyPress={preventEnterSubmit}>
              <Row>
                <Col>
                  <Label>Add a Client</Label>
                  <Async
                    value={value}
                    onChange={this.onChange}
                    valueKey="code"
                    labelKey="name"
                    loadOptions={this.lookupClient}
                    backspaceRemoves={true}
                    noResultsText="Client not found"
                    placeholder="Enter a client code"
                  />
                </Col>
                <Col>
                  <Label>&nbsp;</Label>
                  <div>
                    <Button color="primary" disabled={!value}>
                      Add Client
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Band>
    );
  }
}

export default ClientLookup;
