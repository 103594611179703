import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ajaxCallsInProgress from './ajaxCallReducer';
import clientDetails from './clientDetailsReducer';
import currentJob from './currentJobReducer';
import formInitialValues from './formInitialValuesReducer';
import isAuthenticated from './authReducer';
import jobStateSummaries from './jobStateSummariesReducer';
import jobSummaries from './jobSummariesReducer';
import referenceData from './referenceDataReducer';

const rootReducer = combineReducers({
  ajaxCallsInProgress,
  clientDetails,
  currentJob,
  form: formReducer,
  formInitialValues,
  isAuthenticated,
  jobStateSummaries,
  jobSummaries,
  referenceData
});

export default rootReducer;
