import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getJobSummaries(jobStateName, params) {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getJobSummaries(jobStateName, params)
      .then(
        response => dispatch(getJobSummariesSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getJobSummariesSuccess(response) {
  return {
    type: types.GET_JOB_SUMMARIES_SUCCESS,
    jobSummaries: response.data || []
  };
}
