import React from 'react';
import PropTypes from 'prop-types';

import PlainTextField from './PlainTextField';
import { formatDate } from '../../util/DateHelper';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  label: PropTypes.string,
  showTime: PropTypes.bool,
};

const PlainTextDateField = ({ name, label, value, showTime }) => {
  const date =
    value && (showTime ? formatDate(value, true) : formatDate(value));
  return (
    <div>
      <PlainTextField name={name} label={label} value={date} />
    </div>
  );
};

PlainTextDateField.propTypes = propTypes;

export default PlainTextDateField;
