import React from 'react';
import moment from 'moment';

import PrintPage, {
  Table,
  TableHead,
  TableFoot,
} from '../../../../components/common/PrintPage';

import logo from './logo.png';

const coveringBgColor = '#ceebfd';
const uncoveringBgColor = '#e0ffff';
const footerBgColor = '#9dd7fb';
const baseStyleClass = 'print-page';

const headerStyles = `
  .${baseStyleClass} .logo {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 63px;
  }
  .${baseStyleClass} h1 {
    font-size: 18px;
    margin-right: 130px;
    margin-bottom: 1em;
    margin-top: 1rem;
  }
  .${baseStyleClass} ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  .${baseStyleClass} li {
    width: 32%;
    margin: .5rem 0;
  }
  .${baseStyleClass} dl {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .${baseStyleClass} dt {
    min-width: 6.25rem;
    word-wrap: nowrap;
    border-bottom: 1px solid transparent;
  }
  .${baseStyleClass} dt:after {
    content: ':';
  }
  .${baseStyleClass} dd {
    border-bottom: 1px solid #000;
    flex-grow: 1;
    word-wrap: break-word;
    overflow: hidden;
  }
  .${baseStyleClass} .col-3 dt {
    min-width: 5.625rem;
  }
  .${baseStyleClass} .dosage-rate {
    width: 24%;
  }
  .${baseStyleClass} .min-temp {
    width: 36%;
  }
  .${baseStyleClass} .min-temp dt {
    min-width: calc(6.25rem + 22%);
  }
  .${baseStyleClass} .min-hours {
    width: 36%;
  }
  .${baseStyleClass} .min-hours dt {
    min-width: calc(5.625rem + 11%);
  }
`;

const tableStyles = `
  .${baseStyleClass} table {
    margin: 1rem 0;
  }
  .${baseStyleClass} th, 
  .${baseStyleClass} td {
    text-align: center;
  }
  .${baseStyleClass} th {
    font-weight: 400;
    white-space: nowrap;
  }

  .${baseStyleClass} tfoot th,
  .${baseStyleClass} tfoot td {
    background-color: ${footerBgColor};
  }
  .${baseStyleClass} tfoot th {
    font-weight: 700;
  }
  .${baseStyleClass} .desc {
    font-size: .857em;
  }
`;

const styles = `
  ${headerStyles}
  ${tableStyles}
`;

const LogRowsSummarySheetF15A = ({ currentJob }) => {
  const destination =
    currentJob.treatmentTypeDetails.destination &&
    currentJob.treatmentTypeDetails.destination.toUpperCase();
  return (
    <PrintPage
      footer={['Version 4', 'Log Rows Summary Sheet F15', 'August 2018']}
      baseStyleClass={baseStyleClass}
      css={styles}
    >
      <img src={logo} alt="" className="logo" />
      <h1>Log Rows Summary Sheet</h1>
      <ul>
        <li>
          <dl>
            <dt>Vessel Name</dt>
            <dd>{currentJob.vessel}</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>Job Number</dt>
            <dd>{currentJob.jobReference}</dd>
          </dl>
        </li>
        <li />
        <li>
          <dl>
            <dt>Exporter</dt>
            <dd>{currentJob.clientName}</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>Location</dt>
            <dd>{currentJob.location}</dd>
          </dl>
        </li>
        <li className="col-3">
          <dl>
            <dt>Destination</dt>
            <dd>
              <strong>{destination}</strong>
            </dd>
          </dl>
        </li>
        <li className="dosage-rate">
          <dl>
            <dt>Dosage rate</dt>
            <dd>{currentJob.treatmentDetails.dosageRate}</dd>
          </dl>
        </li>
        <li className="min-temp">
          <dl>
            <dt>Minimum temperature</dt>
            <dd>
              {currentJob.treatmentDetails.mandatoryMinimumTemperature &&
                currentJob.treatmentDetails.mandatoryMinimumTemperature + '+'}
            </dd>
          </dl>
        </li>
        <li className="col-3 min-hours">
          <dl>
            <dt>Minimum hours</dt>
            <dd>{currentJob.treatmentDetails.treatmentPeriod}</dd>
          </dl>
        </li>
      </ul>
      <Table>
        <TableHead>
          <tr>
            <th colSpan={4} bgcolor={coveringBgColor} />
            <th colSpan={2} bgcolor={coveringBgColor}>
              Gas In
            </th>
            <th colSpan={2} bgcolor={uncoveringBgColor}>
              Riken Start Reading
            </th>
            <th colSpan={2} bgcolor={uncoveringBgColor}>
              Uncovering
            </th>
          </tr>
          <tr>
            <th width="8.5%" bgcolor={coveringBgColor}>
              Row No
            </th>
            <th width="12.5%" bgcolor={coveringBgColor}>
              Yard No
            </th>
            <th width="12.5%" bgcolor={coveringBgColor}>
              JAS
            </th>
            <th width="12.5%" bgcolor={coveringBgColor}>
              MB
            </th>
            <th width="10.5%" bgcolor={coveringBgColor}>
              Date
            </th>
            <th width="12.5%" bgcolor={coveringBgColor}>
              Time
            </th>
            <th width="12.5%" bgcolor={uncoveringBgColor}>
              Date
            </th>
            <th width="12.5%" bgcolor={uncoveringBgColor}>
              Time
            </th>
            <th width="12.5%" bgcolor={uncoveringBgColor}>
              Date
            </th>
            <th width="12.5%" bgcolor={uncoveringBgColor}>
              Time
            </th>
          </tr>
        </TableHead>
        <tbody>
          {currentJob.treatmentResultRecords &&
            currentJob.treatmentResultRecords.map((row, key) => (
              <tr key={key}>
                <th bgcolor={coveringBgColor}>{key + 1}</th>
                <td>{row.yardNumber}</td>
                <td>{row.jas && row.jas.toFixed(3)}</td>
                <td>{row.mbkg}</td>
                <td>
                  {row.gasInDateTime &&
                    moment(row.gasInDateTime).format('D-MMM')}
                </td>
                <td>
                  {row.gasInDateTime &&
                    moment(row.gasInDateTime).format('HHmm')}
                </td>
                <td>
                  {row.startDate && moment(row.startDate).format('D-MMM')}
                </td>
                <td>{row.startDate && moment(row.startDate).format('HHmm')}</td>
                <td>
                  {row.ventDateTime && moment(row.ventDateTime).format('D-MMM')}
                </td>
                <td>
                  {row.ventDateTime && moment(row.ventDateTime).format('HHmm')}
                </td>
              </tr>
            ))}
          <tr className="h-border-top h-border-bottom">
            <th bgcolor={coveringBgColor}>Total</th>
            <td />
            <td>
              {currentJob.treatmentResultsSummary.totalJas &&
                currentJob.treatmentResultsSummary.totalJas.toFixed(3)}
            </td>
            <td>{currentJob.treatmentResultsSummary.totalMb}</td>
            <td colSpan={6} />
          </tr>
        </tbody>
        <TableFoot>
          <tr>
            <th colSpan={4} className="total-of-rows">
              Total of All Rows
            </th>
            <td colSpan={6} className="desc">
              {`Start & end time refer to corresponding F13. * Start time is when all concentration 
              readings are above the standard for the FIRST sealed stack. ** End time is when the seal of 
              the LAST stack is broken (eg water tubes broken).`}
            </td>
          </tr>
          <tr>
            <th>Start Date&nbsp;</th>
            <td>
              {currentJob.treatmentResultsSummary.printStartDate &&
                moment(
                  currentJob.treatmentResultsSummary.printStartDate,
                ).format('DD/MM/YYYY')}
            </td>
            <th>End Date&nbsp;&nbsp;</th>
            <td>
              {currentJob.treatmentResultsSummary.printEndDate &&
                moment(currentJob.treatmentResultsSummary.printEndDate).format(
                  'DD/MM/YYYY',
                )}
            </td>
            <td colSpan={5} />
            <td />
          </tr>
          <tr>
            <th>Start Time*</th>
            <td>
              {currentJob.treatmentResultsSummary.printStartDate &&
                moment(
                  currentJob.treatmentResultsSummary.printStartDate,
                ).format('HHmm')}
            </td>
            <th>End Time**</th>
            <td>
              {currentJob.treatmentResultsSummary.printEndDate &&
                moment(currentJob.treatmentResultsSummary.printEndDate).format(
                  'HHmm',
                )}
            </td>
            <th colSpan={5}>Minimum Temperature</th>
            <td>
              {currentJob.treatmentResultsSummary.actualTemperature &&
                currentJob.treatmentResultsSummary.actualTemperature + '°C'}
            </td>
          </tr>
        </TableFoot>
      </Table>
    </PrintPage>
  );
};

export default LogRowsSummarySheetF15A;
