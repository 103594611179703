import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import AppModal from '../common/AppModal';
import JobDuplicateForm from './JobDuplicateForm';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  currentJob: PropTypes.object,
};

const JobDuplicateModal = ({ title, isOpen, toggle, onSubmit, currentJob }) => {
  return (
    <AppModal
      title={title}
      isOpen={isOpen}
      toggle={toggle}
      additionalClasses="modal-duplicate-job"
    >
      <JobDuplicateForm
        initialValues={{
          description: currentJob.treatmentTypeDetails.jobDescription,
          markings: currentJob.treatmentTypeDetails.markings,
          dateOfIntendedTreatment: moment().startOf('day'),
          isNOISelected: false,
        }}
        onSubmit={onSubmit}
        currentJob={currentJob}
      />
    </AppModal>
  );
};

JobDuplicateModal.propTypes = propTypes;

export default JobDuplicateModal;
