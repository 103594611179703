import React from 'react';
import PropTypes from 'prop-types';
import { Band } from 'components/layout';
import TableList from 'components/table/TableList';
import searchResultsTableDefinition from 'constants/searchResultsTableDefinition';
import NoResults from 'components/table/NoResults';

const propTypes = {
  searchTableData: PropTypes.object,
  options: PropTypes.object,
  onPageChange: PropTypes.func,
  onHeaderClick: PropTypes.func
};

const SearchResultsTable = ({
  searchTableData,
  options,
  onPageChange,
  onHeaderClick,
  sort
}) => {
  if (!searchTableData) return <NoResults />;

  return (
    <Band dark>
      <TableList
        {...options}
        bodyContainerClass="px-2"
        data={searchTableData.content}
        total={searchTableData.totalElements}
        page={searchTableData.number + 1}
        pageSize={searchResultsTableDefinition.pageSize}
        definition={searchResultsTableDefinition.columnDefinitions}
        onPageChange={onPageChange}
        onHeaderClick={onHeaderClick}
        sort={sort}
      />
    </Band>
  );
};

SearchResultsTable.propTypes = propTypes;

export default SearchResultsTable;
