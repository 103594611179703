import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getFormValues, change } from 'redux-form';
import JobCompletion from './JobCompletion';
import {
  getContactsByAreaId,
  getDefaultContactsByNoticeKey,
  getDefaultSendNoticeOfCompletion
} from 'selectors/formSelectors';
import { jobCompletionForm } from 'constants/FormConstants';

function mapStateToProps(state, ownProps) {
  const { currentJob } = state;
  return {
    currentJob,
    currentState: currentJob.currentState,
    referenceData: state.referenceData,
    initialValues: {
      newNoticeOfCompletion: {
        sendNotice: getDefaultSendNoticeOfCompletion(state, ownProps),
        contactIds: getDefaultContactsByNoticeKey(
          state,
          currentJob.areaId,
          'defaultForNotificationOfCompletion'
        )
      }
    },
    contacts: getContactsByAreaId(state, currentJob.areaId),
    formValues: getFormValues(jobCompletionForm)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change }, dispatch)
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(JobCompletion);
