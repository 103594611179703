import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function jobTypeReducer(
  state = initialState.referenceData.jobTypes,
  action
) {
  if (action.type === types.GET_JOB_TYPES_SUCCESS) {
    return [...state, ...action.jobTypes];
  }
  return state;
}
