import * as types from 'constants/actionTypes';
import * as JobTypeActions from 'actions/jobTypeActions.js';
import * as ClientSummariesActions from 'actions/clientSummariesActions.js';
import * as JobLocationActions from 'actions/jobLocationActions.js';
import * as JobTableDefinitionActions from 'actions/jobTableDefinitionActions.js';
import * as UserColumnVisibilitiesActions from 'actions/userColumnVisibilitiesActions';
import * as JobStandardsActions from 'actions/jobStandardsActions.js';
import * as ContainerTypesActions from 'actions/containerTypesActions.js';
import * as TreatmentTypesActions from 'actions/treatmentTypesActions.js';
import * as JobCategoriesActions from 'actions/jobCategoriesActions.js';
import * as AreasActions from 'actions/areasActions.js';
import * as DestinationsActions from 'actions/destinationsActions.js';
import * as CommoditiesActions from 'actions/commoditiesActions.js';
import * as TechnicianActions from 'actions/technicianActions.js';
import * as ContactActions from 'actions/contactActions.js';
import * as SizeOfContainersActions from 'actions/sizeOfContainersActions.js';
import * as VesselsActions from 'actions/vesselsActions';

import Logger from 'util/Logger';

export function getAllReferenceData() {
  return function (dispatch) {
    return Promise.all([
      dispatch(JobTypeActions.getJobTypes()),
      dispatch(ClientSummariesActions.getClientSummaries()),
      dispatch(JobLocationActions.getJobLocations()),
      dispatch(JobTableDefinitionActions.getJobTableDefinitions()),
      dispatch(UserColumnVisibilitiesActions.getUserColumnVisibilities()),
      dispatch(JobStandardsActions.getJobStandards()),
      dispatch(TreatmentTypesActions.getTreatmentTypes()),
      dispatch(ContainerTypesActions.getContainerTypes()),
      dispatch(JobCategoriesActions.getJobCategories()),
      dispatch(AreasActions.getAreas()),
      dispatch(DestinationsActions.getDestinations()),
      dispatch(CommoditiesActions.getCommodities()),
      dispatch(TechnicianActions.getTechnicians()),
      dispatch(ContactActions.getContacts()),
      dispatch(SizeOfContainersActions.getSizeOfContainers()),
      dispatch(VesselsActions.getVessels()),
    ]).then(
      (response) => dispatch(getAllReferenceDataSuccess()),
      (error) => Logger.error(error),
    );
  };
}

function getAllReferenceDataSuccess() {
  return {
    type: types.LOAD_REFERENCE_DATA,
    referenceDataLoaded: true,
  };
}
