import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import cx from 'classnames';
import {   getTechnicians,getCurrentJob,getTreatmentType, getCurrentTreatmentResultRecord, getCurrentConcentrationReadingItem,getRecapturePercentage,getCurrentConcentrationChamberReadingItem} from '../selectors';
import * as Validators from '../../../../util/Validators';
import * as operationsApi from '../../../../api/operationsApi';
import { confirmAlert } from '../../../../util/Alerts';

import PlainTextField from '../../../fields/PlainTextField';
import RenderField from '../../../fields/RenderField';
import SearchableSelect from '../../../fields/SearchableSelect';
import DatePickerField from '../../../fields/DatePickerField';
import { getCompetentTechnicians } from '../../../../selectors/technicianSelectors';
import {  isBreakBulkOrExportOfLogsAndMethylBromide, isImportOrExportAndContainer,isExportOtherMBApples} from '../../../../util/JobTypeHelper';
import styles from './styles.module.scss';
import { of } from 'rxjs';

const propTypes = {
  fields: PropTypes.object,
  readOnly: PropTypes.bool,
  fieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onEditing: PropTypes.func.isRequired,
  meta: PropTypes.object,
  currentTreatmentResultRecord :PropTypes.object,
};

function shouldShowWarning(field, fieldValue) {
  const std = +fieldValue(`${field}.actualReading`);
  const topUpDose = +fieldValue(`${field}.topupDose`);
  const jobType = +fieldValue(`${field}.job`);
  return (
    Boolean(std) &&
    !topUpDose &&
    [1, 2, 3].some((i) => {
      const value = +fieldValue(`${field}.reading${i}`);
      return value && value < std;
    })
  );
}

function getTargetValue(field, fieldValue,currentJob,currentConcentrationReadingItem,recapturePercentage) {
  const containerNumber = fieldValue(`${field}.containerNumber`);
  const jobTypeId = currentJob.typeId;
  const commodity = currentJob.treatmentTypeDetails.commodity;
  const standardId = currentJob.treatmentDetails.standardId;
  const treatmentTypeId = currentJob.treatmentDetails.treatmentTypeId
  const isAppleMB = isExportOtherMBApples(jobTypeId,treatmentTypeId,commodity,standardId)
  if(isBreakBulkOrExportOfLogsAndMethylBromide(currentJob.type,currentJob.treatmentDetails.treatmentTypeId)||isAppleMB){
    var endConcentrationItems = currentConcentrationReadingItem.filter((conItem)=> conItem.phase===2) ;
    if(endConcentrationItems.length>=1){
      var conReadingItem = endConcentrationItems[0];
      var read1 = conReadingItem.reading1;
      var read2 = conReadingItem.reading2;
      var read3 = conReadingItem.reading3;
      var percentage = parseInt(recapturePercentage);
      var targetPercentage = (read2 * (percentage/100))
      if(isBreakBulkOrExportOfLogsAndMethylBromide(currentJob.type,currentJob.treatmentDetails.treatmentTypeId)){
         targetPercentage = (read2 * (90/100))
        ret = Math.round((read2 - targetPercentage)*100)/100;
      }if(isImportOrExportAndContainer(currentJob.type)){
        ret = Math.round((read2 - targetPercentage)*100)/100;
      }
      if(isAppleMB){
        targetPercentage = (read2 * (80/100))
        ret = Math.round((read2 - targetPercentage)*100)/100;
      }
      return ret;
    }
  }
  const isCoveredContainer = null;//currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null
    /*if(isBreakBulkOrExportOfLogsAndMethylBromide(currentJob.typeId,currentJob.tr)){

    }*/


  if(containerNumber===undefined){
      return 0;
  }
  var ret = 0
  if(containerNumber !==undefined && containerNumber){
    ret = 0;
    var endConctrationItems = [];
    endConctrationItems = currentConcentrationReadingItem.filter((conItem)=>conItem.containerNumber===containerNumber && conItem.phase===2) 
      if(endConctrationItems.length>=1){
        var concentrationReadingItem = endConctrationItems[0];
        var read1 = concentrationReadingItem.reading1;
        var read2 = concentrationReadingItem.reading2;
        var read3 = concentrationReadingItem.reading3;
        var stdgramPerCubicMetre = concentrationReadingItem.actualReading;
        var percentage = parseInt(recapturePercentage);
            var targetPercentage = (read2 * (percentage/100))
            ret = Math.round((read2 - targetPercentage)*100)/100; 
      return ret;

  }
}

return  ret
}



function getAppleTargetValue(field, fieldValue,currentJob,currentConcentrationReadingChamberItem,recapturePercentage) {
  var ret = 0;
    var endConcentrationItems = currentConcentrationReadingChamberItem.filter((conItem)=> conItem.interval===3) ;
    if(endConcentrationItems.length>=1){
      var conReadingItem = endConcentrationItems[0];
      var read1 = conReadingItem.reading1;
      var read2 = conReadingItem.reading2;
      var read3 = conReadingItem.reading3;
      var targetPercentage = (read2 * (80/100))
        targetPercentage = (read2 * (80/100))
        ret = Math.round((read2 - targetPercentage)*100)/100;
      
    }
    return ret;
}


  const isCoveredContainer = null;//currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null
    /*if(isBreakBulkOrExportOfLogsAndMethylBromide(currentJob.typeId,currentJob.tr)){

    }*/







class RecaptureReadingsFieldArrayItem extends Component {
  render() {
    const {
      field,
      fieldValue,
      fieldError,
      readOnly,
      isEditing,
      onEdit,
      onDone,
      onRemove,
      jobtypename,
      currentJob,
      technicians,
      treatmentType,
      currentConcentrationReadingItem,
      currentConcentrationReadingChamberItem,
      recapturePercentage
    } = this.props;
      const isCoveredContainer = currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null
    var markingOptions = currentJob.treatmentTypeDetails.markings;
    // const doubled = markingOptions.map((marking,index) =>  marking.containerNumber +" "+marking.sealNumber);
    const doubled = markingOptions.map((marking,index) =>  marking.containerNumber);
     const markings2=[] ;
     for(var i =0;i<doubled.length;i++){
       var containerNumber ={value:'' ,label:''}
        containerNumber.value = doubled[i];
        containerNumber.label = doubled[i];
       markings2.push(containerNumber)
     }
     const isContainer = isImportOrExportAndContainer(currentJob.type)
 
     const competentTechs = getCompetentTechnicians(
       technicians,
       treatmentType,
       currentJob.treatmentTypeDetails.commodity,
       currentJob.treatmentDetails.standardId,
       currentJob.typeId,
       currentJob.areaId,
     );

   const showWarning = isCoveredContainer?null:shouldShowWarning(field, fieldValue);
   const jobTypeId = currentJob.typeId;
   const commodity = currentJob.treatmentTypeDetails.commodity;
   const standardId = currentJob.treatmentDetails.standardId;
   const treatmentTypeId = currentJob.treatmentDetails.treatmentTypeId

   const isAppleMB = isExportOtherMBApples(currentJob.type,treatmentTypeId,commodity,standardId)

   const targetValue = isAppleMB ==true? getAppleTargetValue(field, fieldValue,currentJob,currentConcentrationReadingChamberItem,recapturePercentage):getTargetValue(field, fieldValue,currentJob,currentConcentrationReadingItem,recapturePercentage);
   
    const hasError = Boolean(fieldError(field));

    return (
      <tr>
        {isContainer?
        <td>
{isEditing ? (
            <Field
              name={`${field}.containerNumber`}
              component={SearchableSelect}
              options={markings2}
              valueKey="value"
              labelKey="label"
              placeholder="Container#"
             
            />
          ):( 
            <PlainTextField
              name={`${field}.containerNumber`}
              value={fieldValue(`${field}.containerNumber`)}
              
            />
        )}
        </td>
  :null}
        <td>
          {isEditing ? (
            <Field
              name={`${field}.rikenID`}
              component={RenderField}
              type="text"
              placeholder="Riken ID"
            />
          ) : (
            <PlainTextField
              name={`${field}.rikenID`}
              value={fieldValue(`${field}.rikenID`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.startRecaptureDateTime`}
              component={DatePickerField}
              defaultToEmpty
              showTimeSelect
              type="text"
              placeholder="Date/Time"
              validate={[Validators.isValidDateAndTime]}
              timeIntervals={1}
            />
          ) : (
            <PlainTextField
              name={`${field}.startRecaptureDateTime`}
              value={
                fieldValue(`${field}.startRecaptureDateTime`) &&
                moment(fieldValue(`${field}.startRecaptureDateTime`)).format(
                  'DD/MM HH:mm',
                )
              }
            />
          )}
        </td>

        <td>
          {isEditing ? (
            <Field
              name={`${field}.endRecaptureDateTime`}
              component={DatePickerField}
              defaultToEmpty
              showTimeSelect
              type="text"
              placeholder="Date/Time"
              validate={[Validators.isValidDateAndTime]}
              timeIntervals={1}
            />
          ) : (
            <PlainTextField
              name={`${field}.endRecaptureDateTime`}
              value={
                fieldValue(`${field}.endRecaptureDateTime`) &&
                moment(fieldValue(`${field}.endRecaptureDateTime`)).format(
                  'DD/MM HH:mm',
                )
              }
            />
          )}
        </td>

        <td>
          {isEditing ? (
            <Field
              name={`${field}.actualReading`}
              component={RenderField}
              type="number"
              placeholder="Std g/m3"
              validate={[Validators.number]}
            />
          ) : (
            <PlainTextField
              name={`${field}.actualReading`}
              value={fieldValue(`${field}.actualReading`)}
            />
          )}
        </td>

        <td>
            <PlainTextField
              name={`${field}.targetVal`}
              value={targetValue}
            />
        </td>
        
        
        <td>
          {hasError && (
            <span className={styles.errorIcon}>
              <i aria-hidden="true" className="material-icons">
                error
              </i>
            </span>
          )}
          {showWarning && !hasError && (
            <span className={styles.warningIcon}>
              <i aria-hidden="true" className="material-icons">
                warning
              </i>
            </span>
          )}
        </td>
        {!readOnly && (
          <td>
            {isEditing && (
              <Button
                className={styles.iconButton}
                outline
                onClick={onDone}
                color={hasError ? 'disabled' : 'success'}
                disabled={hasError}
              >
                <i aria-hidden="true" className="material-icons">
                  check_outline
                </i>
              </Button>
            )}
            {!isEditing && (
              <Button
                className={styles.iconButton}
                outline
                color="primary"
                onClick={onEdit}
              >
                <i aria-hidden="true" className="material-icons">
                  edit_outline
                </i>
              </Button>
            )}
            <Button
              className={styles.iconButton}
              outline
              color="danger"
              onClick={onRemove}
            >
              <i aria-hidden="true" className="material-icons">
                delete_outline
              </i>
            </Button>
          </td>
        )}
      </tr>
    );
  }
}
RecaptureReadingsFieldArrayItem.propTypes = {
  field: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  fieldValue: PropTypes.func.isRequired,
  fieldError: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
const Item = connect((state, props) => ({
  currentConcentrationReadingItem:getCurrentConcentrationReadingItem(state),
  currentConcentrationReadingChamberItem:getCurrentConcentrationChamberReadingItem(state),
  recapturePercentage:getRecapturePercentage(state),
  currentJob: getCurrentJob(state),
  technicians: getTechnicians(state),
  treatmentType: getTreatmentType(state),
  fieldValue: (field) => formValueSelector(props.form)(state, field),
  fieldError: (field) => _.get(getFormSyncErrors(props.form)(state), field),
}))(RecaptureReadingsFieldArrayItem);

// eslint-disable-next-line react/no-multi-comp
class RecaptureReadingsFieldArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: [],
    };
  }

  render() {
    const {
      fields,
      readOnly = false,
      fieldValue,
      meta,
      jobId,
      onChange,
      onEditing,
      job,
    } = this.props;
    var isCovered =false;// job.treatmentTypeDetails.covered?job.treatmentTypeDetails.covered:false
    const jobTypeName = job.type.name;
    const isContainer =  isImportOrExportAndContainer(job.type)
    const count = fields ? fields.length : 0;
    // We need to do reduce here because fields is actually not an array
    var showWarning =
      fields && 
      fields.reduce(
        (acc, field) => acc || shouldShowWarning(field, fieldValue),
        false,
      );
      if(isCovered){
showWarning = false;
      }

    return (
      <div className={styles.concentrationReadingsFieldArray}>
        <Table hover>
          <thead>
            <tr>
              {isContainer?<th style={{ width: 150 }}>Container Number</th>:null}
              <th>Riken ID</th>
              <th>Start Date/Time</th>
              <th>End Date/Time</th>
              <th>Final Reading</th>
              <th>{ 'Recapture Target'}</th>
              <th>&nbsp;</th>
              {!readOnly && (
                <th style={{ width: 100 }}>
                  <Button
                    className={styles.iconButton}
                    outline
                    onClick={(e) => {
                      fields.push({ jobId });
                      this.setState(
                        (state) => ({
                          editing: [...state.editing, count],
                        }),
                        () => {
                          onEditing(true);
                        },
                      );
                    }}
                    color="primary"
                  >
                    <i aria-hidden="true" className="material-icons">
                      add_outline
                    </i>
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {count === 0 && (
              <tr>
                <td align="center" colSpan={readOnly ? 8 : 9}>
                  There is no data to display
                </td>
              </tr>
            )}
            {fields.map((field, index) => (
              <Item
                key={index}
                field={field}
                form={meta.form}
                jobtypename = {jobTypeName}
                readOnly={readOnly}
                isEditing={this.state.editing.includes(index)}
                onEdit={() => {
                  this.setState(
                    (state) => ({
                      editing: [...state.editing, index],
                    }),
                    () => {
                      onEditing(true);
                    },
                  );
                }}
                onDone={() => {
                  this.setState(
                    (state) => ({
                      editing: state.editing.filter((i) => i !== index),
                    }),
                    () => {
                      onEditing(this.state.editing.length > 0);
                      const updatedField = fields.get(index);
                      const all = fields.getAll();
                      onChange &&
                        onChange([
                          ...all.slice(0, index),
                          updatedField,
                          ...all.slice(index + 1),
                        ]);
                    },
                  );
                }}
                onRemove={async () => {
                  const confirm = await confirmAlert(
                    'Delete',
                    'Are you sure you want to delete this record?',
                  );
                  if (confirm) {
                    const item = fields.get(index);
                    if (item.id) {
                      try {
                        await operationsApi.deleteRecaptureReadingRecord(
                          item.id,
                        );
                      } catch (err) {
                        /* noop */
                      }
                    }
                    fields.remove(index);
                    this.setState(
                      (state) => ({
                        editing: state.editing.filter((i) => i !== index),
                      }),
                      () => {
                        onEditing(this.state.editing.length > 0);
                      },
                    );
                  }
                }}
              />
            ))}
          </tbody>
        </Table>
        {showWarning ? (
          <p className={cx(styles.note, styles.legend)}>
            <span className={styles.warningIcon}>
              <i aria-hidden="true" className="material-icons">
                warning
              </i>
            </span>
            <span> - Check entries or Top up required</span>
          </p>
        ):null}
      </div>
    );
  }
}
RecaptureReadingsFieldArray.propTypes = propTypes;

export default connect((state, props) => ({
  fieldValue: (field) => formValueSelector(props.meta.form)(state, field),
}))(RecaptureReadingsFieldArray);
