import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Label, Collapse } from 'reactstrap';
import { isCompleted } from 'util/JobStateHelper';
import ClientDetailsSectionContainer from 'components/jobdetails/ClientDetailsSectionContainer';
import { Band, ToggleButton } from 'components/layout';

const propTypes = {
  client: PropTypes.object
};

class JobClientSummary extends Component {
  state = {
    collapsed: isCompleted(this.props.currentState) ? false : true
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  render() {
    const { clientDetails } = this.props;
    const { collapsed } = this.state;
    if (!clientDetails) return false;
    return (
      <Band>
        <Band className="py-3">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <Label htmlFor="client">Client</Label>
                <input
                  className="form-control-plaintext"
                  placeholder="No value"
                  type="text"
                  value={clientDetails.name}
                  readOnly
                />
              </Col>
              <Col xs="4" className="text-right">
                <ToggleButton
                  color="light"
                  onClick={this.toggleExpand}
                  active={collapsed}
                  ligature="expand_less"
                />
              </Col>
            </Row>
          </Container>
        </Band>
        <Collapse isOpen={!this.state.collapsed}>
          <ClientDetailsSectionContainer />
        </Collapse>
      </Band>
    );
  }
}

JobClientSummary.propTypes = propTypes;

export default JobClientSummary;
