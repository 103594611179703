import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getJobTypes() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getJobTypes()
      .then(
        response => dispatch(getJobTypesSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getJobTypesSuccess(response) {
  return {
    type: types.GET_JOB_TYPES_SUCCESS,
    jobTypes: response.data || []
  };
}
