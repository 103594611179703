import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as jobSummaryActions from 'actions/jobSummaryActions';
import { getDefinitionForWorkflow } from 'selectors/jobTableDefinitionSelector';
import JobTable from './JobTable';

function mapStateToProps(state, ownProps) {
  return {
    jobSummaries: state.jobSummaries,
    jobTableDefinition: getDefinitionForWorkflow(state, ownProps)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobSummaryActions
      },
      dispatch
    )
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobTable)
);
