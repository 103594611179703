import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function jobTableDefinitionsReducer(
  state = initialState.referenceData.jobTableDefinitions,
  action
) {
  if (action.type === types.GET_JOB_TABLE_DEFINITION_SUCCESS) {
    return [...action.jobTableDefinitions];
  }
  return state;
}
