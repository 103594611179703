import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  getTechnicians,
  getCanEditJob,
  getJobType,
  getTreatmentType,
  getCalculatedMbkg,
  getCurrentJob,
  getTreatmentTypeDetails,
  getCovered,
} from '../selectors';

import TreatmentResultRecordsStartDetailsSection from './TreatmentResultRecordsStartDetailsSection';

export default compose(
  connect((state) => ({
    technicians: getTechnicians(state),
    canEditJob: getCanEditJob(state),
    currentJob: getCurrentJob(state),
    jobType: getJobType(state),
    treatmentType: getTreatmentType(state),
    calculatedMbkg: getCalculatedMbkg(state),
    treatmentTypeDetails: getTreatmentTypeDetails(state),
    covered:getCovered(state),
  })),
)(TreatmentResultRecordsStartDetailsSection);
