import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';

import ResponsiveModal from './ResponsiveModal';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
  onSubmit: PropTypes.func,
  additionalClasses: PropTypes.string,
};

const AppModal = ({
  title,
  isOpen,
  toggle,
  size,
  additionalClasses,
  children,
}) => {
  return (
    <ResponsiveModal
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      className={`app-modal ${additionalClasses}`}
    >
      <ModalHeader toggle={toggle}>{title || ''}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ResponsiveModal>
  );
};

AppModal.propTypes = propTypes;

export default AppModal;
