import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.object.isRequired,
  onDelete: PropTypes.func
};

const ImageAttachment = ({ input }) => {
  const image = input.value;

  const handleDelete = image => {
    input.onChange({
      ...image,
      removed: true
    });
  };

  return (
    <ul className="list-unstyled">
      <li className="media my-2 app-document">
        <img className="mr-2" src={image.url} alt={image.name} />
        <div className="media-body">
          <i
            className="app-document-delete"
            onClick={() => handleDelete(image)}
          >
            x
          </i>
        </div>
      </li>
    </ul>
  );
};

ImageAttachment.propTypes = propTypes;

export default ImageAttachment;
