export const getAreaDisplayNames = (areaIds, areas) => {
  return areaIds.map((o, i) =>
    getAreaDisplayName(o, areas, i === areaIds.length - 1)
  );
};

export const getAreaDisplayName = (areaId, areas, isLast = true) => {
  var area = areas.find(a => {
    return a.id === areaId;
  });

  return !area ? '' : area.displayName + (!isLast ? ', ' : '');
};
