import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Container, Button, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Logger from 'util/Logger';
import { Band } from 'components/layout';
import VesselDetailsSection from './VesselDetailsSection';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func,
};

class VesselForm extends Component {
  render() {
    const { handleSubmit, isSubmitting } = this.props;
    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <Band tint>
          <VesselDetailsSection />
        </Band>
        <Container>
          <Button color="primary" className="btn-save" disabled={isSubmitting}>
            Save
          </Button>
        </Container>
      </Form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    areas: state.referenceData.areas,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'vesselForm' })(VesselForm));
