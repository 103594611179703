import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ShipmentDetailsSection from './ShipmentDetailsSection';
import { formValueSelector } from 'redux-form';
import { jobDetailsForm } from '../../constants/FormConstants';
import { isWaterBlasting } from '../../util/TreatmentTypeHelper';

function mapStateToProps(state, props) {
  const { currentJob } = state;
  const { jobLocations, updatedArea } = props;
  const clientCode = currentJob ? currentJob.clientCode : null;
  const formValues = formValueSelector(jobDetailsForm);
  return {
    ...state.referenceData,
    clientCode: formValues(state, 'clientCode') || clientCode,
    jobLocations: jobLocations,
    updatedArea: updatedArea,
    currentJob: currentJob,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ShipmentDetailsSection);
