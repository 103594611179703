import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions';

export function getLocations() {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getLocations()
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}

export function createNewLocation(location) {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .createNewLocation(location)
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}

export function updateLocation(location) {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .updateLocation(location)
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}
