import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label, FormFeedback } from 'reactstrap';

const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func
};

const RadioOption = ({ name, value, label, checked, disabled, onChange }) => (
  <label
    className={classNames('custom-control', 'custom-radio', {
      'custom-radio-disabled': disabled
    })}
  >
    <input
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      className="custom-control-input"
    />
    <span className="custom-control-indicator" />
    <span className="custom-control-description">{label}</span>
  </label>
);

RadioOption.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.input.value
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.input.value
    });
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState(
      {
        value
      },
      () => {
        this.props.input.onChange(value);
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      }
    );
  }

  render() {
    const {
      input,
      label,
      options,
      valueKey = 'id',
      labelKey = 'displayName',
      disabledKey = 'disabled',
      meta: { error }
    } = this.props;

    return (
      <div>
        <Label for={input.name}>{label}</Label>
        {options.map(option => (
          <RadioOption
            key={option[valueKey]}
            name={input.name}
            value={option[valueKey]}
            label={option[labelKey]}
            checked={this.state.value === option[valueKey]}
            disabled={option[disabledKey]}
            onChange={this.handleChange}
          />
        ))}
        <FormFeedback>{error}</FormFeedback>
      </div>
    );
  }
}

RadioGroup.propTypes = propTypes;

export default RadioGroup;
