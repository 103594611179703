import { connect } from 'react-redux';
import {
  getImportJobTypes,
  getExportJobTypes
} from 'selectors/jobTypeSelectors';
import JobChanger from './JobChanger';

function mapStateToProps(state, ownProps) {
  if (!ownProps.currentJob) return null;

  //currentJob.type.category
  const currentTypeId = ownProps.currentJob.type.id;
  const category = ownProps.currentJob.type.category;
  let categoryType;
  let jobTypes;

  if (category === 10) categoryType = 'import';
  else if (category === 20) categoryType = 'export';
  else return null;

  if (categoryType === 'import') jobTypes = getImportJobTypes(state);
  else if (categoryType === 'export') jobTypes = getExportJobTypes(state);
  else return null;

  return {
    handleChange: ownProps.handleChange,
    currentTypeId: currentTypeId,
    currentCategory: categoryType,
    jobTypes: jobTypes
  };
}

export default connect(mapStateToProps)(JobChanger);
