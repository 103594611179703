import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, change, formValues,formValueSelector } from 'redux-form';
import { Row, Col } from 'reactstrap';

import {
  treatmentResultRecordsForm,
  treatmentResultRecordsMonitoringForm,
} from '../../../constants/FormConstants';
import * as CalculationUtils from '../../../util/CalculationUtils';
import * as Validators from '../../../util/Validators';
import { minZero } from '../../../util/NormalizingHelper';

import RenderField from '../../fields/RenderField';
import { isBreakBulkJobType, isExportAndLogs, isMBLogsInHold,isImportOrExportAndContainer,isImportOrExportContainerAndMethylBromide } from '../../../util/JobTypeHelper';
import { isMethylBromide } from '../../../util/TreatmentTypeHelper';

const propTypes = {
  currentJob: PropTypes.object,
  currentLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeFieldValue: PropTypes.func.isRequired,
  mbTopUp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  jas: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

class DimensionTreatmentResultRecordsFields extends Component {
  componentWillReceiveProps(nextProps) {
    const { currentJob, changeFieldValue,jobType,isCoveredContainer } = this.props;
    let mbKg ;
    var coveredVolume ;
    coveredVolume = CalculationUtils.calculateCoveredVolume(
      nextProps.currentLength,
      nextProps.currentWidth,
      nextProps.currentHeight,
    );
    if(isExportAndLogs(jobType)||isBreakBulkJobType(jobType)){
      return     changeFieldValue(
        treatmentResultRecordsForm,
        'coveredVolume',
        coveredVolume,
      );
    }
    if(isCoveredContainer){
      return     changeFieldValue(
        treatmentResultRecordsForm,
        'jas',
        coveredVolume,
      );
    }
    if(isMBLogsInHold(jobType)){
      coveredVolume = 0;
    }
    let calculatedMbkg ;
   /* if(currentMbkg == undefined || currentMbkg === null){
      calculatedMbkg = CalculationUtils.calculateDosageKgBasedOnVolume(
      coveredVolume,
      currentJob,
      currentMbkg,
    );
     } */
    changeFieldValue(
      treatmentResultRecordsForm,
      'coveredVolume',
      coveredVolume,
    );
    /*
    changeFieldValue(
      treatmentResultRecordsForm,
      'calculatedMbkg',
      calculatedMbkg,
    );
    mbKg = CalculationUtils.calculateTotalMbKgFrom(
      calculatedMbkg,
      nextProps.mbTopUp,
    ); */
    if(isMBLogsInHold(jobType)){
      var dosage= 1;
      if(currentJob.treatmentDetails.dosageRate !== undefined){
        dosage = currentJob.treatmentDetails.dosageRate;
      }
          mbKg = CalculationUtils.calculateMBkgLogsInHold(nextProps.jas,dosage);        
         /* changeFieldValue(
            treatmentResultRecordsForm,
            'calculatedMbkg',
            mbKg,
          );*/
/*
          if(currentMbkg!=undefined || currentMbkg != null){
            changeFieldValue(
              treatmentResultRecordsForm,
              'calculatedMbkg',
              currentMbkg,
            );
          }*/
    }


        
    //changeFieldValue(treatmentResultRecordsForm, 'mbkg', mbKg);
   // changeFieldValue(treatmentResultRecordsMonitoringForm, 'mbkg', mbKg);
   
  }

  render() {
  const {jobType,isCoveredContainer} = this.props;
    return (
      <Row>
        {!isMBLogsInHold(jobType) &&  (!isImportOrExportAndContainer(jobType)  ||isCoveredContainer)?
        <Col md="4">
          <Field
            name="length"
            label="Length"
            component={RenderField}
            type="number"
            placeholder="Enter a Length"
            validate={Validators.number}
            normalize={minZero}
          />
        </Col> :null}
        {!isMBLogsInHold(jobType)&&  (!isImportOrExportAndContainer(jobType) || isCoveredContainer)?
        <Col md="4">
          <Field
            name="width"
            label="Width"
            component={RenderField}
            type="number"
            placeholder="Enter a Width"
            validate={Validators.number}
            normalize={minZero}
          />
        </Col> :null}
        {!isMBLogsInHold(jobType)&& (!isImportOrExportAndContainer(jobType) || isCoveredContainer)?
        <Col md="4">
          <Field
            name="height"
            label="Height"
            component={RenderField}
            type="number"
            placeholder="Enter a Height"
            validate={Validators.number}
            normalize={minZero}
          />
        </Col>:null}
      
      </Row>
    );
  }
}
DimensionTreatmentResultRecordsFields.propTypes = propTypes;

function mapStateToProps(state) {
  const formValues = getFormValues(treatmentResultRecordsForm)(state) || {};
  return {
    currentLength: formValues.length,
    currentWidth: formValues.width,
    currentHeight: formValues.height,
    mbTopUp: formValues.mbTopUp,
    currentJob: state.currentJob,
    jas:formValues.jas,
    currentMbkg:formValues.mbkg,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeFieldValue: function (form, field, value) {
      dispatch(change(form, field, value));
    },
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DimensionTreatmentResultRecordsFields,
);
