import React from 'react';
import { Button } from 'reactstrap';
import Printer from 'components/common/Printer';

import LogRowsSummarySheetF15Container from './LogRowsSummarySheetF15Container';

const TreatmentResultPrint = () => (
  <Printer title="Log Rows Summary Sheet F15">
    <Button color="light" className="mt-3">
      <i className="material-icons ml-1">print</i>
      Print
    </Button>
    <LogRowsSummarySheetF15Container />
  </Printer>
);

export default TreatmentResultPrint;
