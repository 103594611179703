import JobTypeIds from 'constants/JobTypeIdConstants';
import JobTypeCategories from 'constants/JobTypeCategories';
import { getEnumDisplayValue } from 'util/EnumHelper';
import {
  isPhosphine,
  isThermoFog,
  isWaterBlasting,
  isGasFree,
  isPestigas,
} from 'util/TreatmentTypeHelper';
import {
  isAlPVessel,
  isMethylBromide,
  isVaporPhos,
} from './TreatmentTypeHelper';

export const isImportJobType = (jobType) =>
  jobType.category === JobTypeCategories.import;

export const isExportJobType = (jobType) =>
  jobType.category === JobTypeCategories.export;

export const isImportAndHeatTreatment = (jobType) =>
  jobType.id === JobTypeIds.importAndHeatTreatment;

export const isMBLogsInHold = (jobType) => jobType.id === JobTypeIds.logsInHold;

export const isExportAndLogs = (jobType) =>
  jobType.id === JobTypeIds.exportAndLogs;

export const isExportAndBreakBulk = (jobType) =>
  jobType.id === JobTypeIds.exportAndBreakBulk;

export const isImportAndBreakBulk = (jobType) =>
  jobType.id === JobTypeIds.importAndBreakBulk;

export const isBreakBulkOrExportOfLogsAndMethylBromide = (
  jobType,
  treatmentTypeId,
) =>
  (isExportAndLogs(jobType) ||
    isExportAndBreakBulk(jobType) ||
    isImportAndBreakBulk(jobType)) &&
  isMethylBromide(treatmentTypeId);

export const isExportAndLogsAndPhosphine = (jobType, treatmentTypeId) =>
  isExportAndLogs(jobType) && isPhosphine(treatmentTypeId);

export const isOtherAndThermofog = (jobType, treatmentTypeId) =>
  isOtherJobType(jobType) && isThermoFog(treatmentTypeId);

export const isImportAndContainer = (jobType) =>
  jobType.id === JobTypeIds.importAndContainer;

export const isExportAndContainer = (jobType) =>
  jobType.id === JobTypeIds.exportAndContainer;

export const isImportOrExportAndContainer = (jobType) =>
  jobType.id === JobTypeIds.importAndContainer ||
  jobType.id === JobTypeIds.exportAndContainer;

  
  export const isImportOrExportContainerAndMethylBromide = (
    jobType,
    treatmentTypeId,
  ) =>
    (isImportOrExportAndContainer(jobType) &&
    isMethylBromide(treatmentTypeId));

export const isBreakBulkJobType = (jobType) =>
  jobType.id === JobTypeIds.importAndBreakBulk ||
  jobType.id === JobTypeIds.exportAndBreakBulk;

export const isOtherJobType = (jobType) =>
  jobType.id === JobTypeIds.importOther ||
  jobType.id === JobTypeIds.exportOther;

export const isOtherAndWaterBlasting = (jobType, treatmentTypeId) =>
  isOtherJobType(jobType) && isWaterBlasting(treatmentTypeId);

export const isImportOtherAndGasFree = (jobType, treatmentTypeId) =>
  isImportOther(jobType) && isGasFree(treatmentTypeId);

export const isExportOther = (jobType) => jobType.id === JobTypeIds.exportOther;
export const isImportOther = (jobType) => jobType.id === JobTypeIds.importOther;
export const isImportOtherTypeID = (jobTypeID) =>
  jobTypeID === JobTypeIds.importOther;

export const isExportAndLogsOrBreakBulk = (jobType) =>
  isExportAndLogs(jobType) || isBreakBulkJobType(jobType);

export const isPestigasContainer = (jobType, treatmentTypeId) =>
  isImportOrExportAndContainer(jobType) && isPestigas(treatmentTypeId);

export const isExportAndContainerAndPestigas = (jobType, treatmentTypeId) =>
  isExportAndContainer(jobType) && isPestigas(treatmentTypeId);

export const isContainerOrBreakBulkOrOther = (jobType) =>
  isImportOrExportAndContainer(jobType) ||
  isBreakBulkJobType(jobType) ||
  isOtherJobType(jobType);

export const getCategoryDisplayName = (categoryId) =>
  getEnumDisplayValue(JobTypeCategories, categoryId);

export const isTechnicianRequired = (startDate) => startDate !== undefined;

export const isExportAndLogsAndALPVessel = (jobType, treatmentTypeId) =>
  isExportAndLogs(jobType) && isAlPVessel(treatmentTypeId);

export const isExportAndLogsAndVaporPhos = (jobType, treatmentTypeId) =>
  isExportAndLogs(jobType) && isVaporPhos(treatmentTypeId);
  
//StandardId = Japan
  export const isExportOtherMBApples =(jobType,treatmentTypeId,commodity,standardId) =>
  isExportOther(jobType) && isMethylBromide(treatmentTypeId) && commodity.includes('Fresh Fruit') && standardId==='7361b445-7de6-426d-a69a-f3f9006cc60a'
