import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';
import { roundToPrevious } from '../../util/DateHelper';
import { withIsTouch } from '../../hoc/media';

const propTypes = {
  input: PropTypes.object,
  minDate: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  showTimeSelect: PropTypes.bool,
  defaultToEmpty: PropTypes.bool,
  timeIntervals: PropTypes.number,
  isTouch: PropTypes.bool,
};

// Note: This avoids virtual keyboard to popup on touch devices
class TouchDatePickerInput extends Component {
  render() {
    return <input type="text" {...this.props} readOnly />;
  }
}

const DatePickerField = ({
  input,
  label,
  minDate,
  placeholder,
  type,
  className,
  showTimeSelect,
  defaultToEmpty,
  timeIntervals,
  meta: { touched, error, warning },
  isTouch,
}) => {
  let invalidClass = classNames({
    'is-invalid': touched && error,
  });

  const setSelectedValue = (value, showTimeSelect) => {
    const todaysDate = showTimeSelect
      ? roundToPrevious(moment().startOf('minute'))
      : moment().startOf('day');

    if (moment(value).isValid()) {
      return moment(value);
    } else {
      return defaultToEmpty ? null : todaysDate;
    }
  };

  return (
    <div>
      <Label for={input.name}>{label}</Label>
      <DatePicker
        minDate={minDate}
        placeholderText={placeholder}
        dateFormat={showTimeSelect ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
        selected={setSelectedValue(input.value, showTimeSelect)}
        showTimeSelect={showTimeSelect}
        timeFormat={"HH:mm"}
        timeIntervals={timeIntervals || 10}
        onChange={input.onChange}
        onChangeRaw={input.onChange}
        className={classNames('form-control', invalidClass)}
        withPortal={isTouch}
        customInput={isTouch ? <TouchDatePickerInput /> : undefined}
       // utcOffset={0}
      />
      <FormFeedback>{error}</FormFeedback>
    </div>
  );
};

DatePickerField.propTypes = propTypes;

export default withIsTouch(DatePickerField);
