import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'reactstrap';
import RenderField from 'components/fields/RenderField';
import { nicelyRoundUp } from 'util/FormattingHelper';
import { isMBLogsInHold,isImportOrExportAndContainer,isImportOrExportContainerAndMethylBromide } from '../../../util/JobTypeHelper';
import { isMethylBromide } from '../../../util/TreatmentTypeHelper';

class CalculatedTreatmentResultRecordsFields extends Component {
  render() {
    let isBreakBulk = this.props;
    const { jobType, treatmentType,isCoveredContainer } = this.props;
    const isMB = isMethylBromide(treatmentType);
    return (
      <Row>
        {!isMBLogsInHold(jobType)&& (!isImportOrExportContainerAndMethylBromide(jobType,treatmentType)||isCoveredContainer) ? (
          <Col md="6">
            <Field
              name="coveredVolume"
              label="Covered Volume"
              component={RenderField}
              readOnly
              format={nicelyRoundUp}
            />
          </Col>
        ) : null}
  
      </Row>
    );
  }
}

export default CalculatedTreatmentResultRecordsFields;
