import React from 'react';
import TextFormatter from 'components/table/formatters/TextFormatter';
import DateFormatter from 'components/table/formatters/DateFormatter';
import IconAndTextFormatter from 'components/table/formatters/IconAndTextFormatter';
import OrderNumberTextFormatter from 'components/table/formatters/OrderNumberTextFormatter';
import CheckboxFormatter from 'components/table/formatters/CheckboxFormatter';

class TableFormatterService {
  static formatters = {
    Text: (cellData, rowData, func) => <TextFormatter value={cellData} />,
    IconAndText: (cellData, rowData) => <IconAndTextFormatter {...cellData} />,
    Date: (cellData, rowData, func) => <DateFormatter value={cellData} />,
    OrderNumberText: (cellData, rowData, func) => (
      <OrderNumberTextFormatter value={cellData} />
    ),
    Check: (cellData, rowData, func) => (
      <CheckboxFormatter value={cellData} rowData={rowData} onClick={func} />
    )
  };

  static getCellDataFormatter(formatter, cell, row, func) {
    if (!formatter) return false;
    const matchedFormatter = this.formatters[formatter];
    return matchedFormatter ? matchedFormatter(cell, row, func) : null;
  }
}

export default TableFormatterService;
