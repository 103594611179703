import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function destinationsReducer(
  state = initialState.referenceData.destinations,
  action
) {
  if (action.type === types.GET_DESTINATIONS_SUCCESS) {
    return [...action.destinations];
  }
  return state;
}
