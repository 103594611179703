import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getClientDetails(code) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.getClientDetails(code).then(
      (response) => dispatch(getClientDetailsSuccess(response.data)),
      () => dispatch(ajaxCallError()),
    );
  };
}

function getClientDetailsSuccess(data) {
  return {
    type: types.GET_CLIENT_DETAILS_SUCCESS,
    clientDetails: data,
  };
}

export function clearClientDetails() {
  return {
    type: types.CLEAR_CLIENT_DETAILS,
  };
}
