import { connect } from 'react-redux';
import JobMasthead from './JobMasthead';
import { getReferenceDataRecord } from 'selectors/referenceDataSelectors';

function mapStateToProps(state) {
  const { currentJob } = state;
  return {
    linkedJobs: currentJob.linkedJobs,
    duplicatedFromJob: currentJob.duplicatedFromJob,
    currentState: currentJob.currentState,
    jobReference: currentJob.jobReference,
    eralisJobNumber: currentJob.eralisJobNumber,
    technician: getReferenceDataRecord(
      state,
      'technicians',
      'id',
      currentJob.technicianId,
      'JobMasthead.technician'
    )
  };
}

export default connect(mapStateToProps)(JobMasthead);
