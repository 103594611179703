import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm,getFormValues,change } from 'redux-form';
import ResendNoticeCompletionForm from './ResendNoticeCompletionForm';
import { resendNoticeCompletionForm } from 'constants/FormConstants';
import {
  getContactsByAreaId,
  getDefaultContactsByNoticeKey,
} from 'selectors/formSelectors';
import { jobCompletionForm } from 'constants/FormConstants';

function mapStateToProps(state, props) {
  const { currentJob } = state;
  const { noticeKey, formFieldKey } = props;
  const areaId = currentJob.areaId;
  const jobState = currentJob.currentState;
  const initialValues = getInitialValuesByNoticeKey(
    state,
    noticeKey,
    formFieldKey
  );

  return {
    contacts: getContactsByAreaId(state, areaId),
    formValues: getFormValues(jobCompletionForm)(state),
    initialValues,jobState
  };
}

function getInitialValuesByNoticeKey(state, noticeKey, formFieldKey) {
  const areaId = state.currentJob.areaId;
  let initialValues = { ...state.currentJob };
  initialValues[formFieldKey] = {
    contactIds: getDefaultContactsByNoticeKey(state, areaId, noticeKey),
    sendNotice: true
  };
  return initialValues;
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: resendNoticeCompletionForm })
)(ResendNoticeCompletionForm);
