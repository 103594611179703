import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions';

export function getTechnicians() {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getTechnicians()
      .then(
        response => dispatch(getTechniciansSuccess(response.data)),
        error => dispatch(ajaxCallError())
      );
  };
}

export function createNewTechnician(technician) {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .createNewTechnician(technician)
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}

export function updateTechnician(technician) {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return operationsApi
      .updateTechnician(technician)
      .then(response => response, error => dispatch(ajaxCallError()));
  };
}

function getTechniciansSuccess(technicians) {
  return {
    type: types.GET_TECHNICIANS_SUCCESS,
    technicians: technicians || []
  };
}
