import React from 'react';

const OrderNumberTextFormatter = ({ value }) => (
  <span>
    {value && value !== 'null' && value !== 'undefined' ? (
      value
    ) : (
      <span className="emptydate">-</span>
    )}
  </span>
);

export default OrderNumberTextFormatter;
