import { connect } from 'react-redux';
import { compose } from 'redux';
import TreatmentResultsSummary from './TreatmentResultsSummary';

function mapStateToProps(state) {
  const { currentJob } = state;

  return {
    currentJob: currentJob,
    showSummary: currentJob.treatmentResultRecords.length !== 0,
    treatmentResultsSummary: currentJob.treatmentResultsSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TreatmentResultsSummary
);
