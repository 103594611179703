import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { Band } from 'components/layout';
import JobCompletionForm from './JobCompletionForm';
import { updateJob, getNoticeOfCompletion } from 'api/operationsApi';
import PreviewEmailModal from 'components/common/PreviewEmailModal';
import { isInProgress } from 'util/JobStateHelper';
import Logger from 'util/Logger';
import { isHydrogenCyanide } from '../../../util/TreatmentTypeHelper';

const propTypes = {
  technicians: PropTypes.array
};

class JobCompletion extends Component {
  state = {
    collapsed: false,
    showPreviewEmail: false,
    noticeOfCompletionEmail: ''
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  togglePreviewEmail = () => {
    this.setState(prevState => ({
      showPreviewEmail: !prevState.showPreviewEmail
    }));
  };

  getNoticeOfCompletion = () => {
    const { formValues, currentJob } = this.props;
    const request = { ...currentJob, ...formValues };
    return getNoticeOfCompletion(request).then(response => {
      this.setState({
        noticeOfCompletionEmail: response.data,
        showPreviewEmail: true
      });
    });
  };

/*
  sendNoticeOfCompletion = formValues => {
    const { currentJob, history } = this.props;
    const request = { ...currentJob, ...formValues };
    updateJob(request).then(() => {
      Logger.success('Notice of Completed Treatment sent');
      history.push('/dashboard/in-progress');
    });
  };*/
  sendNoticeOfCompletion = formValues => {
    const { currentJob, history } = this.props;
    const request = { ...currentJob, ...formValues };
    updateJob(request).then(() => {
      Logger.success('Notice of Completed Treatment sent');
      history.push('/dashboard/noc sent');
    });
  };

  render() {
    const {
      currentJob,
      contacts,
      initialValues,
      jobId,
      currentState
    } = this.props;
    const { collapsed, showPreviewEmail, noticeOfCompletionEmail } = this.state;
    if (isInProgress(currentState)) {
      return (
        <Band>
          <div className="form-section">
            <Container>
              <Row className="align-items-center">
                <Col xs="8">
                {!isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId) ?  <h4>Notice of Completion</h4>  : <h4>Confirm Completion</h4>}
                 </Col>
              </Row>
              <Collapse isOpen={!collapsed}>
                <JobCompletionForm
                  currentJob={currentJob}
                  contacts={contacts}
                  initialValues={initialValues}
                  onSubmit={this.sendNoticeOfCompletion}
                  onPreview={this.getNoticeOfCompletion}
                />
              </Collapse>
            </Container>
            <PreviewEmailModal
              title="Preview Notice of Completion"
              isOpen={showPreviewEmail}
              toggle={this.togglePreviewEmail}
              jobId={jobId}
              emailContent={noticeOfCompletionEmail}
            />
          </div>
        </Band>
      );
    }
    return null;
  }
}

JobCompletion.propTypes = propTypes;

export default JobCompletion;
