import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row, Collapse } from 'reactstrap';

import { isVerified } from '../../../util/JobStateHelper';
import {
  isExportAndLogs,
  isExportAndLogsAndALPVessel,
  isExportOtherMBApples,
  isImportOrExportAndContainer,
  isImportOrExportContainerAndMethylBromide
} from '../../../util/JobTypeHelper';

import { Band, ToggleButton } from '../../../components/layout';
import Checkbubble from '../../../components/common/Checkbubble';

import TreatmentResultRecordsListContainer from './TreatmentResultRecordsListContainer';
import TreatmentResultSummaryContainer from './TreatmentResultSummaryContainer';
import TreatmentResultPrintContainer from './TreatmentResultPrintContainer';
import TreatmentResultMarkingsFormContainer from './TreatmentResultMarkingsFormContainer';
import {isEthylFormate, isMethylBromide } from '../../../util/TreatmentTypeHelper';
import { userIsCustomer } from '../../../util/AdGroupsHelper';

const propTypes = {
  currentState: PropTypes.string,
  complete: PropTypes.bool,
  jobType: PropTypes.object,
};

class TreatmentResultRecordsSection extends Component {
  state = {
    collapsed: isVerified(this.props.currentState)
      ? false
      : this.props.complete,
  };

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.complete && nextProps.complete) ||
      this.props.currentState !== nextProps.currentState
    ) {
      const nextCollapsed = isVerified(nextProps.currentState)
        ? false
        : nextProps.complete;
      if (nextCollapsed) {
        this.setState({
          collapsed: true,
        });
      }
    }
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { collapsed } = this.state;
    const { complete, jobType, treatmentDetails,commodity,standardId } = this.props;
    const treatmentId = treatmentDetails.treatmentTypeId;

    return (
      <Band dark>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>
                  {isEthylFormate(treatmentId)?"Ethyl Formate Record": isExportAndLogs(jobType)
                    ? 'Log Row Records'
                    : jobType.name === 'MB Logs In Hold'
                    ? 'Logs In Hold'
                    :isExportOtherMBApples(jobType,treatmentId,commodity,standardId)?
                    'Concentration Sheet Apples Japan'
                    :isImportOrExportContainerAndMethylBromide(jobType,treatmentId)?"Container Records"
                    : 'Breakbulk Records'}
                </h4>
              </Col>
              <Col xs="4" className="text-right">
                <Checkbubble
                  className="align-middle"
                  checked={Boolean(complete)}
                />
                <ToggleButton
                  className="ml-3"
                  color="light"
                  active={Boolean(collapsed)}
                  onClick={this.toggleExpand}
                  ligature="expand_less"
                />
              </Col>
            </Row>
            <Collapse isOpen={!collapsed}>
              <TreatmentResultRecordsListContainer />
              <TreatmentResultSummaryContainer />
              {!isExportAndLogs(jobType) && (
                <TreatmentResultMarkingsFormContainer
                  onCollapse={this.toggleExpand}
                />
              )}
              {userIsCustomer()?null:
              <TreatmentResultPrintContainer />}
            </Collapse>
          </Container>
        </div>
      </Band>
    );
  }
}

TreatmentResultRecordsSection.propTypes = propTypes;

export default TreatmentResultRecordsSection;
