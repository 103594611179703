import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function clientDetailsReducer(
  state = initialState.clientDetails,
  action
) {
  if (action.type === types.GET_CLIENT_DETAILS_SUCCESS) {
    return action.clientDetails;
  }
  if (action.type === types.CLEAR_CLIENT_DETAILS) {
    return null;
  }
  return state;
}
