import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ResendNoticesForm from './ResendNoticesForm';
import { resendNoticesForm } from 'constants/FormConstants';
import {
  getContactsByAreaId,
  getDefaultContactsByNoticeKey
} from 'selectors/formSelectors';

function mapStateToProps(state, props) {
  const { currentJob } = state;
  const { noticeKey, formFieldKey } = props;
  const areaId = currentJob.areaId;
  const jobState = currentJob.currentState;
  const initialValues = getInitialValuesByNoticeKey(
    state,
    noticeKey,
    formFieldKey
  );

  return {
    contacts: getContactsByAreaId(state, areaId),
    initialValues,jobState
  };
}

function getInitialValuesByNoticeKey(state, noticeKey, formFieldKey) {
  const areaId = state.currentJob.areaId;
  let initialValues = { ...state.currentJob };
  initialValues[formFieldKey] = {
    contactIds: getDefaultContactsByNoticeKey(state, areaId, noticeKey),
    sendNotice: true
  };
  return initialValues;
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: resendNoticesForm })
)(ResendNoticesForm);
