import pace from 'pace';

export default function() {
  pace.start({
    ajax: {
      trackMethods: ['GET', 'POST', 'PUT', 'DELETE', 'REMOVE']
    },
    restartOnRequestAfter: true
  });
}
