import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';

const propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  createdDate: PropTypes.string,
  createdBy: PropTypes.string,
  onClick: PropTypes.func,
  ligature: PropTypes.string
};

const AttachmentLink = ({
  value,
  label,
  createdDate,
  createdBy,
  onClick,
  ligature
}) => {
  const handleClick = event => {
    event.preventDefault();
    onClick(value);
  };

  return (
    <ListGroupItem action onClick={handleClick}>
      <div className="media app-attachment">
        <i className="material-icons app-attachment-icon">{ligature}</i>
        <span className="media-body">
          <span className="app-attachment-title">
            {label} - {createdDate}
          </span>
          <span className="app-attachment-detail">{createdBy}</span>
        </span>
      </div>
    </ListGroupItem>
  );
};

AttachmentLink.propTypes = propTypes;

export default AttachmentLink;
