import sweetalert from 'sweetalert';

export function alert(title, text, confirmButtonText = 'OK') {
  return sweetalert({
    title: title,
    text: text,
    icon: 'warning',
    buttons: [confirmButtonText, false],
    dangerMode: true
  });
}

export function confirmAlert(
  title,
  text,
  confirmButtonText = 'OK',
  cancelButtonText = 'Cancel'
) {
  return sweetalert({
    title: title,
    text: text,
    icon: 'warning',
    buttons: [cancelButtonText, confirmButtonText],
    dangerMode: true
  });
}

export function inputAlert(text) {
  return sweetalert({
    text: text,
    content: 'input',
    closeModal: false,
    showCancelButton: false,
    closeOnClickOutside: false
  });
}
