import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  wrapClassName: PropTypes.string,
  children: PropTypes.object
};

const OverlayModal = ({ title, isOpen, toggle, wrapClassName, children }) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle} wrapClassName={wrapClassName}>
      <ModalHeader toggle={toggle}>{title || ''}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  </div>
);

OverlayModal.propTypes = propTypes;

export default OverlayModal;
