import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as clientSummariesActions from 'actions/clientSummariesActions.js';
import ClientListPage from 'components/clients/ClientListPage';

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...clientSummariesActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientListPage);
