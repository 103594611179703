import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { getFormValues, change } from 'redux-form';
import JobSignoff from './JobSignoff';
import { getTechniciansByAreaId } from 'selectors/technicianSelectors';
import { jobSignoffForm } from 'constants/FormConstants';
import moment from 'moment';

function mapStateToProps(state, ownProps) {
  const { currentJob } = state;
  const signoffDate = moment().toISOString();
  return {
    clientDetails: state.clientDetails,
    currentJob,
    currentState: currentJob.currentState,
    referenceData: state.referenceData,
    supervisorSignoff: currentJob.supervisorSignoff || {},
    initialValues: {
      signoffDate,
      complete: true,
    },
    technicians: getTechniciansByAreaId(state),
    formValues: getFormValues(jobSignoffForm)(state),
    commoditySel: getFormValues(state, 'treatmentTypeDetails.commodity'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change }, dispatch),
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(JobSignoff);
