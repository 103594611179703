import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function jobStandardsReducer(
  state = initialState.referenceData.jobStandards,
  action
) {
  if (action.type === types.GET_JOB_STANDARDS_SUCCESS) {
    return [...action.jobStandards];
  }
  return state;
}
