import { connect } from 'react-redux';
import {
  getImportJobTypes,
  getExportJobTypes
} from 'selectors/jobTypeSelectors';
import JobCreator from './JobCreator';

function mapStateToProps(state, ownProps) {
  return {
    jobTypes: {
      import: getImportJobTypes(state),
      export: getExportJobTypes(state)
    }
  };
}

export default connect(mapStateToProps)(JobCreator);
