import React from 'react';
import PropTypes from 'prop-types';
import AppModal from 'components/common/AppModal';
import JobCancelForm from './JobCancelForm';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

const JobCancelModal = ({ title, isOpen, toggle, onSubmit }) => (
  <AppModal title={title} isOpen={isOpen} toggle={toggle}>
    <JobCancelForm
      initialValues={{ cancellationOptions: 'SendToSupportOnly' }}
      onSubmit={onSubmit}
    />
  </AppModal>
);

JobCancelModal.propTypes = propTypes;

export default JobCancelModal;
