export default {
  columnDefinitions: [
    {
      key: 'code',
      name: 'Client Code',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false
    },
    {
      key: 'name',
      name: 'Client Name',
      formatter: 'Text',
      isHiddenViewportMediumAndBelow: false
    },
    {
      key: 'orderNumberMandatory',
      name: 'Order Number Mandatory',
      formatter: 'Check',
      width: '40',
      action: 'onClickOrderNumberMandatory',
      isHiddenViewportMediumAndBelow: false
    },  {
      key: 'isHidden',
      name: 'Hide Client',
      formatter: 'Check',
      width: '40',
      action: 'onClickIsHidden',
      isHiddenViewportMediumAndBelow: false
    }
  ]
};
