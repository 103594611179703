import React from 'react';
import PropTypes from 'prop-types';
import DocumentLink from 'components/common/DocumentLink';

const propTypes = {
  input: PropTypes.object,
  readOnly: PropTypes.bool
};

const DocumentField = ({ input, readOnly = false }) => {
  const document = input.value;

  const deleteDocument = value => {
    input.onChange({
      ...value,
      removed: true
    });
  };

  if (document.removed) return null;

  return (
    <li className="my-2">
      <DocumentLink
        name={document.name}
        id={document.id}
        readOnly={readOnly}
        onDelete={() => deleteDocument(document)}
      />
    </li>
  );
};

DocumentField.propTypes = propTypes;

export default DocumentField;
