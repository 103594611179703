import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  emailContent: PropTypes.string
};

class PreviewEmailFrame extends Component {
  iframeRef = null;

  componentDidMount() {
    const { emailContent } = this.props;
    this.writeHtmlToIframe(this.iframeRef, emailContent);
  }

  writeHtmlToIframe = (iframeRef, htmlContent) => {
    if (!iframeRef) return false;
    iframeRef.addEventListener('load', () => {
      iframeRef.contentWindow.document.documentElement.innerHTML = htmlContent;
    });
  };

  refCallback = iframeNode => {
    this.iframeRef = iframeNode;
  };

  render() {
    const { title } = this.props;
    return (
      <div>
        <iframe
          title={title}
          src="#"
          ref={iframeNode => (this.iframeRef = iframeNode)}
          frameBorder="0"
          height="800"
          width="100%"
        />
      </div>
    );
  }
}

PreviewEmailFrame.propTypes = propTypes;

export default PreviewEmailFrame;
