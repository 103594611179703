import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getClientSummaries() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getClientSummaries()
      .then(
        response => dispatch(getClientSummariesSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getClientSummariesSuccess(response) {
  return {
    type: types.GET_CLIENT_SUMMARIES_SUCCESS,
    clientSummaries: response.data || []
  };
}
