import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function contactsReducer(
  state = initialState.referenceData.contacts,
  action
) {
  switch (action.type) {
    case types.GET_CONTACTS_SUCCESS: {
      return action.contacts;
    }
    default:
      return state;
  }
}
