import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  reduxForm,
  change,
  formValueSelector,
  getFormValues,
} from 'redux-form';
import { withRouter } from 'react-router';
import moment from 'moment';

import Logger from '../../../util/Logger';
import { roundToPrevious } from '../../../util/DateHelper';
import * as jobActions from '../../../actions/jobActions';
import TreatmentResultsForm from '../../../components/jobs/treatmentResults/TreatmentResultsForm';
import { treatmentResultsForm } from '../../../constants/FormConstants';
import TreatmentTypeIds from '../../../constants/TreatmentTypeIds';
import {
  isExportOther,
  isImportAndHeatTreatment,
  isImportOtherAndGasFree,
  isImportOtherTypeID,
} from '../../../util/JobTypeHelper';

function calculateEndDate(treatmentResults, state) {
  const formValues = formValueSelector(treatmentResultsForm);
  const currentEndDate = formValues(state, 'endDate');
  const endDateHasValue = treatmentResults && treatmentResults.endDate;
  let calculatedEndDate;
  const defaultEndDate = isImportOtherAndGasFree(
    { id: state.currentJob.typeId },
    state.currentJob.treatmentDetails.treatmentTypeId,
  )
    ? null
    : null; // : roundToPrevious(moment().startOf('minute'), 5);

  if (endDateHasValue) {
    calculatedEndDate = treatmentResults.endDate;
  } else {
    calculatedEndDate = currentEndDate || defaultEndDate;
  }
  return calculatedEndDate;
}

function mapStateToProps(state) {
  const { currentJob } = state;
  const { technicians } = state.referenceData;
  const { currentState, treatmentDetails, treatmentResults } = currentJob;
  const { treatmentTypeId } = treatmentDetails;
  const formValues = formValueSelector(treatmentResultsForm);
  /*const endDate =
    currentState === 'assigned' || currentState === 'in progress'
      ? null
      : calculateEndDate(treatmentResults, state);*/
  const endDate =
    currentState === 'assigned'
      ? null
      : calculateEndDate(treatmentResults, state);
  const markings = currentJob.treatmentTypeDetails.markings || [];
  let mbBottles = [];
  if (currentJob.treatmentResults !== undefined) {
    mbBottles = currentJob.treatmentResults.mbBottles;
  }
  let mbJson = [];
  if (currentJob.treatmentResults !== undefined) {
    mbJson = currentJob.treatmentResults.mbBottles;
  }

  const existingTreatmentResults = {
    ...treatmentResults,
    treatmentTypeId,
    jobId: currentJob.id,
    jobTypeId: currentJob.typeId,
    endDate: endDate /*,
    ventingTechnicianId: ventingTechnicianId || currentJob.technicianId*/,
    markings,
    mbBottles,
    mbJson,
  };

  const defaultInitialValues = {
    jobId: currentJob.id,
    jobTypeId: currentJob.typeId,
    startDate: null, //roundToPrevious(moment().startOf('minute')),
    treatmentTypeId,
    endDate: endDate,
    fumigatingTechnicianId: isImportAndHeatTreatment({ id: currentJob.typeId })
      ? currentJob.technicianId
      : null,
    markings,
    mbBottles,
    mbJson,
  };
  //const treatmentId =
  //state.form.jobDetailsForm.values.treatmentDetails.treatmentTypeId
  //  ? state.form.jobDetailsForm.values.treatmentDetails.treatmentTypeId
  // : null;

  return {
    clientDetails: state.clientDetails,
    technicians,
    currentJob,
    mbTopUp: formValues(state, 'mbTopUp'),
    clientsVolume: formValues(state, 'clientsVolume'),
    initialValues: currentJob.treatmentResults
      ? existingTreatmentResults
      : defaultInitialValues,
    formValues: getFormValues(treatmentResultsForm)(state),
    markings,
    mbBottles,
    mbJson,
    initialDosage:formValues(state, 'initialDosage'),
    topupDosage:formValues(state, 'topupDosage'),
    //   treatmentId:
    //  formValues(state, 'treatmentDetails.treatmentTypeId') || treatmentId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...jobActions, changeFieldValue: change },
      dispatch,
    ),
  };
}

const validate = (values) => {
  const { numberOfSachets, actualTemperature, treatmentTypeId, jobTypeId } =
    values;
  const errors = {};

  if (
    treatmentTypeId === TreatmentTypeIds.MethylBromide &&
    !isExportOther({ id: jobTypeId })
  ) {
    if (numberOfSachets === '0' || !numberOfSachets) {
      errors.numberOfSachets = 'Required';
    }
    if (!isImportOtherTypeID(jobTypeId) && !actualTemperature) {
      errors.actualTemperature = 'Required';
    }
  }
  return errors;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: treatmentResultsForm,
    enableReinitialize: false,
    validate,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Treatment Details not saved',
      );
    },
  }),
)(TreatmentResultsForm);
