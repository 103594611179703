import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function areasReducer(
  state = initialState.referenceData.areas,
  action
) {
  if (action.type === types.GET_AREAS_SUCCESS) {
    return [...action.areas];
  }
  return state;
}
