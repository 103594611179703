import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getJobStateSummaries() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getJobStateSummaries()
      .then(
        response => dispatch(getJobStateSummariesSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getJobStateSummariesSuccess(response) {
  return {
    type: types.GET_JOB_STATE_SUMMARIES_SUCCESS,
    jobStateSummaries: response.data || []
  };
}
