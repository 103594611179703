import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form, FormGroup, Button } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import { required } from 'util/Validators';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func,
  contacts: PropTypes.array,
  formFieldKey: PropTypes.string,
  submitLabel: PropTypes.string
};

const ResendNoticesForm = ({
  handleSubmit,
  contacts,
  formFieldKey,
  submitLabel,
  jobState
}) => {
  return (
    <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
      <fieldset>
        <FormGroup className="col-12">
          <Field
            name={`${formFieldKey}.contactIds`}
            label="Recipients"
            component={SearchableSelect}
            placeholder="Select contacts"
            options={contacts}
            valueKey="id"
            labelKey="name"
            multi
            simpleValue
            validate={!jobState=='certified'?required:null}
          />
        </FormGroup>
        <FormGroup className="col-12 text-right">
          <Button color="primary">{submitLabel}</Button>
        </FormGroup>
      </fieldset>
    </Form>
  );
};

ResendNoticesForm.propTypes = propTypes;

export default ResendNoticesForm;
