import {
  newJob,
  assigned,
  inProgress,
  completed,
  failed,
  verified,
  certified,
  cancelled
} from 'constants/JobStateConstants';

export const isNew = currentState => currentState === newJob;
export const isAssigned = currentState => currentState === assigned;
export const isInProgress = currentState => currentState === inProgress;
export const isCompleted = currentState => currentState === completed;
export const isFailed = currentState => currentState === failed;
export const isVerified = currentState => currentState === verified;
export const isCertified = currentState => currentState === certified;
export const isCancelled = currentState => currentState === cancelled;

export const getJobStateUrl = jobState => {
  const { defaultSortField, defaultSortDirection, stateName } = jobState;
  const jobStateName = convertStateToPath(stateName);
  return `/dashboard/${jobStateName}/1/${defaultSortField},${defaultSortDirection}`;
};

const convertStateToPath = stateName => stateName.replace(' ', '-');
