export const jobDetailsForm = 'jobDetailsForm';
export const jobDuplicateForm = 'jobDuplicateForm';
export const treatmentResultsForm = 'treatmentResultsForm';
export const jobSignoffForm = 'jobSignoffForm';
export const jobCompletionForm = 'jobCompletionForm';
export const jobPicturesForm = 'jobPicturesForm';
export const jobCancelForm = 'jobCancelForm';
export const treatmentResultRecordsForm = 'treatmentResultRecordsForm';
export const containerTreatmentResultRecordsForm = 'containerTreatmentResultRecordsForm';
export const treatmentResultRecordsMonitoringForm =
  'treatmentResultRecordsMonitoringForm';
export const treatmentResultRecordsUncoveringForm =
  'treatmentResultRecordsUncoveringForm';
export const phosphineRecordsApplicationForm =
  'phosphineRecordsApplicationForm';
export const phosphineRecordsTopupForm = 'phosphineRecordsTopupForm';
export const treatmentResultMarkingsForm = 'treatmentResultMarkingsForm';
export const resendNoticesForm = 'resendNoticesForm';
export const jobVerificationForm = 'jobVerificationForm';
export const searchForm = 'searchForm';
export const resendNoticeCompletionForm = 'resendNoticeCompletionForm';
