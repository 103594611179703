import { createSelector } from 'reselect';

const getJobTableDefinitions = state => state.referenceData.jobTableDefinitions;
const getUserColumnVisibilities = state =>
  state.referenceData.userColumnVisibilities;
const getCurrentWorkflowState = (state, props) => props.match.params.state;

const getVisibleColumnsForWorkflow = createSelector(
  [getUserColumnVisibilities, getCurrentWorkflowState],
  (columnVisibilities, currentState) => {
    const setting = columnVisibilities.find(
      ({ state }) => state === currentState.replace('-', ' ')
    );
    return (setting && setting.visibleColumns) || [];
  }
);

export const getDefinitionForWorkflow = createSelector(
  [getJobTableDefinitions, getVisibleColumnsForWorkflow],
  (jobTableDefinitions, visibleColumns) => {
    return jobTableDefinitions.map(column => ({
      ...column,
      visible: visibleColumns.includes(column.key)
    }));
  }
);
