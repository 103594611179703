import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function jobCategoriesReducer(
  state = initialState.referenceData.jobCategories,
  action
) {
  if (action.type === types.GET_JOB_CATEGORIES_SUCCESS) {
    return [...action.jobCategories];
  }
  return state;
}
