const markingsComplete = (markings = []) =>
  markings.every((marking) => marking.sighted);

  export function treatmentResultsComplete(treatmentResults, currentJob){
    let hasEndDate = true;
    if(currentJob.treatmentType && currentJob.treatmentType.abbreviation=='MAC'){
    hasEndDate= currentJob.treatmentResults?currentJob.treatmentResults.endDate:false
    }
  let isComplete =false;
  return isComplete =  treatmentResults && treatmentResults.complete && markingsComplete(currentJob.treatmentTypeDetails.markingsmarkings) && hasEndDate
}

export function treatmentResultsCompleted(treatmentResults, markings) {
  let isComplete = treatmentResults && treatmentResults.complete;
  return isComplete;
}

  export function treatmentResultRecordCompleteContainer(treatmentResultRecords, markings = [],){
    var isComplete = false;
    isComplete = treatmentResultRecords &&
    treatmentResultRecords.length &&
    treatmentResultRecords.every((lrr) => lrr.complete) &&
    markingsComplete(markings);
    return isComplete
  }
  export function treatmentResultRecordsComplete(
    treatmentResultRecords,
    markings = [],
  ) {
  var isComplete = false;
    if(treatmentResultRecords!=undefined){
      if(treatmentResultRecords[0]!=undefined){
        if(treatmentResultRecords[0].efConcentrationReadingItem!=undefined){
          if(treatmentResultRecords[0].efConcentrationReadingItem.length>0){
          return isComplete = treatmentResultRecords[0].efConcentrationReadingItem.some(({phase})=>phase==2)
          && treatmentResultRecords[0].uncoveringTechnicianId && treatmentResultRecords[0].ventDateTime
          }
        }
      }
    }
  if(markingsComplete(markings)){
    isComplete = treatmentResultRecords &&
    treatmentResultRecords.length &&
    treatmentResultRecords.every((lrr) => lrr.complete) &&
    markingsComplete(markings);
    return isComplete

  }


     isComplete = treatmentResultRecords &&
    treatmentResultRecords.length &&
    treatmentResultRecords.every((lrr) => lrr.complete) &&
    markingsComplete(markings);
    return isComplete
  }

export const phosphineTreatmentResultRecordsComplete = (
  phosphineTreatmentResultRecords,
) =>
  phosphineTreatmentResultRecords &&
  phosphineTreatmentResultRecords.length &&
  phosphineTreatmentResultRecords.every((lrr) => lrr.complete)
    ? true
    : false;
