import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector } from 'redux-form';
import { Container, Button, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Logger from 'util/Logger';
import { Band } from 'components/layout';
import TechnicianDetailsSection from './TechnicianDetailsSection';
import TechnicianSignatureSection from './TechnicianSignatureSection';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func
};

class TechnicianForm extends Component {
  render() {
    const { areas, handleSubmit, isSubmitting, signature } = this.props;

    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <Band tint>
          <TechnicianDetailsSection areas={areas} notificationareas = {areas} />
        </Band>
        <Band tint>
          <TechnicianSignatureSection signature={signature} />
        </Band>
        <Container>
          <Button color="primary" className="btn-save" disabled={isSubmitting}>
            Save
          </Button>
        </Container>
      </Form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector('technicianForm');
  return {
    areas: state.referenceData.areas,
    notificationAreas:state.referenceData.notificationareas,
    signature: selector(state, 'signature')
  };
}

TechnicianForm.propTypes = propTypes;

TechnicianForm = reduxForm({
  form: 'technicianForm',
  enableReinitialize: true,
  onSubmitFail: errors => {
    Logger.error(
      'We were unable to save the changes, please review the fields in red and try again.',
      errors,
      'Technician not saved'
    );
  }
})(TechnicianForm);

TechnicianForm = withRouter(connect(mapStateToProps)(TechnicianForm));

export default TechnicianForm;
