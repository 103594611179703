import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, getFormSyncErrors,getFormValues,change } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import cx from 'classnames';
import {   getTechnicians,getCurrentJob,getTreatmentType,getCurrentConcentrationChamberReadingItem,getCurrentActiveConcentrationReadingField} from '../selectors';

import * as Validators from '../../../../util/Validators';
import * as operationsApi from '../../../../api/operationsApi';
import { confirmAlert } from '../../../../util/Alerts';

import PlainTextField from '../../../fields/PlainTextField';
import RenderField from '../../../fields/RenderField';
import SearchableSelect from '../../../fields/SearchableSelect';
import DatePickerField from '../../../fields/DatePickerField';
import { getCompetentTechnicians,getTechByArea } from '../../../../selectors/technicianSelectors';
import {  isExportOtherMBApples,isImportOrExportAndContainer} from '../../../../util/JobTypeHelper';
import styles from './styles.module.scss';
import { treatmentResultRecordsForm } from '../../../../constants/FormConstants';
import { bindActionCreators, compose } from 'redux';

const propTypes = {
  fields: PropTypes.object,
  readOnly: PropTypes.bool,
  fieldValue: PropTypes.func.isRequired,
  jobId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onEditing: PropTypes.func.isRequired,
  meta: PropTypes.object,
  actions: PropTypes.shape({
    changeFieldValue: PropTypes.func.isRequired,
  }),
};

function shouldShowWarning(field, fieldValue) {
  const std = +fieldValue(`${field}.stdgramPerCubicMetre`);
  const topUpDose = +fieldValue(`${field}.topupDose`);
  const jobType = +fieldValue(`${field}.job`);
  return (
    Boolean(std) &&
    !topUpDose &&
    [1, 2, 3].some((i) => {
      const value = +fieldValue(`${field}.reading${i}`);
      return value && value < std;
    })
  );
}


class ConcentrationReadingsChamberFieldArrayItem extends Component {


  render() {
    const {
      field,
      fieldValue,
      fieldError,
      readOnly,
      isEditing,
      onEdit,
      onDone,
      onRemove,
      jobtypename,
      currentJob,
      technicians,
      treatmentType,
      actions,
      currentConcentrationReadingItem,
      formValues,
      activeConcentrationReadingItemField,
    } = this.props;
      const isCoveredContainer = null;//currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null
    const intervalOptions = [
      { value: 0, label: 15 },
      { value: 1, label: 30 },
      { value: 2, label: 60 },
      { value: 3, label: 120 },
    ];
    
    var markingOptions = currentJob.treatmentTypeDetails.markings;
    // const doubled = markingOptions.map((marking,index) =>  marking.containerNumber +" "+marking.sealNumber);
    const doubled = markingOptions.map((marking,index) =>  marking.containerNumber);
     const markings2=[] ;
     for(var i =0;i<doubled.length;i++){
       var containerNumber ={value:'' ,label:''}
        containerNumber.value = doubled[i];
        containerNumber.label = doubled[i];
       markings2.push(containerNumber)
     }
     const isContainer = isImportOrExportAndContainer(currentJob.type)
     const techByArea = getTechByArea(technicians,currentJob.areaId);
 
    /* const competentTechs = getCompetentTechnicians(
       technicians,
       treatmentType,
       currentJob.treatmentTypeDetails.commodity,
       currentJob.treatmentDetails.standardId,
       currentJob.typeId,
       currentJob.areaId,
     );*/
   const showWarning = isCoveredContainer?null:shouldShowWarning(field, fieldValue);
   const isAppleMB = isExportOtherMBApples(currentJob.type,currentJob.treatmentTypeId,currentJob.treatmentTypeDetails.commodity,currentJob.treatmentDetails.standardId)
 
  //  const showWarning = shouldShowWarning(field, fieldValue);
   
    const hasError = Boolean(fieldError(field));

    return (
      <tr>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.interval`}
              component={SearchableSelect}
              options={intervalOptions}
              valueKey="value"
              labelKey="label"
              placeholder="Interval"
              className={styles.phaseSelect}
            />
          ) : (
            <PlainTextField
              name={`${field}.interval`}
              value={
                intervalOptions[fieldValue(`${field}.interval`)] &&
                intervalOptions[fieldValue(`${field}.interval`)].label
              }
            />
          )}
        </td>
        {isContainer?
        <td>
{isEditing ? (
            <Field
              name={`${field}.containerNumber`}
              component={SearchableSelect}
              options={markings2}
              valueKey="value"
              labelKey="label"
              placeholder="Container#"
             
            />
          ):( 
            <PlainTextField
              name={`${field}.containerNumber`}
              value={fieldValue(`${field}.containerNumber`)}
              
            />
        )}
        </td>
  :null}
        <td>
          {isEditing ? (
            <Field
              name={`${field}.rikenID`}
              component={RenderField}
              type="text"
              placeholder="Riken ID"
            />
          ) : (
            <PlainTextField
              name={`${field}.rikenID`}
              value={fieldValue(`${field}.rikenID`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.readingDateTime`}
              component={DatePickerField}
              defaultToEmpty
              showTimeSelect
              type="text"
              placeholder="Date/Time"
              validate={[Validators.isValidDateAndTime]}
              timeIntervals={1}
            />
          ) : (
            <PlainTextField
              name={`${field}.readingDateTime`}
              value={
                fieldValue(`${field}.readingDateTime`) &&
                moment(fieldValue(`${field}.readingDateTime`)).format(
                  'DD/MM HH:mm',
                )
              }
            />
          )}
        </td>
        <td>
          {isAppleMB?null:isEditing ? (
            <Field
              name={`${field}.stdgramPerCubicMetre`}
              component={RenderField}
              type="number"
              placeholder="Std g/m3"
              validate={[Validators.number]}
            />
          ) : (
            <PlainTextField
              name={`${field}.stdgramPerCubicMetre`}
              value={fieldValue(`${field}.stdgramPerCubicMetre`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.reading1`}
              component={RenderField}
              type="number"
              placeholder={jobtypename !== 'MB Logs In Hold'?"1 Front":"Fwd     "}
              validate={isCoveredContainer?null:[ Validators.number, Validators.evenDecimal]}
              manualError={isCoveredContainer?null: fieldError(`${field}.reading1`)}
             // onChange={e=>getTotalDosage(currentJob,currentConcentrationReadingItem,field,fieldValue,actions,formValues)}
            />
          ) : (
            <PlainTextField
              name={`${field}.reading1`}
              value={fieldValue(`${field}.reading1`)}
            />
          )}
        </td>
        <td>
          {isCoveredContainer?null: isEditing ? (
            <Field
              name={`${field}.reading2`}
              component={RenderField}
              type="number"
              placeholder={jobtypename !== 'MB Logs In Hold'?"2 Middle":"Aft      "}
              validate={[Validators.number, Validators.evenDecimal]}
              manualError={fieldError(`${field}.reading2`)}
            />
          ) : (
            <PlainTextField
              name={`${field}.reading1`}
              value={fieldValue(`${field}.reading2`)}
            />
          )}
        </td>
        <td>
          {isCoveredContainer?null:isEditing && jobtypename !== 'MB Logs In Hold' ? (
            <Field
              name={`${field}.reading3`}
              component={RenderField}
              type="number"
              placeholder="3 Back"
              validate={[Validators.number, Validators.evenDecimal]}
              manualError={fieldError(`${field}.reading3`)}
            />
          ) :jobtypename !== 'MB Logs In Hold'? (
            <PlainTextField
              name={`${field}.reading1`}
              value={fieldValue(`${field}.reading3`)}
            />
          ):null}
        </td>


        
        <td>
          {hasError && (
            <span className={styles.errorIcon}>
              <i aria-hidden="true" className="material-icons">
                error
              </i>
            </span>
          )}
          {showWarning && !hasError && (
            <span className={styles.warningIcon}>
              <i aria-hidden="true" className="material-icons">
                warning
              </i>
            </span>
          )}
        </td>
        {!readOnly && (
          <td>
            {isEditing && (
              <Button
                className={styles.iconButton}
                outline
                onClick={onDone}
                color={hasError ? 'disabled' : 'success'}
                disabled={hasError}
              >
                <i aria-hidden="true" className="material-icons">
                  check_outline
                </i>
              </Button>
            )}
            {!isEditing && (
              <Button
                className={styles.iconButton}
                outline
                color="primary"
                onClick={onEdit}
              >
                <i aria-hidden="true" className="material-icons">
                  edit_outline
                </i>
              </Button>
            )}
            <Button
              className={styles.iconButton}
              outline
              color="danger"
              onClick={onRemove}
            >
              <i aria-hidden="true" className="material-icons">
                delete_outline
              </i>
            </Button>
          </td>
        )}
      </tr>
    );
  }
}
ConcentrationReadingsChamberFieldArrayItem.propTypes = {
  field: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  fieldValue: PropTypes.func.isRequired,
  fieldError: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const Item = connect((state, props) => ({
  currentJob: getCurrentJob(state),
  technicians: getTechnicians(state),
  treatmentType: getTreatmentType(state),
  currentConcentrationReadingItem:getCurrentConcentrationChamberReadingItem(state),
  activeConcentrationReadingItemField: getCurrentActiveConcentrationReadingField(state),
  fieldValue: (field) => formValueSelector(props.form)(state, field),
  fieldError: (field) => _.get(getFormSyncErrors(props.form)(state), field),
  formValues: getFormValues(treatmentResultRecordsForm)(state),
}))(ConcentrationReadingsChamberFieldArrayItem);

// eslint-disable-next-line react/no-multi-comp
class ConcentrationReadingsChamberFieldArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: [],
    };
  }

  render() {
    const {
      fields,
      readOnly = false,
      fieldValue,
      meta,
      jobId,
      onChange,
      onEditing,
      job,
      actions,
    } = this.props;
    var isCovered = false;//job.treatmentTypeDetails.covered?job.treatmentTypeDetails.covered:false

    const jobTypeName = job.type.name;
    const isContainer =  isImportOrExportAndContainer(job.type)
    const count = fields ? fields.length : 0;
    // We need to do reduce here because fields is actually not an array
    var showWarning =
      fields && 
      fields.reduce(
        (acc, field) => acc || shouldShowWarning(field, fieldValue),
        false,
      );
      if(isCovered){
showWarning = false;
      }
      const isAppleMB = isExportOtherMBApples(job.type,job.treatmentTypeId,job.treatmentTypeDetails.commodity,job.treatmentDetails.standardId)
    return (

      <div className={styles.concentrationReadingsFieldArray}>
        <Table hover>
          <thead>
            <tr>
              <th style={{ width: 105 }}>Interval</th>
              {isContainer?<th style={{ width: 150 }}>Container Number</th>:null}
              <th>Riken ID</th>
              <th>Date/Time</th>
              {isAppleMB?null:<th>Std g/m3</th>}
              <th>{"Top" } </th>
              <th>{"Middle"}</th>
              <th>{"Bottom"}</th>
              <th>&nbsp;</th>
              {!readOnly && (
                <th style={{ width: 100 }}>
                  <Button
                    className={styles.iconButton}
                    outline
                    onClick={(e) => {
                      fields.push({ jobId });
                      this.setState(
                        (state) => ({
                          editing: [...state.editing, count],
                        }),
                        () => {
                          onEditing(true);
                        },
                      );
                    }}
                    color="primary"
                  >
                    <i aria-hidden="true" className="material-icons">
                      add_outline
                    </i>
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {count === 0 && (
              <tr>
                <td align="center" colSpan={readOnly ? 8 : 9}>
                  There is no data to display
                </td>
              </tr>
            )}
            {fields.map((field, index) => (
              <Item
                key={index}
                field={field}
                form={meta.form}
                jobtypename = {jobTypeName}
                readOnly={readOnly}
                isEditing={this.state.editing.includes(index)}
                onEdit={() => {
                  this.setState(
                    (state) => ({
                      editing: [...state.editing, index],
                    }),
                    () => {
                      onEditing(true);
                    },
                  );
                }}
                onDone={() => {
                  this.setState(
                    (state) => ({
                      editing: state.editing.filter((i) => i !== index),
                    }),
                    () => {
                      onEditing(this.state.editing.length > 0);
                      const updatedField = fields.get(index);
                      const all = fields.getAll();
                      onChange &&
                        onChange([
                          ...all.slice(0, index),
                          updatedField,
                          ...all.slice(index + 1),
                        ]);
                    },
                  );
                }}
                onRemove={async () => {
                  const confirm = await confirmAlert(
                    'Delete',
                    'Are you sure you want to delete this record?',
                  );
                  if (confirm) {
                    const item = fields.get(index);
                    if (item.id) {
                      try {
                        await operationsApi.deleteConcentrationReadingRecord(
                          item.id,
                        );
                      } catch (err) {
                        /* noop */
                      }
                    }
                    fields.remove(index);
                    this.setState(
                      (state) => ({
                        editing: state.editing.filter((i) => i !== index),
                      }),
                      () => {
                        onEditing(this.state.editing.length > 0);
                      },
                    );
                  }
                }}
              />
            ))}
          </tbody>
        </Table>
        {showWarning ? (
          <p className={cx(styles.note, styles.legend)}>
            <span className={styles.warningIcon}>
              <i aria-hidden="true" className="material-icons">
                warning
              </i>
            </span>
            <span> - Check entries or Top up required</span>
          </p>
        ):null}
        <p className={styles.note}>
          If top up at the end, record concentrations after top up &amp; after 4
          hours.
        </p>
      </div>
    );
  }
}

ConcentrationReadingsChamberFieldArray.propTypes = propTypes;


export default connect((state, props) => ({
  fieldValue: (field) => formValueSelector(props.meta.form)(state, field),
}))(ConcentrationReadingsChamberFieldArray);
