import React from 'react';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getShowMonitoring } from './selectors';

export const withPropCheck = (propName) => (Component) => {
  const WrappedComponent = (props) => {
    if (_.isNil(props[propName])) {
      throw new Error(
        `Property ${propName} must be specified on ${Component.displayName}`,
      );
    }
    return <Component {...props} />;
  };
  WrappedComponent.displayName = `WithPropCheck(${propName})(${Component.displayName})`;
  return WrappedComponent;
};

export const withDeepCompareShouldUpdate = (Component) => {
  // eslint-disable-next-line react/no-multi-comp
  class WrappedComponent extends React.Component {
    shouldComponentUpdate(nextProps) {
      return !_.isEqual(this.props, nextProps);
    }
    render() {
      return <Component {...this.props} />;
    }
  }
  WrappedComponent.displayName = `withDeepCompareShouldUpdate(${Component.displayName})`;
  return WrappedComponent;
};

export const withShowMonitoring = connect((state) => ({
  showMonitoring: getShowMonitoring(state),
}));
