import moment from 'moment';

export const roundToPrevious = (datetime, interval = 10) => {
  const rounding = interval * 60 * 1000; /*ms*/
  const roundedDate = moment(Math.floor(+datetime / rounding) * rounding);
  return roundedDate;
};

export const formatDate = (dateTimeString, showTimeInfo) => {
  const date = moment(dateTimeString);
  const dateFormat = 'DD/MM/YYYY';
  const timeFormat = 'HH:mm';
  if (!showTimeInfo) {
    return date.format(dateFormat);
  }
  return date.format(`${dateFormat} ${timeFormat}`);
};
