import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import JobCertification from './JobCertification';
import { formValueSelector, getFormValues } from 'redux-form';
import { jobVerificationForm } from 'constants/FormConstants';
import { isImportJobType } from 'util/JobTypeHelper';
import {
  getContactsByAreaId,
  getDefaultContactsByNoticeKey
} from 'selectors/formSelectors';
//import { updateJob } from '../../api/operationsApi';
import ClientDetailsSectionContainer from '../../jobdetails/ClientDetailsSectionContainer';

function mapStateToProps(state) {
  const { currentJob } = state;
  const formValues = formValueSelector(jobVerificationForm);
  const formV = formValueSelector(ClientDetailsSectionContainer)
  const areaId = currentJob ? currentJob.areaId : null;
  const jobType = currentJob.type;
  var clientStatus  = null
  if(state.clientDetails && state.clientDetails.status){
   clientStatus = state.clientDetails.status
  }



  return {
    status:clientStatus,
    clientDetails:formV(state,'clientDetails.ClientStatus'),
    currentJob,
    certifyJob: formValues(state, 'jobTransitionActions.certifyJob'),
    contacts: getContactsByAreaId(state, areaId),
    formValues: getFormValues(jobVerificationForm)(state),
    initialValues: {
      ...currentJob,
      newTreatmentCertificate: {
        contactIds: getDefaultContactsByJobType(state, areaId, jobType),
        sendNotice: true
      }
    }
  };
}

function getDefaultContactsByJobType(state, areaId, jobType) {
  if (!jobType) return null;
  if (isImportJobType(jobType)) {
    return getDefaultImportContacts(state, areaId);
  } else {
    return getDefaultExportContacts(state, areaId);
  }
}

function getDefaultImportContacts(state, areaId) {
  return getDefaultContactsByNoticeKey(
    state,
    areaId,
    'defaultForTreatmentCertificateImport'
  );
}

function getDefaultExportContacts(state, areaId) {
  return getDefaultContactsByNoticeKey(
    state,
    areaId,
    'defaultForTreatmentCertificateExport'
  );
}

export default compose(withRouter, connect(mapStateToProps))(JobCertification);
