import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as jobActions from 'actions/jobActions';
import * as clientDetailsActions from 'actions/clientDetailsActions';
import { getReferenceDataRecord } from 'selectors/referenceDataSelectors';
import JobPage from 'components/jobs/JobPage';

/*

*/
function mapStateToProps(state, ownProps) {
  const { currentJob } = state;
  const jobId = ownProps.match.params.id;
  let treatmentType;

  if (
    currentJob &&
    currentJob.treatmentDetails &&
    currentJob.id === ownProps.match.params.id
  ) {
    treatmentType = getReferenceDataRecord(
      state,
      'treatmentTypes',
      'id',
      currentJob.treatmentDetails.treatmentTypeId,
      'JobDetailsSummary.treatmentType'
    );
  }

  return {
    currentJob:
      currentJob &&
      currentJob.type &&
      currentJob.id === ownProps.match.params.id
        ? { treatmentType, ...currentJob }
        : null,
    jobId: jobId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobActions,
        ...clientDetailsActions
      },
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(JobPage);
