import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function authReducer(
  state = initialState.isAuthenticated,
  action
) {
  switch (action.type) {
    case types.AUTH_SUCCESS:
      return action.isAuthenticated;
    case types.AUTH_FAILURE:
      return action.isAuthenticated;
    default:
      return state;
  }
}
