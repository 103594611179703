import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Container, Button, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Logger from 'util/Logger';
import { Band } from 'components/layout';
import LocationDetailsSection from './LocationDetailsSection';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func
};

class LocationForm extends Component {
  render() {
    const { areas, handleSubmit, isSubmitting } = this.props;

    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <Band tint>
          <LocationDetailsSection areas={areas} />
        </Band>
        <Container>
          <Button color="primary" className="btn-save" disabled={isSubmitting}>
            Save
          </Button>
        </Container>
      </Form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    areas: state.referenceData.areas
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

LocationForm.propTypes = propTypes;

LocationForm = reduxForm({
  form: 'locationForm',
  enableReinitialize: true,
  onSubmitFail: errors => {
    Logger.error(
      'We were unable to save the changes, please review the fields in red and try again.',
      errors,
      'Location not saved'
    );
  }
})(LocationForm);

LocationForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationForm)
);

export default LocationForm;
