import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import classNames from 'classnames';
import TableFormatterService from 'util/TableFormatterService';
import DeleteRowFormatter from './formatters/DeleteRowFormatter';
import { userIsCustomer } from 'util/AdGroupsHelper';

const propTypes = {
  tableBodyClass: PropTypes.string,
  definition: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  keyField: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  deletable: PropTypes.bool,
  onRowDelete: PropTypes.func
};

class TableList extends Component {
  static defaultProps = {
    keyField: 'id'
  };

  onHeaderClick = column => {
    const { onHeaderClick } = this.props;
    if (onHeaderClick) {
      onHeaderClick(column);
    }
  };

  getSortIndicator = column => {
    const { sort } = this.props;

    if (!sort) return null;

    const order = (sort.name === column.key && sort.order) || 'ASC';
    const keys = {
      ASC: 'keyboard_arrow_up',
      DESC: 'keyboard_arrow_down'
    };

    return (
      <span
        className={classNames('table-sort', {
          sorted: sort.name === column.key
        })}
      >
        <i className="material-icons">{keys[order]}</i>
      </span>
    );
  };

  isVisibleLargeAndAbove = column => column.isHiddenViewportMediumAndBelow;

  render() {
    const {
      tableClassName = 'app-table',
      bodyContainerClass,
      definition,
      data,
      keyField,
      total,
      page,
      pageSize,
      onPageChange,
      onRowClick,
      deletable,
      onRowDelete,
      actions,
      sort
    } = this.props;
    const location = this.props.location;

    const notCust = {
      onRowClick: onRowClick,
      sizePerPage: pageSize || 10,
      onPageChange: onPageChange,
      page: page,
      prePage: 'Prev',
      nextPage: 'Next',
      hideSizePerPage: true
    };
    const cust = {
      sizePerPage: pageSize || 10,
      onPageChange: onPageChange,
      page: page,
      prePage: 'Prev',
      nextPage: 'Next',
      hideSizePerPage: true
    };

    return (
      <div className="table-wrap">
        <BootstrapTable
          className={tableClassName}
          trClassName="app-table-row"
          bodyContainerClass={bodyContainerClass}
          version="4"
          data={data}
          bordered={false}
          remote
          pagination
          fetchInfo={{ dataTotalSize: total }}
          options={
            userIsCustomer() && !(location.pathname.includes('dashboard/new')||location.pathname.includes('dashboard/certified'))
              ? cust
              : notCust
          }
          onRowDelete
        >
          <TableHeaderColumn
            isKey
            className="app-table-header"
            columnClassName="keyColumn"
            dataField={keyField}
            hidden
          />
          {definition.filter(x => x.key).map((column, i) => (
            <TableHeaderColumn
              key={i}
              width={column.width}
              className={classNames(
                'app-table-header',
                {
                  'd-none d-lg-table-cell': this.isVisibleLargeAndAbove(column)
                },
                column.className
              )}
              columnClassName={classNames(
                {
                  'd-none d-lg-table-cell': this.isVisibleLargeAndAbove(column)
                },
                column.className
              )}
              dataField={column.key}
              dataFormat={(cell, row) =>
                TableFormatterService.getCellDataFormatter(
                  column.formatter,
                  cell,
                  row,
                  column.action && actions ? actions[column.action] : null
                )
              }
            >
              <span
                className={classNames({
                  'app-table-header-sortable': Boolean(sort)
                })}
                onClick={() => this.onHeaderClick(column)}
              >
                {column.name}
                {this.getSortIndicator(column)}
              </span>
            </TableHeaderColumn>
          ))}
          {deletable ? (
            <TableHeaderColumn
              className="app-table-header"
              dataField="delete"
              width="32"
              dataAlign="right"
              dataFormat={(cell, row) => (
                <DeleteRowFormatter rowData={row} onDelete={onRowDelete} />
              )}
            />
          ) : null}
        </BootstrapTable>
      </div>
    );
  }
}

TableList.propTypes = propTypes;

export default TableList;
