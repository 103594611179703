import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JobDetailsFormContainer from './JobDetailsFormContainer';
import * as operationsApi from 'api/operationsApi';
import * as JobTypeHelper from 'util/JobTypeHelper';
import * as HistoryHelper from 'util/HistoryHelper';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle,
} from 'components/layout';
import Logger from 'util/Logger';
import { validateJob } from 'util/JobValidationUtils';
import { userCanCancel } from 'util/AdGroupsHelper';
import { confirmAlert, inputAlert } from 'util/Alerts';
import * as CalculationUtils from 'util/CalculationUtils';
import OverlayModal from 'components/layout/OverlayModal';
import JobChangerContainer from 'components/dashboard/JobChangerContainer';
import TreatementTypeIds from 'constants/TreatmentTypeIds';
import { userIsCustomer } from '../../util/AdGroupsHelper';

const propTypes = {
  actions: PropTypes.object,
  jobLocations: PropTypes.array,
};

class JobDetailsEditPage extends Component {
  state = {
    isSubmitting: false,
    isLoading: true,
    showJobModal: false,
    stopCredit: false.valueOf,
    status: '',
  };

  componentDidMount() {
    const { jobId } = this.props;
    this.getJobDetails(jobId);
  }

  getJobDetails = (jobId) => {
    const { actions } = this.props;
    actions.getJob(jobId).then(() => {
      this.toggleLoading();
    });
  };

  toggleLoading = () => {
    this.setState((prevState) => ({ isLoading: !prevState.isLoading }));
  };

  cancelJob = () => {
    confirmAlert(
      'Cancel Job',
      'Are you sure you want to cancel this job?',
    ).then((confirm) => {
      if (!confirm) return false;
      inputAlert('Reason for Cancellation').then((reason) =>
        this.cancelJobSuccess(reason),
      );
    });
  };

  cancelJobSuccess = (comment) => {
    const { actions, currentJob, history } = this.props;
    const jobTransitionActions = { cancelJob: true, comment };
    actions
      .updateJob({
        ...currentJob,
        jobTransitionActions,
        newNoticeOfIntendedTreatment: {},
      })
      .then(() => {
        Logger.success('Job successfully cancelled');
        history.push('/dashboard/cancelled');
      });
  };

  updateJob = (formValues) => {
    let { actions, history, jobId } = this.props;
    let updateJob = this.updateTreatmenTreatmentResultRecords(formValues);

    this.toggleFormSubmit();
    actions
      .updateJob(updateJob)
      .then(() => {
        this.toggleFormSubmit();
        if (
          updateJob.newNoticeOfIntendedTreatment &&
          updateJob.newNoticeOfIntendedTreatment.sendNotice &&
          updateJob.treatmentDetails.treatmentTypeId !==
            TreatementTypeIds.WaterBlasting &&
          updateJob.technicianId != null
        ) {
          Logger.success('Notice of Intended Treatment sent');
        }

        actions.getJobLocations();
        actions.getJob(jobId).then(() => {
          HistoryHelper.performSafeGoBack(history);
        });
      })/*
      .then((data) => {
        operationsApi.updateGreentreeJob(jobId);
      });*/
  };

  updateTreatmenTreatmentResultRecords = (formValues) => {
    if (
      !formValues ||
      !formValues.type ||
      !formValues.treatmentDetails ||
      !formValues.treatmentResultRecords
    )
      return formValues;

    if (!JobTypeHelper.isExportAndLogsOrBreakBulk(formValues.type))
      return formValues;
      if(JobTypeHelper.isExportAndLogsOrBreakBulk(formValues.type)){
        return formValues;
      }
/*
    let treatmentResultRecords = formValues.treatmentResultRecords.map((r) => {
      let calculatedMbkg = CalculationUtils.calculateDosageKgBasedOnVolume(
        r.coveredVolume,
        formValues,
      );
      let mbkg = calculatedMbkg;
      return {
        ...r,
        mbkg,
        calculatedMbkg,
      };
    });
    return {
      ...formValues,
      treatmentResultRecords,
    }; */
  };

  toggleFormSubmit = () => {
    this.setState((prevState) => ({
      isSubmitting: !prevState.isSubmitting,
    }));
  };

  cancel = () => {
    let { history } = this.props;
    HistoryHelper.performSafeGoBack(history);
  };

  getPageHeaderLabel = (jobType) => {
    const jobCategory = JobTypeHelper.getCategoryDisplayName(jobType.category);
    return `Update ${jobType.name} ${jobCategory} job sheet`;
  };

  handleJobTypeChange = (newTypeId) => {
    let { jobId, actions } = this.props;

    actions
      .updateJobType(jobId, newTypeId)
      .then((response) => response)
      .then(() => {
        this.toggleChangeJob();
        this.toggleLoading();
        this.getJobDetails(jobId);
      });
  };

  toggleChangeJob = () => {
    this.setState((prevState) => ({
      showJobModal: !prevState.showJobModal,
    }));
  };

  render() {
    const { currentJob } = this.props;
    const { isLoading, showJobModal } = this.state;
    if (
      this.props.isCreditAllowed !== null &&
      currentJob !== null &&
      currentJob.stopCredit !== null
    ) {
      this.props.isCreditAllowed === 'false' && currentJob.stopCredit !== null
        ? (currentJob.stopCredit = true)
        : (currentJob.stopCredit = false);
    }

    if (isLoading) return false;
    if (!currentJob || !currentJob.type) return false;

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            {userCanCancel(currentJob.currentState)||userIsCustomer()? (
              <HeaderButton
                color="light"
                onClick={this.cancelJob}
                ligature="delete"
              />
            ) : null}
            <HeaderButton
              color="primary"
              onClick={this.cancel}
              ligature="clear"
            />
          </HeaderButtonNav>
          <HeaderTitle
            label={this.getPageHeaderLabel(currentJob.type)}
            imageUrl={currentJob.type.imageUrlFull}
          >
            <HeaderButton
              onClick={this.toggleChangeJob}
              color="light"
              ligature="edit"
            />
          </HeaderTitle>
        </Header>
        <JobDetailsFormContainer
          onSubmit={(formValues) => validateJob(formValues, this.updateJob)}
          isSubmitting={this.state.isSubmitting}
          initialValues={currentJob}
          jobType={currentJob.type}
        />

        {showJobModal && (
          <OverlayModal
            title="Change Job Type"
            isOpen={this.state.showJobModal}
            toggle={this.toggleChangeJob}
            wrapClassName="app-overlay"
          >
            <JobChangerContainer
              handleChange={this.handleJobTypeChange}
              currentJob={currentJob}
            />
          </OverlayModal>
        )}
      </div>
    );
  }
}

JobDetailsEditPage.propTypes = propTypes;

export default JobDetailsEditPage;
