import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableList from 'components/table/TableList';
import NoResults from 'components/table/NoResults';

const propTypes = {
  jobTableDefinition: PropTypes.array,
  jobSummaries: PropTypes.object,
  onPageChange: PropTypes.func
};

class JobTable extends Component {
  render() {
    const {
      jobTableDefinition,
      jobSummaries,
      onPageChange,
      onRowClick,
      onHeaderClick,
      sort,
      location
    } = this.props;

    if (
      !jobTableDefinition ||
      !jobTableDefinition.length ||
      !jobSummaries.content
    ) {
      return <NoResults />;
    }

    return (
      <TableList
        bodyContainerClass="px-2"
        data={jobSummaries.content}
        total={jobSummaries.totalElements}
        page={jobSummaries.number + 1}
        pageSize={jobSummaries.size}
        definition={jobTableDefinition.filter(x => x.visible)}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        onHeaderClick={onHeaderClick}
        sort={sort}
        location={location}
      />
    );
  }
}

JobTable.propTypes = propTypes;

export default JobTable;
