import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';
import * as FileHelper from 'util/FileHelper';
import FileUploadAttachments from 'components/fields/FileUploadAttachments';

const propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object
};

const FileUploadField = ({
  onDrop,
  accept,
  input,
  meta: { touched, error, warning }
}) => {
  const currentFiles = input.value || [];

  const handleDrop = uploadedFiles => {
    const newFiles = currentFiles.concat(uploadedFiles);
    FileHelper.convertFilesToBase64(newFiles).then(files => {
      input.onChange(files);
    });
  };

  const deleteFile = fileName => {
    const newFiles = currentFiles.filter(file => file.name !== fileName);
    input.onChange(newFiles);
  };

  return (
    <div>
      <Dropzone
        className="app-dropzone"
        acceptClassName="success"
        rejectClassName="error"
        onDropAccepted={handleDrop}
        accept={accept}
      >
        <div className="app-dropzone-content">
          <h2 className="app-dropzone-title">
            Drag &amp; Drop files to upload
          </h2>
          <h5 className="app-dropzone-or">or</h5>
          <Button type="button" outline size="lg" color="primary">
            Browse
          </Button>
        </div>
      </Dropzone>
      {touched && error ? <span className="error">{error}</span> : null}
      {currentFiles && Array.isArray(currentFiles) ? (
        <FileUploadAttachments files={currentFiles} onDelete={deleteFile} />
      ) : null}
    </div>
  );
};

FileUploadField.propTypes = propTypes;

export default FileUploadField;
