import React from 'react';
import PropTypes from 'prop-types';
import * as operationsApi from 'api/operationsApi';

const propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
  disableClick: PropTypes.bool
};

const DocumentLink = ({ name, id, onDelete, disableClick, readOnly }) => {
  const handleClick = () => {
    if (disableClick) return true;

    operationsApi.getDocumentUrl(id).then(result => {
      window.location = result.data;
    });
  };

  const handleDelete = e => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <a
      className={`media app-document ${
        disableClick ? 'app-document--no-click' : ''
      }`}
      onClick={handleClick}
    >
      <i className="material-icons app-document-icon">insert_drive_file</i>
      <span className="media-body">
        <span className="app-document-view">{name}</span>
        {!readOnly ? (
          <i className="app-document-delete" onClick={handleDelete}>
            x
          </i>
        ) : null}
      </span>
    </a>
  );
};

DocumentLink.propTypes = propTypes;

export default DocumentLink;
