import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { Band, ToggleButton } from 'components/layout';
import Checkbubble from 'components/common/Checkbubble';
import JobSignoffForm from './JobSignoffForm';
import { updateJob, getNoticeOfCompletion } from 'api/operationsApi';
import JobSignoffCompletedContainer from './JobSignoffCompletedContainer';
import { isCompleted, isVerified } from 'util/JobStateHelper';
import Logger from 'util/Logger';
import {licenseTech} from 'selectors/technicianSelectors'

const propTypes = {
  technicians: PropTypes.array
};

class JobSignoff extends Component {
  state = {
    collapsed: this.isSectionCollapsed(),
    showPreviewEmail: false,
    noticeOfCompletionEmail: ''
  };

  isSectionCollapsed() {
    const { supervisorSignoff, currentState } = this.props;
    if (isVerified(currentState)) {
      return false;
    }
    return supervisorSignoff.complete;
  }

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  togglePreviewEmail = () => {
    this.setState(prevState => ({
      showPreviewEmail: !prevState.showPreviewEmail
    }));
  };

  getNoticeOfCompletion = () => {
    const { formValues } = this.props;
    return getNoticeOfCompletion(formValues).then(response => {
      this.setState({
        noticeOfCompletionEmail: response.data,
        showPreviewEmail: true
      });
    });
  };

  signoffJob = formValues => {
    const { currentJob, history } = this.props;
    updateJob({
      ...currentJob,
      supervisorSignoff: { ...formValues }
    }).then(() => {
      Logger.success('Signoff Completed');

      if (
        formValues.newNoticeOfCompletion &&
        formValues.newNoticeOfCompletion.sendNotice &&
        formValues.supervisorSignoff &&
        formValues.supervisorSignoff.passed
      ) {
        Logger.success('Notice of Completed Treatment sent');
      }
      history.push('/');
    });
  };

  render() {
    const {
      currentJob,
      technicians,
      supervisorSignoff,
      initialValues,
      currentState,
      clientDetails,
    } = this.props;
    const { collapsed } = this.state;
    const   licTechnicians = licenseTech(technicians);
    var status ='Active'
    if(clientDetails !=null){
      status = clientDetails.status;

    }

    if (isCompleted(currentState)&& status === 'Active') {
      return (
        <Band>
          <div className="form-section">
            <Container>
              <Row className="align-items-center">
                <Col xs="8">
                  <h4>Supervisor Signoff</h4>
                </Col>
                <Col xs="4" className="text-right">
                  <Checkbubble
                    className="align-middle"
                    checked={
                      supervisorSignoff ? supervisorSignoff.complete : false
                    }
                  />
                  <ToggleButton
                    color="light"
                    className="ml-3"
                    onClick={this.toggleExpand}
                    active={collapsed}
                    ligature="expand_less"
                  />
                </Col>
              </Row>
              <Collapse isOpen={!collapsed}>
                <JobSignoffForm
                  currentJob={currentJob}
                  technicians={licTechnicians}
                  initialValues={initialValues}
                  onSubmit={this.signoffJob}
                />
              </Collapse>
            </Container>
          </div>
        </Band>
      );
    }

    if (supervisorSignoff) {
      return <JobSignoffCompletedContainer />;
    }

    return null;
  }
}

JobSignoff.propTypes = propTypes;

export default JobSignoff;
