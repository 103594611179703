import React from 'react';
import PropTypes, { checkPropTypes } from 'prop-types';
import { Field } from 'redux-form';
import { Container, FormGroup } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import RenderField from 'components/fields/RenderField';
import { required, email } from 'util/Validators';
import { validCode } from 'util/TechnicianValidators';
import CheckboxField from 'components/fields/CheckboxField';

const propTypes = {
  areas: PropTypes.array,
  notificationareas: PropTypes.array,
};

const TechnicianDetailsSection = ({ areas, notificationareas }) => (
  <section className="form-section">
    <Container>
      <h4>Technician Details</h4>
      <div className="form-row align-items-center">
        <FormGroup className="col-md-4">
          <Field
            name="name"
            label="Name *"
            component={RenderField}
            type="text"
            placeholder="Enter a Name"
            validate={required}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="licenceNumber"
            label="Licence Number"
            component={RenderField}
            type="text"
            placeholder="Enter a Licence Number"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="areaIds"
            label="Area"
            component={SearchableSelect}
            options={areas}
            multi
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="notificationAreaId"
            label="Notifcation Area"
            component={SearchableSelect}
            options={notificationareas}
            multi
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="email"
            label="Email"
            component={RenderField}
            type="text"
            placeholder="Enter an Email"
            validate={email}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="cellPhoneNumber"
            label="Cell Phone Number"
            component={RenderField}
            type="text"
            placeholder="Enter a Cell Phone Number"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="code"
            label="Code"
            component={RenderField}
            type="password"
            autoComplete="off"
            placeholder="Enter a Code"
            validate={validCode}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="hidden"
            label="Hide Technician"
            component={CheckboxField}
          />
        </FormGroup>
      </div>
      <h4>Competency Details</h4>
      <div className="form-row align-items-center">
        <FormGroup className="col-md-4">
          <Field
            name="callington1Shot"
            label="Callington1Shot"
            component={CheckboxField}
          />
        </FormGroup>

        <FormGroup className="col-md-4">
          <Field name="heat" label="Heat" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="hydrogenCyanide"
            label="Hydrogen Cyanide"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="pestigas" label="Pestigas" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="thermofog" label="Thermofog" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="gasFree" label="Gas Free" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="waterBlasting"
            label="Water Blasting"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="mbFreshProduce"
            label="MB Fresh Prod"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="mbLogsInHold"
            label="MB Logs In Hold"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="mbBreakBulk"
            label="MB Break Bulk"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="mbContainer"
            label="MB Container"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="mbLogRow" label="MB Log Row" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="ispM15" label="ISPM15" component={CheckboxField} />
        </FormGroup>

        <FormGroup className="col-md-4">
          <Field name="vapormate" label="Vapormate" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="alpVessel"
            label="AlpVessel Phosphine"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field
            name="alpNonVessel"
            label="AlpNonVessel"
            component={CheckboxField}
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="vaporPhos" label="VaporPhos" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="ethylFormate" label="Ethyl Formate" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Field name="macSlay" label="Mac Slay" component={CheckboxField} />
        </FormGroup>
      </div>
    </Container>
  </section>
);

TechnicianDetailsSection.propTypes = propTypes;

export default TechnicianDetailsSection;
