import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Form, FormGroup, Button } from 'reactstrap';
import { jobCompletionForm } from 'constants/FormConstants';
import NoticeFields from 'components/common/NoticeFields';
import { preventEnterSubmit } from 'actions/formActions';
import { isHydrogenCyanide } from '../../../util/TreatmentTypeHelper';

const propTypes = {
  currentJob: PropTypes.object,
  handleSubmit: PropTypes.func,
  contacts: PropTypes.array,
  onPreview: PropTypes.func
};

const JobCompletionForm = ({
  currentJob,
  handleSubmit,
  contacts,
  onPreview
}) => {
  const handlePreviewClick = () => onPreview();
  return (
    <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
      <fieldset disabled={!currentJob.canEditJob && 'disabled'}>
        <div className="form-row align-items-center">
        {(!isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId)?
          <FormGroup className="col-12">
            <NoticeFields
              contacts={contacts}
              onPreview={handlePreviewClick}
              formFieldKey="newNoticeOfCompletion"
            />
          </FormGroup>
                :null)}
          <FormGroup className="col-4">
            <Button color="primary" type="submit">
            {!isHydrogenCyanide(currentJob.treatmentDetails.treatmentTypeId)?"Send Notice of Completion":"Complete Job"}
            </Button>
          </FormGroup>
        </div>
      </fieldset>
    </Form>
  );
};

JobCompletionForm.propTypes = propTypes;

export default reduxForm({ form: jobCompletionForm })(JobCompletionForm);
