import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  css: PropTypes.string
};

const pageMargin = '1.6cm';
const bgColor = '#fff';
const tableBorderWidth = '2px';
const printPageClass = 'print-page';

const styles = `
  @media print {
    /* Layout */

    @page {
      size: auto;   /* auto is the current printer page size */
      margin: 0mm;  /* this affects the margin in the printer settings */
    }

    html .${printPageClass} {
      background-color: ${bgColor};
      margin: 0px;  /* the margin on the content before printing */
      height: 100%;
    }

    .${printPageClass} #header,
    .${printPageClass} #footer {
      position: fixed;
      left: 0;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      height: ${pageMargin};
      background-color: ${bgColor};
      text-align: center;
    }
    .${printPageClass} #header {
      top: 0;
      align-items: flex-start;
    }
    .${printPageClass} #footer {
      bottom: 0;
      align-items: flex-end;
    }
    .${printPageClass} #header > *,
    .${printPageClass} #footer > * {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem ${pageMargin};
    }

    body .${printPageClass} {
      padding: ${pageMargin};
      /* Fonts */
      font-family: Arial;
      font-size: 14px;
      /* Show background color */
      -webkit-print-color-adjust: exact !important;
    }

    /* Basic reset */
    body .${printPageClass} * {
      margin: 0;
      padding: 0;
    }

    .${printPageClass} #content {
      position: relative;
      height: 100%;
    }

    .${printPageClass} .table-con {
      margin-top: -${pageMargin};
      margin-bottom: -${pageMargin};
    }
    .${printPageClass} table {
      width: 100%;
      border-collapse: collapse;
    }
    .${printPageClass} th, 
    .${printPageClass} td {
      border: 1px solid #999;
      padding: .2rem;
    }
    .${printPageClass} table td:first-child,
    .${printPageClass} table th:first-child {
      border-left: ${tableBorderWidth} solid #000;
    }
    .${printPageClass} table td:last-child,
    .${printPageClass} table th:last-child {
      border-right: ${tableBorderWidth} solid #000;
    }
    .${printPageClass} .h-border-top {
      border-top: ${tableBorderWidth} solid #000;
    }
    .${printPageClass} .h-border-bottom {
      border-bottom: ${tableBorderWidth} solid #000;
    }
    .${printPageClass} .v-border-left {
      border-left: ${tableBorderWidth} solid #000;
    }
    .${printPageClass} .v-border-right {
      border-right: ${tableBorderWidth} solid #000;
    }
      
    .${printPageClass} thead .damm-row,
    .${printPageClass} tfoot .damm-row {
      height: calc(${pageMargin} + ${tableBorderWidth});
      border-color: transparent !important;
      background-color: transparent !important;
    }
    .${printPageClass} thead .damm-row + tr {
      border-top: ${tableBorderWidth} solid #000;
    }
  }
`;

export const Table = ({ children }) => (
  <div className="table-con">
    <table>{children}</table>
  </div>
);

export const TableHead = ({ children }) => (
  <thead>
    <tr className="damm-row" />
    {children}
  </thead>
);

export const TableFoot = ({ children }) => (
  <tfoot>
    {React.Children.map(children, (child, key) => {
      if (key + 1 === React.Children.count(children)) {
        return React.cloneElement(child, {
          className: cx(child.props.className, 'h-border-bottom')
        });
      }
      return child;
    })}
    <tr className="damm-row" />
  </tfoot>
);

const PrintPage = ({ header, footer, baseStyleClass, css = '', children }) => (
  <div className={cx(baseStyleClass, printPageClass)}>
    <style dangerouslySetInnerHTML={{ __html: styles }} />
    <style dangerouslySetInnerHTML={{ __html: `@media print { ${css} }` }} />
    <div id="header">
      <div>
        {header && Array.isArray(header)
          ? header.map((x, i) => <span key={i}>{x}</span>)
          : header}
      </div>
    </div>
    <div id="footer">
      <div>
        {footer && Array.isArray(footer)
          ? footer.map((x, i) => <span key={i}>{x}</span>)
          : footer}
      </div>
    </div>
    <div id="content">{children}</div>
  </div>
);

PrintPage.propTypes = propTypes;

export default PrintPage;
