import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { compose } from 'recompose';
import styles from './styles.module.scss';

import { preventEnterSubmit } from '../../../../actions/formActions';

import Accordion, { AccordionItem } from '../../../common/Accordion';

import TreatmentResultRecordsStartDetailsSection from '../TreatmentResultRecordsStartDetailsSection';
import TreatmentResultRecordsMonitoringSection from '../TreatmentResultRecordsMonitoringSection';
import TreatmentResultRecordsUncoveringSection from '../TreatmentResultRecordsUncoveringSection';

import { withShowMonitoring } from '../enhancers';
import { withAccordionActiveItemIndex } from './enhancers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentJob } from '../selectors';
import { isExportOtherMBApples, isMBLogsInHold } from '../../../../util/JobTypeHelper';
import { isEthylFormate } from '../../../../util/TreatmentTypeHelper';

const propTypes = {
  handleSubmit: PropTypes.func,
  actions: PropTypes.shape({
    changeFieldValue: PropTypes.func.isRequired,
  }),
  showMonitoring: PropTypes.bool,
  accordionActiveItemIndex: PropTypes.number.isRequired,
};

class TreatmentResultRecordsForm extends Component {
  render() {
    const {
      handleSubmit,
      actions,
      showMonitoring,
      accordionActiveItemIndex,
      currentTreatmentResultRecord,
      jobType,
      currentJob,
    } = this.props;
    const standardId = currentJob.treatmentDetails.standardId?currentJob.treatmentDetails.standardId:null;
    const commodity = currentJob.treatmentTypeDetails.commodity?currentJob.treatmentTypeDetails.commodity:null;
    const isApplesJapan = isExportOtherMBApples(jobType,currentJob.treatmentDetails.treatmentTypeId,commodity,standardId)
    var   {isCoveredContainer} = this.props
    if(isCoveredContainer!=undefined){
      isCoveredContainer = isCoveredContainer;
  }else{
    isCoveredContainer = false;
  };
    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <Accordion
          className={styles.accordion}
          open={[accordionActiveItemIndex]}
        >
          <AccordionItem title="Start Details">
            <TreatmentResultRecordsStartDetailsSection actions={actions} commodity={commodity} standardId={standardId} />
          </AccordionItem>
          {showMonitoring && (
            <AccordionItem title="Monitoring" className={styles.monitoring}>
              <TreatmentResultRecordsMonitoringSection actions = {actions} />
            </AccordionItem>
          )}
          <AccordionItem
             title={isEthylFormate( currentJob.treatmentDetails.treatmentTypeId)?"End Details":
              !isMBLogsInHold(jobType) && !isApplesJapan
                ? 'Uncovering Details'
                : 'Venting Details'
            }
          >
            <TreatmentResultRecordsUncoveringSection currentJob={currentJob} jobType={jobType}actions={actions} isCoveredContainer = {isCoveredContainer} currentTreatmentResultRecord={currentTreatmentResultRecord}/>
          </AccordionItem>
        </Accordion>
      </Form>
    );
  }
}

TreatmentResultRecordsForm.propTypes = propTypes;

export default compose(
  withShowMonitoring,
  withAccordionActiveItemIndex,
)(TreatmentResultRecordsForm);
