import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, formValueSelector, getFormValues } from 'redux-form';
import NoticesSection from './NoticesSection';
import { getContactsByAreaId } from 'selectors/formSelectors';
import { jobDetailsForm } from 'constants/FormConstants';

function mapStateToProps(state, ownProps) {
  const formValues = formValueSelector(jobDetailsForm);
  return {
    referenceData: state.referenceData,
    contacts: getContactsByAreaId(state, ownProps.areaId),
    contactIds: formValues(state, 'newNoticeOfIntendedTreatment.contactIds'),
    jobDetailsFormValues: getFormValues(jobDetailsForm)(state),
    jobId: formValues(state, 'jobId')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ change }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticesSection);
