import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TreatmentTypeDetails from './TreatmentTypeDetails';
import { reduxForm, change, formValueSelector } from 'redux-form';
import { jobDetailsForm } from '../../constants/FormConstants';
import Logger from '../../util/Logger';

function mapStateToProps(state, props) {
  const { treatmentTypeDetails } = props;
  const formValues = formValueSelector(jobDetailsForm);
  const treatmentId = state.form.jobDetailsForm.values.treatmentDetails
    .treatmentTypeId
    ? state.form.jobDetailsForm.values.treatmentDetails.treatmentTypeId
    : null;
  return {
    customerCompletedChecked: formValues(state, 'customerCompleted'),
    treatmentTypeDetails,
    ...state.referenceData,
    treatmentId:
      formValues(state, 'treatmentDetails.treatmentTypeId') || treatmentId,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps),
)(TreatmentTypeDetails);
