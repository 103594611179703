import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Button, Form, FormGroup, Row, Col } from 'reactstrap';

import RenderField from '../../../components/fields/RenderField';
import CustomTagSelect from '../../../components/fields/CustomTagSelect';
import SearchableSelect from '../../../components/fields/SearchableSelect';
import DatePickerField from '../../../components/fields/DatePickerField';
import CheckboxField from '../../../components/fields/CheckboxField';
import MarkingsSightedFieldArray from '../../../components/fields/MarkingsSightedFieldArray';

import { preventEnterSubmit } from '../../../actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  markings: PropTypes.array,
  currentJob: PropTypes.object,
};

function TreatmentResultMarkingsForm({
  handleSubmit,
  markings,
  currentJob,
  isSubmitting,
}) {
  if (!markings || markings.length === 0) return null;

  return (
    <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
      <Row style={{ paddingTop: 25 }}>
        <Col xs={12}>
          <FormGroup className="col-md-12">
            <h5>Markings</h5>
          </FormGroup>
          <FormGroup className="col-md-12">
            <FieldArray
              name="markings"
              component={MarkingsSightedFieldArray}
              jobType={currentJob.type}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup className="col-md-4">
          <Button color="primary" className="mt-3" disabled={isSubmitting}>
            Save Markings
          </Button>
        </FormGroup>
      </Row>
    </Form>
  );
}

TreatmentResultMarkingsForm.propTypes = propTypes;

export default TreatmentResultMarkingsForm;
