import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as jobActions from 'actions/jobActions';
import JobDetailsSummary from './JobDetailsSummary';
import { getReferenceDataRecord } from 'selectors/referenceDataSelectors';
import { withRouter } from 'react-router';
import ClientDetailsSectionContainer from '../jobdetails/ClientDetailsSectionContainer';

function mapStateToProps(state, ownProps) {
  const { currentJob } = state;
  const clientDetails = state.clientDetails;
  
 
  return {

    ...currentJob,
    clientDetails,   
    doNotCertify:currentJob.doNotCertify, 
    containerType: getReferenceDataRecord(
      state,
      'containerTypes',
      'id',
      currentJob.treatmentTypeDetails.containerTypeId,
      'JobDetailsSummary.containerType'
    ),
    category: getReferenceDataRecord(
      state,
      'jobCategories',
      'id',
      currentJob.treatmentTypeDetails.categoryId,
      'JobDetailsSummary.category'
    ),
    area: getReferenceDataRecord(
      state,
      'areas',
      'id',
      currentJob.areaId,
      'JobDetailsSummary.area'
    ),
    technician: getReferenceDataRecord(
      state,
      'technicians',
      'id',
      currentJob.technicianId,
      'JobDetailsSummary.technician'
    ),
    standard: getReferenceDataRecord(
      state,
      'jobStandards',
      'id',
      currentJob.treatmentDetails.standardId,
      'JobDetailsSummary.standard'
    ),
    sizeOfContainer: getReferenceDataRecord(
      state,
      'sizeOfContainers',
      'id',
      currentJob.treatmentTypeDetails.sizeOfContainerId,
      'JobDetailsSummary.sizeOfContainer'
    ),
    treatmentType: getReferenceDataRecord(
      state,
      'treatmentTypes',
      'id',
      currentJob.treatmentDetails.treatmentTypeId,
      'JobDetailsSummary.treatmentType'
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...jobActions }, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobDetailsSummary)
);
