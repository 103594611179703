import { createSelector } from 'reselect';

const categoryEnum = {
  import: 10,
  export: 20
};

const getJobTypes = (state) => state.referenceData.jobTypes;

export const getImportJobTypes = createSelector(
  getJobTypes,
  (jobTypes) => {
    return jobTypes.filter(type => type.category === categoryEnum.import);
  }
);

export const getExportJobTypes = createSelector(
  getJobTypes,
  (jobTypes) => {
    return jobTypes.filter(type => type.category === categoryEnum.export);
  }
);
