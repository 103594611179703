import React from 'react';
import { Container, Button } from 'reactstrap';
import { Band } from 'components/layout';

const JobSubmitSection = ({ isSubmitting }) => (
  <Band dark>
    <div className="form-section py-4">
      <Container>
        <Button color="primary" className="btn-save" disabled={isSubmitting}>
          Save
        </Button>
      </Container>
    </div>
  </Band>
);

export default JobSubmitSection;
