import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import JobDocumentsList from './JobDocumentsList';
import { Band } from 'components/layout';
import clipIcon from 'assets/clip.svg';

const propTypes = {
  attachments: PropTypes.array.isRequired
};

class JobDocuments extends Component {
  state = {
    limit: 3
  };

  showAllDocuments = () => {
    this.setState({ limit: this.props.attachments.length });
  };

  render() {
    const { limit } = this.state;
    const { attachments } = this.props;

    if (!attachments || !attachments.length) return null;

    return (
      <Band className="py-3">
        <Container>
          <Row className="align-items-center">
            <Col xs="1">
              <img src={clipIcon} alt="Job Documents" width="20" />
            </Col>
            <Col xs="11">
              <JobDocumentsList
                attachments={attachments}
                onShowAll={this.showAllDocuments}
                limit={limit}
              />
            </Col>
          </Row>
        </Container>
      </Band>
    );
  }
}

JobDocuments.propTypes = propTypes;

export default JobDocuments;
