import * as types from '../constants/actionTypes';
import { type } from 'jquery';
import initialState from './initialState';

export default function currentJobReducer(
  state = initialState.currentJob,
  action,
) {
  if (action.type === types.UPDATE_JOB_SUCCESS) {
    return action.job;
  }
  if (action.type === types.CLEAR_JOB_TREATMENTTYPE) {
    return (action.job = null);
  }

  return state;
}
