import moment from 'moment'

export const createJobFromFormValues = (formValues = {}, referenceData) => {
  const jobModel = Object.assign({}, formValues);
  const clientCode = formValues.clientCode;
  const clientSummaries = referenceData.clientSummaries;
  if (clientCode && clientSummaries) {
    jobModel.clientName = getClientNameByCode(clientCode, clientSummaries);
  }
  return jobModel;
};

export const getClientNameByCode = (clientCode, clientSummaries) => {
  var client = clientSummaries.find(clientSummary => {
    return clientSummary.code === clientCode;
  });
  return client ? client.name : null;
};

export const getClientByCode = (clientCode, clientSummaries) => {
  return clientSummaries.find(clientSummary => {
    return clientSummary.code === clientCode;
  });
};

export const getTechnicianDisplayName = (technicianId, technicians) => {
  var technician = technicians.find(technician => {
    return technician.id === technicianId;
  });
  return technician ? technician.name : '';
};

export const getCoveredTechInRow = (concentrationItemList) =>{  
  var list = concentrationItemList.filter(item=>item.phase==0)
  const sorted = list.sort((a,b)=>moment(a.readingDateTime) -moment(b.readingDateTime));
  if(sorted.length>=1){
      return sorted[0].technician
  }
  return null;
}


export const getTechnicianUncoveredInRow = (concentrationItemList) =>{
  var list = concentrationItemList.filter(item=>item.phase==2)
  const sorted = list.sort((a,b)=>moment(a.readingDateTime) -moment(b.readingDateTime));
  if(sorted.length>=1){
    var i =  sorted.length -1;
      return sorted[i].technician
  }
  return null;
}

export const getContainerNumber = (value) => {
 if (value.concentrationReadingItem.length>=1){
  if(value.concentrationReadingItem.length>1){
    return value.concentrationReadingItem[0].containerNumber+" "+value.concentrationReadingItem[value.concentrationReadingItem.length-1].containerNumber
  }
return value.concentrationReadingItem[0].containerNumber
 }
 return '';
};

export const countContainers=(treatmentResultRecords)=>{
var conList = [];
var containerList =[]
var containerCount;
for(var i = 0 ; i<treatmentResultRecords.length;i++){
conList = conList.concat(treatmentResultRecords[i].concentrationReadingItem.filter(item=>item.phase==0))
}
if(conList.length>=1){
  containerList = conList.map((items)=>items.containerNumber)
  var containerCount = new Set(containerList).size
    return containerCount
}
return containerCount;

}