import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Label } from 'reactstrap';

import * as Validators from '../../util/Validators';
import {
  isImportAndContainer,
  isExportAndContainer,
  isImportJobType,
  isExportAndLogs,
} from '../../util/JobTypeHelper';

import PlainTextField from './PlainTextField';
import CheckboxField from './CheckboxField';

import ToggleButton from '../layout/ToggleButton';

const propTypes = {
  label: PropTypes.string,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  jobType: PropTypes.object.isRequired,
};

function MarkingsSightedFieldArray({
  fields,
  meta: { error },
  label,
  jobType,
}) {
  const isContainer =
    isImportAndContainer(jobType) || isExportAndContainer(jobType);

  return (
    <div>
      {label && <Label>{label}</Label>}
      {fields.map((marking, index) => (
        <div key={index}>
          <Field
            name={`${marking}.sighted`}
            label={`Sighted - ${
              isContainer
                ? `${fields.get(index).containerNumber} ${
                    fields.get(index).sealNumber || ''
                  }`
                : fields.get(index).markingNumber
            }`}
            component={CheckboxField}
          />
        </div>
      ))}
    </div>
  );
}

MarkingsSightedFieldArray.propTypes = propTypes;

export default MarkingsSightedFieldArray;
