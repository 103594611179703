
    export const travelCost = [{id:0,name:"Zone 1"},{id:1,name:"Zone 2"},{id:2,name:"Zone 3"},{id:3,name:"Zone 4"},
                                {id:4,name:"Zone 5"},{id:5,name:"Zone 6"}, {id:6,name:"Zone 7"} ,{id:7,name:"Zone 8"},
                                {id:8,name:"Zone 9"},{id:9,name:"Zone 10"},{id:10,name:"Zone 11"},{id:11,name:"Zone 12"},
                                {id:12,name:"Zone 13"}]

    export const movements = [{id:1,name:1},{id:2,name:2},{id:3,name:3},{id:4,name:4},{id:5,name:5}
                             ,{id:6,name:6},{id:7,name:7},{id:8,name:8},{id:9,name:9},{id:10,name:10},
                             {id:11,name:11},{id:12,name:12},{id:13,name:13},{id:14,name:14},{id:15,name:15}
                            ,{id:16,name:16},{id:17,name:17},{id:18,name:18},{id:19,name:19},{id:20,name:20}]

    export const travelZoneList = [{id:1,name:1},{id:2,name:2},{id:3,name:3},{id:4,name:4},{id:5,name:5},{id:6,name:6},
                 {id:7,name:7},{id:8,name:8},{id:9,name:9},{id:10,name:10},{id:11,name:11},{id:12,name:12},{id:13,name:13}]