import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getVessels() {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi.getVessels().then(
      (response) => dispatch(getVesselsSuccess(response)),
      (error) => dispatch(ajaxCallError()),
    );
  };
}

function getVesselsSuccess(response) {
  return {
    type: types.GET_VESSELS_SUCCESS,
    vessels: response.data || [],
  };
}
