import React from 'react';
import { Card, CardBody } from 'reactstrap';

const NoResults = () => (
  <Card>
    <CardBody>
      <h5 className="text-center">No Results</h5>
    </CardBody>
  </Card>
);

export default NoResults;
