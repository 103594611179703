import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { Form, FormGroup, Row, Button } from 'reactstrap';
import moment from 'moment';

import RenderField from 'components/fields/RenderField';
import SearchableSelect from 'components/fields/SearchableSelect';
import DatePickerField from 'components/fields/DatePickerField';
import * as Validators from 'util/Validators';
import Logger from 'util/Logger';
import * as jobActions from 'actions/jobActions';
import { roundToPrevious } from 'util/DateHelper';
import { phosphineRecordsTopupForm } from 'constants/FormConstants';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  technicians: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
  canEditJob: PropTypes.bool
};

class PhosphineRecordsTopupForm extends Component {
  render() {
    const { technicians, handleSubmit, canEditJob, splitVessel } = this.props;
    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <fieldset disabled={!canEditJob && 'disabled'}>
          <Row className="align-items-center">
            <FormGroup className="col-lg-6">
              <Field
                name="topUpTechnicianId"
                label="Technician *"
                component={SearchableSelect}
                placeholder="Please select a technician"
                options={technicians}
                valueKey="id"
                labelKey="name"
                validate={Validators.required}
              />
            </FormGroup>
            <FormGroup className="col-lg-6">
              <Field
                name="totalApplied"
                label={`Total Applied ${splitVessel ? '' : '*'}`}
                component={RenderField}
                type="number"
                placeholder="Enter Total"
                validate={
                  splitVessel
                    ? [Validators.number]
                    : [Validators.number, Validators.required]
                }
                timeIntervals={1}
              />
            </FormGroup>
            <FormGroup className="col-lg-6">
              <Field
                name="endDate"
                label="End Date *"
                component={DatePickerField}
                showTimeSelect
                type="text"
                placeholder="dd/mm/yyyy"
                validate={[Validators.isValidDateAndTime, Validators.required]}
                timeIntervals={1}
              />
            </FormGroup>
            <FormGroup className="col-12">
              <Button type="submit" className="mt-3" color="primary">
                Save
              </Button>
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    );
  }
}

PhosphineRecordsTopupForm.propTypes = propTypes;

function mapStateToProps(state, props) {
  const { referenceData, currentJob } = state;
  const { currentPhosphineRecord } = props;
  return {
    technicians: referenceData.technicians,
    canEditJob: currentJob.canEditJob,
    splitVessel:
      currentJob.exportShipmentDetails &&
      currentJob.exportShipmentDetails.splitVessel,
    initialValues: currentPhosphineRecord
      ? {
          ...currentPhosphineRecord,
          topUpTechnicianId:
            currentPhosphineRecord.topUpTechnicianId || currentJob.technicianId,
          endDate:
            currentPhosphineRecord.endDate ||
            roundToPrevious(moment().startOf('minute'), 5),
          complete: true
        }
      : null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...jobActions }, dispatch)
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: phosphineRecordsTopupForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Record not saved'
      );
    }
  })
)(PhosphineRecordsTopupForm);
