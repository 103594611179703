import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TreatmentDetails from './TreatmentDetails';
import { formValueSelector, change, reduxForm } from 'redux-form';
import { jobDetailsForm } from '../../constants/FormConstants';

function mapStateToProps(state, props) {
  const { currentJob } = state;
  const { treatmentTypeDetails } = props;
  const treatmentId =
    state.clientDetails != null && currentJob && currentJob.treatmentDetails
      ? currentJob.treatmentDetails.treatmentTypeId
      : null;
  const standardId =
    currentJob && currentJob.treatmentDetails
      ? currentJob.treatmentDetails.standardId
      : null;
  const comment =
    currentJob && currentJob.treatmentDetails
      ? currentJob.treatmentDetails.comments
      : null;
  const formValues = formValueSelector(jobDetailsForm);
  const endPointReadingPercentage =
    currentJob &&
    currentJob.treatmentDetails &&
    currentJob.treatmentDetails.endPointReadingPercentage
      ? currentJob.treatmentDetails.endPointReadingPercentage
      : null;
  return {
    endPointReadingPercentage,
    comment,
    treatmentTypeDetails,
    treatmentId:
      formValues(state, 'treatmentDetails.treatmentTypeId') || treatmentId,
    standardId,
    change,
    ...state.referenceData,
    //,'treatmentDetails.comments'
  };
}

export default compose(withRouter, connect(mapStateToProps))(TreatmentDetails);
