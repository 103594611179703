import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row, Collapse } from 'reactstrap';

import { isVerified } from '../../../util/JobStateHelper';
import { isContainerOrBreakBulkOrOther, isExportAndLogs } from '../../../util/JobTypeHelper';

import { Band, ToggleButton } from '../../layout';
import Checkbubble from '../../common/Checkbubble';

import ContainerTreatmentResultRecordsListContainer from './ContainerTreatmentResultRecordsListContainer';

const propTypes = {
  currentState: PropTypes.string,
  complete: PropTypes.bool,
  jobType: PropTypes.object,
};

class TreatmentResultRecordsCoveredContainerSection extends Component {
  state = {
    collapsed: isVerified(this.props.currentState)
      ? false
      : this.props.complete,
  };

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.complete && nextProps.complete) ||
      this.props.currentState !== nextProps.currentState
    ) {
      const nextCollapsed = isVerified(nextProps.currentState)
        ? false
        : nextProps.complete;
      if (nextCollapsed) {
        this.setState({
          collapsed: true,
        });
      }
    }
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  render() {
    const { collapsed } = this.state;
    const { complete, jobType } = this.props;

    return (
      <Band dark>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>
                     Covered Container Records
                </h4>
              </Col>
              <Col xs="4" className="text-right">
                <Checkbubble
                  className="align-middle"
                  checked={Boolean(complete)}
                />
                <ToggleButton
                  className="ml-3"
                  color="light"
                  active={Boolean(collapsed)}
                  onClick={this.toggleExpand}
                  ligature="expand_less"
                />
              </Col>
            </Row>
            <Collapse isOpen={!collapsed}>
              <ContainerTreatmentResultRecordsListContainer  />
         
            </Collapse>
          </Container>
        </div>
      </Band>
    );
  }
}

TreatmentResultRecordsCoveredContainerSection.propTypes = propTypes;

export default TreatmentResultRecordsCoveredContainerSection;
