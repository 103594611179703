import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as jobStateSummaryActions from 'actions/jobStateSummaryActions';
import * as jobSummaryActions from 'actions/jobSummaryActions';
import JobStateNavigation from 'components/dashboard/JobStateNavigation';
import { Band, Header, HeaderButtonNav, HeaderButton } from 'components/layout';
import JobTableContainer from 'components/dashboard/JobTableContainer';
import OverlayModal from 'components/layout/OverlayModal';
import JobCreatorContainer from 'components/dashboard/JobCreatorContainer';
import { userCanAddJobs } from 'util/AdGroupsHelper';
import TableColumnsDropDown from './TableColumnsDropDown';

class DashboardPage extends Component {
  state = {
    showOverlay: false,
    loaded: false
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const { match, actions } = this.props;
    const jobStateName = match.params.state;
    actions.getJobStateSummaries();
    this.getJobSummaries(jobStateName, {
      page: match.params.page - 1,
      sort: match.params.sort
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) {
      this.unsubscribeFromHistory();
    }
  }

  getJobSummaries(jobStateName, params) {
    const { actions } = this.props;
    this.setState({ loaded: false });
    actions
      .getJobSummaries(jobStateName, params)
      .then(() => this.setState({ loaded: true }));
  }

  toggleNavigation = () => {
    this.setState(prevState => ({
      showOverlay: !prevState.showOverlay
    }));
  };

  jobStateChange = jobState => {
    const jobStateName = jobState.stateName.replace(' ', '-');
    const sort = `${jobState.defaultSortField},${
      jobState.defaultSortDirection
    }`;
    this.loadJobSummaries({
      state: jobStateName,
      page: 1,
      sort: sort
    });
  };

  onPageChange = page => {
    const { match } = this.props;
    const jobStateName = match.params.state;
    this.loadJobSummaries({
      state: jobStateName,
      page: page,
      sort: match.params.sort
    });
  };

  onRowClick = row => {
    const { history } = this.props;

    const url =
      row.currentState === 'new'
        ? '/jobs/details/edit/' + row.id
        : '/jobs/' + row.id;

    history.push(url);
  };

  onHeaderClick = column => {
    const { match } = this.props;
    const jobStateName = match.params.state;

    let sortOrder =
      match.params && match.params.sort
        ? match.params.sort.split(',')[1]
        : null;
    sortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    const sort = `${column.key},${sortOrder}`;

    this.loadJobSummaries({
      state: jobStateName,
      page: match.params.page,
      sort: sort
    });
  };

  loadJobSummaries = params => {
    this.getJobSummaries(params.state, {
      page: params.page - 1,
      sort: params.sort
    });
    const routerAction = this.context.router.history.replace;
    routerAction(`/dashboard/${params.state}/${params.page}/${params.sort}`);
  };

  getCurrentSort = () => {
    let { match } = this.props;
    if (match && match.params) {
      let sort = match.params.sort.split(',');
      return {
        name: sort[0],
        order: sort[1]
      };
    }
    return null;
  };

  render() {
    let { jobStateSummaries } = this.props;
    let sort = this.getCurrentSort();
    return (
      <div>
        <Header showLogo>
          <HeaderButtonNav>
            {userCanAddJobs() && (
              <HeaderButton
                color="primary"
                onClick={this.toggleNavigation}
                ligature="add"
              />
            )}
            <TableColumnsDropDown />
          </HeaderButtonNav>
          <JobStateNavigation
            jobStateSummaries={jobStateSummaries}
            onStateChange={this.jobStateChange}
          />
        </Header>
        <Band dark>
          {this.state.loaded ? (
            <JobTableContainer
              onPageChange={this.onPageChange}
              onRowClick={this.onRowClick}
              onHeaderClick={this.onHeaderClick}
              sort={sort}
            />
          ) : null}
        </Band>
        <OverlayModal
          title="Create a new job"
          isOpen={this.state.showOverlay}
          toggle={this.toggleNavigation}
          wrapClassName="app-overlay"
        >
          <JobCreatorContainer />
        </OverlayModal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    jobStateSummaries: state.jobStateSummaries
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobSummaryActions,
        ...jobStateSummaryActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
