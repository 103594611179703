import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const propTypes = {
  rowData: PropTypes.object,
  onEdit: PropTypes.func.isRequired
};

const EditRowFormatter = ({ rowData, onEdit }) => {
  const handleClick = event => onEdit(rowData);
  return (
    <Button
      color="primary"
      className="btn-toggle btn-toggle-sm"
      onClick={handleClick}
    >
      <i className="material-icons">edit</i>
    </Button>
  );
};

EditRowFormatter.propTypes = propTypes;

export default EditRowFormatter;
