import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container, Row, Col } from 'reactstrap';
import SearchableSelect from '../../components/fields/SearchableSelect';
import { Band } from '../../components/layout';
import { technicianId } from '../../util/JobDetailsValidators';
import { jobDetailsForm } from '../../constants/FormConstants';
import { formValueSelector } from 'redux-form';
import TreatmentTypeDetails from './TreatmentTypeDetails';
import { getCompetentTechnicians } from '../../selectors/technicianSelectors';
import RenderField from 'components/fields/RenderField';
import CheckboxField from '../fields/CheckboxField';

const propTypes = {
  options: PropTypes.array.isRequired,
  currentState: PropTypes.string,
  onTechnicianSelect: PropTypes.func,
  getSelectedCommodity: PropTypes.string,
};

const TechnicianSection = ({
  options,
  currentState,
  onTechnicianSelect,
  treatmentType,
  selectedCommodity,
  selectedStandard,
  jobTypeId,
  areaId,
  skipEralisNumber,
  updateSkipEralisNumber,
  updateEralisNumber,
  eralisJobNumber,
}) => {
  options = getCompetentTechnicians(
    options,
    treatmentType,
    selectedCommodity,
    selectedStandard,
    jobTypeId,
    areaId,
  );
  return (
    <Band dark>
      <div className="form-section">
        <Container>
          <Col md="6">
            <Field
              name={'eralisJobNumber'}
              label={`Eralis Job Number`}
              component={RenderField}
              readOnly={false}
              type="text"
              placeholder="Enter Eralis Number"
              onChange={(e) => updateEralisNumber(e.target.value)}
            />
          </Col>
          <Col md="6">
            <Field
              name={'skipEralisNumber'}
              label={`SAP MultiJob`}
              component={CheckboxField}
              onChange={(e) => updateSkipEralisNumber(e.target.value)}
              placeholder="Enter Eralis Number"
              disabled={false}
            />
          </Col>
          <br />
          <h4>Contact Technician</h4>
          {skipEralisNumber ||
          (eralisJobNumber != '' && eralisJobNumber != undefined) ? (
            <Row>
              <Col md="6">
                <Field
                  name="technicianId"
                  label="Contact Technician"
                  component={SearchableSelect}
                  placeholder="Please select a technician"
                  options={options}
                  onSelect={onTechnicianSelect}
                  valueKey="id"
                  labelKey="name"
                  disabled={treatmentType !== null ? false : true}
                  clearable={!currentState || currentState === 'new'}
                  validate={technicianId}
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </Band>
  );
};

TechnicianSection.propTypes = propTypes;

export default TechnicianSection;
