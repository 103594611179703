import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Badge } from 'reactstrap';
import { Band } from 'components/layout';

const propTypes = {
  jobReference: PropTypes.number.isRequired,
  technician: PropTypes.object,
  currentState: PropTypes.string,
  linkedJobs: PropTypes.array,
  duplicatedFromJob: PropTypes.string,
};

const JobMasthead = ({
  linkedJobs,
  duplicatedFromJob,
  jobReference,
  technician,
  currentState,
  eralisJobNumber,
}) => {
  return (
    <Band tint>
      <div className="form-section">
        <Container>
          <Row>
            <Col md="6">
              <blockquote className="blockquote mb-0">
                <span className="text-primary">Genesys # </span>
                {jobReference}
              </blockquote>
              {eralisJobNumber? 
              <blockquote className="blockquote mb-0">
                <span className="text-primary">Eralis # </span>
                {eralisJobNumber}
              </blockquote>
                :null}
              <Badge color="secondary" pill>
                {currentState}
              </Badge>
            </Col>
            <Col md="6" className="text-right">
              <blockquote className="blockquote mb-0">
                <strong>Contact Technician:</strong>{' '}
                {technician ? technician.name : 'N/A'}
              </blockquote>
              <div className="c-linkedjobs">
                {duplicatedFromJob && (
                  <div className="c-linkedjobs__job">
                    Duplicated from #{duplicatedFromJob}
                  </div>
                )}
                {linkedJobs &&
                  linkedJobs.map((job, index) => {
                    return (
                      <div className="c-linkedjobs__job" key={index}>
                        Created linked job from #{job}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Band>
  );
};

JobMasthead.propTypes = propTypes;

export default JobMasthead;
