import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contactActions from 'actions/contactActions';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';
import ContactsListTable from './ContactsListTable';
import { Label, Row, Col, Button, Card, CardBody, FormGroup } from 'reactstrap';
import Select from 'react-select';

class ContactsList extends Component {
  state = {
    showOverlay: false,
    loaded: false
  };

  componentDidMount() {
    operationsApi.getContacts().then(result => {
      return this.setState({
        loaded: true,
        contacts: result.data
      });
    });
  }

  searchContacts = searchString => {
    if (searchString.length >= 2) {
      operationsApi
        .getContactSearch(searchString)
        .then(result => result.data)
        .then(data => this.setState({ contacts: data }));
      this.setState({ loaded: true });
    }
  };

  toggleNavigation = () => {
    this.setState(prevState => ({
      showOverlay: !prevState.showOverlay
    }));
  };

  resetView = () => {
    this.componentDidMount();
  };

  render() {
    const { loaded, contacts } = this.state;
    const { history, areas } = this.props;

    if (!loaded) return false;

    const options = {
      onRowClick: function(row) {
        history.push('/contacts/edit/' + row.id);
      }
    };

    return (
      <div>
        <Header showLogo>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={() => this.props.history.push('/contacts/create')}
              ligature="add"
            />
          </HeaderButtonNav>
          <HeaderTitle label={'Contacts'} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Select
                    name="Search Val"
                    placeholder="Enter Client name to searching"
                    onInputChange={this.searchContacts}
                  />
                </Col>
                <button onClick={this.resetView}>Reset</button>
                <Col>
                  {' '}
                  <Label>&nbsp;</Label>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Header>
        <ContactsListTable
          options={options}
          contacts={contacts}
          areas={areas}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { areas: state.referenceData.areas };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contactActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
