import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container, FormGroup } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import RenderField from 'components/fields/RenderField';
import * as Validators from 'util/Validators';
import { travelZoneList } from '../../constants/ListConstants';

const propTypes = {
  areas: PropTypes.array
};

const LocationDetailsSection = ({ areas }) => {
  return (
    <section className="form-section">
      <Container>
        <h4>Location Details</h4>
        <div className="form-row align-items-center">
          <FormGroup className="col-md-6">
            <Field
              name="name"
              label="Name *"
              component={RenderField}
              type="text"
              placeholder="Enter a Name"
              validate={Validators.required}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Field
              name="areaId"
              label="Area"
              component={SearchableSelect}
              options={areas}
              valueKey="id"
              labelKey="displayName"
              type="text"
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Field
              name="travelZone"
              label="Travel Zone (1-13)"
              component={SearchableSelect}
              options={travelZoneList}
              valueKey="name"
              labelKey="name"
              type="text"
            />
          </FormGroup>
        </div>
      </Container>
    </section>
  );
};

LocationDetailsSection.propTypes = propTypes;

export default LocationDetailsSection;
