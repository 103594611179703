import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

import Logger from '../../../util/Logger';
import { getTechnicianDisplayName } from '../../../util/JobHelper';
import {
  isExportAndLogs,
  isBreakBulkJobType,
  isMBLogsInHold,
  isImportOrExportAndContainer,
} from '../../../util/JobTypeHelper';
import { roundToPrevious } from '../../../util/DateHelper';
import { confirmAlert } from '../../../util/Alerts';
import * as operationsApi from '../../../api/operationsApi';

import Checkbubble from '../../common/Checkbubble';
import ToggleButton from '../../layout/ToggleButton';

import ContainerTreatmentResultRecordsModal from './ContainerTreatmentResultRecordsModal';
import { map } from 'lodash';
import { isMethylBromide } from '../../../util/TreatmentTypeHelper';
import { nicelyRoundUp, roundToWholeNumber } from '../../../util/FormattingHelper';
import TreatmentResultSummaryContainer from './TreatmentResultSummaryContainer';
import TreatmentResultMarkingsFormContainer from './TreatmentResultMarkingsFormContainer';

const propTypes = {
  actions: PropTypes.object,
  treatmentResultRecords: PropTypes.array.isRequired,
  technicians: PropTypes.array.isRequired,
  jobType: PropTypes.object.isRequired,
  currentJob: PropTypes.object.isRequired,
  jobTypeImageUrl: PropTypes.string.isRequired,
  isTreatmentResultModalDirty: PropTypes.bool,
};

class ContainerTreatmentResultRecordsList extends Component {
  state = {
    selectedTreatmentResultRecord: null,
  };

  toggleShowTreatmentResultModal = (TreatmentResultRecord) => {
    const { showTreatmentResultModal } = this.state;
    this.setState({
      showTreatmentResultModal: !showTreatmentResultModal,
      selectedTreatmentResultRecord: TreatmentResultRecord,
    });
  };

  closeModals = () => {
    if (this.props.isTreatmentResultModalDirty) {
      confirmAlert(
        'Confirm',
        'You have unsaved changes. Are you sure you want to exit?',
      ).then((confirmed) => {
        if (confirmed) {
          this.setState({
            showTreatmentResultModal: false,
          });
        }
      });
    } else {
      this.setState({
        showTreatmentResultModal: false,
      });
    }
    this.getJob({ jobId: this.props.currentJob.id });
  };

  handleSubmission = (formValues) => {
    const { treatmentResultRecords, jobType } = this.props;
    const saveAction = formValues.id
      ? operationsApi.updateTreatmentResultRecord
      : operationsApi.createNewTreatmentResultRecord;

    //Check for duplicate row/stack number in other treatment results
    const rowAndStackValidation = this.validateRowAndStackNumbers(
      formValues,
      treatmentResultRecords,
      jobType,
    );

    if (rowAndStackValidation.showConfirm) {
      confirmAlert(
        'Duplicate Values',
        rowAndStackValidation.confirmMessage,
      ).then(
        (confirm) => confirm && this.performSaveAction(saveAction, formValues),
      );
      return;
    }

    const monitoringValidation = this.validateMonitoringReadingsAgainstStd(
      formValues,
    );
    if (monitoringValidation.showConfirm) {
      confirmAlert('Confirm', monitoringValidation.confirmMessage).then(
        (confirm) => confirm && this.performSaveAction(saveAction, formValues),
      );
      return;
    }

    this.performSaveAction(saveAction, formValues);
  };

  validateRowAndStackNumbers = (
    formValues,
    treatmentResultRecords,
    jobType,
  ) => {
    const filteredTreatmentResults = treatmentResultRecords.filter(
      (tr) => tr.id !== formValues.id,
    );

    if (isExportAndLogs(jobType)) {
      if (formValues.yardNumber) {
        const yardNumbers = filteredTreatmentResults
          .filter((e) => e !== undefined)
          .map((e) => e.yardNumber);

        if (yardNumbers.indexOf(formValues.yardNumber) !== -1) {
          return {
            showConfirm: true,
            confirmMessage: `The yard number '${formValues.yardNumber}' is already in use on this job. Are you sure you want to use this yard number?`,
          };
        }
      }
    } else if (isBreakBulkJobType(jobType) && formValues.stackNumber) {
      const stackNumbers = filteredTreatmentResults
        .filter((e) => e !== undefined)
        .map((e) => e.stackNumber);

      return {
        showConfirm: stackNumbers.indexOf(formValues.stackNumber) !== -1,
        confirmMessage: `The stack number '${formValues.stackNumber}' is already in use on this job. Are you sure you want to use this stack number?`,
      };
    }

    return {
      showConfirm: false,
      confirmMessage: null,
    };
  };

  validateMonitoringReadingsAgainstStd = (formValues) => {
    const readings = formValues.concentrationReadingItem || [];
    const warn = readings.some((row) => {
      return (
        Boolean(+row.stdgramPerCubicMetre) &&
        !+row.topupDose &&
        [1, 2, 3].some((i) => {
          const value = +row[`reading${i}`];
          return value && value < +row.stdgramPerCubicMetre;
        })
      );
    });
    return {
      showConfirm: warn,
      confirmMessage: warn
        ? 'Treatment standards have not been meet do you wish to proceed'
        : null,
    };
  };

  performSaveAction = (saveAction, formValues) => {
    saveAction(formValues)
      .then((response) => Logger.success('Treatment Record Saved'))
      .then(() => this.getJob(formValues));
  };

  deleteTreatmentResultRecord = (record) => {
    const { currentJob } = this.props;
    confirmAlert('Delete', 'Are you sure you want to delete this record?').then(
      (confirm) =>
        confirm &&
        operationsApi
          .deleteTreatmentResultsRecord(record.id)
          .then(() => this.getJob({ jobId: currentJob.id })),
    );
  };

  getJob = (formValues) => {
    const { actions } = this.props;
    actions.getJob(formValues.jobId).then((response) => {
      this.setState({
        showTreatmentResultModal: false,
        selectedTreatmentResultRecord: null,
      });
    });
  };

  render() {
    const {
      treatmentResultRecords,
      jobTypeImageUrl,
      jobType,
      technicians,
      currentJob,
    } = this.props;
    const {
      showTreatmentResultModal,
      selectedTreatmentResultRecord,
    } = this.state;


    const coveredRecords =treatmentResultRecords.filter((e)=>e.coveredContainer===true)

    if (!treatmentResultRecords) {
      return <div>No Records</div>;
    } else {
      return (
        <div>
          <BootstrapTable
            headerContainerClass="table-sm"
            trClassName="app-table-row"
            version="4"
            data={coveredRecords}
            bordered={false}
            remote
            options={{
              onRowClick: this.toggleShowTreatmentResultModal,
            }}
          >
            <TableHeaderColumn
              isKey
              className="col-hidden"
              columnClassName="keyColumn"
              dataField="id"
              hidden
            />
            <TableHeaderColumn
              width="55"
              dataFormat={(cell, row) => (
                <img
                  style={{ maxHeight: '30px' }}
                  src={jobTypeImageUrl}
                  alt="Job Type Icon"
                />
              )}
            />
            {isImportOrExportAndContainer(jobType) && isMethylBromide(currentJob.treatmentDetails.treatmentTypeId) ? (
              <TableHeaderColumn width="150" dataField="containerNumber">
                Cont#
              </TableHeaderColumn>
            ) : 
            jobType.name ==='MB Logs In Hold'?
            <TableHeaderColumn width="80" dataField="containerNumber">
            Hold #
            </TableHeaderColumn>:
            null}
            {isBreakBulkJobType(jobType) ? (
              <TableHeaderColumn width="80" dataField="stackNumber">
                Stack #
              </TableHeaderColumn>
            ) : null}
            
            <TableHeaderColumn
              width="100"
              dataField="M3"
              dataFormat={(_, row) => row.mbkg && roundToWholeNumber(row.mbkg).toFixed(0) }
            >
              MBkg
            </TableHeaderColumn>
            <TableHeaderColumn
              width="150"
              dataFormat={(_, row) => (
               
                <div>{row.startDate && formatDate(row.startDate)}</div>
              )}
            >
              Started at
            </TableHeaderColumn>
            <TableHeaderColumn
              dataFormat={(_, row) => (
                <div>
                  {getTechnicianDisplayName(
                    row.coveringTechnicianId,
                    technicians,
                  )}
                </div>
              )}
            >
              Covered by
            </TableHeaderColumn>
            <TableHeaderColumn
              width="150"
              dataFormat={(_, row) => (
                <div>
                  {row.uncoveringTechnicianId ? formatDate(row.endDate) : null}
                </div>
              )}
            >
              Finished at
            </TableHeaderColumn>
            <TableHeaderColumn
              dataFormat={(cell, row) => (
                <div>
                  {getTechnicianDisplayName(
                    row.uncoveringTechnicianId,
                    technicians,
                  )}
                </div>
              )}
            >
              Uncovered by
            </TableHeaderColumn>
            <TableHeaderColumn
              dataFormat={(cell, row) => <div>{row.extraHours}</div>}
            >
              Extra Hours
            </TableHeaderColumn>
            <TableHeaderColumn
              width="40"
              dataFormat={(cell, row) => (
                <div>
                  {row.uncoveringTechnicianId ? (row.passed ? 'Y' : 'N') : null}
                </div>
              )}
            />
            {currentJob.canEditJob ? (
              <TableHeaderColumn
                width="58"
                dataFormat={(cell, row) => (
                  <Button
                    outline
                    color={'danger'}
                    style={{
                      width: '32px',
                      height: '32px',
                      position: 'relative',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.deleteTreatmentResultRecord(row);
                    }}
                  >
                    <i
                      aria-hidden="true"
                      className="material-icons"
                      style={{
                        position: 'absolute',
                        top: '4px',
                        left: '4px',
                      }}
                    >
                      delete_outline
                    </i>
                  </Button>
                )}
              />
            ) : null}
          </BootstrapTable>

          {currentJob.canEditJob ? (
            <div className="text-right mt-3">
              <ToggleButton
                color="light"
                onClick={() =>
                  this.toggleShowTreatmentResultModal({
                    jobId: currentJob.id,
                    coveringTechnicianId: currentJob.technicianId,
                    startDate: null, //roundToPrevious(moment().startOf('minute'), 5)
                  })
                }
                ligature="add"
              />
            </div>
          ) : (
            <br />
          )}

          <ContainerTreatmentResultRecordsModal
            title={"Treatment Record "+currentJob.jobReference}
            isOpen={showTreatmentResultModal}
            toggle={this.closeModals}
            onSubmit={this.handleSubmission}
            currentTreatmentResultRecord={selectedTreatmentResultRecord}
            jobType={jobType}
          />
        </div>
      );
    }
  }
}

ContainerTreatmentResultRecordsList.propTypes = propTypes;

function formatDate(date) {
  const d = moment(date);
  const now = moment();
  let format;
  if (d.year() === now.year()) {
    format = 'DD/MM HH:mm';
  } else {
    format = 'DD/MM/YYYY';
  }
  return d.format(format);
}

export default ContainerTreatmentResultRecordsList;
