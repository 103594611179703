import operationsApiClient from './operationsApiClient';
import {
  standardWorkflowId,
  defaultPageSize,
} from '../constants/JobWorkflowConstants';
import { convertHTMLToBase64 } from '../util/FileHelper';

export function getJobTypes() {
  return operationsApiClient.get('/jobtypes');
}

export function getJobTableDefinitions() {
  return operationsApiClient.get('/jobTableDefinitions/' + standardWorkflowId);
}

export function getUserColumnVisibilities() {
  return operationsApiClient.get('/usercolumnvisibilities');
}

export function setUserColumnVisibilities(data) {
  return operationsApiClient.put('/usercolumnvisibilities', data);
}

export function deleteUserColumnVisibilities(id) {
  return operationsApiClient.delete(`/usercolumnvisibilities/${id}`);
}

export function getJobLocations() {
  return operationsApiClient.get('/joblocations');
}

export function getJob(jobId) {
  return operationsApiClient
    .get('/jobs/' + jobId)
    .then((response) => response.data);
}

export function createNewJob(job) {
  // eslint-disable-next-line no-console
  return operationsApiClient
    .post('/jobs', job)
    .then((response) => response.data);
}

export function duplicateJob(jobId, job) {
  return operationsApiClient
    .post('/jobs/' + jobId + '/duplicateJob', job)
    .then((response) => response.data);
}

export function validateJobMarkings(jobId, markings) {
  return operationsApiClient
    .get(
      '/JobValidations/markingsExist/' +
        jobId +
        '?markings=' +
        markings
          .map((marking) => marking.markingNumber || marking.containerNumber)
          .join('&markings='),
    )
    .then((response) => response.data);
}

export function updateJob(job) {
  return operationsApiClient.put('/jobs/' + job.id, job);
}

export function updateJobSendNOC(job) {
  return operationsApiClient.put('/jobs/sendNoc', job)
  .then((response)=>response.data);
}


export function updateJobType(jobId, newJobTypeId) {
  return operationsApiClient.put(
    '/jobs/' + jobId + '/changejobtype?typeid=' + newJobTypeId,
  );
}

export function getTechnicians() {
  return operationsApiClient.get('/technicians');
}

export function getTechnician(id) {
  return operationsApiClient.get('/technicians/' + id);
}

export function getParentJobReference(id) {
  return operationsApiClient
    .get('/jobs/byNumber/' + id)
    .then((response) => response.data);
}

export function createNewTechnician(technician) {
  return operationsApiClient.post('/technicians', technician);
}

export function updateTechnician(technician) {
  return operationsApiClient.put('/technicians/' + technician.id, technician);
}

export function getJobSummaries(jobStateName, params) {
  const defaultParams = {
    size: defaultPageSize,
  };
  return operationsApiClient.get(
    `/jobsummary/${standardWorkflowId}/${jobStateName}`,
    {
      params: {
        ...defaultParams,
        ...params,
      },
    },
  );
}

export function getJobSummariesSearch(params) {
  return operationsApiClient
    .get('/jobsummary/search', { params })
    .then((result) => result.data);
}

export function getJobStateSummaries() {
  return operationsApiClient.get('/jobstatessummary/');
}

export function getClientSummaries(params) {
  return operationsApiClient.get('/clientidmappings/GetAll');
}

export function getClientSearch(val) {
  const ret = operationsApiClient.get(`/clientidmappings/${val}`);
  return ret;
}
export function getSubjob(val) {
  return operationsApiClient.get(`/eralisjob/${val}`);
}

export function createClient(client) {
  return operationsApiClient.post('/clientidmappings/', client);
}

export function deleteClient(clientId) {
  return operationsApiClient.delete(`/clientidmappings/${clientId}`);
}

export function patchClient(clientId, data) {
  return operationsApiClient.patch(`/clientidmappings/${clientId}`, data);
}

export function getClientSummariesPage(params) {
  const defaultParams = {
    size: defaultPageSize,
  };
  return operationsApiClient.get('/clientidmappings', {
    params: {
      ...defaultParams,
      ...params,
    },
  });
}

export function getClientDetails(code) {
  return operationsApiClient.get('/clientdetails/' + code);
}

export function getJobStandards() {
  return operationsApiClient.get('/JobStandards');
}

export function getTreatmentTypes() {
  return operationsApiClient.get('/TreatmentTypes');
}

export function getAllTreatmentTypes() {
  //return operationsApiClient.get('/TreatmentTypes');
  return operationsApiClient.get('/TreatmentTypes/treatment');
}

export function getContainerTypes() {
  return operationsApiClient.get('/ContainerTypes');
}

export function getSizeOfContainers() {
  return operationsApiClient.get('/sizeOfContainers');
}

export function getJobCategories() {
  return operationsApiClient.get('/JobCategories');
}

export function getDestinations() {
  return operationsApiClient.get('/destinations');
}

export function getCommodities() {
  return operationsApiClient.get('/Commodities');
}

export function getDocumentUrl(id) {
  return operationsApiClient.get('/documents/' + id + '/url');
}

export function getContacts() {
  return operationsApiClient.get('/contacts');
}

export function getContactSearch(val) {
  const ret = operationsApiClient.get(`/contacts/search/` + val);
  return ret;
}

export function getContact(id) {
  return operationsApiClient.get('/contacts/' + id);
}

export function createNewContact(contact) {
  return operationsApiClient.post('/contacts', contact);
}

export function updateContact(contact) {
  return operationsApiClient.put('/contacts/' + contact.id, contact);
}
//VESSELS

export function getVesselSearch(val) {
  const ret = operationsApiClient.get(`/vessels/search/` + val);
  return ret;
}

export function getVessels() {
  return operationsApiClient.get('/Vessels');
}

export function getAllVessels() {
  return operationsApiClient.get('/Vessels/all');
}

export function getVessel(id) {
  return operationsApiClient.get('/Vessels/' + id);
}

export function createNewVessel(vessel) {
  return operationsApiClient.post('/Vessels', vessel);
}

export function updateVessel(vessel) {
  return operationsApiClient.put('/Vessels/' + vessel.id, vessel);
}

// AREAS
export function getAreas() {
  return operationsApiClient.get('/Areas');
}
export function getArea(id) {
  return operationsApiClient.get('/Areas/' + id);
}
export function getAreaLocations(id) {
  return operationsApiClient.get('/JobLocations/area/' + id);
}

// LOCATIONS
export function getLocations() {
  return operationsApiClient.get('/JobLocations');
}

export function getLocation(id) {
  return operationsApiClient.get('/JobLocations/' + id);
}

export function createNewLocation(location) {
  return operationsApiClient.post('/JobLocations', location);
}

export function updateLocation(location) {
  return operationsApiClient.put('/JobLocations/' + location.id, location);
}
export function deleteLocation(locationId) {
  return operationsApiClient.delete('/JobLocations/' + locationId);
}

//
export function getNoticeOfIntendedTreatment(job) {
  return operationsApiClient.post('/NoticeOfIntendedTreatments', job);
}

export function getNoticeOfCompletion(job) {
  return operationsApiClient.post('/NoticeOfCompletions', job);
}

export function getTreatmentCertificates(job) {
  return operationsApiClient.post('/TreatmentCertificates', job);
}

export function createNewTreatmentResultRecord(treatmentResultRecord) {
  return operationsApiClient.post(
    `/jobs/${treatmentResultRecord.jobId}/treatmentResultRecords`,
    treatmentResultRecord,
  );
}

export function updateTreatmentResultRecord(treatmentResultRecord) {
  return operationsApiClient.put(
    `/treatmentResultRecords/${treatmentResultRecord.id}`,
    treatmentResultRecord,
  );
}

export function deleteTreatmentResultsRecord(id) {
  return operationsApiClient.delete(`/treatmentResultRecords/${id}`);
}

export function deleteConcentrationReadingRecord(id) {
  console.log('deleteCRRI',id)
  return operationsApiClient.delete(`/concentrationReadingItems/${id}`);
}

export function deleteRecaptureReadingRecord(id) {
  console.log('deleteRRI',id)
  return operationsApiClient.delete(`/recaptureReadingItems/${id}`);
}
export function deleteEFRecord(id) {
  return operationsApiClient.delete(`/efReadingItems/${id}`);
}
export function createTreatmentResults(treatmentResults) {
  return operationsApiClient.post(
    `/jobs/${treatmentResults.jobId}/treatmentResults`,
    treatmentResults,
  );
}

export function updateTreatmentResults(treatmentResults) {
  return operationsApiClient.put(
    `/treatmentResults/${treatmentResults.id}`,
    treatmentResults,
  );
}

export function createPhosphineRecord(phosphineRecord) {
  return operationsApiClient.post(
    `/jobs/${phosphineRecord.jobId}/phosphineTreatmentResultRecords`,
    phosphineRecord,
  );
}

export function updatePhosphineRecord(phosphineRecord) {
  return operationsApiClient.put(
    `/phosphineTreatmentResultRecords/${phosphineRecord.id}`,
    phosphineRecord,
  );
}

export function deletePhosphineRecord(id) {
  return operationsApiClient.delete(`/phosphineTreatmentResultRecords/${id}`);
}

export function getEmail(emailId) {
  return operationsApiClient.get(`/emails/${emailId}`);
}

export function generatePDF(HTMLString) {
  const base64String = convertHTMLToBase64(HTMLString);

  return operationsApiClient.post(
    `/pdfs`,
    {
      contents: base64String,
    },
    { responseType: 'arraybuffer' },
  );
}
