import React from 'react';
import { Modal } from 'reactstrap';

import TouchModal from '../TouchModal';

import { withIsTouch } from '../../../hoc/media';

function ResponsiveModal({ isTouch, ...props }) {
  return isTouch ? <TouchModal {...props} /> : <Modal {...props} />;
}

export default withIsTouch(ResponsiveModal);
