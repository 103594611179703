import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Label } from 'reactstrap';

import * as Validators from '../../util/Validators';
import {
  isImportAndContainer,
  isExportAndContainer,
  isImportJobType,
  isExportAndLogs,
} from '../../util/JobTypeHelper';

import RenderField from './RenderField';
import CheckboxField from './CheckboxField';

import ToggleButton from '../layout/ToggleButton';

import styles from './MarkingsFieldArray.module.scss';

const propTypes = {
  label: PropTypes.string,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  jobType: PropTypes.object.isRequired,
  includeSighted: PropTypes.bool,
};

function MarkingsFieldArray({
  fields,
  meta: { error },
  label,
  jobType,
  includeSighted,
}) {
  const isContainer =
    isImportAndContainer(jobType) || isExportAndContainer(jobType);

  const addNewMarking = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      e.preventDefault();
      fields.push({});
    }
  };

  return (
    <div className={styles.markingsFieldArray}>
      {label && <Label className={styles.label}>{label}</Label>}
      {fields.map((marking, index) => (
        <div key={index} className={styles.markingItem}>
          <Field
            className={styles.noLabel}
            name={
              isContainer
                ? `${marking}.containerNumber`
                : `${marking}.markingNumber`
            }
            type="text"
            component={RenderField}
            validate={Validators.required}
            placeholder={isContainer ? 'Container #' : 'Marking #'}
            onKeyPress={addNewMarking}
            autoFocus={index === fields.length - 1}
          />
          {isContainer && (
            <Field
              name={`${marking}.sealNumber`}
              type="text"
              component={RenderField}
              placeholder="Seal #"
              onKeyPress={addNewMarking}
            />
          )}
          {includeSighted && (
            <Field
              name={`${marking}.sighted`}
              label="Sighted"
              component={CheckboxField}
            />
          )}
          <span
            title="Remove Marking"
            aria-label="Clear value"
            onClick={() => fields.remove(index)}
          >
            <span className={styles.clear}>×</span>
          </span>
        </div>
      ))}
      <ToggleButton
        className={styles.addButton}
        color="light"
        onClick={() => fields.push({})}
        ligature="add"
      />
    </div>
  );
}

MarkingsFieldArray.propTypes = propTypes;

export default MarkingsFieldArray;
