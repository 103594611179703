import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import registerLoader from './registerLoader';
import 'styles/main.css';
import App from 'components/App';
import 'babel-polyfill';

const store = configureStore();
const rootNode = document.getElementById('root');
const rootComponent = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(rootComponent, rootNode);
registerLoader();
