import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import JobTypeChangers from './JobTypeChangers';

const propTypes = {
  currentTypeId: PropTypes.string,
  currentCategory: PropTypes.string,
  jobTypes: PropTypes.array.isRequired,
  activeTab: PropTypes.string,
  handleChange: PropTypes.func,
  modalTitle: PropTypes.string
};

class JobChanger extends Component {
  render() {
    const {
      handleChange,
      currentTypeId,
      currentCategory,
      jobTypes
    } = this.props;
    return (
      <div>
        <Nav tabs className="mb-4">
          <NavItem>
            <NavLink className="active">{currentCategory}</NavLink>
          </NavItem>
        </Nav>
        <TabContent>
          <TabPane>
            <JobTypeChangers
              handleChange={handleChange}
              currentCategoryId={currentTypeId}
              jobTypes={jobTypes}
              activeJobType={currentTypeId}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

JobChanger.propTypes = propTypes;

export default JobChanger;
