import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function treatmentTypesReducer(
  state = initialState.referenceData.treatmentTypes,
  action,
) {
  if (action.type === types.GET_TREATMENT_TYPES_SUCCESS) {
    return [...action.treatmentTypes];
  }

  return state;
}
