export const createSelectOptionsFromStringArray = (array) => {
  if (!array || !array.length) return [];

  return array.map((value) => ({
    value: value,
    label: value,
  }));
};

const matchesAreaId = (contact, areaId) =>
  contact.areas.find((area) => area.areaId === areaId);

export const getContactsByAreaId = (contacts, areaId) =>
  contacts.filter((contact) => matchesAreaId(contact, areaId));

export const getDefaultContactsByNoticeKey = (contacts, noticeKey) => {
  let defaultContacts = contacts
    .filter((contact) => contact[noticeKey] === true)
    .map((contact) => contact.id);
  defaultContacts = defaultContacts.join();
  return defaultContacts;
};
