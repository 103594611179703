import { connect } from 'react-redux';
import JobAttachments from 'components/jobs/JobAttachments';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import Logger from 'util/Logger';
import { jobPicturesForm } from 'constants/FormConstants';

function mapStateToProps(state) {
  const formValues = formValueSelector(jobPicturesForm);
  const { currentJob } = state;
  const clientDetails = state.clientDetails;
  return {
    clientDetails,
    canEditJob: currentJob.canEditJob,
    currentState: currentJob.currentState,
    attachments: formValues(state, 'attachments') || [],
    initialValues: { attachments: currentJob.attachments }
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: jobPicturesForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Pictures not saved'
      );
    }
  })
)(JobAttachments);
