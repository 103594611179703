import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlainTextField from '../../../components/fields/PlainTextField';
import PlainTextDateField from '../../../components/fields/PlainTextDateField';
import { Container, Row, Col } from 'reactstrap';
import { Band } from '../../../components/layout';
import JobSignoffAlerts from '../../../components/jobs/jobSignoff/JobSignoffAlerts';
import {
  isExportAndLogs,
  isMBLogsInHold,
  isBreakBulkJobType,
  isImportOrExportAndContainer,
  isExportOtherMBApples,
} from '../../../util/JobTypeHelper';
import { nicelyRoundUp } from '../../../util/FormattingHelper';
import { countContainers } from '../../../util/JobHelper';
import { isCallington1Shot, isEthylFormate } from '../../../util/TreatmentTypeHelper';
import { isClassComponent } from 'recompose';

const propTypes = {
  showSummary: PropTypes.bool.isRequired,
  treatmentResultsSummary: PropTypes.object.isRequired,
};



class TreatmentResultsSummary extends Component {
  getTotalMBApples =(treatmentresultrecords)=>{
    var totalMB = 0;
    let sum = treatmentresultrecords.reduce(function(prev, current) {
      return prev + +current.mbkg}, 0);
     return  sum;
  } 

  render() {
    const { showSummary, treatmentResultsSummary, currentJob } = this.props;
    
    const treatmentTypeId = currentJob.treatmentDetails.treatmentTypeId;
    const jobType = currentJob.type;
    const commodity = currentJob.treatmentTypeDetails.commodity;
    const standardId = currentJob.treatmentDetails.standardId;
    const isAppleMB = isExportOtherMBApples(jobType,treatmentTypeId,commodity,standardId)
    var containerTot = 0;
    const totalAppleMB = this.getTotalMBApples(currentJob.treatmentResultRecords)

   const totalMbUsed = isAppleMB?this.getTotalMBApples(currentJob.treatmentResultRecords):treatmentResultsSummary.totalMb

    if(currentJob.treatmentResultRecords!=undefined){
      containerTot = countContainers(currentJob.treatmentResultRecords)
    }

    let countExtraHoursTotal = 0;
    if (currentJob.treatmentResultRecords) {
      currentJob.treatmentResultRecords.map((result) => {
        if (!result.extraHours) return null;

        return (countExtraHoursTotal =
          countExtraHoursTotal + result.extraHours);
      });
    }
    const isCoveredContainer = currentJob.treatmentTypeDetails.covered?currentJob.treatmentTypeDetails.covered:null;

    if (!showSummary) {
      return null;
    } else {
      return (
        <Band dark>
          <Container>
            <Row>
            {!isImportOrExportAndContainer(currentJob.type)&&!isAppleMB?(
              <Col xs="6" lg="4">
                <PlainTextDateField
                  name="startDate"
                  label="Start Date"
                  value={treatmentResultsSummary.startDate}
                  showTime
                />
              </Col>):null}
              {isBreakBulkJobType(currentJob.type) ||isAppleMB ?null:
              <Col xs="6" lg="4">
                <PlainTextField
                  name="totalJas"
                  label={
                    isBreakBulkJobType(currentJob.type) ||
                    isMBLogsInHold(currentJob.type)||isImportOrExportAndContainer(currentJob.type)
                      ? 'Total M3'
                      : 'Total JAS'
                  }
                  value={
                    treatmentResultsSummary.totalJas &&
                    treatmentResultsSummary.totalJas.toFixed(3)
                  }
                />
              </Col>
                }
                {isCallington1Shot(treatmentTypeId)?null:
              <Col xs="6" lg="4">
                <PlainTextField
                  name="totalMb"
                  label={isEthylFormate(treatmentTypeId)?"EF/CO2 Kg":"Total MB"}
                  value={totalMbUsed}
                  format={nicelyRoundUp}
                />
              </Col>
              }
              {!isMBLogsInHold(currentJob.type) && !isImportOrExportAndContainer(currentJob.type)&&!isAppleMB? (
                <Col xs="6" lg="4">
                  <PlainTextField
                    name="totalCoveredVolume"
                    label={"Total Covered Volume"}
                    value={treatmentResultsSummary.totalCoveredVolume}
                    format={nicelyRoundUp}
                  />
                </Col>
              ) : null}
               {!isImportOrExportAndContainer(currentJob.type)&&!isAppleMB?(
              <Col xs="6" lg="4">
                <PlainTextDateField
                  name="endDate"
                  label="End Date"
                  value={treatmentResultsSummary.endDate}
                  showTime
                />
              </Col> ):null}
              {!isAppleMB&&!isImportOrExportAndContainer(currentJob.type) || isCoveredContainer?(
              <Col xs="6" lg="4">
                <PlainTextField
                  name="actualTemperature"
                  label={isCallington1Shot(treatmentTypeId)?"No of Cans":"Actual Temperature"}
                  value={isCallington1Shot(treatmentTypeId)?treatmentResultsSummary.totalNoCans:treatmentResultsSummary.actualTemperature}
                />
              </Col>):null}
              {isCallington1Shot(treatmentTypeId)||isAppleMB?null:!isImportOrExportAndContainer(currentJob.type) || isCoveredContainer ?(
              <Col xs="6" lg="4">
                <PlainTextField
                  name="rows"
                  label={
                    isExportAndLogs(currentJob.type)
                     ? 'Total Number of Rows':
                     isCoveredContainer?'Total number of Stacks'
                      : currentJob.type.name === 'MB Logs In Hold'
                      ? 'Total Number of Holds'
                      : 'Total number of Stacks'
                  }
                  value={
                    currentJob && currentJob.treatmentResultRecords
                      ? currentJob.treatmentResultRecords.length
                      : 0
                  }
                />
              </Col>):null}
              {isCallington1Shot(treatmentTypeId)||isAppleMB?null:!isImportOrExportAndContainer(currentJob.type)?(
              <Col xs="6" lg="4">
                <PlainTextField
                  name="extraHours"
                  label="Extra Hours"
                  value={countExtraHoursTotal}
                />
              </Col>):null}
              {isEthylFormate(treatmentTypeId)?null:isImportOrExportAndContainer(currentJob.type) && !isCoveredContainer?(
              <Col xs="6" lg="4">
                <PlainTextField
                  name="Container Total"
                  label="Container Total"
                  value={containerTot}
                />
              </Col>
              ):null}
              <Col xs="8">
                <JobSignoffAlerts currentJob={currentJob} />
              </Col>
            </Row>
          </Container>
        </Band>
      );
    }
  }
}

TreatmentResultsSummary.propTypes = propTypes;

export default TreatmentResultsSummary;
