import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change } from 'redux-form';
import { withRouter } from 'react-router';
import { compose, withProps } from 'recompose';

import Logger from '../../../../util/Logger';
import * as jobActions from '../../../../actions/jobActions';
import { containerTreatmentResultRecordsForm } from '../../../../constants/FormConstants';

import { withSortedReadings, withCalculatedFields } from './containerEnhancers';

import ContainerTreatmentResultRecordsForm from './ContainerTreatmentResultRecordsForm';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...jobActions, changeFieldValue: change },
      dispatch,
    ),
  };
}

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  // Sort readings from start/end and by date
  withSortedReadings,
  // Set form initial values
  withProps(({ currentTreatmentResultRecord }) => ({
    initialValues: currentTreatmentResultRecord,
  })),
  reduxForm({
    form: containerTreatmentResultRecordsForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Log Row Record not saved',
      );
    },
  }),
  withCalculatedFields,
)(ContainerTreatmentResultRecordsForm);
