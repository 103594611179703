import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { compose } from 'recompose';

import styles from '../TreatmentResultRecordsForm/styles.module.scss';

import { preventEnterSubmit } from '../../../../actions/formActions';

import Accordion, { AccordionItem } from '../../../common/Accordion';

import ContainerTreatmentResultRecordsStartDetailsSection from '../TreatmentResultRecordsStartDetailsContainerSection';
import TreatmentResultRecordsMonitoringSection from '../TreatmentResultRecordsMonitoringSection';
import TreatmentResultRecordsUncoveringSection from '../TreatmentResultRecordsUncoveringSection';

import { withShowMonitoring } from '../../../jobs/treatmentResultRecords/enhancers';
import { withAccordionActiveItemIndex } from './containerEnhancers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentJob} from '../selectors';
import {
  isMBLogsInHold
} from '../../../../util/JobTypeHelper';

const propTypes = {
  handleSubmit: PropTypes.func,
  actions: PropTypes.shape({
    changeFieldValue: PropTypes.func.isRequired,
  }),
  showMonitoring: PropTypes.bool,
  accordionActiveItemIndex: PropTypes.number.isRequired,
};


class ContainerTreatmentResultRecordsForm extends Component {
 

 
  render() {
    const {
      handleSubmit,
      actions,
      showMonitoring,
      accordionActiveItemIndex,
      currentTreatmentResultRecord,
      jobType,
    } = this.props


    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <Accordion
          className={styles.accordion}
          open={[accordionActiveItemIndex]}
        >
          <AccordionItem  title="Start Details" >
            <ContainerTreatmentResultRecordsStartDetailsSection actions={actions} isCoveredContainer={true}/>
          </AccordionItem>
          {showMonitoring && (
            <AccordionItem title="Monitoring" className={styles.monitoring}>
              <TreatmentResultRecordsMonitoringSection actions={actions} />
            </AccordionItem>
          )}
          <AccordionItem title= {!isMBLogsInHold(jobType)?"Uncovering Details" : 'Venting Details' }>
            <TreatmentResultRecordsUncoveringSection actions={actions} />
          </AccordionItem>
        </Accordion>
      </Form>
    );
  }
}


ContainerTreatmentResultRecordsForm.propTypes = propTypes;


export default compose(
  withShowMonitoring,
  withAccordionActiveItemIndex,
)(ContainerTreatmentResultRecordsForm);
