import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import keycloakManager from '../keycloakManager';
import { Route, Redirect } from 'react-router-dom';

const propTypes = {
  location: PropTypes.object,
  roles: PropTypes.array
};

class AuthedRoute extends Component {
  componentDidMount() {}

  render() {
    const { roles } = this.props;

    if (!keycloakManager.isAuthenticated) {
      keycloakManager.login();
    }

    if (!keycloakManager.isInGroup(roles)) {
      return (
        <Redirect
          to={{
            pathname: '/401',
            state: { from: this.props.location }
          }}
        />
      );
    }

    return <Route {...this.props} />;
  }
}

AuthedRoute.propTypes = propTypes;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthedRoute);
