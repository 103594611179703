import React from 'react';
import PropTypes from 'prop-types';
import AppModal from 'components/common/AppModal';

const propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  duplicatedJob: PropTypes.object
};

const JobDuplicateConfirmationModal = ({
  duplicatedJob,
  title,
  isOpen,
  toggle
}) => {
  return (
    <AppModal title={title} isOpen={isOpen} toggle={toggle}>
      <p>
        Duplicate job #{
          duplicatedJob.linkedJobs[duplicatedJob.linkedJobs.length - 1]
        }{' '}
        successfully created. Visit the Assigned dashboard to view/edit the job
      </p>
    </AppModal>
  );
};

JobDuplicateConfirmationModal.propTypes = propTypes;

export default JobDuplicateConfirmationModal;
