import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Label, Container } from 'reactstrap';
import { Band } from 'components/layout';
import * as fromValidators from 'util/Validators';
import { jobDetailsForm } from 'constants/FormConstants';

const propTypes = {
  clientDetails: PropTypes.object
};

const renderClientEmails = emails => {
  return emails.map(email => (
    <li key={email}>
      <a
        href={`mailto:${email}`}
        className={classNames({ 'u-text-error': !isValidEmail(email) })}
      >
        {email}
      </a>
    </li>
  ));
};

const isValidEmail = email => !fromValidators.email(email);

const ClientDetailsSection = ({ clientDetails }) => {
  if (!clientDetails) return false;
  const {
    address1,
    address2,
    address3,
    suburb,
    state,
    phone,
    fax,
    emails,
    status
  } = clientDetails;
  var credit = clientDetails.stopCredit===true? credit=  'StopCredit':'';
  return (
    <Band tint>
      <div className="form-section">
        <Container>
          <Row>
            <Col md="3">
              <Label>Postal Address</Label>
              <p>
                {address1 ? (
                  <span>
                    {address1} <br />
                  </span>
                ) : null}
                {address2 ? (
                  <span>
                    {address2} <br />
                  </span>
                ) : null}
                {address3 ? (
                  <span>
                    {address3} <br />
                  </span>
                ) : null}
                {suburb ? (
                  <span>
                    {suburb} <br />
                  </span>
                ) : null}
                {state ? (
                  <span>
                    {state} <br />
                  </span>
                ) : null}
              </p>
            </Col>
            <Col md="3">
              <Label>Phone Number</Label>
              <p>{phone}</p>
              <Label>Client Status</Label>
              <p style = {status==="Inactive"||credit==="StopCredit"?{color:"red",fontWeight:"bold"}:{color:"blue"}}>{status + ' '+credit}</p>
            </Col>
            <Col md="3">
              <Label>Fax Number</Label>
              <p>{fax}</p>
            </Col>
            <Col md="3">
              <Label>Email</Label>
              {emails ? (
                <ul className="list-unstyled">{renderClientEmails(emails)}</ul>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </Band>
  );
};

ClientDetailsSection.propTypes = propTypes;

export default ClientDetailsSection;
