import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';
import { Form, FormGroup, Row, Button } from 'reactstrap';

import RenderField from 'components/fields/RenderField';
import RadioGroup from 'components/fields/RadioGroup';
import Logger from 'util/Logger';
import * as jobActions from 'actions/jobActions';
import { jobCancelForm } from 'constants/FormConstants';
import { preventEnterSubmit } from 'actions/formActions';
import * as Validators from 'util/Validators';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

class JobCancelForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
        <fieldset>
          <Row>
            <FormGroup className="col-12">
              <Field
                name="comment"
                label="Reason for cancellation*"
                component={RenderField}
                validate ={Validators.required}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-12 align-self-center">
              <Field
                name="cancellationOptions"
                label="Email cancellation notice"
                component={RadioGroup}
                options={[
                  { id: 'SendToSupportOnly', label: 'Genera support' },
                  {
                    id: 'SendToSupportAndNoiRecipients',
                    label: 'Genera support & NOI recipients'
                  }
                ]}
                valueKey="id"
                labelKey="label"
              />
            </FormGroup>
            <FormGroup className="col-12 text-right">
              <Button className="mt-3" color="primary">
                Save
              </Button>
            </FormGroup>
          </Row>
        </fieldset>
      </Form>
    );
  }
}

JobCancelForm.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...jobActions }, dispatch)
  };
}

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: jobCancelForm,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Record not saved'
      );
    }
  })
)(JobCancelForm);
