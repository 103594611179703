import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import brandLogo from 'logo.png';

const propTypes = {
  children: PropTypes.node,
  showLogo: PropTypes.bool
};

const Header = ({ children, showLogo }) => (
  <header className="app-header">
    {showLogo ? (
      <div className="app-header-brand">
        <NavLink to="/">
          <img
            className="app-header-logo"
            src={brandLogo}
            alt="Genera Operations"
          />
        </NavLink>
      </div>
    ) : null}
    {children}
  </header>
);

Header.propTypes = propTypes;

export default Header;
