import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardImg, CardBody, CardTitle } from 'reactstrap';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrlFull: PropTypes.string,
  isActive: PropTypes.bool,
  handleChange: PropTypes.func
};

const JobTypeChanger = ({ handleChange, isActive, id, name, imageUrlFull }) => (
  <Card>
    <div
      className={`card-link ${isActive ? 'is-active' : ''}`}
      onClick={() => handleChange(id)}
    >
      {isActive && <div className="card-tag">Current Type</div>}
      <CardImg top height="40" src={imageUrlFull} alt={name} />
      <CardBody>
        <CardTitle>{name}</CardTitle>
      </CardBody>
    </div>
  </Card>
);

JobTypeChanger.propTypes = propTypes;

export default JobTypeChanger;
