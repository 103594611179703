import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';

import { treatmentResultMarkingsForm } from '../../../constants/FormConstants';
import * as operationsApi from '../../../api/operationsApi';
import Logger from '../../../util/Logger';

import * as jobActions from '../../../actions/jobActions';

import TreatmentResultMarkingsForm from './TreatmentResultMarkingsForm';

function mapStateToProps(state) {
  const { currentJob } = state;
  const markings = currentJob.treatmentTypeDetails.markings || [];
  return {
    initialValues: {
      markings,
    },
    currentJob: currentJob,
    markings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...jobActions,
      },
      dispatch,
    ),
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: treatmentResultMarkingsForm,
    enableReinitialize: false,

    onSubmit: (formValues, dispatch, { currentJob, actions, onCollapse }) => {
      const { markings } = formValues;

      const saveJobOperation = currentJob.id
        ? operationsApi.updateJob
        : () => Promise.resolve();

      return saveJobOperation({
        ...currentJob,
        treatmentTypeDetails: {
          ...currentJob.treatmentTypeDetails,
          markings: markings,
        },
      })
        .then(() => {
          Logger.success('Markings Updated Successfully');
        })
        .then(() => {
          return actions.getJob(currentJob.id);
        });
    },
    onSubmitFail: (errors, dispatch, submitError) => {
      Logger.error(
        'We were unable to save the changes, please review the fields in red and try again.',
        submitError,
        'Markings not saved',
      );
    },
  }),
)(TreatmentResultMarkingsForm);
