import React, { Component } from 'react';
import { Container, Col, Row, Collapse } from 'reactstrap';
import { Band, ToggleButton } from 'components/layout';
import PhosphineRecordsList from './PhosphineRecordsList';
import PhosphineRecordsSummaryContainer from './PhosphineRecordsSummaryContainer';
import Checkbubble from 'components/common/Checkbubble';
import { isVerified } from 'util/JobStateHelper';

class PhosphineRecordsSection extends Component {
  state = {
    collapsed: isVerified(this.props.currentState) ? false : true
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  render() {
    const { collapsed } = this.state;
    const { complete } = this.props;

    return (
      <Band dark>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>Phosphine Records</h4>
              </Col>
              <Col xs="4" className="text-right">
                <Checkbubble className="align-middle" checked={complete} />
                <ToggleButton
                  className="ml-3"
                  color="light"
                  active={collapsed}
                  onClick={this.toggleExpand}
                  ligature="expand_less"
                />
              </Col>
            </Row>
            <Collapse isOpen={!collapsed}>
              <PhosphineRecordsList />
              <PhosphineRecordsSummaryContainer />
            </Collapse>
          </Container>
        </div>
      </Band>
    );
  }
}

export default PhosphineRecordsSection;
