import React from "react";
import moment from "moment";

const DateFormatter = ({ value }) => (
  <span>
    {value &&
    value !== "null" &&
    value !== "0001-01-01T00:00:00" &&
    value !== "undefined" ? (
      moment(value).format("DD/MM/YYYY")
    ) : (
      <span className="emptydate">-</span>
    )}
  </span>
);

export default DateFormatter;
