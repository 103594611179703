import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contactActions from 'actions/contactActions';
import * as formActions from 'actions/formActions';
import VesselForm from './VesselForm';
import Logger from 'util/Logger';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle,
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';

const propTypes = {
  actions: PropTypes.object,
};

class VesselsPage extends Component {
  state = {
    loaded: false,
    isSubmitting: false,
    vessel: {},
  };

  componentDidMount() {
    const { contactId, mode } = this.props;
    if (mode === 'edit') {
      this.getVessel(contactId);
    }
  }

  createOrUpdateSuccess = (message) => {
    let { actions, history } = this.props;
    this.toggleFormSubmit();
    actions.getContacts();
    Logger.success(message);
    history.push('/vessels');
  };

  getVessel = (vesselId) => {
    operationsApi.getVessel(vesselId).then((result) => {
      this.setState({
        loaded: true,
        vessel: result.data,
      });
    });
  };

  createVessel = (formValues) => {
    this.toggleFormSubmit();
    operationsApi.createNewVessel(formValues).then(() => {
      this.createOrUpdateSuccess('New Vessel Created');
    });
  };

  updateVessel = (formValues) => {
    this.toggleFormSubmit();
    operationsApi.updateVessel(formValues).then(() => {
      this.createOrUpdateSuccess('Vessel Updated');
    });
  };

  toggleFormSubmit = () => {
    this.setState((prevState) => ({
      isSubmitting: !prevState.isSubmitting,
    }));
  };

  cancel = () => {
    this.props.history.push('/vessels');
  };

  render() {
    const { mode } = this.props;
    const { loaded, vessel } = this.state;

    if (mode === 'edit' && !loaded) {
      return null;
    }

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={this.cancel}
              ligature="clear"
            />
          </HeaderButtonNav>
          <HeaderTitle
            label={
              mode === 'edit' ? `Modify existing vessel` : `Create a New vessel`
            }
          />
        </Header>
        <VesselForm
          onSubmit={mode === 'edit' ? this.updateVessel : this.createVessel}
          isSubmitting={this.state.isSubmitting}
          initialValues={mode === 'edit' ? vessel : ''}
        />
      </div>
    );
  }
}

VesselsPage.propTypes = propTypes;

function mapStateToProps(state, ownProps) {
  return {
    mode: ownProps.match.params.action,
    contactId: ownProps.match.params.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contactActions,
        ...formActions,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselsPage);
