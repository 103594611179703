import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function commoditiesReducer(
  state = initialState.referenceData.commodities,
  action
) {
  if (action.type === types.GET_COMMODITIES_SUCCESS) {
    return [...action.commodities];
  }
  return state;
}
