export const JOB_ATTACHMENT_ACCEPTED_TYPES = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.msg',
  '.jpg',
  '.jpeg',
  '.png',
  '.eml'
];
