import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as locationActions from 'actions/locationActions';
import * as jobLocationActions from 'actions/jobLocationActions';
import * as formActions from 'actions/formActions';
import LocationForm from 'components/locations/LocationForm';
import Logger from 'util/Logger';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle
} from 'components/layout';
import * as operationsApi from 'api/operationsApi';

const propTypes = {
  actions: PropTypes.object
};

class LocationFormPage extends Component {
  state = {
    loaded: false,
    isSubmitting: false
  };

  componentDidMount() {
    const { match, mode } = this.props;
    if (mode === 'edit') {
      this.getLocation(match.params.locationid);
    }
  }

  createOrUpdateSuccess = message => {
    let { actions } = this.props;
    this.toggleFormSubmit();
    actions.getJobLocations();
    Logger.success(message);
    this.back();
  };

  getLocation = locationId => {
    operationsApi.getLocation(locationId).then(result => {
      this.setState({
        loaded: true,
        location: result.data
      });
    });
  };

  createLocation = formValues => {
    this.toggleFormSubmit();
    operationsApi.createNewLocation(formValues).then(() => {
      this.createOrUpdateSuccess('New Location Created');
    });
  };

  updateLocation = formValues => {
    this.toggleFormSubmit();
    operationsApi.updateLocation(formValues).then(() => {
      this.createOrUpdateSuccess('Location Updated');
    });
  };

  toggleFormSubmit = () => {
    this.setState(prevState => ({
      isSubmitting: !prevState.isSubmitting
    }));
  };

  back = () => {
    const { match } = this.props;
    this.props.history.push('/areas/' + match.params.areaid);
  };

  render() {
    const { match, mode } = this.props;
    const { loaded, location } = this.state;

    if (mode === 'edit' && !loaded) {
      return null;
    }

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            <HeaderButton
              color="secondary"
              onClick={this.back}
              ligature="keyboard_backspace"
            />
          </HeaderButtonNav>

          <HeaderTitle
            label={
              mode === 'edit'
                ? `Modify existing location`
                : `Create a new location`
            }
          />
        </Header>
        <LocationForm
          onSubmit={mode === 'edit' ? this.updateLocation : this.createLocation}
          isSubmitting={this.state.isSubmitting}
          initialValues={
            mode === 'edit' ? location : { areaId: match.params.areaid }
          }
        />
      </div>
    );
  }
}

LocationFormPage.propTypes = propTypes;

function mapStateToProps(state, ownProps) {
  return {
    mode: ownProps.match.params.action,
    locationId: ownProps.match.params.id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...locationActions,
        ...formActions,
        ...jobLocationActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationFormPage);
