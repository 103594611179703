import { KeycloakManager } from '@cucumber/keycloak-manager';

const env = window.env;

const keycloakConfig = {
  realm: 'Genera',
  url: env.KEYCLOAK_API_HOST + env.KEYCLOAK_API_PATH,
  clientId: 'genera',
  groupsKey: 'ADGroups'
};

export default new KeycloakManager(keycloakConfig);
