import React from 'react';
import PropTypes from 'prop-types';
import * as areaHelper from 'util/AreaHelper';
import { Band } from 'components/layout';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const propTypes = {
  contacts: PropTypes.array,
  options: PropTypes.object,
  areas: PropTypes.array
};

const ContactsListTable = ({ contacts, options, areas }) => {
  return (
    <Band dark>
      <div className="table-wrap">
        <BootstrapTable
          bodyContainerClass="px-2"
          className=""
          trClassName="app-table-row"
          version="4"
          data={contacts}
          bordered={false}
          remote
          options={options}
        >
          <TableHeaderColumn
            isKey
            className="app-table-header"
            columnClassName="keyColumn"
            dataField="id"
            hidden
          />
          <TableHeaderColumn
            className="app-table-header"
            dataField="name"
            width="20%"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            dataField="email"
            width="20%"
          >
            Email
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            dataField="description"
            width="30%"
          >
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            dataFormat={(cell, row) => (
              <span>
                {' '}
                {areaHelper.getAreaDisplayNames(
                  row.areas.map((o, i) => o.areaId),
                  areas
                )}{' '}
              </span>
            )}
            width="30%"
          >
            Areas
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            columnClassName="mobileToggleColumn hidden-sm hidden-md hidden-lg"
          />
        </BootstrapTable>
      </div>
    </Band>
  );
};

ContactsListTable.propTypes = propTypes;

export default ContactsListTable;
