import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function vesselsReducer(
  state = initialState.referenceData.vessels,
  action,
) {
  if (action.type === types.GET_VESSELS_SUCCESS) {
    return [...action.vessels];
  }
  return state;
}
