import * as types from 'constants/actionTypes';
import * as operationsApi from 'api/operationsApi';
import { beginAjaxCall, ajaxCallError } from './ajaxCallActions.js';

export function getCommodities() {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    return operationsApi
      .getCommodities()
      .then(
        response => dispatch(getCommoditiesSuccess(response)),
        error => dispatch(ajaxCallError())
      );
  };
}

function getCommoditiesSuccess(response) {
  return {
    type: types.GET_COMMODITIES_SUCCESS,
    commodities: response.data || []
  };
}
