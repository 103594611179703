import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormFeedback } from 'reactstrap';
import classNames from 'classnames';

const propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  meta: PropTypes.object
};

const CheckboxField = ({
  input,
  label,
  placeholder,
  type,
  className,
  disabled,
  hidden,
  meta: { touched, error, warning }
}) => (
  <span
    className={classNames(className, 'custom-checkbox-field')}
    hidden={hidden}
  >
    <label
      className={classNames(
        'custom-control',
        'custom-checkbox',
        'form-control-custom',
        {
          'custom-checkbox-disabled': disabled,
          'is-invalid': touched && error
        }
      )}
    >
      <Input
        {...input}
        checked={input.value}
        type="checkbox"
        className="custom-control-input"
        disabled={disabled}
        valid={touched && error ? false : undefined}
      />
      <span className="custom-control-indicator" />
      <span className="custom-control-description">{label}</span>
    </label>
    <FormFeedback>{error}</FormFeedback>
  </span>
);

CheckboxField.propTypes = propTypes;

export default CheckboxField;
