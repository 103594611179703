export default {
  importAndHeatTreatment: '1213f201-38c2-404e-9d9c-f16a9b51679e',
  exportAndLogs: '1ce26e7e-6aca-43fd-8e30-444518318212',
  importAndContainer: '981e5107-7d75-44cd-8b98-291b8b2d540f',
  exportAndContainer: 'c8c5e744-6c00-4356-a3f1-fec7f186d034',
  importAndBreakBulk: 'fc2640a1-3d94-4dad-b052-117cb85d4b2d',
  exportAndBreakBulk: 'acd8f4c1-d491-41ea-ada0-54c3fd4aee58',
  importOther: '6e8140cd-7571-4df7-a289-1ad4ff30a3ec',
  exportOther: '90787a34-53e4-4faa-b50b-11e0d951e2e7',
  logsInHold:'6e8140cd-7571-4df7-a289-1ad4ff30c395'
};
