import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import _, { isNumber } from 'lodash';
import cx from 'classnames';
import {getTechnicians,getCurrentJob,getTreatmentType, getCurrentEFConcentrationReadingItem,getCurrentRegisteredFields} from '../../treatmentResultRecords/selectors';
import * as Validators from '../../../../util/Validators';
import * as operationsApi from '../../../../api/operationsApi';
import { confirmAlert } from '../../../../util/Alerts';

import PlainTextField from '../../../fields/PlainTextField';
import RenderField from '../../../fields/RenderField';
import SearchableSelect from '../../../fields/SearchableSelect';
//import DatePickerField from '../../../fields/DatePickerField';
import DatePickerField from '../../../fields/DatePickerField';
import {  isBreakBulkOrExportOfLogsAndMethylBromide, isImportOrExportAndContainer} from '../../../../util/JobTypeHelper';
//import styles from '../styles.module.scss';
import styles from '../../treatmentResultRecords/TreatmentResultRecordsMonitoringSection/styles.module.scss'
 import { treatmentResultsForm } from '../../../../constants/FormConstants'
 import { change, getFormValues } from 'redux-form';
import { event } from 'jquery';

const propTypes = {
  fields: PropTypes.object,
  readOnly: PropTypes.bool,
  fieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onEditing: PropTypes.func.isRequired,
  meta: PropTypes.object,
  currentTreatmentResultRecord :PropTypes.object,
  efConcentrationReadingItem:PropTypes.object,
};

function shouldShowWarning(field, fieldValue) {
  const std = +fieldValue(`${field}.actualReading`);
  const topUpDose = +fieldValue(`${field}.topupDose`);
  const jobType = +fieldValue(`${field}.job`);
  return (
    Boolean(std) &&
    !topUpDose &&
    [1, 2, 3].some((i) => {
      const value = +fieldValue(`${field}.reading${i}`);
      return value && value < std;
    })
  );
}

function getTargetValue(field, fieldValue,currentJob) {
  const phase = fieldValue(`${field}.phase`);
      return phase;
    }
  
  const isCoveredContainer = null;

class EFConcentrationReadingArrayItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      phase:"",
      refresh:false,
    };
  }

  
  render() {
    const {
      field,
      fieldValue,
      fieldError,
      readOnly,
      isEditing,
      onEdit,
      onDone,
      onRemove,
      jobtypename,
      currentJob,
      setSelectedItem
    } = this.props;
     const phase = [{id:0,displayName:"Initial Reading"},{id:1,displayName:"TopUp Reading"}
    ,{id:2,displayName:"Final Reading"}]

     const isContainer = isImportOrExportAndContainer(currentJob.type)

   const showWarning = isCoveredContainer?null:shouldShowWarning(field, fieldValue);
   //const isTimeRecords =  this.state.selectedItem.value ==1 ||this.state.selectedItem.value ==3 ||this.state.selectedItem.value==5 
   
    const hasError = Boolean(fieldError(field));
    return (
      <tr>
        <td>
          {isEditing ? (
            <Field className="col-lg-150"
              name={`${field}.phase`}
              component={SearchableSelect}
              options = {phase}
              placeholder="Phase"

            />
          ) : (
            <PlainTextField className="col-lg-100"
              name={`${field}.phase`}
              value={
                phase[fieldValue(`${field}.phase`)] &&
                phase[fieldValue(`${field}.phase`)].displayName
            }
            />
          )}
        </td>
        <td>
          {isEditing? ( 
           
            <Field
              name={`${field}.microtectorID`}
              component={RenderField}
              type="text"
              placeholder="Microtector ID"
            />
          ) : (

            <PlainTextField
              name={`${field}.microtectorID`}
              value={fieldValue(`${field}.microtectorID`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.readingDateTime`}
              component={DatePickerField}
              defaultToEmpty
              showTimeSelect
              type="text"
              placeholder="Date/Time"
              validate={[Validators.isValidDateAndTime]}
              timeIntervals={1}
            />
          ) : (
            <PlainTextField
              name={`${field}.readingDateTime`}
              value={
                fieldValue(`${field}.readingDateTime`) &&
                moment(fieldValue(`${field}.readingDateTime`)).format(
                  'DD/MM HH:mm',
                )
              }
            />
          )}
        </td>
        <td>
          {isEditing  ? (
            <Field
              name={`${field}.stdgramPerCubicMetre`}
              component={RenderField}
             // showTime
              type="text"
              placeholder="Std g/m3"
             // defaultToEmpty
             // showTimeSelect
              //timeIntervals={1}
              validate={isNumber}
            />
          ) : (
            <PlainTextField
              name={`${field}.stdgramPerCubicMetre`}
              value={
                fieldValue(`${field}.stdgramPerCubicMetre`)
              }
            />
          )}
        </td>

        <td>
          {isEditing ?  (
            <Field
              name={`${field}.frontEf`}
              component={RenderField}
              type="text"
              placeholder="1 Font EF"
            />
          ) : (
            <PlainTextField
              name={`${field}.frontEF`}
              value={fieldValue(`${field}.frontEf`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.frontCo2`}
              component={RenderField}
              type="text"
              placeholder="1 Font CO2"
            />
          ) : (
            <PlainTextField
              name={`${field}.frontCo2`}
              value={fieldValue(`${field}.frontCo2`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.middleEf`}
              component={RenderField}
              type="text"
              placeholder="2 Middle EF"
            />
          ) : (
            <PlainTextField
              name={`${field}.middleEF`}
              value={fieldValue(`${field}.middleEf`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.middleCo2`}
              component={RenderField}
              type="text"
              placeholder="2 Middle CO2"
            />
          ) : (
            <PlainTextField
              name={`${field}.middleCo2`}
              value={fieldValue(`${field}.middleCo2`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.backEf`}
              component={RenderField}
              type="text"
              placeholder="3 Back EF"
            />
          ) : (
            <PlainTextField
              name={`${field}.backEf`}
              value={fieldValue(`${field}.backEf`)}
            />
          )}
        </td>
        <td>
          {isEditing ? (
            <Field
              name={`${field}.backCo2`}
              component={RenderField}
              type="text"
              placeholder="3 Back CO2"
            />
          ) : (
            <PlainTextField
              name={`${field}.backCo2`}
              value={fieldValue(`${field}.backCo2`)}
            />
          )}
        </td>
        <td>
          {hasError && (
            <span className={styles.errorIcon}>
              <i aria-hidden="true" className="material-icons">
                error
              </i>
            </span>
          )}
          {showWarning && !hasError && (
            <span className={styles.warningIcon}>
              <i aria-hidden="true" className="material-icons">
                warning
              </i>
            </span>
          )}
        </td>
        {!readOnly && (
          <td>
            {isEditing && (
              <Button
                className={styles.iconButton}
                outline
                onClick={onDone}
                color={hasError ? 'disabled' : 'success'}
                disabled={hasError}
              >
                <i aria-hidden="true" className="material-icons">
                  check_outline
                </i>
              </Button>
            )}
            {!isEditing && (
              <Button
                className={styles.iconButton}
                outline
                color="primary"
                onClick={onEdit}
              >
                <i aria-hidden="true" className="material-icons">
                  edit_outline
                </i>
              </Button>
            )}
            { phase[fieldValue(`${field}.phase`)]==undefined?null:phase[fieldValue(`${field}.phase`)].displayName.includes('Reading Times')? null:
            <Button
              className={styles.iconButton}
              outline
              color="danger"
              onClick={onRemove}
              
            >
              <i aria-hidden="true" className="material-icons">
                delete_outline
              </i>
            </Button>
            }
          </td>
        )}
      </tr>
    );
  }
}
EFConcentrationReadingArrayItem.propTypes = {
  field: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  fieldValue: PropTypes.func.isRequired,
  fieldError: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
const Item = connect((state, props) => ({
 // currentConcentrationReadingItem:getCurrentConcentrationReadingItem(state),
 // recapturePercentage:getRecapturePercentage(state),
 efConcentrationReadingItem:getCurrentEFConcentrationReadingItem(state),
 registeredFields:getCurrentRegisteredFields(state),
  currentJob: getCurrentJob(state),
  technicians: getTechnicians(state),
  treatmentType: getTreatmentType(state),
  fieldValue: (field) => formValueSelector(props.form)(state, field),
  fieldError: (field) => _.get(getFormSyncErrors(props.form)(state), field),
}))(EFConcentrationReadingArrayItem);

// eslint-disable-next-line react/no-multi-comp
class EFConcentrationReadingArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: [],
    };
  }

  render() {
    const {
      fields,
      readOnly,
      fieldValue,
      meta,
      jobId,
      onChange,
      onEditing,
      job,
    } = this.props;

    var isCovered =false;// job.treatmentTypeDetails.covered?job.treatmentTypeDetails.covered:false
    const jobTypeName = job.type.name;
    const isContainer =  isImportOrExportAndContainer(job.type)
    const count = fields ? fields.length : 0;
    // We need to do reduce here because fields is actually not an array
    var showWarning =
      fields && 
      fields.reduce(
        (acc, field) => acc || shouldShowWarning(field, fieldValue),
        false,
      );
      if(isCovered){
showWarning = false;
      }

    return (
      <div className={styles.concentrationReadingsFieldArray}>
        <Table hover>
          <thead>
            <tr>
              <th style={{ width: 180 }}>Phase</th>
              <th>Microtector ID</th>
              <th>Date/Time</th>
              <th>Std g/m3</th>
              <th>1 Front EF</th>
              <th>1 Front CO2</th>
              <th>2 Middle EF</th>
              <th>2 Middle CO2</th>
              <th>3 Back EF</th>
              <th>3 Back CO2</th>
              <th>&nbsp;</th>
              {!readOnly && (
                <th style={{ width: 100 }}>
                  <Button
                    className={styles.iconButton}
                    outline
                    onClick={(e) => {
                      fields.push({ jobId });
                      this.setState(
                        (state) => ({
                          editing: [...state.editing, count],
                        }),
                        
                        () => {
                          onEditing(true);
                        },
                      );
                    }}
                    color="primary"
                  >
                    <i aria-hidden="true" className="material-icons">
                      add_outline
                    </i>
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {count === 0 && (
              <tr>
                <td align="center" colSpan={readOnly ? 8 : 9}>
                  There is no data to display
                </td>
              </tr>
            )}
            {fields.map((field, index) => (
              <Item
                key={index}
                field={field}
                form={meta.form}
                jobtypename = {jobTypeName}
                readOnly={readOnly}
                isEditing={this.state.editing.includes(index)}
                editing={fields[(this.state.editing)]}
                onEdit={() => {
                  this.setState(
                    (state) => ({
                      editing: [...state.editing, index],
                    }),
                    () => {
                      onEditing(true);
                    },
                  );
                }}
                onDone={() => {
                  this.setState(
                    (state) => ({
                      editing: state.editing.filter((i) => i !== index),
                    }),
                    () => {
                      onEditing(this.state.editing.length > 0);
                      const updatedField = fields.get(index);
                      const all = fields.getAll();
                      onChange &&
                        onChange([
                          ...all.slice(0, index),
                          updatedField,
                          ...all.slice(index + 1),
                        ]);
                    },
                  );
                }}
                onRemove={async () => {
                  const confirm = await confirmAlert(
                    'Delete',
                    'Deleting this record will also remove any times asscociated with this record. Are you sure you want to delete this record;?',
                  );
                  if (confirm) {
                    const item = fields.get(index);
                    if (item.id) {
                      try {
                        await operationsApi.deleteEFRecord(
                          item.id,
                        );
                      } catch (err) {
                        /* noop */
                      }
                    }
                    fields.remove(index);
                    this.setState(
                      (state) => ({
                        editing: state.editing.filter((i) => i !== index),
                      }),
                      () => {
                        onEditing(this.state.editing.length > 0);
                      },
                    );
                  }
                }}
              />
            ))}
          </tbody>
        </Table>
        {showWarning ? (
          <p className={cx(styles.note, styles.legend)}>
            <span className={styles.warningIcon}>
              <i aria-hidden="true" className="material-icons">
                warning
              </i>
            </span>
            <span> - Check entries or Top up required</span>
          </p>
        ):null}
      </div>
    );
  }
}
EFConcentrationReadingArray.propTypes = propTypes;

export default connect((state, props) => ({
  fieldValue: (field) => formValueSelector(props.meta.form)(state, field),
}))(EFConcentrationReadingArray);
