import { connect } from 'react-redux';
import JobClientSummary from './JobClientSummary';

function mapStateToProps(state) {
  return {
    clientDetails: state.clientDetails,
    currentState: state.currentJob.currentState
  };
}

export default connect(mapStateToProps)(JobClientSummary);
