import { connect } from 'react-redux';

import LogRowsSummarySheetF15 from './LogRowsSummarySheetF15';

function mapStateToProps(state, props) {
  const { currentJob } = state;
  return {
    currentJob
  };
}

export default connect(mapStateToProps)(LogRowsSummarySheetF15);
