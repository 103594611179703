export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const LOAD_REFERENCE_DATA = 'LOAD_REFERENCE_DATA';
export const LOAD_INITIAL_VALUES = 'LOAD_INITIAL_VALUES';

export const GET_JOB_TYPES_SUCCESS = 'GET_JOB_TYPES_SUCCESS';
export const GET_JOB_LOCATIONS_SUCCESS = 'GET_JOB_LOCATIONS_SUCCESS';
export const GET_JOB_TABLE_DEFINITION_SUCCESS =
  'GET_JOB_TABLE_DEFINITION_SUCCESS';
export const GET_USER_COLUMN_VISIBILITIES_SUCCESS =
  'GET_USER_COLUMN_VISIBILITIES_SUCCESS';
export const SET_USER_COLUMN_VISIBILITY = 'SET_USER_COLUMN_VISIBILITY';
export const SAVE_USER_COLUMN_VISIBILITY_SUCCESS =
  'SAVE_USER_COLUMN_VISIBILITY_SUCCESS';
export const DELETE_USER_COLUMN_VISIBILITY_SUCCESS =
  'DELETE_USER_COLUMN_VISIBILITY_SUCCESS';
export const GET_JOB_STANDARDS_SUCCESS = 'GET_JOB_STANDARDS_SUCCESS';
export const GET_TREATMENT_TYPES_SUCCESS = 'GET_TREATMENT_TYPES_SUCCESS';
export const GET_CONTAINER_TYPES_SUCCESS = 'GET_CONTAINER_TYPES_SUCCESS';
export const GET_SIZE_OF_CONTAINERS_SUCCESS = 'GET_SIZE_OF_CONTAINERS_SUCCESS';
export const GET_JOB_CATEGORIES_SUCCESS = 'GET_JOB_CATEGORIES_SUCCESS';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
export const GET_DESTINATIONS_SUCCESS = 'GET_DESTINATIONS_SUCCESS';
export const GET_COMMODITIES_SUCCESS = 'GET_COMMODITIES_SUCCESS';
export const GET_TECHNICIANS_SUCCESS = 'GET_TECHNICIANS_SUCCESS';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';

export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const GET_JOB_SUMMARIES_SUCCESS = 'GET_JOB_SUMMARIES_SUCCESS';
export const GET_JOB_STATE_SUMMARIES_SUCCESS =
  'GET_JOB_STATE_SUMMARIES_SUCCESS';
export const GET_CLIENT_SUMMARIES_SUCCESS = 'GET_CLIENT_SUMMARIES_SUCCESS';

export const GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS';
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS';

export const GET_NOTICE_OF_INTENTED_SUCCESS = 'GET_NOTICE_OF_INTENTED_SUCCESS';
export const CLEAR_JOB_TREATMENTTYPE = 'CLEAR_JOB_TREATMENTTYPE';
export const GET_VESSELS_SUCCESS = 'GET_VESSELS_SUCCESS';
