export default {
  ajaxCallsInProgress: 0,
  clientDetails: null,
  currentJob: null,
  currentJobState: 'new',
  formInitialValues: {},
  isAuthenticated: false,
  jobStateSummaries: [],
  jobSummaries: {},
  selectedClient: null,
  referenceData: {
    areas: [],
    clientSummaries: [],
    commodities: [],
    contacts: [],
    containerTypes: [],
    destinations: [],
    jobCategories: [],
    jobLocations: [],
    jobStandards: [],
    jobTableDefinitions: [],
    userColumnVisibilities: [],
    jobTypes: [],
    referenceDataLoaded: false,
    technicians: [],
    treatmentTypes: [],
    sizeOfContainers: [],
    vessels: [],
  },
};
