import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import { Container, FormGroup } from 'reactstrap';
import RenderField from '../../components/fields/RenderField';
import DatePickerField from '../../components/fields/DatePickerField';
import SearchableSelect from '../../components/fields/SearchableSelect';
import CustomTagSelect from '../../components/fields/CustomTagSelect';
import * as Validators from '../../util/Validators';
import { isExportAndLogs } from '../../util/JobTypeHelper';
import {
  isAlPVessel,
  isEthylFormate,
  isMethylBromide,
  isVapormate,
  isVaporPhos,
} from '../../util/TreatmentTypeHelper';
import { Band } from '../../components/layout';
import plasticWrapStatus from '../../constants/plasticWrapStatus';
import jobStandardIds from '../../constants/JobStandardIds';
import { minZero } from '../../util/NormalizingHelper';
import { userIsCustomer } from '../../util/AdGroupsHelper';
import { jobDetailsForm } from '../../constants/FormConstants';
import { isWaterBlasting,isGasFree } from '../../util/TreatmentTypeHelper';
import CheckboxField from 'components/fields/CheckboxField';
import { round } from 'lodash';

const propTypes = {
  treatmentId: PropTypes.any,
  jobType: PropTypes.object,
  treatmentTypes: PropTypes.array,
  actions: PropTypes.object,
  jobStandards: PropTypes.array,
  updateArea: PropTypes.func,
};

class TreatmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDaff:
        props.standardId !== null &&
        (props.standardId === jobStandardIds.DaffId ||
          props.standardId === jobStandardIds.DaffArhopalusId),
    };
  }

  getStandardDisplayName = (value) => {
    const { jobStandards } = this.props;
    const jobStandard = jobStandards.find((element) => element.id == value);
    let displayName = '';
    if (
      jobStandard.displayName !== undefined ||
      jobStandard.displayName !== null
    ) {
      displayName = jobStandard.displayName;
    }
    return displayName;
  };

  getStandardComment = (value) => {
    const { jobStandards } = this.props;
    //row.areas.map((o, i) => o.areaId
    //const name = jobStandards.map((o,val) => o.displayname)
    const jobStandard = jobStandards.find((element) => element.id == value);
    let resp = '';
    if (jobStandard && jobStandard.standardComments != null) {
      resp = jobStandard.standardComments;
    }
    return resp;
  };

  onDetailsChange = (event, value) => {
    this.setState({
      showDaff:
        value === jobStandardIds.DaffId ||
        value === jobStandardIds.DaffArhopalusId,
    });
    const { actions } = this.props;

    if (value != null) {
      if (this.props.comment == null || this.props.comment === '') {
        actions.change(
          jobDetailsForm,
          'treatmentDetails.comments',
          this.getStandardComment(value),
        );
      }
    }
  };

  initalDoseUpdate = (event,value) => {
    const { actions } = this.props;

    console.log('ttd',value)
    if (value != null) {
      const doseRate = round((value *6),2)
        actions.change(
          jobDetailsForm,
          'treatmentDetails.dosageRate',
        doseRate,
        );
    }
  };

  render() {
    const {
      jobType,
      treatmentTypes,
      jobStandards,
      treatmentId,
      status,
      stopCredit,
      updateArea,
      endPointReadingPercentage,
    } = this.props;
    const { showDaff } = this.state;
    let mbOnly = '';
    if (treatmentTypes != null) {
      mbOnly = treatmentTypes.find(
        (el) => el.id === '38764bb4-55b3-4be9-9433-a347a27ba39f',
      );
    }
    //const mbOnly =   treatmentTypes.find(el => el.id === '38764bb4-55b3-4be9-9433-a347a27ba39f');
    const mbArray = [mbOnly];
    let endPoint = ['25','30', '33', '50', '80'];
    if (endPointReadingPercentage != null) {
      endPoint = ['25','30', '33', '50', '80', endPointReadingPercentage];
    }
    return (
      <Band dark>
        <section className="form-section">
          <Container>
            <h4>Treatment</h4>
            <div className="form-row align-items-center">
              {jobType.id === '6e8140cd-7571-4df7-a289-1ad4ff30c395' ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.treatmentTypeId"
                    label="Treatment Type MB ONLY *"
                    component={SearchableSelect}
                    disabled={status === 'Inactive' || stopCredit === true}
                    options={mbArray}
                    type="text"
                    validate={Validators.required}
                  />
                </FormGroup>
              ) : (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.treatmentTypeId"
                    label="Treatment Type *"
                    component={SearchableSelect}
                    disabled={status === 'Inactive' || stopCredit === true}
                    options={treatmentTypes}
                    type="text"
                    placeholder="Enter a Treatment Type"
                    validate={Validators.required}
                    onSelect={updateArea}
                  />
                </FormGroup>
              )}
              <FormGroup className="col-md-4">
                <Field
                  name="treatmentDetails.dateIntendedTreatment"
                  label="Date of Intended Treatment"
                  component={
                    status !== 'Inactive' && stopCredit !== true
                      ? DatePickerField
                      : RenderField
                  }
                  readOnly={status === 'Inactive' || stopCredit === true}
                  showTimeSelect
                  type="text"
                  placeholder="dd/mm/yyyy"
                  validate={Validators.isValidDateAndTime}
                />
              </FormGroup>
              {!isWaterBlasting(treatmentId) && ! isGasFree(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.treatmentPeriod"
                    label="Treatment Period"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    placeholder="Enter a Time Period"
                    validate={[Validators.number]}
                    normalize={minZero}
                  />
                </FormGroup>
              ) : null}
                 {isVapormate(treatmentId) ||isEthylFormate(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.mainDosageRate"
                    label={isEthylFormate(treatmentId)?"EF/Main Dosage Rate":"Initial Dose Rate"}
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    placeholder={isEthylFormate(treatmentId)?'Enter EF Dosage Rate':"Enter a Initial Dosage"}
                    validate={Validators.number}
                    normalize={minZero}
                    //   onChange={(e) => updateEralisNumber(e.target.value)}
                    onChange={this.initalDoseUpdate}
                  />
                </FormGroup>
              ) : null}
              {!isWaterBlasting(treatmentId) && !isGasFree(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.dosageRate"
                    label={isEthylFormate(treatmentId)?"EF with CO2":"Dosage Rate"}
                    component={RenderField}
                    readOnly={isEthylFormate(treatmentId)||isVapormate(treatmentId)||status === 'Inactive' || stopCredit === true}
                    type="number"
                    placeholder="Enter a Dosage Rate"
                    validate={Validators.number}
                    normalize={minZero}
                  />
                </FormGroup>
              ) : null}
              {!isWaterBlasting(treatmentId) &&
              !isAlPVessel(treatmentId) &&
              !isVaporPhos(treatmentId) &&
              !isGasFree(treatmentId)? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.mandatoryMinimumTemperature"
                    label="Mandatory Minimum Temperature"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    placeholder="Enter a Mandatory Minimum Temperature"
                    validate={Validators.number}
                  />
                </FormGroup>
              ) : null}
              {!isWaterBlasting(treatmentId) && ! isGasFree(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.standardId"
                    label="Standard"
                    component={SearchableSelect}
                    disabled={status === 'Inactive' || stopCredit === true}
                    options={jobStandards}
                    type="text"
                    onChange={this.onDetailsChange}
                  />
                </FormGroup>
              ) : null}

              {showDaff &&
              !isMethylBromide(treatmentId) &&
              !isWaterBlasting(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.plasticWrapStatus"
                    label="Plastic Wrap Status *"
                    component={SearchableSelect}
                    options={plasticWrapStatus.options}
                    disabled={status === 'Inactive' || stopCredit === true}
                    type="text"
                    valueKey="id"
                    labelKey="name"
                    validate={Validators.required}
                  />
                </FormGroup>
              ) : null}
              {!isWaterBlasting(treatmentId) ? (
                <FormGroup className="col-md-12">
                  <Field
                    name="treatmentDetails.comments"
                    label="Comments"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="textarea"
                    placeholder="Enter a Comment"
                  />
                </FormGroup>
              ) : null}

              {isExportAndLogs(jobType) &&
              !userIsCustomer() &&
              !isWaterBlasting(treatmentId) &&
              !isAlPVessel(treatmentId) &&
              !isVaporPhos(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.expectedQuantity"
                    label="Expected Quantity (mb/kg)"
                    component={RenderField}
                    readOnly={status === 'Inactive' || stopCredit === true}
                    type="number"
                    validate={Validators.number}
                    placeholder="Enter an Expected Quantity"
                    normalize={minZero}
                  />
                </FormGroup>
              ) : null}
              {isExportAndLogs(jobType) &&
              !userIsCustomer() &&
              !isAlPVessel(treatmentId) &&
              !isVaporPhos(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.expectedVentilationDate"
                    label="Expected Ventilation Date"
                    component={
                      status !== 'Inactive' && stopCredit !== true
                        ? DatePickerField
                        : RenderField
                    }
                    readOnly={status === 'Inactive' || stopCredit === true}
                    showTimeSelect
                    defaultToEmpty
                    type="text"
                    placeholder="Enter an Expected Date"
                  />
                </FormGroup>
              ) : null}
              {isMethylBromide(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.endPointReadingPercentage"
                    label="End Point Reading (%)"
                    component={CustomTagSelect}
                    disabled={status === 'Inactive' || stopCredit === true}
                    options={endPoint}
                    placeholder="Select an Endpoint Reading"
                  />
                </FormGroup>
              ) : null}
               {isMethylBromide(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.doseToConcentration"
                    label="Dose To Concentration"
                    component={CheckboxField}
                  />
                </FormGroup>
              ) : null}

                {isGasFree(treatmentId) ? (
                <FormGroup className="col-md-4">
                  <Field
                    name="treatmentDetails.gasAssessment"
                    label="Gas level assessment"
                    component={CheckboxField}
                  />
                    <Field
                    name="treatmentDetails.residueRelocation"
                    label="Residue relocation"
                    component={CheckboxField}
                  />
                    <Field
                    name="treatmentDetails.residueRemoval"
                    label="Residue removal"
                    component={CheckboxField}
                  />
                                      <Field
                    name="treatmentDetails.disposalResidue"
                    label="Disposal of residue"
                    component={CheckboxField}
                  />
                     <Field
                    name="treatmentDetails.quenchingResidue"
                    label="Quenching residue"
                    component={CheckboxField}
                  />
                     <Field
                    name="treatmentDetails.chamberMonitoring"
                    label="Chamber monitoring"
                    component={CheckboxField}
                  />
                     <Field
                    name="treatmentDetails.callOutFee"
                    label="Call out fee (outside of normal working hours)"
                    component={CheckboxField}
                  />
                  <Field
                    name="treatmentDetails.removalDisposalPipeFan"
                    label="Removal & Disposal of Used Piping & or Fans"
                    component={CheckboxField}
                  />
                  
                </FormGroup>
                
              ) : null}
            </div>
          </Container>
        </section>
      </Band>
    );
  }
}

TreatmentDetails.propTypes = propTypes;

export default TreatmentDetails;
