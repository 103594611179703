import toastr from 'toastr';
import * as log from 'loglevel';

export default class Logger {

  static info(message, data, title) {
    toastr.info(message, title);
    log.info('Info: ' + message, data);
  }

  static success(message, data, title) {
    toastr.options = { "positionClass": "toast-top-left"} 
    toastr.success(message, title);
    log.info('Success: ' + message, data);
  }

  static warning(message, data, title) {
    toastr.options = { "positionClass": "toast-top-left"}
    toastr.warning(message, title);
    log.warn('Warning: ' + message, data);
  }

  static error(message, data, title) {
    const toastrOptions =
      data && data.persistentError
        ? {
         " positionClass":"toast-top-left",
            timeOut: '0',
            extendedTImeout: '0'
          }
        : {};

    toastr.error(message, title, toastrOptions);
    log.error('Error: ' + message, data);
  }
}
