import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'reactstrap';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  approveLabel: PropTypes.string,
  rejectLabel: PropTypes.string
};

const ApprovalControl = ({
  value,
  onApprove,
  onReject,
  approveLabel,
  rejectLabel
}) => {
  return (
    <div>
      <ButtonGroup>
        <Button outline={value !== true} color="success" onClick={onApprove}>
          <i className={'material-icons mr-1'}>check_circle</i>
          {approveLabel || 'Pass'}
        </Button>
        <Button outline={value !== false} color="danger" onClick={onReject}>
          <i className={'material-icons mr-1'}>cancel</i>
          {rejectLabel || 'Fail'}
        </Button>
      </ButtonGroup>
    </div>
  );
};

ApprovalControl.propTypes = propTypes;

export default ApprovalControl;
