import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Band } from 'components/layout';
import className from 'classnames';
import { formatDate } from 'util/DateHelper';

const propTypes = {
  signoffTechnician: PropTypes.object,
  signoffDate: PropTypes.string,
  passed: PropTypes.bool
};

const JobSignoffCompleted = ({ signoffTechnician, signoffDate, passed }) => {
  if (!signoffTechnician) return false;
  const label = passed ? 'PASS' : 'FAIL';
  const iconLigature = passed ? 'check_circle' : 'cancel';
  const colorClass = passed ? 'text-success' : 'text-danger';
  const formattedDate = formatDate(signoffDate, true);
  return (
    <Band>
      <div className="form-section">
        <Container>
          <Row>
            <Col xs="8">
              <span>
                <strong>{label}</strong>
                <i
                  className={className(
                    'material-icons align-middle mx-3',
                    colorClass
                  )}
                >
                  {iconLigature}
                </i>
              </span>
              <strong>Signed - {signoffTechnician.name}</strong> ({
                formattedDate
              })
            </Col>
          </Row>
        </Container>
      </div>
    </Band>
  );
};

JobSignoffCompleted.propTypes = propTypes;

export default JobSignoffCompleted;
