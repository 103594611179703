import React from 'react';
import PropTypes from 'prop-types';
import { Band } from 'components/layout';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DeleteRowFormatter from '../table/formatters/DeleteRowFormatter';
import EditRowFormatter from '../table/formatters/EditRowFormatter';

const propTypes = {
  options: PropTypes.object,
  locations: PropTypes.array
};

const LocationsListTable = ({ locations, options }) => {
  return (
    <Band dark>
      <div className="table-wrap">
        <BootstrapTable
          bodyContainerClass="px-2"
          className=""
          trClassName="app-table-row"
          version="4"
          data={locations}
          bordered={false}
          remote
          options={options}
        >
          <TableHeaderColumn
            isKey
            className="app-table-header"
            columnClassName="keyColumn"
            dataField="id"
            hidden
          />
          <TableHeaderColumn
            className="app-table-header"
            dataField="name"
            width="20%"
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            className="app-table-header"
            dataField="edit"
            width="15"
            dataAlign="right"
            dataFormat={(cell, row) => (
              <EditRowFormatter rowData={row} onEdit={options.onRowEdit} />
            )}
          />
          <TableHeaderColumn
            className="app-table-header"
            dataField="delete"
            width="15"
            dataAlign="right"
            dataFormat={(cell, row) => (
              <DeleteRowFormatter
                rowData={row}
                onDelete={options.onRowDelete}
              />
            )}
          />
        </BootstrapTable>
      </div>
    </Band>
  );
};

LocationsListTable.propTypes = propTypes;

export default LocationsListTable;
