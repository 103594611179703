import React, { Component } from 'react';
import { getJobSummariesSearch } from 'api/operationsApi';
import { Header, HeaderTitle } from 'components/layout';
import SearchResultsTable from './SearchResultsTable';
import SearchForm from './SearchForm';
import searchIcon from 'assets/search.svg';

class SearchPage extends Component {
  state = {
    searchTableData: null,
    searchCount: 0,
    loading: false,
    sort: `jobReference,DESC`,
  };

  componentDidMount() {
    const { formValues, initialValues } = this.props;
    let values = formValues || initialValues;
    this.getJobSummaries({
      ...values,
      sort: this.state.sort,
    });
  }

  searchJobs = (formValues) => {
    this.getJobSummaries({
      ...formValues,
      sort: this.state.sort,
    });
  };

  getJobSummaries = (formValues) => {
    this.toggleLoading();
    getJobSummariesSearch(formValues).then((searchTableData) => {
      this.setState((prevState) => ({
        searchTableData,
        searchCount: prevState.searchCount + 1,
        loading: false,
        sort: formValues.sort,
      }));
    });
  };

  toggleLoading = () => {
    this.setState((prevState) => ({ loading: !prevState.loading }));
  };

  goToJobPage = (row) => {
    const { history } = this.props;
    history.push('/jobs/' + row.id);
  };

  onPageChange = (page) => {
    const { formValues } = this.props;
    this.getJobSummaries({
      ...formValues,
      page: page - 1,
      sort: this.state.sort,
    });
  };

  onHeaderClick = (column) => {
    const { formValues } = this.props;

    let sortOrder = this.state.sort ? this.state.sort.split(',')[1] : null;
    sortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    const sort = `${column.key},${sortOrder}`;

    this.getJobSummaries({
      ...formValues,
      sort: sort,
    });
  };

  getCurrentSort = () => {
    let { sort } = this.state;
    if (sort) {
      let sorts = sort.split(',');
      return {
        name: sorts[0],
        order: "DESC",//sorts[1],
      };
    }
    return null;
  };

  render() {
    const { searchTableData, searchCount, loading } = this.state;
    const {
      actions,
      clientSummaries,
      treatmentTypes,
      initialValues,
      treatmentDateFrom,
      treatmentDateTo,
      destinations,
      jobStandards,
      areas,
    } = this.props;
    const onRowClick = this.goToJobPage;

    return (
      <div>
        <Header showLogo>
          <HeaderTitle label={'Search Jobs'} imageUrl={searchIcon} />
        </Header>
        <SearchForm
          actions={actions}
          clientSummaries={clientSummaries}
          treatmentTypes={treatmentTypes}
          initialValues={initialValues}
          treatmentDateFrom={treatmentDateFrom}
          treatmentDateTo={treatmentDateTo}
          onSubmit={this.searchJobs}
          destinations={destinations}
          jobStandards={jobStandards}
          areas={areas}
        />
        {searchCount > 0 && !loading ? (
          <SearchResultsTable
            searchTableData={searchTableData}
            onPageChange={this.onPageChange}
            onHeaderClick={this.onHeaderClick}
            options={{ onRowClick }}
            sort={this.getCurrentSort()}
          />
        ) : null}
      </div>
    );
  }
}

export default SearchPage;
