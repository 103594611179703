import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import cx from 'classnames';

import styles from './Accordion.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
};

export class AccordionItem extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      if (this.el) {
        setTimeout(() => {
          this.el.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    }
  }

  render() {
    const { title, children, open, onToggle, className } = this.props;
    return (
      <div
        ref={(el) => {
          this.el = el;
        }}
      >
        <Card
          className={cx(styles.accordionItem, className, {
            [styles.collapsed]: !open,
          })}
        >
          <CardHeader className={styles.title} onClick={onToggle}>
            <span>{title}</span>
            <i className="material-icons">
              {open ? 'expand_less' : 'expand_more'}
            </i>
          </CardHeader>
          <Collapse isOpen={open}>
            <CardBody>{children}</CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || [],
    };
  }

  handleItemToggle(itemIndex) {
    this.setState((state) => {
      if (state.open.includes(itemIndex)) {
        return {
          open: state.open.filter((i) => i !== itemIndex),
        };
      } else {
        return {
          open: [...state.open, itemIndex],
        };
      }
    });
  }

  render() {
    return (
      <div className={styles.accordion}>
        {React.Children.toArray(this.props.children)
          .filter(Boolean)
          .map((child, index) =>
            React.cloneElement(child, {
              key: index,
              open: this.state.open.includes(index),
              onToggle: this.handleItemToggle.bind(this, index),
              className: cx(this.props.className, child.props.className),
            }),
          )}
      </div>
    );
  }
}

Accordion.propTypes = propTypes;

export default Accordion;
