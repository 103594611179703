import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Form, FormGroup, Button } from 'reactstrap';
import { required } from 'util/Validators';
import RenderField from 'components/fields/RenderField';
import { jobVerificationForm } from 'constants/FormConstants';
import ApprovalField from 'components/fields/ApprovalField';
import NoticeFields from 'components/common/NoticeFields';
import { preventEnterSubmit } from 'actions/formActions';

const propTypes = {
  handleSubmit: PropTypes.func,
  contacts: PropTypes.array,
  onPreview: PropTypes.func,
  certifyJob: PropTypes.bool,
};

const JobCertificationForm = ({
  handleSubmit,
  contacts,
  onPreview,
  certifyJob,
}) => {
  const handlePreviewClick = () => onPreview();
  const certifyJobFailed = certifyJob === false;
  return (
    <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
      <div className="form-row align-items-center">
        <FormGroup className="col-sm-4">
          <Field
            name="jobTransitionActions.certifyJob"
            label="Job Certification"
            approveLabel="Certify"
            component={ApprovalField}
            validate={required}
          />
        </FormGroup>
        {certifyJobFailed ? (
          <FormGroup className="col-sm-4">
            <Field
              name="jobTransitionActions.comment"
              label="Reason"
              component={RenderField}
              validate={certifyJobFailed && required}
            />
          </FormGroup>
        ) : null}
        <FormGroup className="col-12">
          <NoticeFields
            contacts={contacts}
            onPreview={handlePreviewClick}
            buttonText={'Preview Certificate'}
            formFieldKey="newTreatmentCertificate"
          />
        </FormGroup>
        <FormGroup className="col-12">
          <Button color="primary" type="submit">
            Save
          </Button>
        </FormGroup>
      </div>
    </Form>
  );
};

JobCertificationForm.propTypes = propTypes;

export default reduxForm({ form: jobVerificationForm })(JobCertificationForm);
