import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const propTypes = {
  rowData: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

const DeleteRowFormatter = ({ rowData, onDelete }) => {
  const handleClick = event => onDelete(rowData);
  return (
    <Button
      color="danger"
      className="btn-toggle btn-toggle-sm"
      onClick={handleClick}
    >
      <i className="material-icons">clear</i>
    </Button>
  );
};

DeleteRowFormatter.propTypes = propTypes;

export default DeleteRowFormatter;
