import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { Band, ToggleButton } from 'components/layout';
import PreviewEmailModal from 'components/common/PreviewEmailModal';
import JobCertificationForm from './JobCertificationForm';
import { confirmAlert } from 'util/Alerts';
import { getTreatmentCertificates,updateJob } from 'api/operationsApi';
import JobCertificationAlerts from './JobCertificationAlerts';

const propTypes = {
  updateJob: PropTypes.func
};

class JobCertification extends Component {
  state = {
    collapsed: false,
    showPreviewEmail: false,
    treatmentCertificateEmail: ''
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  };

  togglePreviewEmail = () => {
    this.setState(prevState => ({
      showPreviewEmail: !prevState.showPreviewEmail
    }));
  };

  getTreatmentCertificates = () => {
    const { formValues } = this.props;
    return getTreatmentCertificates(formValues).then(response => {
      this.setState({
        treatmentCertificateEmail: response.data,
        showPreviewEmail: true
      });
    });
  };

  submitJob = formValues => {
    const { jobTransitionActions } = formValues;
    const markedAsCertified =
      jobTransitionActions && jobTransitionActions.certifyJob;
    if (markedAsCertified) {
      this.certifyJob(formValues);
    } else {
      this.failJob(formValues);
    }
  };

  failJob = formValues => {
    confirmAlert('Fail', 'Are you sure you want to fail this job?').then(
      reason => reason && this.verifyOrFailJobSuccess(formValues)
    );
  };

  certifyJob = formValues => {
    confirmAlert('Certify', 'Are you sure you want to certify this job?').then(
      confirm => confirm && this.verifyOrFailJobSuccess(formValues)
    );
  };

  verifyOrFailJobSuccess = formValues => {
    const { currentJob, history } = this.props;
    updateJob({
      ...currentJob,
      ...formValues
    }).then(() => history.push('/dashboard/completed'));
  };

  canSubmitJobCertification() {
    const { currentJob,status} = this.props;
  
    var canSubmit = true;
    if(currentJob.stopCredit || currentJob.doNotCertify || status === 'Inactive'){
     return  canSubmit = false
    }
    if(!currentJob.stopCredit && !currentJob.doNotCertify && status !=="Inactive" ){
      canSubmit = true
    }

    return canSubmit;
  }

  render() {
    
    const {
      collapsed,
      showPreviewEmail,
      treatmentCertificateEmail
    } = this.state;
    const { currentJob, contacts, certifyJob, initialValues,status } = this.props;
    initialValues.treatmentResultRecords = currentJob.treatmentResultRecords;
    const canSubmitJobCertification = this.canSubmitJobCertification();
    return (
      <Band dark>
        <div className="form-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="8">
                <h4>Job Certification</h4>
              </Col>
              {canSubmitJobCertification ? (
                <Col xs="4" className="text-right">
                  <ToggleButton
                    color="light"
                    className="ml-3"
                    onClick={this.toggleExpand}
                    active={collapsed}
                    ligature="expand_less"
                  />
                </Col>
              ) : null}
            </Row>
            <Row>
              <JobCertificationAlerts currentJob={currentJob} status={status}/>
            </Row>
            {canSubmitJobCertification ? (
              <Collapse isOpen={!collapsed}>
                <JobCertificationForm
                  onSubmit={this.submitJob}
                  contacts={contacts}
                  onPreview={this.getTreatmentCertificates}
                  certifyJob={certifyJob}
                  initialValues={initialValues}
                />
              </Collapse>
            ) : null}
          </Container>
        </div>
        <PreviewEmailModal
          title="Preview Treatment Certificate"
          isOpen={showPreviewEmail}
          toggle={this.togglePreviewEmail}
          jobId={currentJob.jobId}
          emailContent={treatmentCertificateEmail}
        />
      </Band>
    );
  }
}

JobCertification.propTypes = propTypes;

export default JobCertification;
