import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Container, FormGroup } from 'reactstrap';
import SearchableSelect from 'components/fields/SearchableSelect';
import RenderField from 'components/fields/RenderField';
import CheckboxField from 'components/fields/CheckboxField';
import * as Validators from 'util/Validators';

const propTypes = {
  areas: PropTypes.array,
};

const VesselDetailsSection = () => (
  <section className="form-section">
    <Container>
      <h4>Vessel Details</h4>
      <div className="form-row align-items-center">
        <FormGroup className="col-md-4">
          <Field
            name="displayName"
            label="Name *"
            component={RenderField}
            type="text"
            placeholder="Enter Vessel Name"
            validate={Validators.required}
          />
        </FormGroup>

        <FormGroup className="col-md-4 align-middle">
          <Field name="active" label="Is Active" component={CheckboxField} />
        </FormGroup>
        <FormGroup className="col-md-4 align-middle" />
        <br />
      </div>
    </Container>
  </section>
);

VesselDetailsSection.propTypes = propTypes;

export default VesselDetailsSection;
