import React from 'react';
import PropTypes from 'prop-types';
import DocumentLink from 'components/common/DocumentLink';
import { Button } from 'reactstrap';

const propTypes = {
  attachments: PropTypes.array.isRequired,
  limit: PropTypes.number,
  onShowAll: PropTypes.func
};

const JobDocumentsList = ({ attachments, limit, onShowAll }) => {
  if (!attachments.length) return false;
  const documentsToDisplay = limit ? attachments.slice(0, limit) : attachments;
  const handleClick = () => onShowAll();
  return (
    <ul className="list-inline text-right mb-0">
      {documentsToDisplay.map(document => (
        <li className="list-inline-item my-1" key={document.id}>
          <DocumentLink id={document.id} name={document.name} readOnly />
        </li>
      ))}
      {attachments.length > limit ? (
        <li className="list-inline-item my-1 align-top">
          <Button color="primary" onClick={handleClick}>
            + {attachments.length - limit} more
          </Button>
        </li>
      ) : null}
    </ul>
  );
};

JobDocumentsList.propTypes = propTypes;

export default JobDocumentsList;
