import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JobDetailsFormContainer from './JobDetailsFormContainer';
import { getCategoryDisplayName } from 'util/JobTypeHelper';
import * as operationsApi from 'api/operationsApi';
import Logger from 'util/Logger';
import * as HistoryHelper from 'util/HistoryHelper';
import {
  Header,
  HeaderButtonNav,
  HeaderButton,
  HeaderTitle,
} from 'components/layout';
import { validateJob } from 'util/JobValidationUtils';
import TreatementTypeIds from 'constants/TreatmentTypeIds';

const propTypes = {
  actions: PropTypes.object,
  jobLocations: PropTypes.array,
};

class JobDetailsCreatePage extends Component {
  state = {
    isSubmitting: false,
    stopCredit: false,
    status: '',
  };

  componentDidMount() {
    let { actions } = this.props;
    actions.clearClientDetails();
    actions.clearTreatmentType();
  }

  createJob = (formValues) => {
    let { actions, history } = this.props;
    this.toggleFormSubmit();
    actions
      .createNewJob(formValues)
      .then((data) => {
        this.toggleFormSubmit();
        Logger.success('New Job Created');

        if (
          formValues.newNoticeOfIntendedTreatment &&
          formValues.newNoticeOfIntendedTreatment.sendNotice &&
          formValues.treatmentDetails.treatmentTypeId !==
            TreatementTypeIds.WaterBlasting &&
          formValues.technicianId !== null
        ) {
          Logger.success('Notice of Intended Treatment sent');
        }

        actions.getJobLocations();
        history.push('/');
        return data;
      })/*
      .then((data) => {
        operationsApi.createNewGreentreeJob(data.job);
      });*/
  };

  toggleFormSubmit = () => {
    this.setState((prevState) => ({
      isSubmitting: !prevState.isSubmitting,
    }));
  };

  cancelJob = () => {
    let { history } = this.props;
    HistoryHelper.performSafeGoBack(history);
  };

  getPageHeaderLabel = (jobType) => {
    const jobCategory = getCategoryDisplayName(jobType.category);
    return `New ${jobType.name} ${jobCategory} job sheet`;
  };

  render() {
    const { initialValues, jobType } = this.props;
    initialValues.customerCompleted = false;

    if (
      this.props.isCreditAllowed === 'false'
        ? (initialValues.stopCredit = true)
        : (initialValues.stopCredit = false)
    )
      if (!jobType) {
        return null;
      }

    return (
      <div>
        <Header>
          <HeaderButtonNav>
            <HeaderButton
              color="primary"
              onClick={this.cancelJob}
              ligature="clear"
            />
          </HeaderButtonNav>
          <HeaderTitle
            label={this.getPageHeaderLabel(jobType)}
            imageUrl={jobType.imageUrlFull}
          />
        </Header>
        <JobDetailsFormContainer
          onSubmit={(formValues) => validateJob(formValues, this.createJob)}
          isSubmitting={this.state.isSubmitting}
          initialValues={initialValues}
          jobType={jobType}
        />
      </div>
    );
  }
}

JobDetailsCreatePage.propTypes = propTypes;

export default JobDetailsCreatePage;
