import { connect } from 'react-redux';
import { compose, branch, renderNothing, mapProps } from 'recompose';
import { isExportAndLogs } from 'util/JobTypeHelper';

import TreatmentResultPrint from './TreatmentResultPrint';

function mapStateToProps(state, props) {
  const { currentJob } = state;
  return {
    currentJob
  };
}

export default compose(
  connect(mapStateToProps),
  branch(
    ({ currentJob }) =>
      !isExportAndLogs(currentJob.type) ||
      !currentJob.treatmentResultRecords ||
      !currentJob.treatmentResultRecords.length,
    renderNothing
  ),
  mapProps(({ currentJob, ...props }) => props)
)(TreatmentResultPrint);
