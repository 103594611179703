import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import JobTypeItems from './JobTypeItems';

const propTypes = {
  jobTypes: PropTypes.object.isRequired,
  activeTab: PropTypes.string
};

class JobCreator extends Component {
  state = {
    activeTab: 'import'
  };

  toggleTab = tabId => {
    this.setState({
      activeTab: tabId
    });
  };

  render() {
    const { jobTypes } = this.props;
    const { activeTab } = this.state;
    return (
      <div>
        <Nav tabs className="mb-4">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'import' })}
              onClick={() => {
                this.toggleTab('import');
              }}
            >
              Import
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'export' })}
              onClick={() => {
                this.toggleTab('export');
              }}
            >
              Export
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="import">
            <JobTypeItems jobTypes={jobTypes.import} />
          </TabPane>
          <TabPane tabId="export">
            <JobTypeItems jobTypes={jobTypes.export} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

JobCreator.propTypes = propTypes;

export default JobCreator;
