import React from 'react';
import PropTypes from 'prop-types';
import { Band } from 'components/layout';
import TableList from 'components/table/TableList';
import clientListTableDefinition from 'constants/clientListTableDefinition';
import NoResults from 'components/table/NoResults';

const propTypes = {
  clientTableData: PropTypes.object,
  options: PropTypes.object,
  onPageChange: PropTypes.func,
  onRowDelete: PropTypes.func
};

const ClientListTable = ({
  clientTableData,
  options,
  onPageChange,
  onRowDelete,
  actions
}) => {
  if (!clientTableData) return <NoResults />;

  return (
    <Band dark>
      <TableList
        tableClassName=""
        bodyContainerClass="px-2"
        data={clientTableData.content}
        total={clientTableData.totalElements}
        page={clientTableData.number + 1}
        pageSize={clientListTableDefinition.pageSize}
        definition={clientListTableDefinition.columnDefinitions}
        onPageChange={onPageChange}
        onRowDelete={onRowDelete}
        actions={actions}
        deletable
      />
    </Band>
  );
};

ClientListTable.propTypes = propTypes;

export default ClientListTable;
