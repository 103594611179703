import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default function jobStateSummariesReducer(
  state = initialState.jobStateSummaries,
  action
) {
  if (action.type === types.GET_JOB_STATE_SUMMARIES_SUCCESS) {
    return [...action.jobStateSummaries];
  }
  return state;
}
