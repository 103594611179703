import React, { Component } from 'react';
import PropTypes from 'prop-types';

const hasMatchMedia =
  typeof window !== 'undefined' && typeof window.matchMedia === 'function';

function debounce(func, wait) {
  let timeout;
  return function debouncedFn(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export const withMediaQuery = (propName, mediaQuery) => (WrappedComponent) => {
  class MediaComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        matchesQuery: hasMatchMedia
          ? window.matchMedia(mediaQuery).matches
          : false,
      };
    }

    mediaQueryListener = debounce(({ matches, media }) => {
      if (media === mediaQuery) {
        this.setState({
          matchesQuery: matches,
        });
      }
    });

    componentDidMount() {
      if (hasMatchMedia) {
        const mql = window.matchMedia(mediaQuery);
        mql.addListener(this.mediaQueryListener);
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...{ [propName]: this.state.matchesQuery }}
        />
      );
    }
  }

  MediaComponent.propTypes = {
    ...(WrappedComponent.propTypes || {}),
    [propName]: PropTypes.bool,
  };

  return MediaComponent;
};

export const withIsTouch = withMediaQuery(
  'isTouch',
  '(-moz-touch-enabled: 1), (pointer:coarse)',
);
