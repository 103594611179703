import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Button } from 'reactstrap';

import { jobSignoffForm } from '../../../constants/FormConstants';
import { isFourDigitCode, required } from '../../../util/Validators';
import { preventEnterSubmit } from '../../../actions/formActions';
import { getCompetentTechnicians } from '../../../selectors/technicianSelectors';

import JobSignoffAlerts from './JobSignoffAlerts';

import SearchableSelect from '../../fields/SearchableSelect';
import RenderField from '../../fields/RenderField';
import ApprovalField from '../../fields/ApprovalField';
import { connect } from 'react-redux';

const propTypes = {
  currentJob: PropTypes.object,
  handleSubmit: PropTypes.func,
  technicians: PropTypes.array,
  clientDetails: PropTypes.object,
};

const JobSignoffForm = ({
  currentJob,
  handleSubmit,
  technicians,
  clientDetails,
}) => {
  const competentTechs = getCompetentTechnicians(
    technicians,
    currentJob.treatmentDetails.treatmentTypeId,
    currentJob.treatmentTypeDetails.commodity,
    currentJob.treatmentDetails.standardId,
    currentJob.typeId,
    currentJob.areaId,
  );
  return (
    <Form onSubmit={handleSubmit} onKeyPress={preventEnterSubmit}>
      <fieldset disabled={!currentJob.canEditJob && 'disabled'}>
        <div className="form-row">
          <FormGroup className="col-sm-4">
            <Field
              name="signoffTechnicianId"
              label="Technician"
              component={SearchableSelect}
              placeholder="Please select a technician"
              options={competentTechs}
              // Note: I don't know where is this comming from but this is a functional
              //       component, hence "this" is undefined. Did you mean onTechnicianSelect prop?
              // onSelect={this.onTechnicianSelect}
              valueKey="id"
              labelKey="name"
              clearable
              validate={required}
            />
          </FormGroup>
          <FormGroup className="col-sm-4">
            <Field
              name="technicianCode"
              label="Technician Code"
              component={RenderField}
              type="password"
              autoComplete="off"
              placeholder="Enter a Technician Code"
              validate={isFourDigitCode}
            />
          </FormGroup>
          <FormGroup className="col-sm-4 align-self-center">
            <Field
              name="passed"
              label="Status"
              component={ApprovalField}
              validate={required}
            />
          </FormGroup>
          <FormGroup className="col-4">
            <Button color="primary" type="submit">
              Save
            </Button>
          </FormGroup>
          <FormGroup className="col-8">
            <JobSignoffAlerts currentJob={currentJob} />
          </FormGroup>
        </div>
      </fieldset>
    </Form>
  );
};

JobSignoffForm.propTypes = propTypes;

export default reduxForm({ form: jobSignoffForm })(JobSignoffForm);
